/* global monstecLib */
/* global M */

import AbstractQuackPage from './quackviewpage.js';

export default class ChatQuackPage extends AbstractQuackPage {
    constructor() {
        super();

        this.i18next = monstecLib.i18next;

        this.log = new monstecLib.Log(1);
    }

    initialise(expertId, chatIntegrationSuccessful) {
        const instance = this;

        // obtain the quack's ID from the main section container
        const chatId = $('#quackSingleChatContainer').attr('data-chat-id');
        instance.log.debug('Page will be set up for chat: ', chatId);

        instance.commentSection = new monstecLib.QuackCommentSection();
        instance.commentSection.attachTo('quackCommentSection');
        instance.commentSection.sendComment = function(referenceId, text) {
            return instance.chatService.addChatComment(referenceId, text);
        };

        instance._initRatingControl(instance.authenticator.permCat.RATE_CHAT, chatId);

        instance._initReviewCapabilites(instance.authenticator.permCat.REVIEW_CHATS);

        instance.cookie.setCookieOkCookies();
        instance.register.initSignInForm(false, instance.cookie, false, true, ['#raise-new-question-bottom', '#answer-questions-bottom']);

        instance._tablifyDialogue(); //tablify before linkify

        let asyncLinkifyCaller = async function() {
            await instance._linkifyDialogue($('#quacklist-wrapper .question-hyperlink'));
            await instance._linkifyDialogue($('.quack-comment-section .quack-comment-text'));
        }

        asyncLinkifyCaller().then(() => {
            instance.lazyload.lazyloaderInit();
            instance.utils.initMaterializeInContentBlock()
        });

        instance.initBaseSiteMaterialize();
        instance._initContactExpertLink();
        instance._initQuacksScrollBar();
        instance._showHotOffersInAdBox();
        instance.utils.initShareContent();
        instance.utils.styleShariff();
        instance._showInternalAds();
        instance._adjustCarouselHeight();
        $('[data-i18n]').localize();

        if (!chatIntegrationSuccessful) instance._hideChatInQuacks();
    }

    /**
     * Actually adds the review controls.
     */
    async _addReviewCapabilites() {
        let chatId = $('#quackSingleChatContainer').attr('data-chat-id');
        const instance = this;
        instance.utils.linkifyText('');

        instance.log.debug('Initialising review controls!');
        $('#question').append('<div id="topicReviewControls"></div>');

        const topicEditField = new monstecLib.InPlaceEditField($('#chatTopicLabel'));

        let topicButtonConfig = [
            {
                icon: 'edit',
                showLoader: true,
                func: function() {
                    topicEditField.onCommit = async function() {
                        let topic = topicEditField.getValue();

                        let params = { "id": chatId, "topic": topic};

                        return instance.chatService.updateChat(params)
                            .then(function () {
                                M.toast({html: instance.i18next.t('toasts.title_updated')});
                                return true;
                            }, function(reason) {
                                M.toast({html: instance.i18next.t('toasts.error')});
                                instance.log.error('update failed because ', reason);
                                return false;
                            });
                    };

                    topicEditField.onGetContent = async function() {
                        let chat = await instance.chatService.getChatForEditing(chatId);
                        return chat.topic;
                    };

                    topicEditField.onBeforeRender = async function() {
                        let chat = await instance.chatService.getPublicChatData(chatId);
                        return chat.topic;
                    };

                    return topicEditField.replaceSource();
                }
            }
        ];

        let topicActionButtons = new monstecLib.ActionButtons(topicButtonConfig, 'left');
        topicActionButtons.attachTo('topicReviewControls');

        // a function for sending an update to a message to the platform
        async function updateChatMessage(text, messageId) {
            return instance.chatService.updateChatMessage(messageId, text)
                .then(function () {
                    M.toast({html: instance.i18next.t('toasts.message_updated')});
                    setTimeout(() => {
                        instance.utils.initMaterializeInContentBlock();
                    }, 1000);
                    return true;
                }, function(reason) {
                    M.toast({html: instance.i18next.t('toasts.error')});
                    instance.log.error('update failed because ', reason);
                    return false;
                });
        }

        // a function for sending a delete request for a message to the platform
        async function deleteChatMessage(messageId) {
            var headline = instance.i18next.t('text.message_delete');
            var text = instance.i18next.t('text.message_history_info');
            var optionOne = instance.i18next.t('text.yes_delete');
            var optionTwo = instance.i18next.t('general.no');
            instance.utils.createModal($('body'), headline, text, optionOne, optionTwo, 'deleteMessageModal');

            // call delete function if delete gets confirmed
            $('#deleteMessageModal .modal-close.option-one').on('click', function() {
                $(document).trigger("loader:on");
                instance.chatService.deleteChatMessage(messageId).then(function() {
                    $('#messageBlock' + messageId).remove();
                    $(document).trigger("loader:off");
                    M.toast({html: instance.i18next.t('toasts.message_deleted')});
                }, function (error) {
                    $(document).trigger("loader:off");
                    M.toast({html: instance.i18next.t('toasts.error')});
                    instance.log.error('Deletion failed: ', error);
                });
                $('#deleteMessageModal').remove();
            });

            // reset all conditions if delete gets declined
            $('#deleteMessageModal .modal-close.option-two').on('click', function() {
                $('#deleteMessageModal').remove();
            });
        }

        $('.js-action-buttons').each(function(index, element) {

            if ($(element).children().length !== 0) {
                return false;
            }

            let messageId = $(element).attr('data-message-id');

            const messageEditField = new monstecLib.InPlaceEditField($('#messageTextLabel' + messageId), true, 'window');

            let messageButtonConfig = [
                {
                    icon: 'delete_forever',
                    func: function() {
                        deleteChatMessage(messageId);
                    }
                },
                {
                    icon: 'edit',
                    showLoader: true,
                    func: async function() {
                        messageEditField.onCommit = async function() {
                            let text = messageEditField.getValue();
                            return updateChatMessage(text, messageId);
                        };

                        messageEditField.onBeforeRender = async function() {
                            let message = await instance.chatService.getPublicMessageData(messageId);
                            return message.text.linkify('*');
                        };

                        messageEditField.onGetContent = async function() {
                            let message = await instance.chatService.getChatMessageForEditing(messageId);
                            return message.text;
                        };

                        return messageEditField.replaceSource();
                    }
                }
            ];

            let messageActionButtons = new monstecLib.ActionButtons(messageButtonConfig, 'left');
            messageActionButtons.attachTo('messageActionButtons' + messageId);
            $(element).removeClass('hide');
        });

        $(document).trigger("loader:off");
    }

    /**
     * Initialises the control at the end of the page that enables a user to rate the
     * current quack or change a previous rating.
     */
    _initRatingControl(permission, chatId) {
        const instance = this;
        instance.ratingControl = new monstecLib.RatingControl(permission, true);
        instance.log.debug('Initialising chat rating control.');

        instance.ratingControl.getCurrentRating = async function() {
            try {
                if (!await instance.authenticator.checkPermission(instance.authenticator.permCat.RATE_CHAT)) {
                    instance.log.debug('No permission RATE_CHAT!');
                    return undefined;
                }
            } catch (e) {
                instance.log.debug('Permission check for RATE_CHAT failed!');
                return undefined;
            }

            return await instance.chatService.getChatRating(chatId);
        };

        instance.ratingControl.onStarClicked = async function(value) {
            try {
                let result = await instance.chatService.addChatRating(chatId, value);
                instance.ratingControl.setRating(result.value);
                $('#aggregatedRatingLabel').text(Number(result.aggregatedRating).toFixed(1));
                $('#ratingCountLabel').text(result.ratingCount);
                M.toast({html: instance.i18next.t('toasts.data_updated')});
            } catch (error) {
                M.toast({html: instance.i18next.t('toasts.error')});
                instance.log.error('could not add chat rating: ',error);
            }
        }.bind(this);

        instance.ratingControl.attachTo('quackRatingControl');
    }

    _showInternalAds() {

        const instance = this;

        let tagsArr = $('.quack-tag-block').data('tags');
        let targetElem = $('.dialogue-summary.right-duck').first();

        instance.advertisement.initialise(tagsArr, targetElem);

    }
}
