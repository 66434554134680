/* global monstecLib */
/* global M */

/**
 * Component class representing the settings section of the expert portal.
 */
export default class SettingsSection {
    /**
     * Creates a new settings section.
     */
    constructor() {
        this.i18next = monstecLib.i18next;
        this.utils = monstecLib.produckContext.utils;
        this.cookie = monstecLib.produckContext.cookie;
        this.authenticator = monstecLib.produckContext.authenticator;
        this.identityService = monstecLib.produckContext.identityService;
        this.chatService = monstecLib.produckContext.chatService;
        this.chatClient = monstecLib.produckContext.chatClient;
        this.billingService = monstecLib.produckContext.billingService;

        this.log = new monstecLib.Log(1);
    }

    async attachTo(htmlId) {
        const instance = this;
        instance.settingsContainer = $('#' + htmlId);

        // function to activate chat - set on- /offline status
        $('#os-checkbox-mobile').on('change', function () {
            if($('#os-checkbox-mobile').is(':checked')){
                instance.chatClient.registerOnlineStatus();
                M.toast({html: instance.i18next.t("toasts.chat_activated")});
            } else {
                instance.chatClient.registerOfflineStatus();
                // in case the connection has been lost and the corresponding marker is set, remove it since
                // the user has deliberatly set his status to offline
                $('#os-checkbox-mobile').siblings('.lever').removeClass('inactive');
                M.toast({html: instance.i18next.t("toasts.chat_deactivated")});
            }
        });

        if (await instance.authenticator.checkPermission(instance.authenticator.permCat.DEFINE_CUSTOM_AFFILIATE_PARAMETER)) {
            $('#produckAffiliateSettings').removeClass('hide');
        }
    }

    async initialise(userId) {
        const instance = this;
        try {
            if (!userId) userId = await instance.cookie.getUidFromCookies();
            instance.fillUserSettings(userId);
            instance.initNotificationSwitches();
            instance.initMatFormSelect();
            instance.initEditSettings();
            instance.utils.initLoader();
        } catch (e) {
            instance.log.error('Settings section could not be initialised!', e);
        }
    }

    async initNotificationSwitches() {
        const instance = this;

        try {
            let userSettings = await instance.chatService.getSettings();
            instance.log.debug('User notification settings on load are: ', userSettings);

            let switchIds = [];
            let settingsProperties = [];
            let payloadProperties = [];

            switchIds[0] = 'chatRequestPushNotificationSwitch';
            settingsProperties[0] = 'pushNotificationsChatRequest';
            payloadProperties[0] = 'chatRequest';
            switchIds[1] = 'chatMessagePushNotificationSwitch';
            settingsProperties[1] = 'pushNotificationsChatMessage';
            payloadProperties[1] = 'chatMessage';
            switchIds[2] = 'answerPushNotificationSwitch';
            settingsProperties[2] = 'pushNotificationsAnswer';
            payloadProperties[2] = 'pushAnswer';
            switchIds[3] = 'commentPushNotificationSwitch';
            settingsProperties[3] = 'pushNotificationsComment';
            payloadProperties[3] = 'pushComment';
            switchIds[4] = 'answerEmailNotificationSwitch';
            settingsProperties[4] = 'emailNotificationsAnswer';
            payloadProperties[4] = 'emailAnswer';
            switchIds[5] = 'articleSuggestionEmailNotificationSwitch';
            settingsProperties[5] = 'emailNotificationsArticleSuggestion';
            payloadProperties[5] = 'emailArticleSuggestion';
            switchIds[6] = 'articleAcceptanceEmailNotificationSwitch';
            settingsProperties[6] = 'emailNotificationsArticleAcceptance';
            payloadProperties[6] = 'emailArticleAcceptance';
            switchIds[7] = 'articleRequestAcceptanceEmailNotificationSwitch';
            settingsProperties[7] = 'emailNotificationsArticleRequestAcceptance';
            payloadProperties[7] = 'emailArticleRequestAcceptance';
            switchIds[8] = 'commentEmailNotificationSwitch';
            settingsProperties[8] = 'emailNotificationsComment';
            payloadProperties[8] = 'emailComment';

            for (let i = 0; i < switchIds.length; i++) {
                let settingSwitch = $('#' + switchIds[i]);
                settingSwitch.prop('checked', userSettings[settingsProperties[i]]);

                settingSwitch.on('change', function() {
                    let payload = {};
                    payload[payloadProperties[i]] = $(this).is(':checked');
                    instance.chatService.saveNotificationSettings(payload);
                });
            }
        } catch (error) {
            instance.log.error('FATAL error - could not retrieve user settings. Controls in settings section will not have '
                               + 'their correct states!', error);
        }
    }

    fillUserSettings(uid) {
        var instance = this;

        // get contact data
        instance.identityService.getUserData(uid).then((result) => {
            fillContactElems(result);
        }, (error) => {
            instance.log.error('Could not fill contact settings section.', error);
            $(document).trigger("loader:off");
        });

        //get billing data
        instance.identityService.getBillingInformation().then((result) => {
            fillBillingElems(result);
        }, (error) => {
            instance.log.error('Could not fill billing information settings section.', error);
            $(document).trigger("loader:off");
        });

        //get billing user settings
        instance.billingService.getUserSettings().then((result) => {
            fillBillingUserSettings(result);
        }, (error) => {
            console.log('Error: ', error);
            instance.log.error('Could not fill billing user settings section.', error);
            $(document).trigger("loader:off");
        });

        function fillContactElems(input) {
            if (input.contactEmail) {
                $('#email-absent .absent-text').text(input.contactEmail);
            } else {
                $('#email-absent .absent-text').attr('data-placeholder', instance.i18next.t('text.leave_email'));
            }

            if (input.contactMobile) {
                $('#phone-absent .absent-text').text(input.contactMobile);
            } else {
                $('#phone-absent .absent-text').attr('data-placeholder', instance.i18next.t('text.leave_number'));
            }

            if (input.contactForm) {
                $('#form-absent .absent-text').text(input.contactForm);
            } else {
                $('#form-absent .absent-text').attr('data-placeholder', instance.i18next.t('text.leave_form'));
            }

            var profileAwayModeSelect = $('#profileAwayModeSelect');
            profileAwayModeSelect.val(input.awayMode);
            M.FormSelect.init(profileAwayModeSelect, {'classes': 'away-mode-select'});
        }

        function fillBillingElems(input) {
            $('#iban-value').text(input.iban ? input.iban : '');
            $('#paypal-value').text(input.paypalClientId ? input.paypalClientId : '');
        }

        function fillBillingUserSettings(input) {
            $('#amazonTagLabel').text(input.amazonPartnerTag ? input.amazonPartnerTag : '');
            $('#customAffiliateParamNameLabel').text(input.produckAffiliateReferenceParamName ? input.produckAffiliateReferenceParamName : '');
            $('#customAffiliateParamValueLabel').text(input.produckAffiliateReferenceParamValue ? input.produckAffiliateReferenceParamValue : '');
        }

        let credentials = new monstecLib.Credentials(this.identityService);
        credentials.updatePw(uid);
        credentials.updateNick(uid);
        credentials.matchPasswords();
    }

    //turn spans into input fields and enables to save data
    initEditSettings() {
        var instance = this;

        instance._initAwayModeControls();

        $(document).off().on('click', '.setting-data-wrapper .edit-button', async (ev) => {
            var uid = await instance.cookie.getUidFromCookies(),
                currentTarget = $(ev.currentTarget),
                dataParam = $(ev.currentTarget).data("param"),
                inputField = $('.item-text[data-param="'+dataParam+'"]'),
                dataPlaceholder = inputField.attr("data-placeholder"),
                data = {};

            function errorAlert(error) {
                $(document).trigger("loader:off");
                var responseTxt = 'Keine';

                if (error.response) {
                    responseTxt = error.response.replace(/_/g, " ").replace("]", "").replace("[", "");
                }

                var textBody = 'Daten konnten nicht aktualisiert werden. Fehlermeldung: ' + responseTxt + ' ('+ error.status +')';
                instance.utils.createSimpleAlertModal(textBody);
                console.log('data retrieval failed because ', error.status, error.response);
            }

            if ($('.save-item-button').is(currentTarget)) {
                if (!inputField.hasClass("invalid") && currentTarget.closest('.setting-data-wrapper').is('#payment-credentials')) {
                    data[dataParam] = inputField.val();
                    $(document).trigger("loader:on");
                    instance.identityService.saveBillingInformation(data).then(() => {
                        $(document).trigger("loader:off");
                        M.toast({html: instance.i18next.t('toasts.data_updated')});
                        resetForm(inputField.val());
                    }, (error) => {
                        errorAlert(error);
                    });
                } else if (!inputField.hasClass("invalid") && currentTarget.closest('.setting-data-wrapper').is('#absent-credentials')) {
                    $(document).trigger("loader:on");
                    data = {["id"]: uid, [dataParam]: inputField.val()};

                    return instance.identityService.saveUserData(data).then((result) => {
                        $(document).trigger("loader:off");
                        M.toast({html: instance.i18next.t('toasts.data_updated')});
                        resetForm(inputField.val());
                        console.log('data retrieval succeeded', result);
                    }, (error) => {
                        errorAlert(error);
                    });
                } else if (!inputField.hasClass('invalid') && currentTarget.closest('.setting-data-wrapper').is('#amazonAffiliateSettings')) {
                    $(document).trigger("loader:on");

                    data = {['id']: uid, [dataParam]: inputField.val()};

                    return instance.billingService.saveUserSettings(data).then((result) => {
                        $(document).trigger("loader:off");
                        M.toast({html: instance.i18next.t('toasts.data_updated')});
                        resetForm(inputField.val());
                        console.log('data retrieval succeeded', result);
                    }, (error) => {
                        errorAlert(error);
                    });
                } else if (!inputField.hasClass('invalid') && currentTarget.closest('.setting-data-wrapper').is('#produckAffiliateSettings')) {
                    $(document).trigger('loader:on');
                    data = {['id']: uid, [dataParam]: inputField.val()};

                    if (await instance.authenticator.checkPermission(instance.authenticator.permCat.DEFINE_CUSTOM_AFFILIATE_PARAMETER)) {
                        return instance.billingService.saveUserSettings(data).then((result) => {
                            $(document).trigger('loader:off');
                            M.toast({html: instance.i18next.t('toasts.data_updated')});
                            resetForm(inputField.val());
                            console.log('data retrieval succeeded', result);
                        }, (error) => {
                            errorAlert(error);
                        });
                    }
                }

            } else if ($('.cancel-item-button').is(currentTarget)) {
                var val = currentTarget.closest('.save-options').data('initialval');
                resetForm(val);

            } else if ($('.edit-button').is(currentTarget)) {
                var validateAttr = '';
                if (dataParam === 'iban') {
                    validateAttr = 'minlength="14" maxlength="32" pattern="^[A-Z]{2}[0-9A-Z]*$"';
                } else if (dataParam === 'paypalClientId') {
                    validateAttr = 'pattern="[a-zA-Z0-9_-]+"';
                } else if (dataParam === 'amazonTag' || dataParam === 'customAffiliateParamName' || dataParam === 'customAffiliateParamValue') {
                    validateAttr = 'minlength="3" maxlength="80" pattern="[a-zA-Z0-9-]+"';
                }

                currentTarget.closest('.initial').hide();
                inputField.replaceWith('<input type="text" class="item-text chat-message-field validate" data-param="'+dataParam+'" autocomplete="off" value="'+ inputField.text() +'"' + validateAttr + ' data-placeholder="' + dataPlaceholder + '" placeholder="' + dataPlaceholder + '">');
                currentTarget.closest('.current-content').append('<div class="save-options" data-initialval="'+ inputField.text() +'"><div class="edit-item update"><button type="submit" class="edit-button save-item-button waves-effect waves-light" data-param="'+dataParam+'"><i class="material-icons edit-icon">check</i></button></div><div class="edit-item cancel"><button type="cancel" class="edit-button cancel-item-button waves-effect waves-light" data-param="'+dataParam+'"><i class="material-icons edit-icon">cancel</i></button></div></div>');
            }

            function resetForm(content) {
                currentTarget.closest('.edit-form').find('.initial').show();
                currentTarget.closest('.save-options').remove();
                inputField.replaceWith('<span class="item-text" data-param="' + dataParam + '" data-placeholder="' + dataPlaceholder + '">' + content + '</span>');
            }
        });
    }

    _initAwayModeControls() {
        var instance = this;
        // save select field values whenever they change

        $('#profileAwayModeSelect').on('change', function(e) {
            e.preventDefault();
            $(document).trigger("loader:on");

            let mode = $('#profileAwayModeSelect').val();

            instance.identityService.setAwayMode(mode)
                .then(function() {
                    $(document).trigger("loader:off");
                    M.toast({html:  instance.i18next.t('toasts.absent_mode_saved')});
                }, function(requestObject) {
                    $(document).trigger("loader:off");
                    console.log('data retrieval failed because ', requestObject);
                    var textBody = instance.i18next.t('text.absent_mode_failed');
                    instance.utils.createSimpleAlertModal(textBody);
                });
        });
    }

    async initMatFormSelect() {
        var instance = this;

        var uid = await instance.cookie.getUidFromCookies();
        if(uid){
            instance.chatClient.setUserId(uid);
        }

        function evListenersTimeChange() {
            function collectAndSave () {
                let state = $('#oph-chat-mode').val();
                // all switches are disabled to force status "day" for collection, when state is "on" to prevent saving failure, when no values are given / send
                if (state === "on") { $('.oph-switch').each((i, ele) => { $(ele).prop('checked', true).prop('disabled', true); }); }
                if (state === "off") { $('.oph-switch').each((i, ele) => { $(ele).prop('checked', false).prop('disabled', true); }); }

                let newTimes = instance._collectChatProfileTimes();
                newTimes.state = state;

                instance._saveProfileChatSettings(newTimes);
            }

            $('#oph-save').on('click', () => {
                $(document).trigger("loader:on");
                collectAndSave();
            });

            // save select field values whenever they change
            $('#oph-chat-mode').on('change', (e) => {
                e.preventDefault();
                e.stopImmediatePropagation();
                $(document).trigger("loader:on");
                collectAndSave();
            });

            $('.oph-switch').on('change', (e) => {
                var target = e.currentTarget;
                var timepicker = $(target).parents('.oph-line').find('.timepicker');
                if ($(target).is(':checked')) {
                    timepicker.prop('disabled', true);
                } else {
                    timepicker.prop('disabled', false);
                }
            });
        }

        //call time setting
        instance.identityService.getUserChatTiming(uid).then((timesObj) => {

            //init form select and fill times in input fields
            instance._initMatTimepicker(timesObj);
            evListenersTimeChange();

        }, (error) => {
            if (error.status === 404) {
                // no data found, init fields anyway
                instance._initMatTimepicker();
                evListenersTimeChange();
            } else {
                console.log(error);
            }
        });
    }

        // loads the time settings into the front-end
    _initMatTimepicker(times) {
        var instance = this;
        var dayWrapper =  $('.picker-wrapper');
        var ophMode = $('#profileAwayModeSelect');
        if (times) {
            ophMode.val(times.state);
        }
        M.FormSelect.init(ophMode, {'classes': 'oph-mode-select'});

        dayWrapper.each((index, dayEle) => {
            var day = $(dayEle).data('day');
            var dayArray = [];
            var timepicker = $(dayEle).find('.timepicker');
            var daySwitch = $(dayEle).siblings('.switch').find('.oph-switch')[0];
            var timeInstance;

            if (!times) {
                $(daySwitch).prop('checked', true).prop("disabled", true);
                timepicker.each((index, timeEle) => {
                    $(timeEle).prop("disabled", true);
                });
                timeInstance = M.Timepicker.init(timepicker, { twelveHour: false });
            } else if (times.state === "on") {
                $(daySwitch).prop('checked', true).prop("disabled", true);
                timepicker.each((index, timeEle) => {
                    $(timeEle).prop("disabled", true);
                });
                times[day] !== "day" ? initGivenTimes() : timeInstance = M.Timepicker.init(timepicker, { twelveHour: false });

            } else if (times.state === "off") {
                $(daySwitch).prop('checked', false).prop("disabled", true);
                timepicker.each((index, timeEle) => {
                    $(timeEle).prop("disabled", true);
                });
                initGivenTimes();
            } else if (times.state === "interval" && times[day] === "day") {
                $(daySwitch).prop('checked', true);
                timepicker.each((index, timeEle) => {
                    $(timeEle).prop("disabled", true);
                });
                timeInstance = M.Timepicker.init(timepicker, { twelveHour: false });
            } else if (times.state === "interval" && times[day] === "off") {
                $(daySwitch).prop('checked', false);
                timepicker.each((index, timeEle) => {
                    $(timeEle).prop("disabled", true);
                });
                timeInstance = M.Timepicker.init(timepicker, { twelveHour: false });
            } else if (times.state === "interval") {
                initGivenTimes();
            }

            timepicker.on('change', () => {
                $(daySwitch).prop('checked', false);
                validateTimes(day);
            });

            function formattedTimes(input) {
                var time = input < 10 ? "0" + input : input;
                return time;
            }

            function initGivenTimes() {
                timepicker.each((index, timeEle) => {
                    if (times[day] && times[day].length > 0) {
                        var start = formattedTimes(times[day][0]) +':'+ formattedTimes(times[day][1]);
                        var end = formattedTimes(times[day][2]) +':'+ formattedTimes(times[day][3]);
                        var finalTime;

                        if (index == 0) {
                            finalTime = start;
                        } else if (index == 1) {
                            finalTime = end;
                        }

                        $(timeEle).val(finalTime);

                        timeInstance = M.Timepicker.init(timepicker, {
                            defaultTime: finalTime,
                            twelveHour: false
                        });
                    } else {
                        timeInstance = M.Timepicker.init(timepicker, {
                            twelveHour: false,
                        });
                    }
                });
            }

            function validateTimes(day) {
                var picker = $('.picker-wrapper[data-day="'+day+'"]');
                var startTime = picker.find('.timepicker.start').val();
                var endTime = picker.find('.timepicker.end').val();

                // validation compare start and endtime
                if (startTime.length > 0 && endTime.length > 0 && new Date ('1/1/2020 ' + startTime) >= new Date ('1/1/2020 ' + endTime)) {
                    var textBody = instance.i18next.t('toasts.times_twisted');
                    instance.utils.createSimpleAlertModal(textBody);
                    picker.find('.timepicker').addClass("invalid");
                } else {
                    picker.find('.timepicker').removeClass("invalid");

                }
            }
        });
    }

    _collectChatProfileTimes() {
        var instance = this;

        // collect times for transmission to server
        // As soon https://github.com/Dogfalo/materialize/issues/6317 is fixed in MaterializeCSS the
        // following can be replaced something that directly uses the data array of the chips element
        var timesArr = {};
        var dayWrapper =  $('.picker-wrapper');

        dayWrapper.each((index, dayEle) => {

            var day = $(dayEle).data('day');
            var dayArray = [];
            var timepicker = $(dayEle).find('.timepicker');
            var daySwitch = $(dayEle).siblings('.switch').find('.oph-switch')[0];

            if ($(daySwitch).is(':checked')) {
                timesArr[day] = 'day';
            } else {
                timepicker.each((index, timeEle) => {
                    var time = $(timeEle).val();
                    if (!time) return false;
                    if (time && time.length > 0) {

                        var hour = time.split(':')[0];
                        var minute = time.split(':')[1];

                        dayArray.push(parseInt(hour),parseInt(minute));
                    }
                });
                timesArr[day] = dayArray;
            }
        });

        return timesArr;
    }

    _saveProfileChatSettings(timesObj) {
        var instance = this;
        instance.identityService.saveTimingConfiguration(timesObj)
            .then(function() {
                var timepicker = $('.oph-switch:not(:checkbox:checked)').parents('.oph-line').find('.timepicker');
                if (timesObj.state === "off" || timesObj.state === "on") {
                    $('.timepicker').each((i, ele) => {$(ele).prop('disabled', true);});
                } else {
                    $(timepicker).each((i, ele) => {$(ele).prop('disabled', false);});
                    $('.oph-switch').each((i, ele) => {$(ele).prop('disabled', false);});
                }
                $(document).trigger("loader:off");
                M.toast({html: instance.i18next.t('toasts.data_updated')});
            }, function(requestObject) {
                $(document).trigger("loader:off");
                instance.log.error('_saveProfileChatSettings - saving timing configuration failed', requestObject);
                var textBody = instance.i18next.t('toasts.data_update_failed');
                instance.utils.createSimpleAlertModal(textBody);
            });
    }
}
