/* global monstecLib */
/* global M */

import i18next from 'i18next';

export default class PortalSupport {
    constructor(externalAuthenticator, externalCookie, externalChatSupport, externalProfileHandler) {
        this.authenticator = externalAuthenticator;
        this.cookie = externalCookie;
        this.chatSupport = externalChatSupport;
        this.profiledatahandler = externalProfileHandler;
    }

    async saveSessLocation(target) {
        var instance = this;

        var produckCookie = await instance.cookie.getRightStorage('produck');
        var hash = (target && target.location) ? target.location : $('.panel_content.active').attr('id');
        var tab = (target && target.targetTab) ? target.targetTab : ($('.panel_content.active').has('.tabs') && $('.panel_content.active .tab a.active').parent('.tab').is('[data-val]') ? $('.panel_content.active .tab a.active').parent('.tab').data('val') : undefined );

        if (produckCookie) {
            produckCookie.location = hash;
            produckCookie.targetTab = tab;
        }
        else {
            produckCookie = {'location': hash, 'targetTab': tab };
        }

        instance.cookie.saveToRightStorage('produck', produckCookie, 30);

    }

    /**
* Init a Nav Bar
* @param {*} userData holds information about click target and is just required when user is already logged in, otherwise register methods take over navigation, however targetTab is stored
* @param {*} questionLinks is an array of elements, were event listener set for. The html elements optionally have a data-target-tab parameter to allow deep-linking - is saved in cookies and detected by deepLinkRef method in xpert/user.js
*/
    initLinkNavigation (deepLinks, userData) {
        var instance = this;
        var targetSite = false;
        var targetLocation = undefined;
        var targetTab = undefined;

        if (userData) {

            var userRole = (userData.userRole === "EXPERT" || userData.userRole === "PROVIDER") ? 'xpert' : 'user';
            targetSite = '/'+userRole+'.html';
        }

        function setTargetTab(target) {
            if (target.is('[data-target-tab]')) {
                targetTab = target.data('target-tab');
            }
        }

        function setTargetLocation(target) {
            if (target.is('[data-target-hash]')) {
                targetLocation = target.data('target-hash');
            }
        }

        deepLinks.forEach(function(target) {

            $(target).on('click', async () => {

                setTargetTab($(target));
                setTargetLocation($(target));
                await instance.saveSessLocation({'location': targetLocation, 'targetTab': targetTab});
                if (targetSite) window.location.href = targetSite;
            });        
        });        
    }


    refreshSite(trigger) {
        trigger.on('click', function() {
            $(document).trigger("loader:on");
            location.reload(); // session location is automatically saved through onbeforeunload event listener
        });
    }


    setLanguage() {
        var instance = this;

        if(i18next.language === "de" || i18next.language.indexOf('de') > -1) {
            $('#lng-switch').prop('checked', true);
            instance.cookie.saveToRightStorage('i18next', i18next.language, 7300);
        }
        else if(i18next.language === "en" || i18next.language.indexOf('en') > -1) {
            $('#lng-switch').prop('checked', false);
            instance.cookie.saveToRightStorage('i18next', i18next.language, 7300);
        }

        //switch language
        $('#lng-switch').on('change', function () {

            if($(this).is(':checked')) {
                i18next.changeLanguage("de", function() {$("body").localize();});
                instance.cookie.saveToRightStorage('i18next', i18next.language, 7300);
            } else {
                i18next.changeLanguage("en", function() {$("body").localize();});
                instance.cookie.saveToRightStorage('i18next', i18next.language, 7300);
            }

            window.location.reload(true);
        });
    }

    initSideNav() {
        // mobile Menu toggle
        var instance = this;
        var elems = document.querySelectorAll('.collapsible');
        var instances = M.Collapsible.init(elems);
        $('#slide-out').sidenav({draggable: true}); // Smartphone menu open draggable //Closes side-nav on <a> clicks, useful for Angular/Meteor
        $('#right_menu').sidenav({draggable: true, edge: "right"});

        //customize header
        $(document).on('click', '.user-view #user-name', function () {
            instance.slideInChatContainer($(".user-view"));
            instance.profiledatahandler.displayUserProfile();
        });
    }

    /*
    * Animate container sliding into view from top to bottom
    * @param {targetContainerMenuItem} holds the data-href information
    * @param {chatId} to open a specific chatTab
    * needs a click event to fire
    */
    slideInChatContainer(targetContainerMenuItem, chatId) {
        jQuery(function ($) {
            targetContainerMenuItem.click(function () {
                let $target = $($(this).attr('data-href'));

                //this action is just for the menu
                if ($(this).hasClass('thumb-nav__item')){
                    // Hightlight Menu Point in Menu Bar
                    targetContainerMenuItem.parent().css({ "border": "none" });
                    $(this).parent().css({ "border-left": "3px solid rgba(43, 187, 173, 1)" });
                }

                // hide remaining container
                if (!$target.hasClass('active')) {
                    let $other = $target.siblings('.active');
                    $other.each((index, currentSibling) => $(currentSibling).removeClass('active'));
                    // top down sliding effect
                    $target.addClass('active').css({top: -$target.width()}).animate({ top: 0 }, 500);
                }

                let chatTabWrapper = $('#chat_tab_wrapper');
                if (chatId !== undefined && isFinite(chatId) && chatTabWrapper.find('.tab').length > 1) {
                    let correspondingTab = chatTabWrapper.find(".tab a[data-chatid=" + chatId + "]");

                    if (correspondingTab[0]) {
                        correspondingTab[0].click();
                        let chatlog = $('.cl.active .chatlog');
                        chatlog.animate({scrollTop: chatlog.scrollHeight}, 10);
                    }
                }
            });
        });
    }

}
