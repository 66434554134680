/*
 * Container class for conversational search procedure.
 */
/* global monstecLib */

export default class InternalAdvertisement {
    constructor(externalUtils) {
        this.utils = externalUtils;
        this.ads = adsObj; //deals.json
        this.adsCache;
        this.i18next = monstecLib.i18next;
    }

    initialise (tagsArray, targetElem) {
        let instance = this;

        if (instance.utils.checkExpirationDate(instance.ads[0].expirationDateCampaign)) {
            instance.buildProductCarousel(tagsArray, targetElem);
        }

        if (instance.utils.checkExpirationDate(instance.ads[0].expirationDatePopupCampaign)) {
            instance.buildPopupCompain(60000);
        }
    }
          
    buildProductCarousel(tagsArray, targetPosition) {
        let instance = this;
        let adsCollection = '';
        let dealsArrayLength = 0;
        let adsItem = Object.entries(instance.ads[0].adsItems);
        let contextRelSortedAdsArray = instance._getContextRelatedAds(tagsArray);
        let adsArray = contextRelSortedAdsArray && contextRelSortedAdsArray.length > 0 ? contextRelSortedAdsArray : Object.keys(adsItem).length ? adsItem.map(element => element[1]) : false;

        if (adsArray) {
            for (const [index, el] of adsArray.entries()) {
               
                let array = adsArray[index];

                if (instance.utils.checkExpirationDate(array.expirationDate)) {
                   
                       let tag = (array.tag && array.tag.length > 0) ? array.tag : '';                                      
                       let img = `<div class="carousel-img-wrapper"><img class="rel-item-image lazyload" loading="lazy" alt="${array.title}" src="${array.img}" datasrc="${array.img}" /></div>`;
                       let shortname = `<span class="rel-item-name" >${array.title.substring(0, 75)}...</span>`;
                       let priceEle = (array.price && array.price.length > 0) ? `<span class="rel-item-price" >${array.price} &euro;</span>` : '';
                       let href = `<a href="${array.link}" data-tags="${array.tag}" class="carousel-item darken-4" target="_blank" rel="noopener nofollow">${img}${shortname}${priceEle}</a>`;
                        
                       adsCollection += href;
                       dealsArrayLength++;

                       if (dealsArrayLength >= 10) break;               
                }
            }
        }

        if (adsCollection.length) {

            function getRandomCronJobTime() {
                const today = new Date().getDate();
                const randomMinutes = Math.floor(Math.random() * 10) + 10;
                
                return `08:${randomMinutes}`;
            }

            let itemNo = dealsArrayLength > 5 ? 5 : dealsArrayLength;
            let time = getRandomCronJobTime(); 

            let adsItem = '<div id="carousel-block" class="w100">'
            + '<div id="main-carousel" class="carousel product-carousel">'
            +  adsCollection
            + '<div class="adnote">'+ instance.i18next.t("deals.ads") +'</div></div></div>'
            + '<div class="full-width-center-content-row fs-12" style="margin: 5px 10px 0 10px" ><span class="darkco-grey">'+ instance.i18next.t("deals.state") +': '+time+' (UTC). <a class="darkco-grey" href="#affiliate-note">'+ instance.i18next.t("deals.further_info") +'*</a></span></div>'
            + '<a class="flex-box center-all btn whiteco" target="_blank" href="/deals.html" style="margin:5px 0px 10px 0">' + instance.i18next.t("deals.all_deals") + '&nbsp;&nbsp;<span class="fas fa-hand-pointer lightco"></span></a>';            
        
            targetPosition.after(adsItem);

            // carousel to show related products
            let elems = document.querySelectorAll('.carousel');

            //links just work with fullWidth true  
            M.Carousel.init(elems, { duration: 300, dist: 0, fullWidth: true, shift: 50, padding: 50, numVisible: itemNo, noWrap: false });
            
            let autoplay = true;

            setInterval(function() { 
                if(autoplay && dealsArrayLength > 3) $('#main-carousel').carousel('next');
            }, 4500);

            $('#main-carousel').hover(function(){ 
                autoplay = false;
            }, function(){ 
                autoplay = true; 
            });

        }
    }

    _getContextRelatedAds(tagsArray) {

        const instance = this;

        let adsItem = Object.entries(instance.ads[0].adsItems);
        let quacksTags;
        
        if (instance.adsCache) {
            return instance.adsCache;            
        } else {

            if (tagsArray) {
                quacksTags = tagsArray.slice(1,-1).split(', ');
            } else {
                return false;
            }

            let adsItemRed = adsItem.map(element => element[1]);        

            for (let el of adsItemRed) {

                el['matches'] = 0;

                for (let tagString of quacksTags) {

                    if (el.tag.toLowerCase().includes(tagString.toLowerCase())) el.matches ++;
                }
            }

            let sortedArray =  adsItemRed.sort((a, b) => b.matches - a.matches);

            instance.adsCache = sortedArray;

            return sortedArray;
        }
    }

    buildPopupCompain(timeout) {
        let instance = this;
        let cookie = new monstecLib.Cookie();

        // save user's action regarding campaign  
        let campCookie = cookie.getCookie('okcamp');
        //just save this if cookie is not yet existing and the cookie confirmation is actually on the page

        if (!campCookie) {
            let textBody = "</p>" + "<h4 class='tealco' style='text-align: center'>ProDuck Tester Prämie</h4><br/><p style='text-align: center'>Schreibe jetzt deinen ersten Produktreview auf ProDuck und sichere dir einen <b>Amazon <i class='fab fa-amazon darkco'>&nbsp;</i> Gutschein im Wert von 100 Euro</b>.</p><br/><a href='/tester-praemie.html' class='btn flex prdk-btn' target='_blank'>Zur Teilnahme</a>" + "<p>";

            function setCampCookie() {
                let cookieConf = { 'campSeen': 'true' };
                cookie.setCookie('okcamp', cookieConf, 30);
            }

            setTimeout (() => {
                instance.utils.createSimpleAlertModal(textBody, "campaign-modal");
                setCampCookie();
            }, timeout);
        }
    }
}
