/* global monstecLib */
import i18next from 'i18next';

export default class PredefinedText {
    constructor(externalChatClient, identityServiceAccess, externalChatSupport, externalUtils, externalProductHandler) {

        // create and configure chatclient
        this.chatclient = externalChatClient;
        this.identityservice = identityServiceAccess;
        this.chatsupport = externalChatSupport;
        this.utils = externalUtils;
        this.producthandler = externalProductHandler;
    }

    addExpertChatRequestInfo(chatlog, topic, product, link) {
        const chatHeader = chatlog.siblings('.chat-header');
        const requestInfoContainer = chatHeader.children('.chat-request-info');
        const slideDownLink = chatHeader.find('.chat_intro .slide_down_link_wrapper a');

        requestInfoContainer.append($('<p>' + i18next.t('text.question') + ' - ' + topic + '</p>'));

        let productReference;
        if (product && link) {
            productReference = '<p>'  + i18next.t('text.product') + ' &dash; <a href="' + link + '" target="_blank">' + product + '</a></p>';
        } else if (link) {
            productReference = '<p><a href="' + link + '" target="_blank">' + i18next.t('text.product_ref') + '</a></p>';
        } else if (product) {
            productReference = '<p>' + i18next.t('text.product') + ' &dash; ' + product + '</p>';
        }

        if (productReference) {
            requestInfoContainer.append(productReference);
        }

        const slideUpLinkWrapper = $('<div class="slide_up_link_wrapper"></div>');
        const slideUpLink = $('<a class="slide_up_link"><i class="material-icons">keyboard_arrow_up<i/></a>');
        slideUpLinkWrapper.append(slideUpLink);
        requestInfoContainer.append(slideUpLinkWrapper);

        slideUpLink.click(function() {
            requestInfoContainer.slideToggle(400, function() {
                slideDownLink.removeClass('hide');
            });
        });

        slideDownLink.click(function() {
            requestInfoContainer.slideToggle(400, function() {
                slideDownLink.addClass('hide');
            });
        });
    }

    autoTextEvent(text, chatlog, partner, time) {
        
        let messageContainer = document.createElement('div');
        let avatarContainer = document.createElement('div');
        let textContainer = document.createElement('p');
        textContainer.className = 'chat-message';
        textContainer.innerHTML = text;

        var authorAllocation = partner;
        if (authorAllocation && authorAllocation.indexOf('robot-msg') > -1) {
            messageContainer.className = "chat user " + authorAllocation;
            if(time) {
                messageContainer.title = time;
            }
            const img = document.createElement('img');
            img.setAttribute("src", "/assets/img/icons/chatbot.png");
            img.className = "chatter-portrait";
            avatarContainer.className = "user-photo";
            avatarContainer.appendChild(img);
            messageContainer.appendChild(avatarContainer);
        } else {
            messageContainer.className = "chat xpert";
        }

        // add avatar and text to message container
        messageContainer.appendChild(textContainer);
        chatlog[0].appendChild(messageContainer);
        chatlog.animate({scrollTop: chatlog[0].scrollHeight}, 50);

        // save value to DB via API. hardcoded values for now
    }

    sendButton(btnid, btntext, chatlogBubble) {
        //Triggering Chat Closing through xpert
        var addChatBtnDiv = document.createElement('div');
        var addChatBtn = document.createElement('button');
        addChatBtnDiv.className = 'addChatBtnDiv';
        addChatBtn.id = btnid;
        addChatBtn.innerHTML = btntext;
        addChatBtn.type = 'button';
        addChatBtn.className = 'btn waves-effect waves-light'
        addChatBtn.tabIndex = -1;
        chatlogBubble[0].appendChild(addChatBtnDiv);


        addChatBtnDiv.appendChild(addChatBtn);
    }

    addPreloader() {
        var addNotification = document.createElement('span');
        addNotification.className = 'progress-notification';
        addNotification.innerHTML = '...Waiting...';
        $('.cl.active .chatlog.conversation-card')[0].prepend(addNotification);
    }

    /*
    welcomeFunction1() {
        var chatlog = $(".cl.active .chatlog.conversation-card");

        setTimeout(function () {
            var text1 = 'Hallo und herzlich willkommen. Geben Sie nachfolgend Ihre Fragestellung oder Ihr Interessengebiet ein und bestätigen Sie anschließend mit Click auf den Button \"Experte suchen\"'; //Optional could ask to confirm with "#Submit" and check for this
            var btnsubmittext = 'Experte suchen';
            var btnsubmitid = 'requestButton'
            this.autoTextEvent(text1, chatlog, 'robot-msg');
            this.sendButton(btnsubmitid, btnsubmittext, chatlog);
        }.bind(this), 3000);
    }*/

    welcomeFunction2(text) {

        var chatlog = $(".cl.active .chatlog.conversation-card");

        setTimeout(function () {            
            var text2 = i18next.t('html.request_submitted', { topic: text });
            this.addPreloader();
            this.autoTextEvent(text2, chatlog, 'robot-msg');
        }.bind(this), 1000);
    }

    welcomeFunction3() {
        var chatlog = $(".cl.active .chatlog.conversation-card");

        setTimeout(function () {
            var text = i18next.t('text.chat_usage_prompt');
            this.autoTextEvent(text, chatlog, 'robot-msg');
            monstecLib.produckContext.iframeDispatcher.sendInfoAboutMessage();
        }.bind(this), 5000);
    }

    welcomeFunction4() {
        var chatlog = $(".cl.active .chatlog.conversation-card");

        setTimeout(function () {
            var text = i18next.t('html.chat_name_request');
            this.autoTextEvent(text, chatlog, 'robot-msg name-msg');
            var btnsubmitid = 'sendButtonInChat';
            var btnsubmittext = 'Senden <i class=material-icons>send</i>';
            this.sendButton(btnsubmitid, btnsubmittext, chatlog.find('.name-msg .chat-message'));
            this.submitNameInChat();
            monstecLib.produckContext.iframeDispatcher.sendInfoAboutMessage();
        }.bind(this), 0);
    }
        
    welcomeFunction5() {
        var chatlog = $(".cl.active .chatlog.conversation-card");

        setTimeout(function () {
            var text = i18next.t('text.chat_usage_prompt2');
            this.autoTextEvent(text, chatlog, 'robot-msg');
            monstecLib.produckContext.iframeDispatcher.sendInfoAboutMessage();
        }.bind(this), 180000);
    }

    generousAutoText(text) {
        var chatlog = $(".cl.active .chatlog.conversation-card");
        setTimeout(function () {          
            this.autoTextEvent(text, chatlog, 'robot-msg');
        }.bind(this), 1500);
    }

    submitNameInChat() {
        var instance = this;
        $('#sendButtonInChat').click(function() {
            var name = $('.name-msg input').val().trim(),
                nameMsg = i18next.t('text.chat_partner_info') + name,
                nameOwnChat = " " + i18next.t('text.thank_you') + name + "!";

            if (name.length > 1) {
                instance.chatclient.sendMessage(nameMsg);
                this.remove();
                $('.name-msg input').replaceWith("<span><b>" + nameOwnChat + "</b></span>");
            }
        });
    }

    shortCutBtn() {
        var instance = this;
        var selfintro = 'Hallo, gerne helfe ich Ihnen weiter.';
        var selfintro2 = 'Hallo, wie kann ich Ihnen helfen?';
        var textHint1 = 'Das ist nur eine persönliche Empfehlung basierend auf meinen Erfahrungen. Ich empfehle jedoch, sich auch andere Quellen anzuschauen.';
        var textThanks = 'Danke für Ihre Anfrage. Ich hoffe Sie bald wieder bei uns begrüßen zu dürfen.';
        var textClosing = 'Beenden Sie den Chat mit einem Klick auf folgenden Button';
        var textSharing = 'Empfehlen Sie Produck.de gerne Ihrem sozialen Netzwerk. Mit einem Klick auf folgenden Button werden Ihnen die Buttons zum Teilen angezeigt und der Chat wird beendet.';
        var toCustServ = 'Bitte wenden Sie sich mit dieser Frage direkt an den Kundenservice.';
        var pleaseWait = 'Bitte erlauben Sie mir einen Moment, während ich es für Sie überprüfe.';
        var thxForWaiting = 'Vielen Dank, dass Sie gewartet haben.';
        var furherQuestion = 'Gibt es noch etwas, wobei ich Ihnen behilflich sein kann?';
        var btnTitles = {'welcome_btn': selfintro, 'welcome_btn2': selfintro2, 'hint1_btn': textHint1, 'thanks_btn': textThanks, 'sharing_btn': textSharing, 'closing_btn': textClosing, 'service_btn': toCustServ, 'wait_btn': pleaseWait, 'wait_over_btn': thxForWaiting, 'further_question_btn': furherQuestion};

        for (var key in btnTitles) {
            if (btnTitles.hasOwnProperty(key)) {
                var tooltipEle = $('#' + key).siblings('div');
                tooltipEle.attr('data-tooltip-content', btnTitles[key]).attr('data-position', 'left');

                var options = {
                    margin: 0,
                    transitionMovement: 0
                }

                M.Tooltip.init(tooltipEle, instance.utils.addToolTipWithArrow(tooltipEle, options));
            }
        }

        //Definition of  predefined text elements
        $('#welcome_btn').on('click', function () {
            instance.chatsupport.sendTextMessageSelf(selfintro);
        });

        //Definition of  predefined text elements
        $('#welcome_btn2').on('click', function () {
            instance.chatsupport.sendTextMessageSelf(selfintro2);
        });

        //$(document).on('click', '#account_btn', function () {
        //    var payText = 'Du kannst mir gerne ein Trinkgeld senden. Hier ist mein Paypal.Me Link monstec@paypal.de';
        //    instance.autoTextEvent(payText, $('.cl.active .chatlog.conversation-card'));
        //    instance.chatclient.sendMessage(payText);
        //});

        $('#hint1_btn').on('click', function () {
            instance.chatsupport.sendTextMessageSelf(textHint1);
        });

        $('#thanks_btn').on('click', function () {
            instance.chatsupport.sendTextMessageSelf(textThanks);
        }); 

        $('#closing_btn').on('click', function () {
            var btnclosetext = '#Chat beenden'
            var btncloseid = 'chatClosingBtn';

            instance.chatsupport.sendTextMessageSelf(textClosing);

            setTimeout(function() {
                instance.chatclient.sendMessage(btnclosetext);            
            }, 500); //delay to make sure, message is attached to "correct last" message
        });

        $('#sharing_btn').on('click', function () {
            var btnclosetext = '#Teilen'
            var btncloseid = 'sharingBtn';

            instance.chatsupport.sendTextMessageSelf(textSharing);

            setTimeout(function() {
                instance.chatclient.sendMessage(btnclosetext);
            }, 500); //delay to make sure, message is attached to "correct last" message
        });

        //Definition of  predefined text elements
        $('#service_btn').on('click', function () {

            let providerId = $('.cl.active').data('providerid');

            if (providerId) {
                instance.identityservice.getPublicUserData(providerId).then((result) => {

                    let text = (result && (result.contactForm || result.contactEmail)) ? (result.contactForm ? toCustServ + ' Link: ' + result.contactForm : toCustServ + ' Link: ' + result.contactEmail) : toCustServ;

                    instance.chatsupport.sendTextMessageSelf(text);
                }).catch(function(e) {

                    let text = toCustServ;
                    instance.chatsupport.sendTextMessageSelf(text);

                });
            } else {
                instance.chatsupport.sendTextMessageSelf(toCustServ);
            };
        });

        //Definition of  predefined text elements
        $('#wait_btn').on('click', function () {
            instance.chatsupport.sendTextMessageSelf(pleaseWait);
        });

        //Definition of  predefined text elements
        $('#wait_over_btn').on('click', function () {
            instance.chatsupport.sendTextMessageSelf(thxForWaiting);
        });

        //Definition of  predefined text elements
        $('#further_question_btn').on('click', function () {
            instance.chatsupport.sendTextMessageSelf(furherQuestion);
        });

    }
}
