/* global monstecLib */
/* global M */

export default class AbstractQuackPage {
    constructor() {
        this.i18next = monstecLib.i18next;
        this.cookie = new monstecLib.Cookie();
        this.utils = new monstecLib.Utils();
        this.advertisement = new monstecLib.InternalAdvertisement(this.utils);
        this.authenticator = new monstecLib.Authenticator(this.cookie);
        this.chatService = new monstecLib.ChatServiceAccess();
        this.catalogueService = new monstecLib.CatalogueServiceAccess();
        this.register = new monstecLib.Register(this.authenticator);
        this.lazyload = new monstecLib.LazyLoad();

        let context = monstecLib.produckContext;
        context.setBean('utils', this.utils);
        context.setBean('advertisement', this.advertisement);
        context.setBean('authenticator', this.authenticator);
        context.setBean('chatService', this.chatService);
        context.setBean('catalogueService', this.catalogueService);
        context.setBean('cookie', this.cookie);
        context.setBean('register', this.register);

        monstecLib.produckContext.initialiseRegisteredBeans();

        this.utils.initLoader();

        this.cookie.saveLastVisitedPage(window.location.href);

        this.log = new monstecLib.Log(1);
    }

    //convert url in quack-overview in clickable links
    async _linkifyDialogue(textElem) {
        let instance = this,
            linkPatterns = instance.utils.linkifyText(),
            linkFound = false;

        textElem.each(async function () {
            let htmlTextOfElement = $(this).html();

            // just replace text if containing urlPattern
            if (htmlTextOfElement.match(linkPatterns.uglyLinksPattern) || htmlTextOfElement.match(linkPatterns.urlPattern) || htmlTextOfElement.match(linkPatterns.pseudoUrlPattern) || htmlTextOfElement.match(linkPatterns.emailAddressPattern) || htmlTextOfElement.match(linkPatterns.asinPatterns) || htmlTextOfElement.match(linkPatterns.tableOfContentPattern) || htmlTextOfElement.match(linkPatterns.inTextDealsPattern)) {
                let note = '*',
                    userId = $(this).closest('[data-author-id]').data('author-id'),
                    linkedText = await htmlTextOfElement.linkify(note, userId);
                if (htmlTextOfElement.match(linkPatterns.tableOfContentPattern)) {
                    let headlinesElems = $('#quacklist-wrapper .question-hyperlink h2:not(#toc-headline)');
                    linkedText = linkedText.addTableOfContent(headlinesElems);                        
                }

                if (htmlTextOfElement.match(linkPatterns.inTextDealsPattern)) {

                    let tagsArr = $('.quack-tag-block').data('tags');
                    let matchedProductsArr = instance.advertisement._getContextRelatedAds(tagsArr);

                    if (matchedProductsArr) {
                        matchedProductsArr = matchedProductsArr.filter(element => element.matches > 0 && instance.utils.checkExpirationDate(element.expirationDate));
                        linkedText = linkedText.addDealBox(matchedProductsArr);                                       
                    } else {
                        linkedText = linkedText.addDealBox(false);
                    }
                }
                              
                $(this).html(linkedText);

                linkFound = true;
                setAffiliateNote();
            }
        });

            function setAffiliateNote () {

            if (linkFound && $('#affiliate-note').length === 0) {
                let affiliateNote = "<p id='affiliate-note'>* Bitte beachten Sie, dass Links auf dieser Seite Links zu Werbepartnern sein k&ouml;nnen. F&uuml;r K&auml;ufe, die &uuml;ber einen dieser Links zustande kommen, erhalten wir (falls sie die Marketingcookies des Werbepartners annehmen) Provision. Ihnen entstehen dadurch keine zus&auml;tzlichen Kosten. Sie unterstützen jedoch unseren Service (<a href='/docu/general.html#affiliate-links' rel='nofollow' target='_blank'>mehr erfahren</a>). Preise, Lieferbedingungen und Verf&uuml;gbarkeiten entsprechen dem angegebenen Stand (Datum/Uhrzeit) und können sich jederzeit ändern. Angaben auf unserer Seite weichen daher ggf. von denen der Partnerseiten ab. Für den Kauf eines betreffenden Produkts gelten die Angaben zu Preis und Verfügbarkeit, die zum Kaufzeitpunkt auf der/den maßgeblichen Website(s) (z.B. Amazon) angezeigt werden. Bestimmte Inhalte, die auf dieser Website angezeigt werden, stammen von Amazon. Diese Inhalte werden‚ 'wie besehen' bereitgestellt und können jederzeit geändert oder entfernt werden.</p>";
                $('#quacklist-wrapper').append(affiliateNote);
            }
        }     
    }   

    // add classes to set a beautiful table style
    _tablifyDialogue() {
        let tableElem = $('#quacklist-wrapper .question-hyperlink table');
        // materialize parameters 'striped', 'highlight', 'responsive-table'

        tableElem.each((i, table) => {            

            let tableElemColumns = $(table).find('tbody tr:first').children();

            if (tableElemColumns.length > 3) {

                $(table).addClass('striped highlight responsive-table');

                let tBodyCells = $(table).find('tbody tr').children();
                let theadCells = $(table).find('thead tr').children();
                let heightsArr = theadCells.map(function () { return $(this).innerHeight(); }).get();
                
                let theadCellHeight = Math.max.apply(null, heightsArr);
              
                if ($(table).find('tbody')[0].scrollWidth > Math.ceil($(table).width())) {
                    
                    $(table).addClass('scrollable beauty-scroll');
                    tBodyCells.innerHeight(theadCellHeight);
                    theadCells.innerHeight(theadCellHeight);
                } else {
                    $(table).removeClass('scrollable');
                }
            } else {
                $(table).addClass('striped highlight');
            }
        });
    }

    _initQuacksScrollBar() {
        // Parameter targetClass is string defining css-class or html-id
        function assignScrollFunction(element, targetClass, offsetTolerance) {
            $(element).click(function (e) {
                e.preventDefault();
                $('html, body')
                    .animate({
                        scrollTop: $(targetClass).offset().top - offsetTolerance
                    }, 'slow', 'swing', function () { });
            });
        }

        assignScrollFunction('.desk .scroll-to-question', '#quack-info-bar', 56);
        assignScrollFunction('.desk .scroll-to-quacks', '#quacks-external-box', 56);
        assignScrollFunction('.desk .scroll-to-carousel', '#carousel-heading', 56);
        assignScrollFunction('.desk .scroll-to-page-footer', '#page-footer', 56);
        assignScrollFunction('.mobile .scroll-to-question', '#quack-info-bar', 90);
        assignScrollFunction('.mobile .scroll-to-quacks', '#quacks-external-box', 90);
        assignScrollFunction('.mobile .scroll-to-carousel', '#carousel-heading', 90);
        assignScrollFunction('.mobile .scroll-to-page-footer', '#page-footer', 90);
    }

    _initContactExpertLink() {
        //toggle box with link in fixed side bar
        $('.go-to-produck').click(() => {
            var chatTrigger = $('#produck-chat-block-home');
            if (!chatTrigger.hasClass('quacks-active')) {
                setTimeout(() => {
                    chatTrigger.toggleClass('quacks-active');
                }, 10);
            } else if (chatTrigger.hasClass('quacks-active')) {
                setTimeout(() => {
                    chatTrigger.toggleClass('quacks-passive');
                }, 10);
            }
        });
    }

    // initialisation of materializecss
    initBaseSiteMaterialize() {
        const instance = this;
        // use height (defined via #navigation in CSS) of navigation bar as scroll offset
        var elems = document.querySelectorAll('.scrollspy');
        M.ScrollSpy.init(elems, { scrollOffset: 0 });

        function initializeCarousel(selector) {
            var elems = $(selector).find(".carousel");
            M.Carousel.init(elems, {
                duration: 300,
                dist: 20,
                fullWidth: true,
                shift: 0,
                padding: 50,
                numVisible: 3,
                noWrap: true,
            });
        }

        function attachScrollHandlers(selector) {
            $(selector).find(".scroll-btn-circle-left").on("click", function () {
                var elem = $(selector).find(".carousel");
                var instance = M.Carousel.getInstance(elem[0]);
                if (instance) instance.prev(3);
            });

            const scrollRightElems = $(selector).find(".scroll-btn-circle-right, .fst-headline");

            scrollRightElems.on("click", function () {
                var elem = $(selector).find(".carousel");
                var instance = M.Carousel.getInstance(elem[0]);
                if (instance) instance.next(3);
            });
        }

        // Initialize carousels and attach handlers
        var carouselItems = $(".article-carousel-cycle");
        $(carouselItems).each((i, elem) => {
            initializeCarousel(elem);
            attachScrollHandlers(elem);
        });
    }

    // remove all buttons that can activate the chat iframe
    _hideChatInQuacks() {
        $('.go-to-produck').remove();
        $('#produck-chat-block-home').remove();
        $('#action-btns-wrapper').css('justify-content', 'center');
    }

    /**
     * Initialises controls for users with review capabilities.
     * Note! For some reason it does not work to use async-await here. The resulting code will not run in the browser.
     */
    _initReviewCapabilites(permission) {
        const instance = this;
        if (!instance._addReviewCapabilites) {
            instance.log.debug('The function _addReviewCapabilites is not defined for this page, review controls will not be available.');
            return;
        }

        instance.authenticator.checkPermission(permission).then(function(hasPermission) {
            function initReviewButtons(event) {
                $(event.currentTarget).addClass('hide');
                $(document).trigger("loader:on");
                instance._addReviewCapabilites();
            }

            if (hasPermission) {

                let reviewNavLink = $('<a>Review starten</a>');
                let reviewNavLinkIcon = $('<a><i class="fas fa-edit"></i></a>');
                let reviewNavLinkListItem = $('<li></li>');
                reviewNavLinkListItem.append(reviewNavLink);
                $('#leftNavigationMenuDesk').append(reviewNavLinkListItem);
                $('#leftNavigationMenuMobile').append(reviewNavLinkIcon);

                $(reviewNavLink).on('click', function(event) {
                    initReviewButtons(event);
                    $(reviewNavLinkIcon).hide();
                });

                $(reviewNavLinkIcon).on('click', function(event) {
                    initReviewButtons(event);
                    $(reviewNavLink).hide();
                });
            } else {
                instance.log.debug('Review not allowed!');
            }
        });
    }

    async _showHotOffersInAdBox() {

        let productHandler = new monstecLib.ProductHandler();
        productHandler.initialiseContextBeans();
        let infoMainBox = $('#info-sidebox #rel-article');                
        let sourceHeight = $('#quacklist-wrapper').height();

        const loadShopArticle = async function() {
            const myAsyncLoopFunction = async (array) => {
                const asyncOfferArray = [];
                for (const item of array) {
                    const asyncResult = await productHandler.getOfferDetailsByMtin(item.shopId, item.mtin)
                    asyncOfferArray.push(asyncResult)
                }
                return asyncOfferArray
            }

            let mergedArticleString = '';  
            let amazonIcon = '<i class="fab fa-amazon"></i>';
            let offersToCall = [{'shopId': 2127, 'mtin': "2127P00233"}, {'shopId': 2127, 'mtin': "2127P00234"}];
            let asyncOfferArray = await myAsyncLoopFunction(offersToCall);

            asyncOfferArray.forEach((item) => {
                let articleObj = productHandler._transformOffer(item);
                mergedArticleString += productHandler.buildSingleItem(articleObj, 2111);
            });

            const stringBuilderCallback = function(array) {

                array.forEach((articleObj) => {
                    let string = productHandler.buildSingleItem(articleObj, 2111, amazonIcon);
                    mergedArticleString += string;
                });

                infoMainBox.append('<div class="adnote">Anzeige</div><div class="hot-offers">Aktuelle Angebote</div>' + mergedArticleString);

                $('#rel-article .buy-btn').on('click', function() {
                    alert("Diese Funktion ist gerade im Aufbau und wird Ihnen in den nächsten Tagen zur Verfügung stehen");
                });
            }

            productHandler.callAmznProd(5, stringBuilderCallback);

        } 

        loadShopArticle();

        $(window).on('scroll', function() {          
            $('#info-sidebox').height(sourceHeight);
        });
    }
    
    _adjustCarouselHeight() {
        var carouselItems = $(".article-carousel-cycle");

        function setUniformCardHeight(targetElem) {
            var maxHeight = 0;
            var carouselElem = $(targetElem).find($(".cycle-step"));

            $(carouselElem).each((i, el) => {
                var itemHeight = $(el).outerHeight();
                if (itemHeight > maxHeight) {
                    maxHeight = itemHeight;
                }
            });

            $(targetElem).height(maxHeight + 150);
            $(carouselElem).height(maxHeight);
        }

        $(carouselItems).each((i, el) => setUniformCardHeight(el));
    }
}
