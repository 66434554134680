/* global monstecLib */
/* TODO refactor usage of global variables
 * This class uses global variables defined in index.html. It can be hard to find errors when using this kind of
 * "design". Instead the respective values / objects should be given by constructor arguments or via set-methods.
 */
import i18next from '../localisation.js';

export default class UserPortal {
    /**
     * Constructor
     *
     * @param {*} externalAuthenticator object that handles authentication with the services
     */
    constructor(produckWindowPort) {
        new monstecLib.Setup();
        this.constants = new monstecLib.Constants();
        this.utils = new monstecLib.Utils();
        this.cookie = new monstecLib.Cookie(produckWindowPort);
        this.authenticator = new monstecLib.Authenticator(this.cookie);
        this.wsClient = new monstecLib.WebSocketClient(this.constants.websocketChat, this.authenticator);
        this.chatclient = new monstecLib.ChatClient(this.wsClient, this.authenticator, this.cookie);
        this.identityService = new monstecLib.IdentityServiceAccess();
        this.chatService = new monstecLib.ChatServiceAccess();
        this.chatSupport = new monstecLib.ChatSupport(this.chatService, this.chatclient, this.utils, this.constants, this.cookie);
        this.profileDataHandler = new monstecLib.ProfileDataHandler(this.identityService, this.cookie, this.authenticator, undefined, this.utils);

        this.cookie.saveLastVisitedPage(window.location.href);

        var context = monstecLib.produckContext;
        context.setBean('utils', this.utils);
        context.setBean('authenticator', this.authenticator);
        context.setBean('identityService', this.identityService);
        context.setBean('chatService', this.chatService);
        context.setBean('chatClient', this.chatclient);
        context.setBean('chatSupport', this.chatSupport);
        context.setBean('profileDataHandler', this.profileDataHandler);

        monstecLib.produckContext.initialiseRegisteredBeans();

        this.log = new monstecLib.Log(1);
    }

    /*
    * This function enables the initiation of different menu items from the url that is called.
    * It uses the # Anchor i.e. #target1, which is called in utils.js
    */
    async deepLinkRef() {
        var instance = this;

        let calledTarget = "#target" + this.target;
        let sectionSubTarget;
        let windowHref = window.location.href;
        let sessStorage = await instance.cookie.getRightStorage('produck');

        // if target in url and is not target2 (chat)
        if (windowHref.indexOf('#target') > -1) {
            $(calledTarget).addClass('active');
        } else if (sessStorage && sessStorage.location) {
            $('#' + sessStorage.location).addClass('active');
            this.target = sessStorage.location.split('target').pop();
        } else {
            $('#targetQuestion').addClass('active');
            instance.questionSection.initialise();
        }

        // define subsection navigation for question section
        if (this.target === 'Question') {
            sectionSubTarget = 'questionSectionNavigation';
            instance.questionSection.initialise();
        }

        //activate target when profile is called
        if (parseInt(this.target) === 5) {
            instance.profileDataHandler.displayUserProfile();
        }

        // open certain tab
        if (sectionSubTarget && sessStorage && sessStorage.targetTab) {
            $('#' + sectionSubTarget).tabs('select', sessStorage.targetTab);
        }

    }

    preloadProcesses() {
        const instance = this;
        var nickname = "nickname";
        var form = $("#update-nickname");

        let register = new monstecLib.Register(instance.authenticator);

        instance.utils.initLoader();
        $(document).trigger("loader:on", ['', 'transparent']);

        instance.initialise().then(function () {
            let checkUri = instance.constants.apiHostIdentity + "registration/check";

            register.logout();
            register.checkCredentials(form, checkUri, nickname);

            // TODO loadCookie muss als erste Entscheidung vor Pageload stattfinden, da es den access token erneuert
            // und damit ein anderes Layout bereitstellt (aktuell wird Login Layout geladen, danach wieder Profil, da
            // mit erstem Laden der code refreshed wurde)
            console.log('user.html - Checking authentication status...');
            instance.authenticator.checkAuthenticationStatus().then(async function () {
                console.log('Continuing for authenticated user...');

                window.isAuthenticated = true; // TODO refactor that global variable away!

                var portalSupport = new monstecLib.PortalSupport(instance.authenticator, instance.cookie, instance.profileDataHandler, instance.chatService);

                portalSupport.slideInChatContainer($('.thumb-nav__item'));
                instance.profileDataHandler.initAccountChanges();
                instance.profileDataHandler.setUserName();

            })
            .catch(function (data) {
                console.log('Authentication check negative, data: ', data);
                // error handler
                if (data.status == 401) {
                    instance.utils.createSimpleAlertModal('Sitzung abgelaufen. Bitte melde dich erneut an.');
                    instance.cookie.clearCookie();

                    // if website is executed in app-webview send signal to logout
                    if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(JSON.stringify({ 'targetFunc': 'logout' }));
                    }
                }

                window.location.replace('login.html');
            })
            .finally(function () {
                $(document).trigger("loader:off");
            });
        });
    }


    async initialise() {
        var instance = this;
        var uid = await instance.cookie.getUidFromCookies();

        this.credentials = new monstecLib.Credentials(this.identityService);
        this.target = instance.utils.getValueFromAnchor('target');

        const authChecker = await instance.authenticator.getSynchronousPermissionChecker();

        this.portalSupport = new monstecLib.PortalSupport(instance.authenticator, instance.cookie, instance.profileDataHandler, instance.chatService);
        window.onbeforeunload = function () { this.portalSupport.saveSessLocation(); }.bind(this);
        this.portalSupport.refreshSite($('#refresh-btn-menu'));

        // ADD QUESTION SECTION
        instance.questionSection = new monstecLib.QuestionSection(authChecker);
        instance.questionSection.attachTo('targetQuestion');
        monstecLib.pageComponentRegistry.register('targetQuestion', instance.questionSection);

        instance.settingsSection = new monstecLib.SettingsSection();
        instance.settingsSection.attachTo('target7');
        monstecLib.pageComponentRegistry.register('target7', instance.settingsSection);

        this.portalSupport.setLanguage();
        this.portalSupport.initSideNav();
        instance.deepLinkRef();

        instance.credentials.updatePw(uid);
        instance.credentials.updateNick(uid);
        instance.credentials.matchPasswords();

        // INIT PROFILE MENU
        $('#xpert-profile-mobile').on('click', function() {
            instance.profileDataHandler.displayUserProfile();
        });

        // INIT QUESTION SECTION MENU
        $('#question-mobile').on('click', function() {
            instance.questionSection.initialise();
        });

    }
}
