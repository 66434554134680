/**
 * Provides a handler that will be called whenever a textarea with multimedia support is opened.
 */
import i18next from 'i18next';
export default class WysiwygControl {
    /**
     * Creates a new Wysiwyg Bar
     */
    constructor(externalUtils) {
        this.utils = externalUtils;
    }

    createWysiwygBarHTML() {
        var instance = this;

        let buttons =  [
            { name: "bold",
                title: "Fett <strong> Strg+B",
                icon: "format_bold",
                tag: "<strong></strong>",
                wrap: true
            },
            { name: "italic",
                title: "Kursiv <em> Strg+I",
                icon: "format_italic",
                tag: "<em></em>",
                wrap: true
            },
            { name: "unterline",
                title: "Unterstreichen <u>",
                icon: "format_underline",
                tag: "<u></u>",
                wrap: true
            },
            { name: "headline-h2",
                title: "Überschrift <h2>",
                icon: "looks_two",
                tag: "<h2></h2>\r\n",
                wrap: true
            },
            { name: "headline-h3",
                title: "Überschrift <h3>",
                icon: "looks_3",
                tag: "<h3></h3>\r\n",
                wrap: true
            },
            { name: "link",
                title: "Link <a> Strg+K",
                icon: "link",
                tag: "[url=\"Link\",name=\"Linkname\",titel=\"Linktitel\"]"
            },
            { name: "quote",
                title: "Zitat <blockquote>",
                icon: "format_quote",
                tag: "\r\n<blockquote><p></p></blockquote>\r\n",
                wrap: true
            },
            { name: "hor-line",
                title: "Horizontale Linie <hr>",
                icon: "remove",
                tag: "\r\n<hr>\r\n"
            },
            { name: "line-break",
                title: "Zeilenumbruch <br>",
                icon: "wrap_text",
                tag: "\r\n<br>\r\n"
            },
            { name: "image",
                title: "Bild <img> Strg+G",
                icon: "image",
                tag: "\r\n<figure>\r\n\t<img src=\"Link eintragen\" alt=\"Bildbeschreibung\">\r\n\t<figcaption>Bilduntertitel</figcaption>\r\n</figure>\r\n"
            },
            { name: "olist",
                title: "Nummerierung <ol>",
                icon: "format_list_numbered",
                tag: "\r\n<ol>\r\n\t<li></li>\r\n\t<li></li>\r\n</ol>\r\n"
            },
            { name: "ulist",
                title: "Aufzählungszeichen <ul>",
                icon: "format_list_bulleted",
                tag: "\r\n<ul>\r\n\t<li></li>\r\n\t<li></li>\r\n</ul>\r\n"
            },
            { name: "table",
                title: "Tabelle <table>",
                icon: "view_list",
                tag: "\r\n<table>\r\n\t<thead>\r\n\t\t<tr><th></th><th></th></tr>\r\n\t</thead>\r\n\t<tbody>\r\n\t\t<tr><td></td><td></td></tr>\r\n\t</tbody>\r\n</table>\r\n"
            },
            { name: "undo",
                title: "Rückgänging Strg+Z",
                icon: "undo",
                tag: ""
            },
            { name: "redo",
                title: "Wiederholen Strg+Y",
                icon: "redo",
            },
            { name: "switchView",
                title: "Ansicht wechseln </>",
                icon: "code"
            },
            { name: "spacer",
                title: "",
                icon: ""
            },
        ];

        // text embedded in template literals
        var row = `<ul class="wswg-button-row">${buttons.map(button => `<li class="wswg-button wswg-${button.name}-button" title="${button.title}"><i class="material-icons">${button.icon}</i></li>`).join('')}</ul>`;
        var imgUpldHtml = instance.imgUpldContHTML();
        var linkAddHTML = instance.linkContHTML();
        var wrapper = `<div class="wswg-button-bar">${row}${imgUpldHtml}${linkAddHTML}</div>`;

        return {wrapper, buttons};
    }


    handleShortCuts(textField) {

        let instance = this,
            ctrlDown = false,
            ctrlKey = 17, kKey = 75, gKey = 71, returnKey = 13, pkey = 80, bkey = 66, ikey = 73,
            insertText = function(link) {document.execCommand("insertText", false, link)},
            adjustTextfield = function(newCursorLocation) {
                $(textField).prop('selectionEnd', newCursorLocation);
                instance.utils.adjustTextarea($(textField), 1500);
            }


        textField.onkeydown = async function(e) {
            const keyCode = (window.event) ? e.which : e.keyCode;

            if (keyCode == 17 || keyCode == 91) {
                ctrlDown = true;
            }

            async function getClipboardContents() {
                let browser = instance.utils.detectBrowser(),
                    content = null;

                if (browser === "firefox") {
                    alert('Diese Funktion ist in Firefox nicht verfügbar.');
                    return
                } else {
                    content = await navigator.clipboard.readText();
                }
                return content
            }


            //bei gespeicherten Blöcken wird handleShortCuts wohl nicht aufgerufen

            //Ctrl + k for hyperlinks
            if (ctrlDown && keyCode == kKey) {
                e.preventDefault();                

                const start = $(textField)[0].selectionStart;
                const end = $(textField)[0].selectionEnd;
                const url = await getClipboardContents();
                const selText = instance.utils.getSelectionText($(textField));
                const urlFormatted = `[url="${url}",name="${selText}",title=""]`;
                const cursorLocation = instance.utils.returnCursorPosition($(textField));

                let newCursorLocation = start + urlFormatted.length;

                insertText(urlFormatted);

                adjustTextfield(newCursorLocation);

                ctrlDown = false;

                return false;
            }

            //Ctrl + p to wrap in paragraph
            if (ctrlDown && keyCode == pkey) {
                e.preventDefault();                

                const start = $(textField)[0].selectionStart;
                const end = $(textField)[0].selectionEnd;
                const selText = instance.utils.getSelectionText($(textField));
                const textFormatted = `<p>${selText}</p>`;
                const cursorLocation = instance.utils.returnCursorPosition($(textField));

                let newCursorLocation = start + textFormatted.length;

                insertText(textFormatted);

                adjustTextfield(newCursorLocation);

                ctrlDown = false;

                return false;
            }


            function textWrapper(key, patternStart, patternEnd) {
                if (ctrlDown && keyCode == key) {
                    e.preventDefault();                

                    const start = $(textField)[0].selectionStart;
                    const end = $(textField)[0].selectionEnd;
                    const selText = instance.utils.getSelectionText($(textField));
                    const textFormatted = `${patternStart}${selText}${patternEnd}`;
                    const cursorLocation = instance.utils.returnCursorPosition($(textField));

                    let newCursorLocation = start + textFormatted.length;

                    insertText(textFormatted);

                    adjustTextfield(newCursorLocation);

                    ctrlDown = false;

                    return false;
                }
            }

            //Ctrl + p to wrap in paragraph
            textWrapper(pkey, "<p>", "</p>");
            //Ctrl + b to wrap in strong - bold
            textWrapper(bkey, "<strong>", "</strong>");
            //Ctrl + i to wrap in em - italic
            textWrapper(ikey, "<em>", "</em>");

            //Ctrl + g for ímages
            if (ctrlDown && keyCode == gKey) {
                e.preventDefault();                

                const imgLink = await getClipboardContents(),
                      headline = `Bild einfügen`,
                      optionOne = i18next.t('text.yes'),
                      optionTwo = i18next.t('general.cancel'),
                      patterns = instance.utils.linkifyText();
                
                let imgLinkFormatted,
                    cursorLocation = instance.utils.returnCursorPosition($(textField));

                function initModal (textBody, modalId, imgLink) {

                    instance.utils.createModal($('body'), headline, textBody, optionOne, optionTwo, modalId);

                    $('#' + modalId).find('.modal-close.option-one').focus().on('click ', function() {
                        insertText(imgLinkFormatted);
                    });

                    $(document).on('keydown.modalHandler', function(e) {
                        const keyCode = (window.event) ? e.which : e.keyCode;

                        if ($('#' + modalId).length && $('#' + modalId).find('.modal-close.option-one').is(":focus") && keyCode == returnKey) {
                            insertText(imgLink);
                            $('#' + modalId).modal('close');
                            $(document).off('keydown.modalHandler');

                            let newCursorLocation = cursorLocation.pos + imgLinkFormatted.length;
                            adjustTextfield(newCursorLocation);

                        } else if ($('#' + modalId).length && $('#' + modalId).find('.modal-close.option-two').is(":focus") && keyCode == returnKey) {
                            $('#' + modalId).modal('close');
                        }
                    });

                }

                if (imgLink.match(patterns.urlPattern)) {

                    let textBody = `Möchtest du das Bild mit folgenderm Link einfügen?<br><br>${imgLink}`;
                    imgLinkFormatted = `\r\n<figure>\r\n\t<img src="${imgLink}" alt="BILDBESCHREIBUNG">\r\n\t<figcaption>BILDUNTERTITEL</figcaption>\r\n</figure>\r\n`;

                    initModal(textBody, 'imgLinkAsURLModal', imgLinkFormatted);
             
                } else if (imgLink.match(patterns.pseudoUrlPattern)) {

                    let textBody = `Bitte gib deinen Bild Link beginnend mit https ein:<br><br>"${imgLink.substr(0, 8)}..."`;
                    imgLinkFormatted = `\r\n<figure>\r\n\t<img src="https://${imgLink}" alt="BILDBESCHREIBUNG">\r\n\t<figcaption>BILDUNTERTITEL</figcaption>\r\n</figure>\r\n`;

                    initModal(textBody, 'imgLinkAsPseudoURLModal', imgLinkFormatted);

                } else {
                    imgLinkFormatted = `\r\n<figure>\r\n\t<img src="LINK EINFÜGEN" alt="BILDBESCHREIBUNG">\r\n\t<figcaption>BILDUNTERTITEL</figcaption>\r\n</figure>\r\n`;
                    insertText(imgLinkFormatted);

                    let newCursorLocation = cursorLocation.pos + imgLinkFormatted.length;
                    adjustTextfield(newCursorLocation);
                }             

                ctrlDown = false;
                return false;
            }
        }

        textField.onkeyup = function(e) {
            const keyCode = (window.event) ? e.which : e.keyCode;

            if (keyCode == 17 || keyCode == 91) {
                ctrlDown = false;
            };
        };

    }


    //set new text
    initPasteTextToCursorPosition(wysiwygBar, buttonsDefinition, textField) {
        var instance = this;

        instance.redoUndoCache = [];
        var actionsInCache = instance.redoUndoCache;
        //actionsInCache.length = 0;
        let currentStep = 0;

        buttonsDefinition.map(button => {
            var buttonElem = wysiwygBar.find(`.wswg-${button.name}-button`);

            buttonElem.off().on('click', () => {
                if (button.name === "undo") {
                    if (currentStep > 0) {
                        $(textField).val(actionsInCache[currentStep - 1].initialText);

                        $(textField).prop('selectionEnd', actionsInCache[currentStep - 1].initialCursorLocation).focus();

                        currentStep--;
                    }

                } else if (button.name === "redo") {
                    if (currentStep < actionsInCache.length) {
                        $(textField).val(actionsInCache[currentStep].newText);

                        $(textField).prop('selectionEnd', actionsInCache[currentStep].newCursorLocation).focus();

                        currentStep++;
                    } else {
                        return;
                    }

                } else if (button.name === "image") {
                    var imgContainer = wysiwygBar.find('.img-upload-container');

                    imgContainer.toggleClass('editor-active');
                    instance.initImageUpload(imgContainer, false, $(textField));
                    //TODO allow for adding a title and alt text to image for SEO
                } else if (button.name === "link") {
                    var urlContainer = wysiwygBar.find('#add-url-container');

                    urlContainer.toggleClass('editor-active');
                    instance._buildLink(urlContainer, $(textField));
                    instance._closeInputContainerListener(urlContainer);

                } else if (button.name === "switchView") {                   
                    
                    let content = $(textField).val();
                    let wrappedParagraphs = instance.utils.wrapParagraphs(content);
                           
                    //TODO: Ist content von fremder Person, muss dieser vom Server abgerufen werden, da sonst script injection mgl, Problem allerdings, dass content ja noch nicht gespeichert

                    let simplScriptRegex = /\.*(script|onerror|href|javascript)\b/gmi;

                    if (wrappedParagraphs.match(simplScriptRegex)){
                        alert("potential script detected");
                        return false;
                    } else {
                        $(textField).toggleClass('hide'); 
                        $(textField).siblings('.js-visual-input-area').html(wrappedParagraphs).toggleClass('hide');
                    }
                }

                else {
                    let selText = instance.utils.getSelectionText($(textField)),
                        buttonTag = button.tag;

                    if (selText && button.wrap === true) {

                        let tag = button.tag,
                            openTag = buttonTag.match(/<[^\/ ]+>/gi),
                            closeTag = buttonTag.match(/<[\/].*>/gi);

                        buttonTag = openTag + selText + closeTag;
                    }

                    instance._addTagsToTextarea($(textField), buttonTag);
                    currentStep++;
                }
            });
        });
    }

    _addTagsToTextarea(textField, innerHtml) {

        var instance = this,
            cursorLocation = instance.utils.returnCursorPosition(textField),
            initialText =  textField.val(),
            newText = initialText.substr(0, cursorLocation.pos) + innerHtml + initialText.substr(cursorLocation.pos + cursorLocation.selLength),
            firstLibr = (innerHtml.split(/^\r\n/).length - 1),
            innerLibr = (innerHtml.split(/\r\n.(?!:\r\n)/).length - 1) - (innerHtml.split(/^\r\n/).length - 1),
            innerTabs = innerHtml.split(/\t/).length - 1, // count all tabs; calc -1 to exclude last split fragment
        plainInnerHTML = innerHtml.trim().replace(/\r|\n|\t/g, ''); // remove all whitespaces

        let newCursorLocation = cursorLocation.pos + plainInnerHTML.length + firstLibr + innerLibr + innerTabs;

        textField.val(newText);
        textField.prop('selectionEnd', newCursorLocation);
        instance.utils.adjustTextarea(textField, 1500);
        textField.focus();

        instance.redoUndoCache.push({initialText: initialText, newText: newText, initialCursorLocation: cursorLocation.pos, newCursorLocation: newCursorLocation});
    }

    imgUpldContHTML() {
        var uploadInterFaceHtml = '<div class="img-upload-container">'
            +'  <input class="file-upld-path ps-absolute o0" type="file" name="file" accept="image/*" />'
            +'  <div class="img-upload-area drag-trgt">'
            +'    <div class="img-upload-info fs-14 darkco">'
            +'      Nutze folgende Optionen, um ein Bild einzufügen: Ziehe deine Bild-Datei in diesen Bereich, wähle eine'
            +'      <label for="file-upld-trigger" class="fs-14">'
            +'        <a class="browse-file prdk-link">Datei</a>'
            +'      </label> aus oder füge die '
            +'      <a class="add-url prdk-link">URL des Bildes</a> ein.<span class="info-span"> (Max. 4 MB)<span>'
            +'    </div>'
            +'    <div class="js-url-input-container input-one-liner-box">'
            +'      <div class="ps-relative input-field">'
            +'        <input class="img-upload-url-input placeholder validate" placeholder="Füge einen Link ein" type="url">'
            +'      </div>'
            +'      <a class="js-cancel-url cancel-act prdk-link fs-14">Abbrechen</a>'
            +'    </div>'
            +'  </div>'
            +'  <div class="attached-row">'
            +'      <div class="img-upload-confirm-area button-row fs-14">'
            +'        <a class="file-upld-button prdk-btn btn waves-effect waves-light disabled fs-14">Bild hinzufügen</a>'
            +'        <a class="js-cancel-add cancel-act prdk-link">Abbrechen</a>'
            +'      </div>'
            +'      <div class="flex-box license-info">'
            +'        <span class="flex-box">Powered by imgbb.&nbsp;</span>'
            +'        <span class="flex-box">User contributions licensed under cc by-sa (content policy).</span>'
            +'      </div>'
            +'  </div>'
            +'</div>';

        return uploadInterFaceHtml;
    }

    linkContHTML() {
        var insertUrlInterfaceHtml = '<div id="add-url-container" class="add-input-container">'
            +'  <div id="add-url-area" class="add-input-area">'
            +'    <div class="js-url-input-container active">'
            +'      <div class="input-field ps-relative flex-wrap">'
            +'        <input class="add-url-input placeholder validate" name="href" placeholder="Füge einen Link ein" type="url" value="https://">'
            +'        <input class="add-url-input placeholder validate" name="name" placeholder="Füge den Namen des Links ein" type="text">'
            +'        <input class="add-url-input placeholder validate" name="title" placeholder="Füge eine Beschreibung des Links ein (optional)" type="text">'
            +'      </div>'
            +'    </div>'
            +'  </div>'
            +'  <div class="attached-row">'
            +'      <div class="add-confirm-area button-row fs-14">'
            +'        <a class="add-button prdk-btn btn waves-effect waves-light fs-14">Link hinzufügen</a>'
            +'        <a class="js-cancel-add cancel-act prdk-link">Abbrechen</a>'
            +'      </div>' 
            +'  </div>'
            +'</div>';

        return insertUrlInterfaceHtml;
    }


    initImageUpload(imgContainer, inputField, textfield) {
        var instance = this,
            inputElement = imgContainer.find('.file-upld-path'),
            fileUploadBtn = imgContainer.find('.file-upld-button'),
            dragTarget = imgContainer.find('.drag-trgt');

        function initDragStyle() {

            dragTarget.off();

            dragTarget.on('dragover', function (event) {
                event.preventDefault();
                event.stopPropagation();
                $(this).addClass('dragging');
            });

            dragTarget.on('dragleave', function (event) {
                event.preventDefault();
                event.stopPropagation();
                $(this).removeClass('dragging');
            });

            dragTarget.on('drop', function (event) {
                event.preventDefault();
                event.stopPropagation();

                $(this).removeClass('dragging');
                const file = event.originalEvent.dataTransfer.files[0];

                if (validateImage(file)) {
                    createPreviewFromUpld(file);
                    activateUpldBtn(file);
                }
            });
        }

        function initUploadviaBrowse() {
            imgContainer.find('.browse-file').off().click(() => inputElement.click());

            inputElement.off().on('change', () => {
                const file = inputElement.get(0).files[0];
                if (validateImage(file)) {
                    createPreviewFromUpld(file);
                    activateUpldBtn(file);
                }
            });
        }

        function initUploadviaURL() {
            imgContainer.find('.add-url').off().on('click', () => {
                imgContainer.find('.js-url-input-container').toggleClass('active');
                resetImgPreview(imgContainer);
            });

            imgContainer.find('.js-cancel-url').off().on('click', () => {
                imgContainer.find('.js-url-input-container').removeClass('active');
                resetImgPreview(imgContainer);
            });

            imgContainer.find('.js-url-input-container').on('input', () => {
                var imgInput = imgContainer.find('.img-upload-url-input');
                var imgUrl = imgInput.val().trim();
                var imgDatafromUrl = { "data": {"image": {"url": imgUrl }}};
                createPreviewFromURL(imgContainer, imgUrl);
                activateUpldBtn(imgDatafromUrl, 'url');
            });
        }

        function createPreviewFromURL(imgContainer, imgUrl) {

            let imagePreviewBox = imgContainer.find('.js-image-upld-preview');

            imagePreviewBox.length ? imagePreviewBox.attr('src', imgUrl) : setNewImage(imgUrl);

            function setNewImage(url) {
                var img = new Image();

                $('.img-upload-area').append(img);

                img.src = url;
                img.className = 'js-image-upld-preview';
            }
        }

        function resetImgPreview(imgContainer) {
            let imagePreviewBox = imgContainer.find('.js-image-upld-preview');
            if (imagePreviewBox.length) imagePreviewBox.attr('src', '');
        }


        function activateUpldBtn(file, type) {

            fileUploadBtn.removeClass('disabled');

            fileUploadBtn.off().on('click', () => {

                if (type === 'url') {
                    instance._showPictureinContentArea(JSON.stringify(file), imgContainer, inputField, textfield);
                    fileUploadBtn.addClass('disabled');
                    imgContainer.find('.img-upload-url-input').val('');
                    instance._closeInputContainer(imgContainer);
                    resetImgPreview(imgContainer);                
                } else {
                    imgLoader();
                    inputElement.val('');
                    instance.imageUploadImgbb(file, imgContainer, inputField, textfield);
                    resetImgPreview(imgContainer);
                }

            });
        }

        function createPreviewFromUpld(arrayBufferWithIMG) {

            var prevImg = imgContainer.find('.js-image-upld-preview'),
                blob = new Blob([arrayBufferWithIMG], {type: "image/*"}),
                url = URL.createObjectURL(blob);

            prevImg.length ? prevImg.attr('src', url) : setNewImage();

            function setNewImage() {
                var img = new Image();

                img.onload = function() {
                    URL.revokeObjectURL(this.src);     // clean-up memory
                    $('.img-upload-area').append(this);   // add image to DOM
                }

                img.src = url;
                img.className = 'js-image-upld-preview';
            }
        }        

        function handleFiles(files) {
            for (var i = 0, len = files.length; i < len; i++) {
                if (validateImage(files[i]))
                    previewAnduploadImage(files[i]);
            }
        }

        function validateImage(image) {
            // check the type
            var validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
            if (validTypes.indexOf( image.type ) === -1) {
                instance.utils.createSimpleAlertModal("Invalid File Type. Use jpeg, jpg, png or gif.");
                return false;
            }

            // check the size
            var maxSizeInBytes = 4e6; // 4MB
            if (image.size > maxSizeInBytes) {
                instance.utils.createSimpleAlertModal("File too large. The max. file size is 4MB");
                return false;
            }

            return true;
        }


        function imgLoader() {
            const loaderContainer = document.createElement('div');
            loaderContainer.className = "loader-circle";
            imgContainer.find('.img-upload-confirm-area').prepend(loaderContainer);
            instance.utils.addLoader(loaderContainer, 'tiny');
        }

        instance._closeInputContainerListener(imgContainer);

        initDragStyle();
        initUploadviaBrowse();
        initUploadviaURL();
    }

    imageUploadImgbb(imgObj, imgContainer, inputField, textfield) {
        var instance = this,
            fileUploadBtn = imgContainer.find('.file-upld-button'),
            form = new FormData();

        form.append("image", imgObj);

        //var settings = {
        //  "url": "https://api.imgur.com/3/image",
        //  "method": "POST",
        //  "timeout": 0,
        //  "headers": {
        //    "Authorization": "Bearer 315fb69051dbf7060d19e31192cc06cd9d3b2bb2",
        //  },
        //  "processData": false,
        //  "mimeType": "multipart/form-data",
        //  "contentType": false,
        //  "data": form
        //};

        var settings = {
            "url": "https://api.imgbb.com/1/upload?key=9653c85c61f072f5effa773e572125c2",
            "method": "POST",
            "timeout": 0,
            "processData": false,
            "mimeType": "multipart/form-data",
            "contentType": false,
            "data": form
        };

        var loader = imgContainer.find('.loader');

        $.ajax(settings).done( (response) => {
            //console.log('Upload successful: ', response);

            instance._showPictureinContentArea(response, imgContainer, inputField, textfield);

            loader.remove();
            fileUploadBtn.addClass('disabled');
            imgContainer.find('.file-upld-path').val('');
            instance._closeInputContainer(imgContainer);

        }).fail((error) => {
            console.log('Could not upload file: ', error);
            var textBody = 'Leider ist beim Bildupload etwas schief gelaufen. Bitte versuchen Sie es noch einmal.';
            loader.remove();
            fileUploadBtn.addClass('disabled');
            instance.utils.createSimpleAlertModal(textBody)
        });
    }

    _showPictureinContentArea(data, imgContainer, inputField, textField) {
        var instance = this,
            dataParsed = JSON.parse(data),
            imgData = data,
            imgLink = dataParsed.data.image.url;

        if (inputField) {
            $(inputField).val(imgLink);
        } else {
            var divImgLink = `\r\n<figure>\r\n\t<img src="${imgLink}" alt="BILDBESCHREIBUNG">\r\n\t<figcaption>BILDUNTERTITEL</figcaption>\r\n</figure>\r\n`;
            //var textField = imgContainer.siblings('textarea.wswg-active');

            instance._addTagsToTextarea(textField, divImgLink);
        }
    }


    _buildLink(urlContainer, textField) {
        var instance = this,
            $submitLink = urlContainer.find('.add-button'),
            linkData = {},
            selText = instance.utils.getSelectionText(textField);

        if (selText) {
            var $inputFieldLinkName = urlContainer.find('.add-url-input[name="name"]');
            $inputFieldLinkName.val(selText);
        }

        $submitLink.off().on('click', function() {

            var $inputFields = urlContainer.find('.add-url-input');
            
            $inputFields.each((i, ele) => {
                var value = $(ele).val();
                var key = $(ele).attr('name');

                linkData[key] = value;

                $(ele).val('');
            });

            var linkObj = '[url="' +linkData['href']+'",name="' +linkData['name']+'",title="' +linkData['title']+'"]';
            //var textField = urlContainer.siblings('textarea.wswg-active');
            instance._addTagsToTextarea(textField, linkObj);
            instance._closeInputContainer(urlContainer);
        });
    }

    _closeInputContainerListener(container) {
        container.find('.js-cancel-add').off().click(() => container.removeClass('editor-active'));
    }

    _closeInputContainer(container) {
        container.removeClass('editor-active');
    }
    // detect link pattern on load
    // build link with a href title
    // allow alt attributes for images

    // TODO i18n Translation
    // TODO Link for License Agreement see https://stackoverflow.com/help/licensing
}
