/* jshint esversion: 6 */
/* global monstecLib */
/* global M */

import Cookie from './cookie.js';
import i18next from 'i18next';

export default class Register {
    constructor(externalAuthenticator) {
        this.authenticator = externalAuthenticator;
        this.utils = new monstecLib.Utils();
        this.constants = new monstecLib.Constants();
        this.cookie;
        this.portalSupport;

        this.log = new monstecLib.Log(1);
    }

    regReadyFunction() {
        var viewport = document.querySelector("meta[name=viewport]");
        var viewheight = $(window).height();
        var viewwidth = $(window).width();

        //Viewport adjustment for mobile device (esp. android) viewport change on soft keyboard pop-up
        window.setTimeout(function keyboardAdj() {
            viewport.setAttribute("content", "height=" + viewheight + "px, width=" + viewwidth + "px, initial-scale=1.0");
        }, 300);


        function hoverEnter() {
            $(this).addClass("active");
            $(this).find('button, input, span.confirm_text').attr("tabindex", '1');
            $(this).siblings().removeClass("active").find('button, input, span.confirm_text').attr("tabindex", '-1');
        }

        // Prevent tabindex when login__form hidden
        $('#main--customer, #main--xpert, #login__customer, #info--bar--customer, #login__xpert, #info--bar--xpert').on('click', (hoverEnter));
    }

    // Function: Adjust image size to viewport
    regImageAdj() {
        var viewheight = $(window).height();
        var viewwidth = $(window).width();
        var customerForm = '#main--customer';
        var xpertForm = '#main--xpert';

        this.image_url = $(customerForm).css('background-image').match(/^url\("?(.+?)"?\)$/); // Remove url() or for Chrome url("")

        if (this.image_url[1]) {

            var image_url = this.image_url[1];
            var image = new Image();
            image.src = image_url;

            var ratioImgLc = 2 * image.width / image.height; //Lc = Landscape
            var ratioImgPt = image.width / (2 * image.height); //Pt = Portrait
            var ratioPage = viewwidth / viewheight;
            var ratioTotalLc = ratioImgLc / ratioPage;
            var ratioTotalPt = ratioImgPt / ratioPage;
        }

        if ((viewwidth > 991 && ratioTotalLc < 1.25) || (viewwidth <= 991 && ratioTotalPt < 1)) {
            $('#main--customer, #main--xpert').css('background-size', 'cover');
        }
        else {
            $('#main--customer, #main--xpert').css('background-size', '');
        }
    }


    //Load reg button after image load completed
    regBtnLoad() {
        window.setTimeout(function () {
            $('#register-customer, #register-xpert').css({ 'display': 'flex' });
        }, 1000);
    }

    //use validateForm() in utils in future
    validateRegistrationForm(form) {

        var instance = this;

        let regBtn = form.find('button'),
            checkbox = form.find(':checkbox'),
            input = form.find('input'),
            inputArray = input.toArray();

        if (input.length) {

            input.bind('change', function(){
                instance.utils.validateInputInForm(inputArray, regBtn);
            });

            checkbox.on('change', function(){
                instance.utils.validateInputInForm(inputArray, regBtn);
            });
        }
        
        function determineCheckboxValidity(boxElem) {
            if (boxElem.prop('checked')) {
                boxElem.addClass('valid');
            } else {
                boxElem.removeClass('valid');
            }
        }

        if (checkbox.length)

            // control AGB-togglebox
            checkbox.parent().click(function(e) {

                if (e.target.type != "checkbox") {
                    // Stop the bubbling to prevent triggering the ancestors', especially the parent's,
                    // click event listeners.
                    e.stopPropagation();
                } else {
                    determineCheckboxValidity(checkbox);
                }
            });
    }

    //register and login function, which calls authenticator.authenticate to send credentials
    authentify(form, url, isAuthenticated, targetLocation) {

        var instance = this;
        var regBtn = form.find('button');

        form.bind('submit', function (e) {
            $(document).trigger("loader:on", ['', 'transparent']);
            e.preventDefault(); // do not trigger the browser form submit
            var input = form.find("input");
            var uri = url +  form.attr('data-apiurl');   // assign to right partner - customer or expert
            var isLogin = form.hasClass('login');

            // collect the form data while iterating over the inputs
            var data = {};
            for (var i = 0; i < input.length; ++i) {
                var inputElem = input[i];

                if (inputElem.name) {
                    if (inputElem.name === "email" || inputElem.name === "username") {
                        data[inputElem.name] = inputElem.value.trim().toLowerCase();
                    } else {
                        data[inputElem.name] = inputElem.value.trim();
                    }
                }
            }

            instance.authenticator.authenticate(data, uri, isLogin)
                .then(function() {

                    if(isLogin) {
                        isAuthenticated = true;

                        instance.authenticator.checkPermission(instance.authenticator.permCat.ACCESS_EXPERT_VIEW).then((result) => {

                            if (result) {
                                targetLocation ? (targetLocation === 'reload' ? window.location.reload(true) :  window.location.replace(targetLocation)) : window.location.replace('/xpert.html');
                            } else {

                                targetLocation ? (targetLocation === 'reload' ? window.location.reload(true) :  window.location.replace(targetLocation)) : window.location.replace('/user.html');
                            }
                        }, error => {
                            instance.log.error('ERROR - authentication failed', error);
                            window.location.replace('/index.html');
                        });
                    }
                }, error => {
                    isAuthenticated = false;
                    instance.log.error('ERROR - authentication failed', error);
                    $(document).trigger("loader:off");
                });
        });
    }

    /**
     * Gives logout behaviour to logout-buttons on the page.
     */
    logout(){
        var instance = this;
        $('#logout-btn, #logout-btn-menu').click(function(){
            instance.authenticator.logout('/index.html');
        });
    }

    errorMsgForNicknameFnc(errorMsg, helperTextEle) {
        let newHelperTextEle = $(helperTextEle)[0];
        $(helperTextEle).attr('data-error', i18next.t(errorMsg));
    }

    checkCredentials (form, url, credential) {
        var instance = this;
        var input = form.find("input[name='" + credential + "']");
        var helperText = form.find('.helper-text');
        url = url + credential;
        var data = {};
        let nicknamePattern = new RegExp('^[a-zA-Z0-9\\-\\.]+$');

        function validateCredentials(callbackSuccess, callbackFail) {

            $.ajax({
                type: "GET",
                url: url,
                data: data,
                dataType: "json",
                cache: false,
                output: JSON,
                success: function (data) {
                    if (data.result) {
                        input.addClass('valid').removeClass('invalid');
                        if (callbackSuccess) callbackSuccess();
                        helperText.css({'display':'block'});                    
                    } else {
                        input.addClass('invalid').removeClass('valid');
                        if (callbackFail) callbackFail("text.username_error", helperText);
                        helperText.css({'display':'block'});
                    }
                },
                error: function (data) {
                    console.log('Error ' + data.status);
                },
            });        
        }

        input.bind('focusout', function () {

            if (credential === "email" || credential === "username") {
                data[credential] = input.val().trim().toLowerCase();

                if (data[credential].length && data[credential] !== 'undefined') validateCredentials();

            } else if (credential === "nickname") {

                let newNickname = data[credential] = input.val().trim();

                function callbackSuccess() { if ($('#submit-nickname').length) $('#submit-nickname').removeClass("disabled"); };
                function callbackFail(msg, target) {
                    if ($('#submit-nickname').length) $('#submit-nickname').addClass("disabled");
                    instance.errorMsgForNicknameFnc(msg, target);
                };

                if (data[credential].length && newNickname.match(nicknamePattern)) {
                    validateCredentials(callbackSuccess, callbackFail);
                } else {
                    input.addClass('invalid').removeClass('valid');
                    callbackFail("text.username_pattern", helperText);
                    helperText.css({'display':'block'});
                }
            };
        });
    }

    /**
     * Assigns functionality to all specified targets (i.e. buttons) as soon as user is authenticated, otherwise signin is prompted.
     * @param {*} instantLoginCall opens signin form directly
     * @param {*} cookie instance
     * @param {*} callback instance
     * @param {boolean} initNavBar if the site has the produck navbar, which needs to be initialised set true, else false
     * @param {*} optionalButtonsArr set further button with type of button in array to assign signin functionality, i.e. ['#commentButton', '#ratingButton']
     */
    initSignInForm(instantLoginCall, cookie, callback, initNavBar, optionalButtonsArr) {
        var instance = this;
        instance.cookie = cookie;
        instance.portalSupport = new monstecLib.PortalSupport(instance.authenticator, cookie);
        var userData = undefined;

        if (instantLoginCall) {
            instance.callInstantOnSiteSignIn('reload');
        } else {

            // init the login status bar and buttons to portal functions if logged in / else init buttons to call instant signin form
            instance.authenticator.checkAuthenticationStatus().then(async function(result) {
                let userRole = await instance.authenticator.getUserRole();
                if (userRole == 'TEMPORARY' || userRole == 'ANONYMOUS')  {
                    // Throw an error for visitors that are authenticated but not actually logged in, because they do not (yet)
                    // have access to the ProDuck portal. This leads to the ecexution of the code in the catch below. So
                    // for actions that require a logged in user a login/register-popup will be shown like for a visitor that
                    // is not authenticated at all.
                    throw 'no portal access';
                }
                userData = instance.authenticator.getTokenParam(result);

                if (initNavBar) {
                    instance.initNavbar(true, userData);
                }

                if (optionalButtonsArr) instance.portalSupport.initLinkNavigation(optionalButtonsArr, userData);
                if (callback) callback(userData, instance.accountLinkSideNavSnippet());

            }).catch(function (result) {
                var isAuthenticated = false;

                if (initNavBar) {

                    instance.initNavbar(false, undefined, optionalButtonsArr);

                } else if (optionalButtonsArr) {

                    optionalButtonsArr.forEach(function(target) {
                        instance.initCallInstantOnSiteSignIn(target);
                    });

                    instance.portalSupport.initLinkNavigation(optionalButtonsArr);
                }

                if (callback) callback(userData, instance.accountLinkSideNavSnippet());

            }).then(function () {
                instance._initNavMaterial();
                M.Tabs.init($('.tabs'));
            });
        }
    }


    initNavbar(isAuthenticated, userData, optionalButtonsArr) {
        var instance = this;

        function initAccountNavigationMenu(profileObj, userData) {
            let userRolePageName = (userData.userRole === "EXPERT" || userData.userRole === "PROVIDER") ? 'xpert' : 'user';
            let name;

            if (profileObj.nickname) {
                name = profileObj.nickname;
            } else if (profileObj.firstName) {
                name = profileObj.firstName;
            } else if (userData.userName) {
                name = userData.userName.split('@')[0];
            } else {
                name = 'Ducky';
            }

            if (name.length > 12) name = name.substr(0, 9) + '...';

            var navItem = '<li class="js-user-navitem bold"><a class="sidenav-close waves-light btn dropdown-trigger white-text" data-target="account-dropdown" data-userId="' + userData.userId +'"><i class="material-icons right">person</i>' + name + '</a></li>';

            var accountQuestionLnksNav = '<ul id="account-dropdown" class="dropdown-content">'
            +'   <li><a id="account-portal-lnk" href="/' + userRolePageName + '.html" class="pointer">Mein Portal</a></li>'
            +'   <li><a id="account-profile-lnk" href="/profile/' + userData.userId + '" class="pointer">Mein Profil</a></li>'
            +'   <li><a id="account-logout-btn" data-i18n="text.logout" class="pointer">Logout</a></li>'
            +'  </ul>';

            var portraitPath = profileObj.portraitImg ? profileObj.portraitImg : '/assets/img/ducky.png';
            var backgroundPath = profileObj.backgroundImg ? profileObj.backgroundImg : '/assets/img/profiles/prdk_bgrd_profile_pattern_2.jpg';

            var sideNavItem = '<div class="user-view" data-href="#target5">'
            +'    <div class="background">'
            +'       <img class="background" src="' + backgroundPath + '">'
            +'    </div>'
            +'    <a class="nav-portrait-lnk" href="/profile/' + userData.userId + '" title="Zu meinem Profil">'
            +'      <img class="nav-portrait" src="' + portraitPath + '"">'
            +'    </a><br>'
            +'    <a id="user-name" class="nav-profile-name" href="/profile/' + userData.userId + '" title="Zu meinem Profil">'
            +'      <span class="white-text name">Hallo ' + name + '</span>'
            +'    </a><br>'
            +'    <a class="nav-chat-lnk" href="/' + userRolePageName + '.html">'
            +'      <span class="teal-text email prdk-link">Mein Portal</span>'
            +'    </a>'
            +'    <a id="logout-btn-sidenav" class="waves-effect waves-light btn logout-user" title="Logout">'
            +'      <i class="material-icons">power_settings_new</i>'
            +'    </a></div>';

            $('.nav-list').append(navItem);
            $('body').prepend(accountQuestionLnksNav);

            $('.sidenav#mobile-view').prepend(instance.accountLinkSideNavSnippet());
            $('.sidenav#mobile-view').prepend(sideNavItem);
        }

        function initAccountSignInButton() {
            var signInNavButton = '<li class="bold"><a id="signin-button-nav" class="waves-effect waves-light" data-target-location="reload">Anmelden</a></li>';
            var signInSideNavButton = '<li><a id="signin-button-sidenav" class="sidenav-close waves-effect btn white-text" data-target-location="reload">Anmelden</a></li>';
            $('.nav-list').append(signInNavButton);
            $('.sidenav#mobile-view').prepend(signInSideNavButton);
        }

        var questionLinks = ['#create-article-lnk', '#create-chat-lnk', '#ask-question-lnk', '#create-article-lnk-sidenav', '#create-chat-lnk-sidenav', '#ask-question-lnk-sidenav',''];

        function initLogout() {
            var reloadUri = window.location.href;
            $('#account-logout-btn, #logout-btn-sidenav').on('click', () => instance.authenticator.logout(reloadUri));
        }

        if (isAuthenticated) {
            instance.cookie.getExtendedUserData().then(userDataFromCookie => {

                let promise = new Promise((resolve, reject) => {

                    if (userDataFromCookie) {
                        initAccountNavigationMenu(userDataFromCookie, userData);
                        resolve();
                    } else {
                        instance.identityService = new monstecLib.IdentityServiceAccess(instance.authenticator);
                        
                        instance.identityService.getPublicProfileData(userData.userId).then(async userDataFromDb => {
                        userDataFromDb.userId = userData.userId;
                        let profileObj = await instance.cookie.saveExtendedUserDataInCookie(userDataFromDb);

                        initAccountNavigationMenu(profileObj, userData);
                        resolve();
                    })
                    .catch(error => {
                        // This is a fix of a login problem and not solved very nicely. The whole function should be reworked a bit.
                        console.log('No User Data: ', error);
                        initAccountNavigationMenu(false, userData);
                        resolve();
                    });
                }

                    
                });

            promise.then(() => {

                instance.portalSupport.initLinkNavigation(questionLinks, userData);
                instance._initNavMaterial();
                M.Tabs.init($('.tabs'));
                initLogout(); // keep initLogout on last position, after navbar init
            });

        })
        } else {
            $('.sidenav#mobile-view').append(instance.accountLinkSideNavSnippet());

    initAccountSignInButton();
    // these buttons are according to design guidelines always integrated in navbar,so can be set as fixed buttons
    var clickEvTargetArr = $.merge(questionLinks, ['#signin-button-nav', '#signin-button-sidenav']);

    if(optionalButtonsArr) {
        $.merge(clickEvTargetArr, optionalButtonsArr);
    }

    clickEvTargetArr.forEach(function(target) {
        instance.initCallInstantOnSiteSignIn(target);
    });

    instance.portalSupport.initLinkNavigation(questionLinks);
}
}

accountLinkSideNavSnippet() {
    var accountQuestionLnksSidenav = '<li class="no-padding">'
        +'    <ul class="collapsible expandable">'
        +'        <li class="bold">'
        +'            <a class="collapsible-header waves-effect waves-light" tabindex="0">Text erstellen</a>'
        +'            <div class="collapsible-body" style="display: block;">'
        +'                <ul>'
        +'                    <li><a id="create-article-lnk-sidenav" class="pointer sidenav-close" data-target-hash="targetArticle" data-target-tab="myArticlesSubSection" data-i18n="navigation.create_article">Artikel erstellen</a></li>'
        +'                    <li><a id="create-chat-lnk-sidenav" class="pointer sidenav-close" data-target-hash="targetQuacks" data-i18n="navigation.create_chat">Chat erstellen</a></li>'
        +'                    <li><a id="ask-question-lnk-sidenav" class="pointer sidenav-close" data-target-hash="targetQuestion" data-target-tab="answerableQuestionsSection" data-i18n="navigation.ask_question">Frage stellen</a></li>' 
        +'                </ul>'
        +'            </div>'
        +'        </li>'
        +'    </ul>'
        +'</li>';

    return accountQuestionLnksSidenav;
}

_initNavMaterial() {
    // init dropdown in top navigation

    M.Dropdown.init($('.dropdown-trigger'), {hover: true, constrainWidth: false, closeOnClick: false, coverTrigger: false });

    // init side navigation for mobile
    M.Sidenav.init($('.sidenav'), { edge: 'left', closeOnClick: true });
    M.Collapsible.init($('.sidenav .collapsible'));
    $('.sidenav .collapsible-body').css('display', '');
}

initCallInstantOnSiteSignIn(target) {
    var instance = this;
    $(target).on('click', function() {

        var targetLocation = false;

        if ($(this).is('[data-target-location]')) {
            targetLocation = $(this).data('target-location');
        }

        instance.callInstantOnSiteSignIn(targetLocation);
    });
}

callInstantOnSiteSignIn(targetLocation) {

    var instance = this;
    var isAuthenticated = null;
    
    var nickname = '<div class="input-field col s12 m12 l12">'
        +'      <input id="user_name" name="nickname" type="text" tabindex="1" autocomplete="new-password" data-i18n="[title]text.username_pattern" required="required" maxlength="60" minlength="4" class="bordered validate" pattern="^[a-zA-Z0-9\-\.]+$" >'
        +'      <label for="user_name" data-i18n="text.username"></label>'
        +'      <span class="helper-text" data-i18n="[data-success]text.username_success;[data-error]text.username_error" tabindex="-1"></span>'
        +'    </div>';

    var googleLogin = '<a class="oauth-login-button btn google in-modal" href="' + instance.constants.apiHostOauth2 + 'authorization/google">'
        + '  <svg aria-hidden="true" width="18" height="18" viewBox="0 0 18 18"><path d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 002.38-5.88c0-.57-.05-.66-.15-1.18z" fill="#4285F4"></path><path d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 01-7.18-2.54H1.83v2.07A8 8 0 008.98 17z" fill="#34A853"></path><path d="M4.5 10.52a4.8 4.8 0 010-3.04V5.41H1.83a8 8 0 000 7.18l2.67-2.07z" fill="#FBBC05"></path><path d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 001.83 5.4L4.5 7.49a4.77 4.77 0 014.48-3.3z" fill="#EA4335"></path></svg>'
        + '  Log in with Google'
        + '</a>';

    var fbLogin = '<a class="oauth-login-button btn facebook in-modal" href="' + instance.constants.apiHostOauth2 + 'authorization/facebook">'
        + '  <svg aria-hidden="true" width="18" height="18" viewBox="0 0 18 18"><path fill="white" d="M3 1a2 2 0 00-2 2v12c0 1.1.9 2 2 2h12a2 2 0 002-2V3a2 2 0 00-2-2H3zm6.55 16v-6.2H7.46V8.4h2.09V6.61c0-2.07 1.26-3.2 3.1-3.2.88 0 1.64.07 1.87.1v2.16h-1.29c-1 0-1.19.48-1.19 1.18V8.4h2.39l-.31 2.42h-2.08V17h-2.5z" fill="#4167B2"></path></svg>'
        + '  Log in with Facebook'
        + '</a>';

    var acceptTaC = '<div class="input-field col s12 m12 l12">'
        +'      <p>'
        +'        <label>'
        +'          <input id="tac-checkbox" type="checkbox" name="checkbox" class="filled-in" tabindex="4" />'
        +'          <span class="confirm_text fs-14" data-i18n="[html]order.terms"></span>'
        +'        </label>'
        +'      </p>'
        +'    </div>';

    var forgottenPW = '<div class="input-field col s12 m12 l12 center">'
        +' <a href="/reset-start.html" id="pw_forgotten" data-i18n="text.password_forgotten" class="prdk-link"></a>'
        +' </div>';

    var loginHelperText = '<div class="input-field col s12 m12 l12">'
         + '<input id="server-response" style="display: none">'
         + '<span class="helper-text login-helper center" id="helper-text" data-i18n="[data-error]text.password_username_error" ></span>'
         + '</div>';

    var signInForm = function (type) {

        function inputReg(string) {
            return type === 'register' ? string : '';
        }

        function inputLog(string) {
            return type === 'login' ? string : '';
        }

        var string = $('<form class="form '+type+'" autocomplete="new-password">'
        +     googleLogin
        +     fbLogin
        +     '<br>'
        +     inputReg(nickname)
        +'    <div class="input-field col s12 m12 l12">'
        +'      <input id="email-'+type+'" class="email bordered validate" ' + inputLog('name="username"') + inputReg('name="email"') + ' type="email" autocomplete="new-password" tabindex="2">'
        +'      <label for="email-'+type+'" data-i18n="text.email"></label>'
        +      inputReg('<span class="helper-text" data-i18n="[data-success]text.email_success;[data-error]text.email_error" tabindex="-1"></span>')
        +'    </div>'
        +'    <div class="input-field password-box col s12 m12 l12">'
        +'      <input id="password-'+type+'" class="password bordered validate" name="password" type="password" data-i18n="[title]text.password_pattern" pattern="'+ (type === 'register' ? ('(?=^.{8,}$)(?=.*\\d+)(?=.*\\W+)(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$') : ('(?=^.{0,}$)([^\\s]).*$')) +'" autocomplete="new-password" tabindex="3">'
        +'      <span toggle="#password-field" class="far fa-eye field-icon toggle-password"></span>'
        +'      <label for="confirm-password" data-i18n="text.password"></label>'
        +'    </div>'
        +     inputReg(acceptTaC)
        +     inputLog(loginHelperText)
        +'    <div class="submit-btn-wrapper input-field col s12 m12 l12">'
        +'      <button id="'+type+'-btn" class="btn waves-effect waves-light prdk-button '+ inputReg('disabled') + '" tabindex="5" type="submit" name="action"  data-i18n="text.'+type+'">'
        +'      </button>'
        +'    </div>'
        +     inputLog(forgottenPW)
        +'</form>');

        return string;
    }

    var stringLogin = signInForm('login')[0].outerHTML;
    var stringRegistration = signInForm('register')[0].outerHTML;

    var modalBox = $('<div class="modal dynamic-modal signin-modal">'
        +   '<div class="modal-content">'
        +       '<ul id="tabs-swipe-demo" class="tabs signin-tabs">'
        +           '<li class="tab col s6 m6 l6"><a class="active" href="#register-swipe">Registrieren</a></li>'
        +           '<li class="tab col s6 m6 l6"><a href="#login-swipe">Einloggen</a></li>'
        +       '</ul>'
        +       '<div id="register-swipe" class="inner-box col s12 white">'+stringRegistration+'</div>'
        +       '<div id="login-swipe" class="inner-box col s12 white">'+stringLogin+'</div>'
        +   '</div>'
        +   '<div class="modal-footer">'
        +       '<a class="option-one modal-close waves-effect waves-teal btn-flat center" data-i18n="text.close"></a>'
        +   '</div>'
        + '</div>');


    function createRegistrationModal() {

        $('body').append(modalBox);

        var tabs = $('.signin-tabs');
        var tabOptions = {
            swipeable: false,
            //responsiveThreshold: 991
        }

        var modalOptions = {
            dismissible: false,
        }

        M.Modal.init(modalBox, modalOptions);
        modalBox.modal('open');
        M.Tabs.init(tabs, tabOptions);

        var roleInfoTooltip = $('.tooltipped-elem');
        M.Tooltip.init(roleInfoTooltip, instance.utils.addToolTipWithArrow(roleInfoTooltip));
        var customClass = roleInfoTooltip.data('tooltip-class');
        if (customClass) $('.tooltip-html.signin-tooltip').parents('.material-tooltip').addClass('dark-tooltip');

        $('.inner-box, .option-one').localize();

        instance.utils.adjustTabIndicator(tabs, true);
    };


    if (!$('.signin-modal').length) {
        createRegistrationModal();

        var logUri = instance.constants.apiHostOauth;
        var regUri = instance.constants.apiHostIdentity + "registration/";
        var checkUri = instance.constants.apiHostIdentity + "registration/check";

        //init Login
        var loginForm = $('.signin-modal form.login');
        let trigger = [$('form.register > .oauth-login-button'), $('#register-btn')];
        loginForm.attr('data-apiurl', 'token');
        instance.authentify(loginForm, logUri, isAuthenticated, targetLocation);

        //init Registration
        var regForm = $('.signin-modal form.register')
        regForm.attr('data-apiurl', 'expert');

        instance.validateRegistrationForm(regForm);
        instance.checkCredentials(regForm, checkUri, 'nickname');
        instance.checkCredentials(regForm, checkUri, 'email');
        instance.authentify(regForm, regUri, isAuthenticated);
        instance.utils.togglePassword($(".signin-modal #password-login"));
        instance.utils.togglePassword($(".signin-modal #password-register"));
        instance.saveRegistrationLocation(trigger);
        instance.utils.initLoader();
        instance.logout();
    }
    else {

        $('.signin-modal').modal('open');
    }
}

saveRegistrationLocation(location) {
    var instance = this;

    location.forEach((el, i) => {
        $(el).on('click', function(ev) {
            instance.cookie.saveSpecificPage(window.location.href);
        });
    });
}
}

