/* global monstecLib */
/* global M */

/**
 * Provides access to the chat service by creating a REST client and providing some
 * quick access methods for retrieving data.
 */
export default class IdentityServiceAccess {
    constructor(authenticatorSingleton) {
        this.constants = new monstecLib.Constants();
        this.authenticator = authenticatorSingleton;
        this.restClient = this._createRestClientForIdentityService();

        this.log = new monstecLib.Log(1);
    }

    initialiseContextBeans() {
        this.authenticator = monstecLib.produckContext.authenticator;
    }

    /**
     * Creates a REST Client that enables you to contact and work with the identity service.
     */
    _createRestClientForIdentityService() {
        var instance = this;
        var restBuilder = new monstecLib.RestClientBuilder();
        var restApi = restBuilder.createRestClient(instance.constants.apiHostIdentity);

        // configure resources to use
        restApi.res('registration');
        restApi.registration.res('upgrade');
        restApi.registration.upgrade.res('provider');
        restApi.res('passwordreset');
        restApi.passwordreset.res('token');
        restApi.passwordreset.res('new');
        restApi.res('user');
        restApi.user.res('public');
        restApi.user.res('timing');
        restApi.user.res('password');
        restApi.user.res('away');
        restApi.res('users');
        restApi.users.res('identification');
        restApi.res('profile');
        restApi.res('billing');
        restApi.res('billing');
        restApi.billing.res('paypal');
        restApi.res('settings');
        restApi.settings.res('bundle');
        restApi.res('contact');
        restApi.res('websites');
        restApi.websites.res('settings');
        restApi.websites.res('all');

        return restApi;
    }

    async _getAccessToken() {
        try {
            return await this.authenticator.getAccessToken();
        } catch (error) {
            this.authenticator.promptForRelogin();
            return;
        }
    }

    async checkHealth() {
        const instance = this;
        let accessToken = await this._getAccessToken();

        return $.ajax({
            url: this.constants.apiHostIdentity.slice(0,-1) + 'actuator/health',
            type: 'GET',
            cache: false,
            headers: {'Authorization': 'Bearer ' + accessToken},
            error: function(response) {
                console.log('Could not retrieve information: ', response);
            }
        });
    }

    getPublicUserData(uid) {
        var instance = this;
        instance.restClient.accessToken = undefined;

        return instance.restClient.user.public.get({"id": uid}).then(function (result) {
            return result;
        }, function(reason) {
            M.toast({html: 'Es ist ein Fehler aufgetreten'});
            instance.log.error('Retrieval of public user data failed because ', reason);
            return reason;
        });
    }

    getUserChatTiming(uid) {
        var instance = this;
        instance.restClient.accessToken = undefined;

        return instance.restClient.user.timing.get({"id": uid});
    }

    async saveTimingConfiguration(data) {
        var instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.user.timing.put(data);
    }

    async getUserData(uid) {
        var instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.user.get({"id": uid}).then(function (result) {
            return result;
        }, function(reason) {
            M.toast({html: 'Es ist ein Fehler aufgetreten'});
            instance.log.error('user data retrieval failed because ', reason);
            return reason;
        });
    }

    async getUsersData(userIdsArray) {
        var instance = this;
        try {
            instance.restClient.accessToken = await this._getAccessToken();        
            let idsString = userIdsArray.join(',');        
            let result = await instance.restClient.users.identification.get({ ids: idsString });
            return result;
        } catch (error) {
            M.toast({html: 'Es ist ein Fehler aufgetreten'});
            instance.log.error('Users data retrieval failed because: ', error);
            throw error;
        }
    }
    
    async saveUserData(userData) {
        var instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.user.put(userData);
    }

    async upgradeToProvider(userData) {
        var instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.registration.upgrade.provider.post(userData);
    }

    async setAwayMode(mode) {
        var instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.user.away.put({'value': mode}, 'application/x-www-form-urlencoded');
    }

    /**
     * Changes the password of a user.
     *
     * @param {number} userId the technical identifier of the user to change the password for
     * @param {string} currentPassword the current password of the user
     * @param {string} newPassword the new password to set for the user
     */
    async changePassword(userId, currentPassword, newPassword) {
        var instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.user.password.post({"id": userId, "current": currentPassword, "update": newPassword});
    }

    /**
     * Changes the nickname of a user.
     *
     * @param {number} userId the technical identifier of the user to change the nickname for
     * @param {string} newNick the new nickname of the user
     */
    async changeNickname(userId, newNick) {
        var instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.user.put({"id": userId, "nickname": newNick});
    }

    /**
     * Requests an email from the identity service being sent to the given e-mail address that will contain
     * a token enabling the requester to reset his password.
     *
     * @param {string} email the e-mail address to send the token to
     */
    async requestPasswordResetToken(email) {
        // Do not check for an access token, since in the case of a requested password reset token
        // the user won't probably be logged in. And it is not necessary to be logged in to request a
        // password reset.
        var instance = this;
        return instance.restClient.passwordreset.token.post({"email": email}, 'application/x-www-form-urlencoded');
    }

    /**
     * Resets the user's password.
     *
     * @param {string} token a valid password reset token obtained via the appropriate service endpoint
     * @param {string} password the future password of the user
     */
    async resetPassword(token, password) {
        // It is not necessary nor probable anyway that the user is logged in if he wants to reset his password.
        var instance = this;
        return instance.restClient.passwordreset.new.post({"token": token, "pw": password});
    }

    /**
     * Gets profile data for public profile.
     *
     * @param {number} uid is userId from expert
     */
     getPublicProfileData(uid) {
        var instance = this;
        return instance.restClient.profile.get({"userId": uid});
    }

    async saveProfileData(userData) {
        var instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.profile.put(userData);
    }

    /**
     * Saves the billing information given in the paramater 'data'. This is the maximum of data
     * that can be given:
     * {
     *   'iban': 'DE72900700003425688932',
     *   'paypalClientId': '43567834275647835'
     * }
     *
     * If you leave out one of the items in 'data' it will simply not be saved.
     * In order to delete an already saved value, include an empty string like this:
     * {
     *   'iban': ''
     * }
     *
     * @param {*} data should contain the billing information meant to save
     */
    async saveBillingInformation(data) {
        var instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.billing.put(data);
    }

    /**
     * Gets previously saved billing information of a specific user.
     * Will return something like:
     *
     * {
     *   'iban': 'DE72900700003425688932',
     *   'paypalClientId': '43567834275647835'
     * }
     */
    async getBillingInformation() {
        var instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.billing.get();
    }

    /**
     * Gets publicly available paypal client ID of a specific user.
     * Will return something like:
     *
     * {
     *   'paypalClientId': '43567834275647835'
     * }
     *
     * @param {number} userId is userId from expert
     */
    async getPaypalClientId(userId) {
        var instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.billing.paypal.get({"userId": userId});
    }

    async getBundle(userId) {
        var instance = this;
        return instance.restClient.settings.bundle.get({'userId': userId});
    }

    async sendContactForm(data) {
        var instance = this;        
        return instance.restClient.contact.post(data);
    }

    async getUserWebsites() {
        var instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.websites.get();
    }

    async getUserWebsitesByStatus(verificationStatus) {
        var instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.websites.all.get({"status": verificationStatus});
    }

    async saveUserWebsiteSettings(settings) {
        var instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.websites.settings.put(settings);
    }

    async getWebsiteDNSKey(domain) {
        var instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.websites.put({'domain': domain});
    }

    async deleteUserWebsite(token) {
        var instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.websites(token).delete();
    }
}
