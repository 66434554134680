/* global monstecLib */
import i18next from 'i18next';


export default class ContactForm {
    constructor(externalUtils, externalIdentityService) {
        this.utils = externalUtils;
        this.identityService = externalIdentityService;
    }

    _collectInputValuesInForm($inputFields) {
        var valueObj = {};

        $inputFields.map((i, elem) => {
            let element = $(elem);

            if (element.attr('type') === 'checkbox') {
                valueObj[element.data('value')] = element.is(':checked');
            } else {
                valueObj[element.data('value')] = element.val();
            }
        });

        return valueObj
    }

    _markRequiredFields() {
        var $requiredFields = $('.js-validate-input').siblings('label');
        $requiredFields.map((i, element) => {
            var value = $(element).val();
            $(element).val(value + '*');
        });
    }

    sendForm(data, targetLocation) {
        var instance = this;

        instance.identityService.sendContactForm(data).then(function() {
            $(document).trigger("loader:off");
            let textBody = i18next.t('text.contact_us_successful');
            instance.utils.createSimpleAlertModal(textBody).then(function() {
                targetLocation ? window.location.href = targetLocation : ( targetLocation !== false ?  window.location.href= 'https://www.produck.de' : console.log('no redirect'));
            });
        })
          .catch(function(e) {
              $(document).trigger("loader:off");
              console.error('Could not send message', e);
              let textBody = i18next.t('text.contact_us_failed');
              instance.utils.createSimpleAlertModal(textBody);
          });
    }


    initContactForm(subject, targetLocation) {
        var instance = this;
        this.utils.initLoader();
        this._markRequiredFields();

        var form = $("#contact-form");
        var checkbox = $("#newsletter-checkbox");
        var submitBtn = $("#submit-contact-form");

        instance.utils.validateForm(form, checkbox, submitBtn);
        
        // trigger submission of data and send email
        form.on('submit', (e) => {
            e.preventDefault();
            $(document).trigger("loader:on", ['', 'transparent']);
            let collectedValues = instance._collectInputValuesInForm($('.js-collect'));
            collectedValues['mailSubject'] = subject ? subject : "No subject";

            instance.sendForm(collectedValues, targetLocation);
        });
    }
}
