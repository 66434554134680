/* global monstecLib */
import i18next from 'i18next';
import 'js-cookie';

// helper functions for pre-ES2015
function defaultFor(arg, val) { return typeof arg !== 'undefined' ? arg : val; }

// transpilation via webpack and babel to ES5 enables us to use ES2015 classes here
export default class ChatClient {
    constructor(wsClient, externalAuthenticator, externalCookies) {
        if (!wsClient) {
            throw 'ERROR - a transport client must be given.';
        }

        var instance = this;

        this.constants = new monstecLib.Constants();
        this.cookie = externalCookies;
        this.authenticator = externalAuthenticator;
        this.identityService = null;
        this.chatService = null;
        this.wvbridge = new monstecLib.wvBridge();
        this.utils = new monstecLib.Utils();

        this.config = null; // will be set by configure method
        this.mode = 'normal';
        this.tempEmail = null;
        this.tempPass = null;

        // websocket connection
        this.webSocketClient = wsClient;
        this.webSocketClient.registerConsumer(this); // register for all message types
        this.reconnectActions = [];

        this.webSocketClient.onReconnect = function() {
            instance.reconnectActions.forEach((action) => {
                action();
            });
        };

        // pointer to last message
        this.latestMessage = {
            id: '',
            date: new Date(0)
        };

        // chat configuration
        // chat session id from chat service
        this.chatId = -1;
        // user id from identification service
        this.userId = -1;
        // default output class container
        this.outputContainer = 'chatlog';

        // data structure that keeps track of open chat registration websocket requests
        this.chatRegistrations = {};

        // data structure that keeps track of open chat registration websocket requests
        this.chatRegistrations = {};

        // id of chat that was requested by somebody else
        this.pendingChatId = -1;

        // chatId which is linked to the current message
        this.nomChatId = -1;

        this.log = new monstecLib.Log(1);
    }

    initialiseContextBeans() {
        this.iframeDispatcher = monstecLib.produckContext.iframeDispatcher;
    }

    // sets the protocol config which defines how the client reacts to specific message types
    // of the chat protocol
    configure(protocolConfig) {
        this.config = protocolConfig;
    }

    setChatServiceClient(chatServiceAccess) {
        this.chatService = chatServiceAccess;
    }

    setIdentityServiceClient(identityServiceAccess) {
        this.identityService = identityServiceAccess;
    }

    setChatId(id) {
        this.chatId = id;
    }

    setUserId(id) {
        this.userId = id;
    }

    setExpertId(id) {
        this.expertId = id;
    }

    getUserId() {
        return this.userId;
    }

    /**
     * !!! Before using this directly, consider using an already encapuslated version like getMessages in ChatSupport.
     */
    loadMessages(lastId, callback, chatId, includeStartId) {
        var instance = this;
        // init return array where relevant information will be kept in chronological order
        var returnMessages = [];

        // if no chatId is given use the one saved in the current chatclient instance
        if (!chatId) {
            chatId = this.nomChatId; // TODO refactor nomChatId away (probalby it isn't used anymore -> check all occurrences of loadMessages here and getMessages in ChatSupport
        }

        return this.chatService.getChatMessages(chatId, lastId, includeStartId).then(function (result) {
            for (var msgIndex = 0; msgIndex < result.length; msgIndex++) {
                if (result[msgIndex].id > lastId){
                    instance.wvbridge.icmHandler(instance.latestMessage.id);
                }

                returnMessages.push(result[msgIndex]);
            }
            // only invoke callback if valid function
            if (callback && typeof (callback) === "function") {
                return callback(returnMessages); // return the result of the callback so that a returned promise gets properly treated
            } else {
                console.log('no callback provided');
            }
        }, function (errorReason) {
            console.log('Failed to fetch messages!', errorReason);
        });
    }   

    // send chat message to REST api endpoint to save in DB
    async sendMessage(messageText, chatID) {
        var instance = this;
        var chatId = chatID;
        if(!chatId || chatId === undefined || !isFinite(chatId)) {
            chatId = $('.cl.active').data('chatid');
        }

        var userId = instance.userId;

        return this.chatService.postMessage(chatId, userId, messageText)
            .then(function (messageId) {
                return messageId;
            })
            .catch(function (errorReason) {
                console.log('ChatClient - sendMessage: Failed to send message!');

                // enable the caller to react on the failure with an own catch
                return new Promise.reject(errorReason);
            });
    }

    /**
     * request a new chat from backend
     *
     * @param {*} topic the intial message
     * @param {*} mode whether the request goes to the pool, a specific group, or a specific expert
     *                 the mode is given as number where
     *                 0: general mode, question goes to the expert pool
     *                 1: group mode
     *                 2: specific expert mode
     * @param {*} targetId an identifier that is mandatory if mode is something else than 0, it specifies the group or expert
     * @param {*} customerId an identifier that is mandatory and in any case specifies the monstec customer the chat is associated to;
     *                       this customer may also be an independent expert
     */
    async requestChat(topic, mode, targetId, customerId) {
        var instance = this;
        var sessionCookie = await instance.cookie.getRightStorage('sess_au');
        var requesterId;

        if(!!sessionCookie) {
            requesterId  = (await instance.cookie.getRightStorage('produck')).userId;
        }

        // an expert must not make a request to himself
        if (mode == 2 && requesterId == targetId) {
            throw 'request_to_self';
        }

        return instance._internalRequestChat(topic, requesterId, mode, targetId, customerId)
            .then(function(data, textStatus, jpXHR) {
                if (requesterId) {
                    // If the current user is already authenticated and identified so no need for temporary
                    // credentials.
                    var successDef = $.Deferred();
                    successDef.resolve(data, textStatus, jpXHR);
                    return successDef.promise();
                } else if (!!instance.tempEmail && !!instance.tempPass) {
                    // anonymous chat, try to authenticate for role ANONYMOUS
                    var loginUrl = instance.constants.apiHostOauth + 'token';
                    var credentials = {};
                    credentials.username = instance.tempEmail;
                    credentials.password = instance.tempPass;
                    console.log('tempPass');
                    return instance.authenticator.authenticate(credentials, loginUrl, true);
                } else {
                    // The user is not authenticated and temporary credentials were not received in the
                    // server response. So chat initiation is not possible, because the chat connection
                    // can not be authenticated.
                    // Also if the response status is 204 no chat is possible. In that case no temporary
                    // credentials will be provided by the server and the following rejected Deferred will
                    // be returned.
                    // It may also be the case that a specificylly chosen expert is offline and he did configure
                    // an away mode that declines chat requests completely.
                    instance.log.debug('Temporary credentials could not be obtained, chat not possible.');
                    var failDef = $.Deferred();
                    failDef.reject(jpXHR);
                    return failDef.promise();
                }
            })
            .then(function() {
                console.log('Now connecting the websocket client.');
                return instance.webSocketClient.connect();
            })
            .then(function() {
                console.log('Now registering for chat via websocket (userId:%i, chatId:%i)', instance.userId, instance.chatId);
                if (instance.userId && instance.chatId > 0) {
                    // perform rfc to connect websocket ids with REST results
                    return instance.wsRegisterForChat(instance.userId, instance.chatId);
                }
            });
    }

    /**
     * Add an action that will be performed if and when the client reconnects to the chat server after having lost
     * the connection.
     *
     * @param {Function} actionFunction a function that will be called on when a reconnect occurs
     */
    addReconnectAction(actionFunction) {
        this.reconnectActions.push(actionFunction);
    }

    /**
     * For parameter documentation see the 'requestChat'-method.
     */
    _internalRequestChat(topic, requesterId, mode, targetId, customerId) {
        var instance = this;
        var requestParams;

        requestParams = 'topic=' + encodeURIComponent(topic);

        if (requesterId) {
            console.log('Requesting chat by authenticated user: ', requesterId);
            requestParams += '&userId=' + requesterId;
        }

        let specParam;
        if (mode == 1) { // group mode
            specParam = '&groupId=';
        } else if (mode == 2) { // specific expert mode
            specParam = '&expertId=';
        }

        if (targetId) {
            console.log('Requesting chat in mode ' + mode + ' with target ID :', targetId);
            if (specParam) {
                requestParams += specParam + targetId;
            } else {
                console.log('ERROR - chatclient - _internalRequestChat: targetID has been specified but using illegal mode:', mode);
                // fall back to pool mode
            }
        } else {
            if (mode != 0) console.log("ERROR - chatclient - _internalRequestChat: target ID must be specified when not in pool mode!");
            // fall back to pool mode
        }

        if (customerId) {
            requestParams += '&customerId=' + customerId;
        }

        if (instance.cookie.chatInfoLink) {
            requestParams += '&link=' + instance.cookie.chatInfoLink;
        }

        if (instance.cookie.chatInfoProduct) {
            requestParams += '&product=' + instance.cookie.chatInfoProduct;
        }

        var uri = this.constants.apiHostChat + 'chat/request?' + requestParams;

        return $.ajax({
            type: "GET",
            url:  uri,
            contentType: 'application/x-www-form-urlencoded',
            success: function (content, textStatus, jqXHR) {
                console.log("chat request returned successfully (Status: " + jqXHR.status + ").");

                // Saving the chatId to the associated container in the DOM
                function configureChat(chatData) {
                    $('.cl').attr('data-chatid', chatData.chatId).attr('data-providerid', chatData.providerId);
                    $('.cl').find('.chat-online-signal').attr('id', 'chat-online-signal' + chatData.chatId);
                    instance.chatId = chatData.chatId;
                    instance.nomChatId = chatData.chatId;
                    instance.tempEmail = chatData.email;
                    instance.tempPass = chatData.password;
                    instance.setUserId(chatData.userId);
                }

                function getAlternativeContactSentence(data) {
                    if (data.contactEmail && data.contactMobile) {
                        return  i18next.t("html.contact_proposal1") + '&nbsp;<a href="mailto:'+data.contactEmail+'">'+data.contactEmail+'</a>&nbsp;' + i18next.t('text.or') + '&nbsp;<a href="'+data.contactMobile+'">'+data.contactMobile+'</a>.';
                    } else if (data.contactEmail && !data.contactMobile) {
                        return i18next.t("html.contact_proposal2") + '&nbsp;<a href="mailto:'+data.contactEmail+'">'+data.contactEmail+'</a>.';
                    } else if (!data.contactEmail && data.contactMobile) {
                        return i18next.t("html.contact_proposal3") + '&nbsp;<a href="'+data.contactMobile+'">'+data.contactMobile+'</a>.';
                    } else {
                        return '';
                    }
                }

                function callWelcomeFunction () {
                    pretext.welcomeFunction2(topic);
                    pretext.welcomeFunction3();
                    setTimeout(async function () {
                        var userRole = await instance.authenticator.getUserRole();
                        if(userRole === 'TEMPORARY') {
                            pretext.welcomeFunction4();
                        }
                    }, 10000);
                }

                // Prepare content of a modal dialogue. The dialogue will be shown to the user, if the
                // chat can not be started immediately. This may be due to varying reasons.
                var text;
                var headline;
                var optionOne = i18next.t('text.done');
                var optionTwo = i18next.t('text.try_again');

                if (jqXHR.status === 200) {
                    if (!content) {
                        headline = i18next.t('text.no_service'); // will be changed later if necessary
                        text = i18next.t('text.no_data_retrieved');

                    } else if (content.requestMode === 'BROADCAST') {
                        // configure chat and return the XHR, the chat will have a first message telling the user to stay in the
                        // chat until an expert arrives, no modal dialogue necessary
                        configureChat(content);
                        callWelcomeFunction();
                        return jqXHR;

                    } else if (content.status === 'ONLINE') {
                        // This is the success case for the immediate begin of the chat, no modal information dialogue has
                        // to be shown, so configure the chat and return.
                        configureChat(content);
                        callWelcomeFunction();
                        return jqXHR;

                    } else {
                        // i.e. status === 'OFFLINE' or not set
                        if (content.awayMode === 'POSTPONE') {
                            text = i18next.t('text.content_postpone');
                            configureChat(content);
                            callWelcomeFunction();
                            pretext.generousAutoText(text);
                        } else if (content.awayMode === 'ALTERNATIVE') {
                            text = i18next.t('text.content_offline') + ' ' + getAlternativeContactSentence(content);
                            instance.disabled = true;
                            pretext.generousAutoText(text);
                        } else if (content.awayMode === 'ALTERNATIVE_POSTPONE') {
                            text = i18next.t('text.content_postpone') + ' ' + getAlternativeContactSentence(content);
                            configureChat(content);
                            callWelcomeFunction();
                            pretext.generousAutoText(text);
                        } else {
                            // i.e. content.awayMode === 'DECLINE' or unexpected case
                            text = i18next.t('text.content_offline') + ' ' + i18next.t('html.contact_proposal4') ;
                            instance.disabled = true;
                            pretext.generousAutoText(text);
                        }
                    }
                } else if (jqXHR.status === 204) {
                    if (customerId) {
                        text = i18next.t('text.content_not_found');
                        pretext.generousAutoText(text);
                    } else {
                        text = i18next.t('text.content_not_available');
                        pretext.generousAutoText(text);
                    }
                }

                return jqXHR;
            },
            error: function (jqXHR) {
                // error handler
                console.log("Chat request failed. Server returned status " + jqXHR.status);
                return jqXHR;
            }
        });
    }


    /**
     * Reconnect to active chats. User should be authenticated to use this method. If he is not
     * the method will try to authenticate him. Of course this will fail if there is no valid
     * access refresh token.
     *
     * @param {integer} chatRole defines which chats are reconnected by specifying the role the user
     *                           has in the chat, 0 for asking person, 1 for expert
     */
    async reconnectChats(chatRole) {
        var instance = this;

        var accessToken;
        try {
            accessToken = await instance.authenticator.getAccessToken();
        } catch(error)  {
            throw "Not authenticated, can't reconnect to chats!";
        }

        var returnPromise;
        if (!instance.webSocketClient.isConnected()) {
            returnPromise = instance.webSocketClient.connect(accessToken);
        } else if (!instance.webSocketClient.isSessionAuthenticated()) {
            returnPromise = instance.webSocketClient.authenticate(accessToken);
        } else {
            var dfd = $.Deferred();
            dfd.resolve();
            returnPromise = dfd.promise();
        }

        return returnPromise.then(function() {
            return instance.authenticator.getUserRole();
            // returned userRole currently not needed, maybe remove this call?
        }).then(function(){
            if (instance.webSocketClient.isSessionAuthenticated()) {
                return instance.chatService.getActiveChats(chatRole);
            } else {
                var dfd = $.Deferred();
                dfd.reject("Websocket authentication failed!");
                return dfd.promise();
            }
        });
    }

    onWebSocketMessage(msg) {
        this._wsOnMessage(this.config, msg);
    }

    _wsOnMessage(config, payload) {
        var instance = this;
        // config allows us to define custom callbacks
        if (config != null) {
            instance.log.debug('ws.onmessage, config:', payload.type);
        }

        if (payload.type === 'acr') {
            if(this.chatRegistrations[this.chatId]) {
                this.chatRegistrations[this.chatId].resolve();
                this.chatRegistrations[this.chatId] = null;
            }

            if (config != null && config.acr != null && typeof (config.acr) === 'function') {
                config.acr(this.chatId);
            } else {
                console.log('Chat registration acknowledged');
            }
        } else if (payload.type === 'nom') {
            if (config != null && config.nom != null && typeof (config.nom) === 'function') {
                this.nomChatId = payload.chatId; // refactor nomChatId away
                config.nom(payload.chatId, payload.messageId);
            } else {
                console.log('No callack configured for "nom"!');
            }
        } else if (payload.type === 'not') {
            //console.log('Notification that user is typing received.', payload.chatId);
            if (config != null && config.not != null && typeof (config.not) === 'function') {
                config.not(payload.userId, payload.chatId);
            } else {
                console.log('No callack configured for "not"!');
            }

        } else if (payload.type === 'icr') {
            console.log('icr: User ' + payload.userId + ' requested to chat with the expert.');

            if (config !== null && config.icr !== null && typeof (config.icr) === 'function') {
                config.icr(payload);
            } else {
                console.log('ERROR! User requested to chat with the expert in chat:' + payload.chatId
                            + ', but there is no icr-handler defined in the config.');
            }
            instance.wvbridge.icrHandler(payload);

        } else if (payload.type === 'ire') {
            console.log('ire: Chat ' + payload.chatId + ' has been accepted or aborted so the corresponding request is expired.');

            if (config !== null && config.ire !== null && typeof (config.ire) === 'function') {
                config.ire(payload.chatId);
            } else {
                console.log('ERROR! Request for chat:' + payload.chatId
                            + 'should have been expired, but there is no ire-handler defined in the config.');
            }
            instance.wvbridge.icrHandler(payload);

        } else if (payload.type === 'ira') {
            console.log('ira: Chat ' + payload.chatId + ' has been accepted in another session.');

            if (config !== null && config.ira !== null && typeof (config.ira) === 'function') {
                config.ira(payload.chatId);
            } else {
                console.log('ERROR! Request for chat:' + payload.chatId
                            + 'should have been announced as accepted in another session, but there is no ira-handler defined in the config.');
            }
            instance.wvbridge.icrHandler(payload);

        } else if (payload.type === 'ird') {
            console.log('ird: Chat ' + payload.chatId + ' has been declined in another session.');

            if (config !== null && config.ird !== null && typeof (config.ird) === 'function') {
                config.ird(payload.chatId);
            } else {
                console.log('ERROR! Request for chat:' + payload.chatId
                            + 'should have been announced as declined in another session, but there is no ird-handler defined in the config.');
            }
            instance.wvbridge.icrHandler(payload);

        } else if (payload.type === 'icc') {
            instance.identityService.getPublicUserData(payload.userId).then(
                userData => {
                    $(".cl[data-chatid=" + payload.chatId + "]").attr('data-chatpartner', payload.userId);
                    instance.cookie.updateChatHeader(userData);
                    instance.iframeDispatcher.sendInfoAboutExpertJoined();
                }
            );
            instance.stopPreloader();

            if (config != null && config.icc != null && typeof (config.icc) === 'function') {
                config.icc(payload.chatId);
            } else {
                console.log('ERROR! No handler defined for "icc" in the config.');
            }
        } else if (payload.type === 'rfc') {
            if (config != null && config.rfc != null && typeof (config.rfc) === 'function') {
                config.rfc();
            } else {
                console.log('ERROR! No handler defined for "rfc" in the config.');
            }
        } else if (payload.type === 'eac') {
            if (config != null && config.eac != null && typeof (config.eac) === 'function') {
                config.eac();
            } else {
                console.log('ERROR! No handler defined for "eac" in the config.');
            }
        } else if (payload.type === 'ice') {
            if (config != null && config.ice != null && typeof (config.ice) === 'function') {
                config.ice(payload.chatId, payload.userId);
            } else {
                console.log('Chat has been closed by chat partner.');
            }
        } else if (payload.type === 'imd') {
            if (config != null && config.imd != null && typeof (config.imd) === 'function') {
                config.imd(payload.chatId, payload.messageId);
            }
        } else if (payload.type === 'imr') {
            if (config != null && config.imr != null && typeof (config.imr) === 'function') {
                config.imr(payload.chatId, payload.messageId);
            }
        } else if (payload.type === 'nea') {
            if (config != null && config.nea != null && typeof (config.nea) === 'function') {
                config.nea();
            } else {
                var string = i18next.t('text.request_declined');
                instance.utils.createSimpleAlertModal(decodeURI(string));
            }
        } else if (payload.type === 'spm') {
            if (config != null && config.spm != null && typeof (config.spm) === 'function') {
                config.spm(payload.chatId);
            } else {
                console.log('Chat is eligible for commission.');
            }
        } else {
            console.log('ERROR! Received unknown message payload type: ' + payload.type);
        }
    }

    // mark this instance as available for chats
    async registerOnlineStatus() {
        console.log('Registering expert online status.');
        var message = { 'type': 'ros', 'userId': await this.getUserId() };
        this.wsSendMessage(JSON.stringify(message));
    }

    // mark this instance as unavailable for chats
    async registerOfflineStatus() {
        console.log('Registering expert offline status.');
        var userId = await this.getUserId();
        var message = { 'type': 'sos', 'userId': userId };
        this.wsSendMessage(JSON.stringify(message));
    }

    /**
     * Register a new ws chatsession from clientside.
     *
     * @param {*} userId the ID of the current user
     * @param {*} value the topic of the chat
     *
     * @returns a promise that will be resolved when or if the chat registration gets acknowledged by the server
     */
    wsRegisterForChat(userId, chatId) {
        var message = { 'type': 'rfc', 'userId': userId, 'chatId': chatId };
        this.wsSendMessage(JSON.stringify(message));
        var chatRegistrationAckDeferred = $.Deferred();
        this.chatRegistrations[this.chatId] = chatRegistrationAckDeferred;

        // set timeout for the connection promise
        function onChatRegistrationTimeout() {
            if (chatRegistrationAckDeferred.state() === 'pending') {
                chatRegistrationAckDeferred.reject();
            }
        }
        setTimeout(onChatRegistrationTimeout, 5000);

        return chatRegistrationAckDeferred.promise();
    }

    // accept a new chatsession on userside
    async acknowledgeChat(chatId) {
        this.pendingChatId = chatId;

        if (this.pendingChatId != -1) {
            // switch this client over to the new chat
            this.chatId = this.pendingChatId;
            return this.chatService.acknowledgeChat(chatId, this.webSocketClient.serverSessionId);
        } else {
            console.log('no chat request was registered yet');
        }
    }

    async wsStopChat(chatId) {
        if (!chatId) {
            this.log.error('wsStopChat - Cannot stop chat without ID!');
            return;
        }

        var userId = await this.getUserId();

        var message = { "type": "eac", "userId": userId, "chatId": chatId};
        this.wsSendMessage(JSON.stringify(message));
    }

    /**
     * Informs the server if a specific message has been received or read
     * 
     * @param {*} type can be 'mmd' for delivered or 'mmr' for read
     * @param {*} messageId technical identifier of the message
     */
    async wsSendMessageStatusUpdate(type, messageId) {
        if (!messageId) {
            this.log.error('wsSendMessageStatusUpdate - Cannot update message without ID!');
            return;
        }

        var userId = this.getUserId();

        var message = { "type": type, "userId": userId, "messageId": messageId};
        this.wsSendMessage(JSON.stringify(message));
    }

    stopPreloader() {
        var instance = this;
        // $('.chatlog > .progress').remove(); // set inactive
        $(".progress-notification").text('Experte Online').addClass('active-chat');
    }

    async submitRequestDeclined(chatId) {
        var userId = this.getUserId();
        var message = {};
        
        message.type = "dcr";
        message.userId = userId;
        message.chatId = chatId;

        if (this.webSocketClient.serverSessionId) {
            message.pcSessionId = this.webSocketClient.serverSessionId;
        }

        this.wsSendMessage(JSON.stringify(message));
    }

    wsSendMessage(message) {
        this.webSocketClient.sendMessage(message);
    }

    sendTypingInformation(chatId) {
        var message = { "type": "iot", "chatId": chatId};
        this.wsSendMessage(JSON.stringify(message));
    }

    /**
     * Sends a quackify request to the server.
     *
     * @param {Object} quackData must be of the following form:
     *                           var quackData = {
     *                               'id': [chatId],      # {number} the ID of the chat to quackify
     *                               'mode': [quackMode]  # {string} the target domain for publishing  the chat
     *                               'title': [title],    # {string} the topic of the chat, i.e. the title of the quack
     *                               'tags': [tags],      # {string[]} tags to be associated with the quack
     *                               'lang': [langIso]    # {string} the iso 639-1 code of the language of the quack
     *                           };
     * @param {Function} successCallback a function that will be called when the request returns with OK
     * @param {Function} errorCallback a function that will be called when the request returns an error
     */
    quackifyChat(quackData, successCallback, errorCallback) {
        console.log('Quackifying Chat having id [' + quackData.id + '] with topic [' + quackData.title + ']');
        console.log('QuackData', quackData);
        console.log('scb', successCallback);
        console.log('fcb', errorCallback);

        var data = {};
        data.quackId = quackData.id;
        data.mode = quackData.mode;
        data.title = quackData.title;

        var tags = quackData.tags;
        if (tags && tags.length > 0) {
            data.tags = tags;
        }

        data.language = quackData.lang;

        console.log('Sending', data);

        this.chatService.postChatAsQuack(data)
            .then(function () {
                successCallback();
            }, function () {
                errorCallback();
            });
    }
}
