/*
 * Container class for common utility functions.
 */
/* global monstecLib */
import i18next from 'i18next';

export default class Utils {
    constructor() {
        this.soundPlayed = false;

        this.log = new monstecLib.Log(1);

        this.asinRunIdCache = [];
        this.requestedAsinsCache = [];
    }

    isNumber(x) {
        return typeof x === 'number';
    }

    isUnsignedInt(x) {
        var parsed = parseInt(x);
        return !isNaN(parsed) && parsed >= 0;
    }

    capitaliseFirstLetter(input) {
        return input.charAt(0).toUpperCase() + input.substr(1);
    }

    formatDate(aDate) {
        let toBeFormatted;
        if (typeof aDate === 'string') {
            toBeFormatted = new Date(aDate);
        } else {
            toBeFormatted = aDate;
        }

        // To check whether the browser supports the language and options parameters of the Date.toLocaleString-function,
        // use the requirement that illegal language tags are rejected with a RangeError exception.
        try {
            new Date().toLocaleString('i');
            // no error means no suport
        } catch (e) {
            if (e instanceof RangeError) {
                return toBeFormatted.toLocaleString(undefined,
                    {
                        hourCycle:'h24',
                        weekday: 'short',
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit'
                    }
                );
            } else {
                return toBeFormatted.toLocaleString();
            }
        }

        // the no support case:
        return toBeFormatted.toLocaleString();
    }

    transformDate(timestamp) {
        var dd = timestamp.getDate();
        var mm = timestamp.getMonth() + 1; //January is 0!
        var yyyy = timestamp.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        var date = dd + '.' + mm + '.' + yyyy;
        return date;
    }

    transformTime(timestamp) {
        var secondsSinceEpoch = (timestamp / 1000) | 0; // substract 120 to get local time
        var secondsInDay = ((secondsSinceEpoch % 86400) + 86400) % 86400; // The remainder operator returns the remainder left over when one operand is divided by a second operand.
        var minutes = ((secondsInDay / 60) | 0) % 60;
        var hours = ((secondsInDay / 3600) + 2) | 0;
        return hours + (minutes < 10 ? ":0" : ":") + minutes;
    }

    calcDaysFromNow(msFromEpoch) {
        var oneDay = 24*60*60*1000; // hours*minutes*seconds*ms
        var today = new Date();
        var secondDate = new Date(msFromEpoch);

        var diffDays = Math.abs((secondDate.getTime() - today.getTime())/oneDay);

        return diffDays;
    }

    checkExpirationDate (expirationDate) {
        let transformedExpDate = new Date(expirationDate);
        let currentDate = new Date();
        let entityValid = false;

        if (transformedExpDate.getTime() >= currentDate.getTime()) entityValid = true;
        
        return entityValid;
    }

    getStandardDatePickerConfig() {
        return {
            autoClose: true,
            maxDate: new Date(),
            showClearBtn: true,
            format: monstecLib.i18next.t('general.date_format_short'),
            i18n: {
                cancel: monstecLib.i18next.t('general.cancel'),
                clear: monstecLib.i18next.t('general.clear'),
                done: monstecLib.i18next.t('general.ok'),
                months: monstecLib.i18next.t('general.months'),
                monthsShort: monstecLib.i18next.t('general.months_short'),
                weekdays: monstecLib.i18next.t('general.weekdays'),
                weekdaysShort: monstecLib.i18next.t('general.weekdays_short'),
                weekdaysAbbrev: monstecLib.i18next.t('general.weekdays_abbreviation')
            }
        };
    }

    getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    getAnchor(url) {
        if (!url) url = window.location.href;
        var regex = new RegExp('#([^?&#]+)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[1]) return '';
        return decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    getValueFromAnchor(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('#' + name + '([^?&#]+)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[1]) return '';
        return decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    createModal(target, headline, textBody, optionOne, optionTwo, htmlId) {
        if (typeof(htmlId) !== 'string') {
            htmlId = 'startANewModal';
        }

        if($('#' + htmlId).length) {
            $('#' + htmlId).remove();
        }

        var modal = '<div id="' + htmlId + '" class="modal dynamic-modal">'
        + '<div class="modal-content">'
        + '  <h4>' + headline + '</h4>'
        + '  <p>' + textBody + '</p></div>'
        + '  <div class="modal-footer">'
        + '    <a class="option-one modal-close waves-effect waves-teal btn-flat" tabindex="1">' + optionOne + '</a>'
        + '    <a class="option-two modal-close waves-effect waves-teal btn-flat" tabindex="2">' + optionTwo + '</a>'
        + '  </div>'
        + '</div>';

        target.append(modal);

        $('#' + htmlId).modal({
            dismissible: false,
            onCloseEnd: this._removeUnusedModalLayers
        });

        $('#' + htmlId).modal('open');
    }

    /**
     * Creates a modal that simply shows a hint to the user and can be removed by clicking on the single button on it.
     * The modal will resolve the returned promise when the button is clicked or reject it after the timeout if one is
     * defined.
     *
     * @param {string} textBody
     * @param {string} htmlId
     * @param {number} timeout a duration in seconds (optional)
     * @param {string} buttonText the label of the button on the modal (will be 'close' if this argument is not given)
     *
     * @returns a promise that will be resolved when the ok-button is clicked or when the timeout occurs
     */
    createSimpleAlertModal(textBody, htmlId, timeout, buttonText) {
        const instance = this;
        if (typeof(htmlId) !== 'string') {
            if($('#startANewModal').length) {
                $('#startANewModal').remove();
            }
            htmlId = 'startANewModal';
        }
        var modal = '<div id="' + htmlId + '" class="modal dynamic-modal simple-alert">'
        + '<div class="modal-content">'
        + '<p>' + textBody + '</p></div>'
        + '<div class="modal-footer">'
        + '<a class="option-one modal-close waves-effect waves-teal btn-flat" tabindex="1">' + ((!!buttonText) ? buttonText : i18next.t('text.close')) + '</a>'
        + '</div></div>';

        $('body').append(modal);

        $('#' + htmlId).modal({
            dismissible: false,
            onCloseEnd: this._removeUnusedModalLayers
        });

        $('#' + htmlId).modal('open');

        // Create a promise that will be resolved when the link on the model is pressed or
        // or rejected when a timout occurs. It is up to the user of the model whether or not
        // to react on these events.
        return new Promise(
            function(resolve, reject) {
                $('#' + htmlId).find('a').on('click', () => resolve());

                if (timeout && instance.isNumber(timeout))
                    window.setTimeout(
                        function() {
                            reject();
                        }, timeout);
            }
        );
    }

    // function that counters the inability of materializecss to handle its modals properly
    _removeUnusedModalLayers() {
        // look for open modals and overlays
        let openModals = $('.modal.open');
        let overlays = $('.modal-overlay');

        // If there are more overlays than open modals some of the overlays are left overs.
        // Since materializecss internally links the overlays to specific modals, such a modal
        // can only close a specific overlay. Unfortunately materializecss does not mark the
        // overlay by an attribute so it is not possible to determine which overlay belongs to
        // which modal (at least not without heavily studying the code). However it seems that
        // the overlay is always inserted right after the modal element so the useless overlays
        // should come first in the list.
        let modalOverCount = overlays.length - openModals.length;

        for (let i = 0; i < modalOverCount; i ++) {
            $(overlays[i]).remove();
        }
    }

    /**
     * Creating a textarea with auto-resize and scrollbar if required
     * scrollElem refers to the parent block where the textarea is embedded and which has the scroll caret
     */
    adjustTextarea(textareaElem, maxHeight, minHeight) {

        let newMinHeight = minHeight ? minHeight : 0;

        $(textareaElem).each(function () {

            let textArea = this;

            if (textArea.scrollHeight > maxHeight) {
                let newHeight = Math.max(newMinHeight, maxHeight);
                textArea.setAttribute('style', 'height:' + newHeight + 'px;overflow-y:auto;');
                $(this).addClass('beauty-scroll');
            } else {
                let newHeight = Math.max(newMinHeight, textArea.scrollHeight);
                textArea.setAttribute('style', 'height:' + newHeight + 'px;overflow-y:hidden;');
            }

        }).on('input', function () {

            if (this.scrollHeight <= maxHeight && this.scrollHeight >= newMinHeight ) {
                this.style.height = 'auto'; //required, though redundant
                this.style.height = this.scrollHeight + 'px';
            } else if (this.scrollHeight > maxHeight) {
                $(this).addClass('beauty-scroll');
                this.style.height = maxHeight + 'px';
                this.style.overflow = 'auto';
            }
        });
    }

    /**
    * Correction for Chrome scrollBug, see https://stackoverflow.com/questions/63747542/ or https://stackoverflow.com/questions/56329625/
    */
    async adjustScrollBugInTextarea(textareaElem, focusElement) {
        let lastScrollPosition = 0;
        let windowScrollDisabled = focusElement !== 'window' ? true : false;

        if (windowScrollDisabled) {
            $(textareaElem).closest(focusElement).scroll(function(){
                lastScrollPosition = $(this).scrollTop();
            });
        } else {
            window.onscroll=function(){
                lastScrollPosition = (window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop);
            }
        }

        $(textareaElem).on('input', function () {
            if (lastScrollPosition > 0 && windowScrollDisabled) {
                $(this).closest(focusElement).scrollTop(lastScrollPosition);
            } else if (lastScrollPosition > 0 && !windowScrollDisabled) {
                window.scrollTo(0, lastScrollPosition);
            }
        });
    }

    setRating(ratingValue) {
        var emptyStar = ' <i class="material-icons">star_border</i>';
        var halfStar = ' <i class="material-icons">star_half</i>';
        var fullStar = ' <i class="material-icons">star</i>';

        var ratingHtml = '';

        if (ratingValue < 0.25) {
            ratingHtml += emptyStar + emptyStar + emptyStar + emptyStar + emptyStar;
        }
        else if (ratingValue >= 0.25 && ratingValue < 0.75) {
            ratingHtml += halfStar + emptyStar + emptyStar + emptyStar + emptyStar;
        }
        else if (ratingValue >= 0.75 && ratingValue < 1.25) {
            ratingHtml += fullStar + emptyStar + emptyStar + emptyStar + emptyStar;
        }
        else if (ratingValue >= 1.25 && ratingValue < 1.75) {
            ratingHtml += fullStar + halfStar + emptyStar + emptyStar + emptyStar;
        }
        else if (ratingValue >= 1.75 && ratingValue < 2.25) {
            ratingHtml += fullStar + fullStar + emptyStar + emptyStar + emptyStar;
        }
        else if (ratingValue >= 2.25 && ratingValue < 2.75) {
            ratingHtml += fullStar + fullStar + halfStar + emptyStar + emptyStar;
        }
        else if (ratingValue >= 2.75 && ratingValue < 3.25) {
            ratingHtml += fullStar + fullStar + fullStar + emptyStar + emptyStar;
        }
        else if (ratingValue >= 3.25 && ratingValue < 3.75) {
            ratingHtml += fullStar + fullStar + fullStar + halfStar + emptyStar;
        }
        else if (ratingValue >= 3.75 && ratingValue < 4.25) {
            ratingHtml += fullStar + fullStar + fullStar + fullStar + emptyStar;
        }
        else if (ratingValue >= 4.25 && ratingValue < 4.75) {
            ratingHtml += fullStar + fullStar + fullStar + fullStar + halfStar;
        }
        else if (ratingValue >= 4.75) {
            ratingHtml += fullStar + fullStar + fullStar + fullStar + fullStar;
        }
        return ratingHtml;
    }

    styleShariff() {
        var buttonsContainer = $('.shariff');
        new Shariff(buttonsContainer, {
            orientation: 'horizontal',
            url: "https://www.produck.de",
            mailUrl: "mailto:",
            mailBody: decodeURI (i18next.t('shariff.mailBody1')),
            lang: "de",
            infoUrl: "https://produck.de#info",
            title: i18next.t('shariff.title'),
            services: "[whatsapp; telegram; facebook; twitter; xing; linkedin; mail;]",
            mediaUrl: "/assets/img/android-chrome-512x512.png",
            buttonStyle: "icon",
            theme: "standard",
            referrerTrack: null,
            twitterVia: null
        });
    }

    styleShareShariff(refUrl, title, mailBody) {
        var buttonsContainer = $('.share-shariff');
        new Shariff(buttonsContainer, {
            orientation: 'horizontal',
            url: refUrl,
            mailUrl: "mailto:",
            mailBody: mailBody,
            lang: "de",
            infoUrl: refUrl,
            title: title,
            services: "[facebook; twitter; xing; linkedin; mail;]",
            buttonStyle: "icon",
            theme: "standard",
            referrerTrack: null,
            twitterVia: null
        });
    }

    initShareContent() {
        const instance = this;

        $(document).on('click', '.share-brand > .share', function (e) {
            // for quacksSite get href from current site
            let questionRefDetailSite = window.location.href;
            let questionTextDetailSite = $("#headline-block > h1").text();

            // remove anything after a potential # first, to get rid of "#!"
            questionRefDetailSite = questionRefDetailSite.split('#')[0];

            let targetId = $(e.target).attr("data-link-target-id");
            if (targetId) {
                questionRefDetailSite += "#" + targetId;
            }
            let mailBody =  i18next.t('shariff.shareQuack') + ': ' + questionTextDetailSite + " - Link: ";
            createShareCard(questionRefDetailSite, questionTextDetailSite, mailBody);
        });

        $(document).on('click', '.data_descr.custid', function () {
            let questionRefSingleCard = 'https://produck.de/chat.html?cid=' + $('.data_descr.custid').data('id');
            let questionTextSingleCard = 'Mein Produck.de-Channel:';
            let mailBody =  i18next.t('shariff.mailBody2') + ' ' + questionRefSingleCard;
            createShareCard(questionRefSingleCard, questionTextSingleCard, mailBody);
        });

        $(document).on('click', '#profile-wrapper .data_descr.custid', function () {
            let questionRefSingleCard = 'https://produck.de/profile/' + $('.data_descr.custid').data('id');
            let questionTextSingleCard = 'Mein Produck.de-Channel:';
            let mailBody =  i18next.t('shariff.mailBody2') + ' ' + questionRefSingleCard;
            createShareCard(questionRefSingleCard, questionTextSingleCard, mailBody);
        });

        $(document).on('click', '.views > .share', function () {
            let questionRefSingleCard = $(this).parents('.dialogue-summary').find('h3 > a').attr('href');
            let questionTextSingleCard = $(this).parents('.dialogue-summary').find('h3 > a').text();
            let mailBody =  i18next.t('shariff.shareQuack') + ': '+ questionTextSingleCard + " - Link: ";
            createShareCard(questionRefSingleCard, questionTextSingleCard, mailBody);
        });

        function createShareCard(href, title, mailBody) {
            if (navigator.share) {
                navigator
                .share({
                    title: title,
                    text: mailBody,
                    url: href,
                })
                .catch((error) => instance.log.error('Error sharing', error));
            }
            else if (!navigator.share) {
                $(".share-url").val(href);
                $('#share-modal').css({ "display": "flex" });
                instance.styleShareShariff(href, title, mailBody);
                instance.copytoClipboard(href,  $('#share-modal').find('.content-copy'));
                instance.closeShareCard();
            }
        }
    }


    initCopytoClipboard(inputVal, triggerBtn) {
        const instance = this;

        triggerBtn.on('click', function () {
            instance.copytoClipboard(inputVal);
        });
    }

    copytoClipboard(inputVal) {

        // Create textarea element
        let textarea = document.createElement('textarea');

        // Set the value of the text
        textarea.value = inputVal;

        // Make sure we cant change the text of the textarea
        textarea.setAttribute('readonly', '');

        // Hide the textarea off the screnn
        textarea.style.position = 'absolute';
        textarea.style.left = '-9999px';

        // Add the textarea to the page
        document.body.appendChild(textarea);

        // Copy the value of the textarea
        textarea.select();

        try {
            var successful = document.execCommand('copy'); //jshint ignore:line
            this.copied = true;
        } catch (err) {
            this.copied = false;
        }
        textarea.remove();
    }


    closeShareCard() {
        $(document).on('click', '#close-share-modal', function () {
            $('#share-modal').css({ "display": "none" });
        });
    }

    controlSoundPlay() {
        var instance = this;
        instance.checkTabActiveStatus();

        if (instance.tabStatus && !instance.soundPlayed) {
            /**
            * @license This sound is provided under a Creative Commons Attribution license. See https://creativecommons.org/licenses/   by/4.0/   legalcode (including disclaimer of warranties).
            * Under Copyright of https://notificationsounds.com
            * Source: https://notificationsounds.com/message-tones/to-the-point-568, August 2018
            * Creator unknown
            */
            var sound = new Audio('/assets/snd/to-the-point.mp3'); //license https://notificationsounds.com/message-tones/to-the-point-568
            sound.play();
            instance.soundPlayed = true;
        }

        setTimeout (() => { instance.soundPlayed = false; }, 3000);
    }

    checkTabActiveStatus() {
        var instance = this;

        var hidden,
            visibilityChange;

        if (typeof document.hidden !== "undefined") {
            hidden = "hidden";
            visibilityChange = "visibilitychange";
        } else if (typeof document.mozHidden !== "undefined") {
            hidden = "mozHidden";
            visibilityChange = "mozvisibilitychange";
        } else if (typeof document.msHidden !== "undefined") {
            hidden = "msHidden";
            visibilityChange = "msvisibilitychange";
        } else if (typeof document.webkitHidden !== "undefined") {
            hidden = "webkitHidden";
            visibilityChange = "webkitvisibilitychange";
        }

        document.addEventListener(visibilityChange, handleVisibilityChange(), true);
        //tabstatus "true" means document hidden, window is in background
        function handleVisibilityChange() {
            instance.tabStatus = document[hidden];
        }
    }


    buildWidgetItem(productObj, widgetType) {

        let teaserList = "",
            primeString = "",
            textToViewportRatio = window.matchMedia("(max-width: 991px)").matches ? 55 : 150;

        if (productObj.features !== null) {

            let teaserListElems = "";

            productObj.features.forEach( (bulletPoint, i) => {

                let bulletPointHtmlFree = bulletPoint.replace(/<[^>]*>+/gm, '').trim();

                if (i <= 2) teaserListElems += bulletPointHtmlFree.length > textToViewportRatio ? '<li>' + bulletPointHtmlFree.substr(0, textToViewportRatio) + '...</li>' : '<li>' + bulletPointHtmlFree + '</li>';
            });

            teaserList = '<ul>'+teaserListElems+'</ul>';

        } else if (productObj.features !== null && productObj.description) {
            teaserList = '<ul><li>'+productObj.description.substr(0, textToViewportRatio)+'</li></ul>';
        }

        function transformPrice (price, currency, alternativeText, gtagTrigger) {

            function setAlternativeTextAndTriggerAnalytics() {

                if (gtagTrigger) {
                    dataLayer.push({
                        'event': 'Product Price Not Found',
                    });
                }
                return alternativeText
            }

            return price && price !== null ? price.toFixed(2) + '&nbsp;&euro;' : setAlternativeTextAndTriggerAnalytics();
        }

        if (productObj.premiumDelivery) primeString = '<div class="prime-status"><img src="/assets/img/icons/amazon-prime.png" alt="Amazon Prime Logo" loading="lazy"></div>';

        let price = transformPrice(productObj.price, productObj.currency, 'Ohne Preisangabe', true),
            basePrice = productObj.basePrice > productObj.price ? transformPrice(productObj.basePrice, productObj.currency, '', false) : '',
            discount = basePrice.length > 0 && productObj.discount !== null ? '-' + productObj.discount : '',
            teaserString = '<div class="product-teaser">' + teaserList + '</div>',
            metaInfos = '<div class="product-meta-info"><span class="product-baseprice meta-info-item">'+ basePrice +'</span><span class="product-discount meta-info-item">'+ discount +'</span><span class="product-price meta-info-item">'+ price +'</span>' + primeString + '</div>',
            getMinutes = productObj.lastUpdate.minute < 10 ? '0' + productObj.lastUpdate.minute : productObj.lastUpdate.minute,
            widgetString = '<div class="prdk-widget">'
                + '<div class="product-block" data-product-id="'+productObj.referenceId+'" data-product-title="'+productObj.productName+'">'
                    + '<div class="product-block-inner ' + widgetType + '-widget">'
                        + '<div class="product-image-wrapper"><img src="'+productObj.imageUrl+'" title="Bild von '+productObj.productName+'" el="nofollow" target="_blank" alt="'+productObj.productName+'" loading="lazy" /></div>'
                        + '<div class="product-content">'
                            + '<p class="product-title dark-tealco">'+ (productObj.productName.length > textToViewportRatio ? productObj.productName.substr(0, textToViewportRatio) + '...' : productObj.productName) +'</p>'
                            + teaserString
                            + metaInfos
                            +'<div class="product-button">'
                                +'<a class="prdk-btn amazon-buy-btn" href="'+productObj.productUrl+'" title="'+i18next.t('text.view_on_amzn')+'" target="_blank" rel="nofollow noopener"><i class="fab fa-amazon"></i>'+i18next.t('text.view_on_amzn')+'</a>'
                            +'</div>'
                            +'<div class="product-notes">'
                                +'<span class="product-price-info">Preis inkl. MwSt., zzgl. Versandkosten. Letzte Aktualisierung am '+productObj.lastUpdate.dayOfMonth+'.'+productObj.lastUpdate.monthValue+'.'+productObj.lastUpdate.year+' um '+ productObj.lastUpdate.hour +':'+ getMinutes +' Uhr (UTC). <a href="#affiliate-note">Weitere Infos*</a></span>'
                            +'</div>'
                        +'</div>'
                    +'</div>'
                +'</div>'
            +'</div>';

        return widgetString;
    }

    linkifyText() {
        var instance = this;

        // match all letters between square brackets if followed by "url"
        const uglyLinksPattern = /\[url(?:=|&#61;)("|&quot;|&#34;)(.*?)\1(?:,name(?:=|&#61;)("|&quot;|&#34;))(.*?)\1(?:,title(?:=|&#61;)("|&quot;|&#34;))(.*?)\1]/gim;

        const asinPattern = /\[asin(?:=|&#61;)("|&quot;|&#34;)(.*?)\1(?:,type(?:=|&#61;)("|&quot;|&#34;))(.*?)\1]/gim;

        const tableOfContentPattern = /\[(tableofcontent|toc)]/i;

        const inTextDealsPattern = /\[(topdeals)]/gi;

        /*
        (?!     # Negative lookahead start (will cause match to fail if contents match)
        [^<]*   # Any number of non-'<' characters
        >\]     # A character of the following set > ]
         )      # End negative lookahead.
        */
        // http://, https://, ftp://
        const urlPattern = /\b(?![^<|\[]*[>\]])(?:https?|ftp):\/\/([a-z0-9-+&@#\/%?=~_|!:,.;]*[a-z0-9-+&@#\/%=~_|])/gim;

        // www. sans http:// or https://
        const pseudoUrlPattern = /(^|[^\/])(\bwww\.[a-z0-9-+&@#\/%?=~_|!:,.;]*[a-z0-9-+&@#\/%=~_|])/gim;

        // Email addresses
        const emailAddressPattern = /[\w.]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+/gim;

        if(!String.linkify) {
            String.prototype.linkify = function(note, userId) {
                let textInput = this;
                let asinTagMatches = [...textInput.matchAll(asinPattern)];
                let linkifyRunUid = Math.random().toString(16).slice(2);                

                let verifyInternalOrigin = function (match, returnValFalse = "", returnValTrue = "") {
                    return match.indexOf("produck.de") === -1 ? returnValFalse : returnValTrue;
                  };

                //replace arguments defined here: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace
                let beautifyLinks = function (match, p1, p2, p3, p4, p5, p6, offset, string) {
                    let linkNote = verifyInternalOrigin(p2, note);
                    let relAttr = verifyInternalOrigin(p2,'target="_blank" rel="noopener nofollow ugc"', 'rel="ugc"');
                    let newLink = '<a '+relAttr+' href="'+p2+'" title="'+p6+'">'+p4+'</a>' + linkNote;
                    return newLink;
                };

                if (asinTagMatches.length > 0) {
                    const catalogueService = monstecLib.produckContext.catalogueService;
                    //extract all asins reduced by duplicates and update array
                    let asinsDuplRed = [...new Set(asinTagMatches.map(pattern => pattern[2]))];

                    //check if ASINS were already requested and if so remove those from api request
                    if (instance.requestedAsinsCache.length) {
                        asinsDuplRed.forEach(function(el, i) {

                            console.log('AsinsInCache: ', instance.requestedAsinsCache);                                                       
                            console.log('IncludesAsinTest: ', el, instance.requestedAsinsCache[0].includes(el));

                            if (instance.requestedAsinsCache[0].includes(el)) {
                                asinsDuplRed = asinsDuplRed.filter(x => x !== el);

                                console.log('RemainingRequestAsinsAfterIteration: ', asinsDuplRed);

                            } else {
                                instance.requestedAsinsCache[0].push(el);
                            }
                        });
                    } else {
                        instance.requestedAsinsCache.push(asinsDuplRed);
                    }

                    console.log('remainingUniqueAsins: ', asinsDuplRed);

                    // put placeholders for the widgets in the input that will be replaced later (equal asins get same runid across blocks)
                    // as soon as the widget data will have been fetched from the catalogue service
                    let insertWidgetPlaceholder = function (match, p1, asin, p3, widgetType) {
                                         
                        //let placeHolderReference = linkifyRunUid + "-" + asin;
                        let placeHolderReference;
                        let loaderPart = instance.getLoaderHtml('small');
                        let runIdFromCache = instance.asinRunIdCache[asin]; //if asin was not requested before with other runId

                        if (runIdFromCache) {
                            placeHolderReference = runIdFromCache + "-" + asin;
                            linkifyRunUid = runIdFromCache;
                        } else {
                            placeHolderReference = linkifyRunUid + "-" + asin;
                            instance.asinRunIdCache[asin] = linkifyRunUid;    
                        }

                        let replacement = `<div class="js-widget-placeholder full-width-center-content-row" data-widget-type="${widgetType}" `
                                          + `data-ref="${placeHolderReference}" style="margin: 20px 0">${loaderPart}</div>`;     
                    

                        return replacement;
                    };

                    textInput = textInput.replace(asinPattern, insertWidgetPlaceholder);

                    if (asinsDuplRed.length > 0) {
                        
                        const amazonProductsPromise = catalogueService.getAmazonProductData(asinsDuplRed, userId);
                        amazonProductsPromise.then(productDataRecords => {
                            instance._replacePlaceholdersWithWidgets(productDataRecords, instance.asinRunIdCache[asinsDuplRed[0]]);
                        }).catch(function (err) {
                            instance.log.error('Could not retrieve product information. Amazonify failed. Status: ' + err.status);
                        });
                    }                    
                }

                let attrExtLink = 'target="_blank" rel="noopener nofollow ugc"';
                let attrIntLink = 'rel="ugc"';

                //caution - order of replace method matters
                return textInput
                    .replace(uglyLinksPattern, beautifyLinks)
                    .replace(urlPattern, (match) => `<a ${verifyInternalOrigin(match, attrExtLink, attrIntLink)} href="${match}">${match}</a>${verifyInternalOrigin(match, note)}`)
                    .replace(pseudoUrlPattern, (match, p1, p2) => `${p1}<a ${verifyInternalOrigin(p2, attrExtLink, attrIntLink)} href="https://${p2}">${p2}</a>${verifyInternalOrigin(p2, note)}`)
                    .replace(emailAddressPattern, '<a href="mailto:$&">$&</a>');
            }
        }


        if(!String.addTableOfContent) {
            String.prototype.addTableOfContent = function(target) {
                let textInput = this;                                      

                function buildToC() {

                    let tocListElems = '';

                    //create index list linked to headlines
                    target.each(function(index, obj) {
                        let newObj = $(obj)[0],
                            id = $(newObj).attr("id"),
                            text = $(newObj).text();

                        tocListElems += `<li><a href="#${id}">${text}</a></li>`;
                    });

                    let toc = '<div class="prdk-toc"><h2 id="toc-headline">In diesem Artikel</h2><ol class="table-of-contents">' + tocListElems + '</ol></div>';

                    return toc;
                }

                return textInput.replace(tableOfContentPattern, buildToC);

            }
        }

        if(!String.addDealBox) {
            String.prototype.addDealBox = function(matchedProdArr) {
                let textInput = this;                           

                function buildInTextOffer() {

                    let offerListElems = '';
                    let today = new Date().getDay();
                    let time = (today > 10) ? "08:15" : (today > 20) ? "08:17" : "08:11"; 

                    for (const [index, item] of matchedProdArr.entries()) {
                        let price = item.price ? ' für ' + item.price + '&euro;': '';
                        offerListElems += `<li><a class="fs-14" href="${item.link}" target="_blank">${item.title}${price}*</a></li>`;
                    if (index === 2) break;
                }

                let text = '<div class="prdk-intxt-box"><strong>Folgende Angebote könnten dich interessieren:</strong><ul>' + offerListElems + '</ul><span id="marketing-cookie-hint">Stand: '+time+' Uhr (UTC). <a href="#affiliate-note">Weitere Infos*</a> | Sie möchten uns unterstützen? Für Käufe über unsere Partnershops erhalten wir eine Provision. Für ein korrektes Tracking ist es jedoch notwendig, dass Sie die Marketingcookies unserer Partner annehmen. Der Preis ändert sich dadurch nicht. Wir würden uns über Ihre Unterstützung freuen und wünschen weiterhin viel Spaß auf unserer Seite. Ihr ProDuck Team</span></div>';

                return text;
            }

            let replaceString = matchedProdArr.length > 0 ? buildInTextOffer() : '';
            return textInput.replace(inTextDealsPattern, replaceString);
        }
    }

    return {uglyLinksPattern, urlPattern, pseudoUrlPattern, emailAddressPattern, asinPatterns: asinPattern, tableOfContentPattern, inTextDealsPattern};
}


/**
 * Replaces placeholders on the page that are designated for advertisement widgets.
 *
 * @param {*} advertisementItems an array of advertisement items
 */
_replacePlaceholdersWithWidgets(advertisementItems, placeholderRefPrefix) {
    if (!advertisementItems) return;
    const instance = this;

    function replaceFnc() {

        advertisementItems.forEach(function(advertisementItem) {

            let placeHoldersForAsin = $(`div[data-ref=${placeholderRefPrefix}-${advertisementItem.referenceId}]`);

            placeHoldersForAsin.each(function(index, placeholder) {
                let placeholderElement = $(placeholder);
                let widgetType = placeholderElement.attr('data-widget-type');
                let widgetHtml = instance.buildWidgetItem(advertisementItem, widgetType);

                placeholderElement.replaceWith($(widgetHtml));

            });
        });
    }

    replaceFnc();

    function replaceRemainingPlaceholders () {

        let remainingElements = $(".js-widget-placeholder[data-ref^='"+placeholderRefPrefix+"-']");
                
        if(remainingElements.length) {

            console.log("PRODUCT LOAD RETRY");
                
            replaceFnc();            

            let remainingElementsAfterRetry = $(".js-widget-placeholder[data-ref^='"+placeholderRefPrefix+"-']");

            if (remainingElementsAfterRetry.length) {

                remainingElements.replaceWith('<p class="fs-12" style="text-indent: 20px"><em>Produkt nicht gefunden</em><p>');

                console.log("PRODUCT LOAD FAILED");

                // trigger google analytics event
                dataLayer.push({
                    'event': 'Product Not Found',
                });

            } else {
                console.log("PRODUCT LOAD RETRY SUCCEEDED");
            }

        }        
    }

    setTimeout (() => { replaceRemainingPlaceholders(); instance.clearAsinCache(); }, 10000);
}

clearAsinCache () {
    this.asinRunIdCache = [];
    this.requestedAsinsCache = [];
}

//convert url in textelems to clickable links
// there is a duplicate _linkifyDialogue used for all quack-pages
async linkifyDialogue(textElem, userId) {
    let instance = this,
        linkPatterns = instance.linkifyText(),
        textinHTML = textElem.html(),
        linkFound = false;

    // just replace text if containing urlPattern
    if (textinHTML.match(linkPatterns.uglyLinksPattern) || textinHTML.match(linkPatterns.urlPattern) || textinHTML.match(linkPatterns.pseudoUrlPattern) || textinHTML.match(linkPatterns.emailAddressPattern) || textinHTML.match(linkPatterns.asinPatterns)) {
        let note = '*',
            linkedText = await textinHTML.linkify(note, userId);

        textElem.html(linkedText);
        linkFound = true;

        if ($('#affiliate-note').length === 0) setAffiliateNote();
    }


    function setAffiliateNote () {
        if (linkFound && $('#affiliate-note').length === 0) {

            let affiliateNote = "<p id='affiliate-note'>* Bitte beachten Sie, dass Links auf dieser Seite Links zu Werbepartnern sein k&ouml;nnen. F&uuml;r K&auml;ufe, die &uuml;ber einen dieser Links zustande kommen, erhalten wir (falls sie die Marketingcookies des Werbepartners annehmen) Provision. Ihnen entstehen dadurch keine zus&auml;tzlichen Kosten. Sie unterstützen jedoch unseren Service (<a href='/docu/general.html#affiliate-links' rel='nofollow' target='_blank'>mehr erfahren</a>). Preise, Lieferbedingungen und Verf&uuml;gbarkeiten entsprechen dem angegebenen Stand (Datum/Uhrzeit) und können sich jederzeit ändern. Angaben auf unserer Seite weichen daher ggf. von denen der Partnerseiten ab. Für den Kauf eines betreffenden Produkts gelten die Angaben zu Preis und Verfügbarkeit, die zum Kaufzeitpunkt auf der/den maßgeblichen Website(s) (z.B. Amazon) angezeigt werden. Bestimmte Inhalte, die auf dieser Website angezeigt werden, stammen von Amazon. Diese Inhalte werden‚ 'wie besehen' bereitgestellt und können jederzeit geändert oder entfernt werden.</p>";
            $('.mons-footer').append(affiliateNote);
        }
    }
}

buildPagination(parent, numPages, page, pageLoadCallback) {
    let chevronLeft = $('<li><a><i class="material-icons">chevron_left</i></a></li>');
    if (page < 2) {
        chevronLeft.addClass('disabled');
    } else {
        chevronLeft.find('a').on('click', function() {
            pageLoadCallback(page - 1);
        });
    }
    parent.append(chevronLeft);

    // There should be at most to pages left or right from the current page
    let numberOfPagesBeforeCurrent = Math.min(page, 2);
    let numberOfPagesAfterCurrent = Math.min(numPages - page, 2);

    let startPageLink = page - numberOfPagesBeforeCurrent + 1; // +1 because of current page
    let numPageLinks = numberOfPagesBeforeCurrent + numberOfPagesAfterCurrent;

    for (let i = startPageLink; i < startPageLink + numPageLinks; i++) {
        let pageLink = $('<li><a>' + i + '</a></li>');
        if (i == page) {
            pageLink.addClass('active');
        } else {
            pageLink.addClass('waves-effect');
            pageLink.on('click', function() {
                pageLoadCallback(i);
            });
        }
        parent.append(pageLink);
    }

    let chevronRight = $('<li><a><i class="material-icons">chevron_right</i></a></li>');
    if (page >= numPages) {
        chevronRight.addClass('disabled');
    } else {
        chevronRight.find("a").on('click', function () {
            pageLoadCallback(page + 1);
        });
    }
    parent.append(chevronRight);
}

htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    var content = doc.documentElement.textContent.replace("<script>", "&lt;script&gt;")
                                                 .replace("</script>", "&lt;/script&gt;")
                                                 .replace("onerror", "on&macr;error");
    return content;
}

/**
* @param {size} the size of the loader determines border width, width and height - use big, medium, tiny, adaptive
* @param {transparency} sets a semi-transparent blackish background - use transparent
* @param {parent} defines the parent element, to which the loader is appended to, i.e. document.getElementById('target8')
**/
initLoader() {
    let loaderFrame = document.createElement('div');
    let loaderElement = document.createElement('div');

    $(document).on( "loader:on", (event, size, transparency, parent) => {

        if ($("#produck-loader-frame").length === 0 ) {
            loaderFrame.id = 'produck-loader-frame';
            loaderFrame.style.zIndex = '2147483647';
            loaderElement.className = 'loader';
            loaderFrame.appendChild(loaderElement);

            if (transparency) loaderFrame.classList.add(transparency);
            if(size) loaderElement.classList.add(size);

            if (parent) {
                loaderFrame.classList.add('loader-is-sub');
                parent.appendChild(loaderFrame);
            } else {
                document.body.appendChild(loaderFrame);
            }
        }
    });

    $(document).on( "loader:off", () => {
        loaderFrame.remove();
    });
}

/**
 * Adds a loader to an element in the specified size.
 * Possible values for size:
 * 'tiny' :  20px
 * 'medium': 70px
 * 'big':    120px
 * 'adaptive': 100% of the parent
 *
 * if size is left out it defaults 50px
 *
 * @param {*} parent the element the loader will be appended to
 * @param {*} size defines the size of the loader
 */
addLoader(parent, size) {
    const instance = this;

    if (parent == undefined) {
        instance.log.error('Can not add loader to undefined!');
        return;
    }

    if (!parent.jquery) {
        parent = $(parent);
    }

    let loader = $(this.getLoaderHtml(size));

    parent.append(loader);
}

getLoaderHtml(size) {
    let sizeClass = '';
    if (size === 'big' || size === 'medium' || size === 'small' || size === 'tiny' || size === 'adaptive') {
        sizeClass = ' ' + size;
    }

    return `<div class="js-produck-loader loader${sizeClass}"></div>`;
}

/**
 * Removes a loader that has been previously added by the above function 'addLoader'.
 *
 * @param {*} element the element to remove the loader from
 */
removeLoader(element) {
    const instance = this;

    if (element == undefined) {
        instance.log.error('No loader can be removed from "undefined"!');
        return;
    }

    if (!element.jquery) {
        element = $(element);
    }

    element.find('.js-produck-loader').remove();
}

/**
 * @param {*} button a JQuery element representing a button
 * @param {boolean} dynamicWidth If false this function preserve the current height of the button when adding the loader;
 *                               this means, the button will have a fixed length during the display of the loader. That is useful
 *                               if the button's dimensions are defined by its content and it should have the same dimensions while
 *                               showing the loader (and not shrink to fit the loader). If the buttons dymensions are defined
 *                               otherwise, for example 100% of its parent, then set dynamicWidth to true so that the button will
 *                               adere to the style-definitions even while showing the loader.
 * @param {boolean} dynamicHeight analogous to dynamicWidth
 */
addButtonLoader(button, dynamicWidth = false, dynamicHeight = false) {
    button.prop('disabled', true);
    let width = button.width();
    let height = button.height();

    // Preserve button-interior and -dimensions. For the waves effect MaterializeCSS adds a div to the button.
    // That must be removed in the preserved html-string otherwise the restored button will look like pressed
    // later.
    button.find('.waves-ripple').remove();
    button.data('preservedContent', button.html());

    button.html('');
    this.addLoader(button, 'tiny');

    if (!dynamicWidth) button.width(width);
    if (!dynamicHeight) button.height(height);
}

removeButtonLoader(button) {
    button.prop('disabled', false);
    this.removeLoader(button);
    button.html(button.data('preservedContent'));
    button.width('');
    button.height('');
    button.blur();
}

/**
 * Adds a counter to a textfield and will display it in the given label element.
 *
 * @returns a function that will update the counter using the current state of the linked text field
 */
addCharacterCounter(textField, countLabel, minChars = 1, maxChars = 4000, nullAllowed = false) {
    if (!countLabel) {
        countLabel = $('<span class="js-number-of-chars-hint number-of-chars-label"></span>'); //Minimum nicht erreicht
        countLabel.insertAfter(textField);
    }

    let updateFunction = function() {
        let currentText = textField.val();
        let currentLength = (!!currentText) ? currentText.length : 0;

        if (nullAllowed && currentLength == 0) {
            countLabel.text('');
            textField.removeClass('invalid');
        } else if (currentLength >= minChars) {
            countLabel.text(currentLength + '/' + maxChars);
            if (currentLength > maxChars) {
                textField.addClass('invalid');
            } else {
                textField.removeClass('invalid');
            }
        } else {
            let label = i18next.t('general.minimum_character_count_label', { min: minChars});
            countLabel.text(label);

            if (currentLength > 0 && currentLength < minChars) {
                textField.addClass('invalid');
            } else {
                textField.removeClass('invalid');
            }
        }
    };

    textField.on('input', updateFunction);
    updateFunction();

    return updateFunction;
}

togglePassword(input) {
    var togglePw = input.next('.toggle-password');
    togglePw.on('click', function() {
        $(this).toggleClass("fa-eye fa-eye-slash");
        input.attr('type') === 'password' ? input.attr('type','text') : input.attr('type','password');
    });
}

convertSourceLink(chatRequest) {
    let productLink;
    if (chatRequest.link && chatRequest.product) {
        productLink = '<p class="data_descr source_link"><a href="' + chatRequest.link + '" target="_blank">' + chatRequest.product + '</a></p>';
    } else if (!!chatRequest.product) {
        productLink = '<p class="data_descr source_link">' + chatRequest.product + '</p>';
    } else if (!!chatRequest.link) {
        productLink = '<p class="data_descr source_link"><a href="' + chatRequest.link + '" target="_blank">' + chatRequest.link + '</a></p>';
    } else {
        productLink = '';
    }

    return productLink;
}

addWswgBar(textarea) {
    var instance = this;
    instance.wysiwyg = new monstecLib.WysiwygControl(instance);

    textarea.not('.wswg-active').each(function() {
        let wswgBar = instance.wysiwyg.createWysiwygBarHTML();
        let wysiwygWrapper = $(wswgBar.wrapper);
        $(this).before(wysiwygWrapper);
        instance.wysiwyg.initPasteTextToCursorPosition(wysiwygWrapper, wswgBar.buttons, this);
        instance.wysiwyg.handleShortCuts(this);
        $(this).addClass('wswg-active');
    });
}

wrapParagraphs(content) {

    let paragraphs = /^(?=\w.|<(strong|em|i|b|u|span|a|s)>)(?!<(p|h1|h2|h3|h4|h5|h6|blockquote|img|table|thead|tbody|tr|th|td|ul|ol|li|iframe|outerbox|innerbox)+?>.*|\n|\r|\t<\/\1>)(?!<(img|br)).*$/gm; // "$" match position at start of line and "^" endofline and replace with p-tags

    let wrappedParagraphs = content.replace(paragraphs, "<p>$&</p>");

    return wrappedParagraphs;
}

//upload from pictures to plain inputFields
initImgUpld(inputField) {
    var instance = this;
    instance.wysiwyg = new monstecLib.WysiwygControl(instance);

    inputField.on('click', (ev) => {
        let textBody = instance.wysiwyg.imgUpldContHTML(),
            modalBox = createImgUpldModal(textBody),
            imgContainer = $(modalBox).find('.img-upload-container');

        imgContainer.addClass('editor-active');
        instance.wysiwyg.initImageUpload(imgContainer, ev.currentTarget);

        let modalCloseBtns = imgContainer.find('.img-upload-confirm-area').find('a');

        $(modalCloseBtns).each(function() {
            $(this).addClass('modal-close');
        });
    });

    function createImgUpldModal(textBody) {

        var modalBox = $('<div id="imgUpldModal" class="modal dynamic-modal">'
        + '<div class="modal-content">'
        +  textBody
        + '</div>'
        + '</div>');

        $('body').append(modalBox);

        modalBox.modal({
            dismissible: false,
            onCloseEnd: instance._removeUnusedModalLayers
        });

        modalBox.modal('open');

        return modalBox;
    }
}

// adjusts width or position, with optional delay
adjustTabIndicator(tabs, width, position, delay) {
    var instance = M.Tabs.getInstance(tabs);

    if (!delay) delay = 0;

    setTimeout (() => {

        if (width) {
            let indicator = tabs.find('.indicator'),
                tabWidth = tabs.find('.active').outerWidth();

            indicator.width(tabWidth);
            tabs.find('.tab').one('click', function() {
                indicator.width('')
            });
            instance.updateTabIndicator();

        } else if (position) {
            instance.updateTabIndicator();
        } else {
            let indicator = tabs.find('.indicator').hide();
            tabs.find('.tab').one('click', function() {
                indicator.show();
            });
        }
    }, delay);
}

/**
 * Returns the tooltip options to create a specified arrow and text in a tooltip.
 * To define text, set data-tooltip-content in html element
 * To define arrow position, set data-position in html element
 * @param {*} tooltipEle selects all tooltips, with same desired format
 */
addToolTipWithArrow(tooltipEle, furtherOptions) {

    var position = tooltipEle.data('position'),
        text = tooltipEle.data('tooltip-content'),
        customClass = tooltipEle.data('tooltip-class') ? tooltipEle.data('tooltip-class') : '',
        htmlInput;

    if (position === 'right') {
        htmlInput = '<div class="tooltip-html arrow-lft '+ customClass +'">'+  text + '</div>'
    }
    else if (position === 'left') {
        htmlInput = '<div class="tooltip-html arrow-rgt '+ customClass +'">'+  text + '</div>'
    }
    else if (position === 'top') {
        htmlInput = '<div class="tooltip-html arrow-btm '+ customClass +'">'+  text + '</div>'
    }
    else if (position === 'bottom') {
        htmlInput = '<div class="tooltip-html arrow-top '+ customClass +'">'+  text + '</div>'
    }

    var options = {
        html: htmlInput,
    }

    if (furtherOptions) $.extend( options, furtherOptions );

    return options;
}

initShadowAndSideNavZIndex() {
    $('section').each(function (i) {

        if ($(this).hasClass('scrollspy')) {
            // create smooth shadow effect
            $(this).css({ zIndex: $('section').length - i + 1 });
        }
        else {
            // h1 and nav-wrapper get highest z-index
            $(this).css({ zIndex: $('section').length + 1 });
            $('.nav-wrapper').css({ zIndex: $('section').length + 2 });
        }
    });
}

returnCursorPosition(textField) {

    // find position for text substitution
    $.fn.getCursorPosition = function() {
        var tf = $(this).get(0);
        var pos = 0;
        var selLength = 0; //if text fragment is selected, set length of fragment enable fragment substitution

        if('selectionStart' in tf) {
            pos = tf.selectionStart;
            selLength = tf.value.substring(tf.selectionStart, tf.selectionEnd).length;
        } else if('selection' in document) {
            tf.focus();
            var sel = document.selection.createRange();
            selLength = sel.text.length;
            sel.moveStart('character', -tf.value.length);
            pos = sel.text.length - selLength;
        }
        return {'pos': pos, 'selLength': selLength};
    };

    return  textField.getCursorPosition();
}

getSelectionText($textfield) {
    const instance = this;
    if (window.getSelection) {
        try {
            var tf = $textfield.get(0),
                selVal = tf.value.substring(tf.selectionStart, tf.selectionEnd);

            return selVal;
        } catch (e) {
            instance.log.error('Cant get selection text');
        }
    }
    // For IE
    if (document.selection && document.selection.type != "Control") return document.selection.createRange().text;
}

countWordsInQuacks(stringArray) {
    let wordCountArr = [];

    stringArray.forEach(element => {
        let text = element.text;

        if (text && text.length > 0) {

            var countWordsInBlocks = text
                .replace(/((&nbsp;)|(<[^>]*>))+/g, '') // remove html spaces and tags
                .replace(/\s+/gu, ' ') // merge multiple spaces into one
                .trim() // trim ending and beginning spaces (yes, this is needed)
                .match(/\s/g); // find all spaces by regex

            countWordsInBlocks ===  null ? countWordsInBlocks = 1 : countWordsInBlocks = countWordsInBlocks.length + 1; //always add one for the first word
            wordCountArr.push(countWordsInBlocks);
        } else {
            wordCountArr.push(0);
        }
    });

    var totalWords = wordCountArr.length > 0 ? wordCountArr.reduce((accumulator, currentValue) => accumulator + currentValue, 0) : '0';

    return totalWords
}

/**
* Initialises the form by that the customer will confirm user data and terms.
*/
validateForm(form, checkbox, submitBtn) {
    var instance = this;
    let input = form.find(".js-validate-input");
    let inputArray = input.toArray();

    // control terms-togglebox
    if (checkbox.length && checkbox.hasClass('js-verify-cb') && input.length) {
        checkbox.on("change", function () {
            if (checkbox.prop("checked")) {
                $(this).addClass("valid");
            } else {
                $(this).removeClass("valid");
            }
        });
    } else if (checkbox.length && checkbox.hasClass('js-verify-cb') && !input.length) {
        checkbox.on("change", function () {

            if (checkbox.prop("checked")) {
                submitBtn.removeClass("disabled");
                submitBtn.addClass("active");
            } else {
                submitBtn.removeClass("active");
                submitBtn.addClass("disabled");
            }
        });
    }

    if (input.length) {
        input.on("click change blur", () => instance.validateInputInForm(inputArray, submitBtn));

        // terms have to be accepted again for every reload
        checkbox.prop("checked", false);

        // Trigger validation of the text input fields at initialisation time in case of prefilled form items.
        inputArray.forEach(field => {
            if (field.type === "text" || field.type === "email" || field.type === "tel") {
                if (field.value !== "") {
                    field.checkValidity() ? $(field).addClass("valid") : $(field).addClass("invalid");
                }
            }
        });
    }
}

validateInputInForm(inputArray, submitBtn) {

    var isValid = inputArray.every(element => $("#" + element.id).hasClass("valid"));
    var isNotEmpty = inputArray.every(element => $("#" + element.id).val().length > 0);

    if (isValid && isNotEmpty) {
        submitBtn.removeClass("disabled");
        submitBtn.addClass("active");
    } else {
        submitBtn.removeClass("active");
        submitBtn.addClass("disabled");
    }
};

detectBrowser() {
    var browser = (function(agent){
        switch(true){
            case agent.indexOf("edga65") > -1: return "edge";
            case agent.indexOf("opr") > -1 && !!window.opr: return "opera";
            case agent.indexOf("samsung") > -1: return "samsung";
            case agent.indexOf("chrome") > -1 && !!window.chrome: return "chrome";
            case agent.indexOf("trident") > -1: return "ie";
            case agent.indexOf("firefox") > -1: return "firefox";
            case agent.indexOf("safari") > -1: return "safari";
            default: return "other";
        }
    })(window.navigator.userAgent.toLowerCase());

    return browser;
}

getHeightSetHeight(sourceElem, targetElem) {
    let sourceHeight = sourceElem.height();
    targetElem.height(sourceHeight);
}

initMaterializeInContentBlock() {

    let elems = document.querySelectorAll('.question-hyperlink .scrollspy');
    M.ScrollSpy.init(elems, { scrollOffset: 75});

    elems = document.querySelectorAll('.materialboxed');
    M.Materialbox.init(elems);

    elems = document.querySelectorAll('.collapsible');
    M.Collapsible.init(elems);

    elems = document.querySelectorAll('.collapsible.expandable');
    M.Collapsible.init(elems, {
        accordion: false
    });
}
}
