/* global monstecLib */
/* global M */
import ObjectList from '../common/objectlist';

/**
 * Article list for "Suggested Articles".
 */
export default class ArticleSuggestionList extends ObjectList {
    constructor(currentUserId) {
        super();
        this.MIN_CHARACTERS_SUG_FOR_IMP = 20;
        this.MAX_CHARACTERS_SUG_FOR_IMP = 10000;

        this.currentUserId = currentUserId;
        this.noResultsProperty = 'articlesection.no_articles';

        this.chatService = monstecLib.produckContext.chatService;
        this.identityService = monstecLib.produckContext.identityService;

        this.log = new monstecLib.Log(1);
    }

    /**
     * Creates a DOM-element that will display a single article in the list of articles.
     *
     * @param {*} article the article to create a list item for
     */
    _createListItem(article) {
        const instance = this;

        let itemHeaderId = instance.htmlId + 'ArticleItemHeader' + article.id;
        instance.utils.linkifyText();

        let acceptedStatusChip = (article.acceptanceTime) ? ('<div class="chip status-chip accepted" title="' + instance.utils.formatDate(article.acceptanceTime) + '">' + instance.i18next.t('articlesection.status_label_ACCEPTED') + '</div>') : '';

        let articleItemHtml = '<li class="js-collapsible-item">'
          + '  <div class="collapsible-header object-list-item" id="' + itemHeaderId + '" data-article-id="' + article.id + '" >'
          + '    <div class="article-info-header simple-vertical-content">'
          + '      <span id="' + itemHeaderId + 'Title" class="object-title current-content bold">' + article.title + '</span>'
          + '      <div class="simple-horizontal-content">'
          + '        <div class="chip status-chip ' + article.status + '">' + instance.i18next.t('articlesection.status_label_' + article.status) + '</div>'
          +          acceptedStatusChip
          + '      </div>'
          + '    </div>'
          + '    <div class="simple-horizontal-content">'
          + '      <div id="' + instance.htmlId + 'ArticleActionButtons' + article.id + '"></div>'
          + '    </div>'
          + '  </div>'
          + '  <div class="collapsible-body" data-article-id="' + article.id + '" data-user-id="' + article.userId + '">'
          + '    <div class="stats-wrapper">'
          + '      <span class="stats" title="' + instance.i18next.t("articlesection.word_count_min_label") + '">' + ((!!article.wordCountMin) ? article.wordCountMin : '&dash;') + '<i class="material-icons">subject</i>&nbsp;min</span>'
          + '      <span class="stats" title="' + instance.i18next.t("articlesection.word_count_max_label") + '">' + ((!!article.wordCountMax) ? article.wordCountMax : '&dash;') + '<i class="material-icons">subject</i>&nbsp;max</span>'
          + '      <span class="timestamp stats" title="' + instance.i18next.t("text.date_of_creation") + '">' + instance.utils.formatDate(article.creationTime) + '</span>'
          + '      <span class="stats" title="' + instance.i18next.t("articlesection.language_label") + '">' + instance.i18next.t('locales.' + article.language) + '</span>'
          + '    </div>'
          + '    <div class="js-author-block author-block">'
          + '      <div itemprop="author" itemscope itemtype="https://schema.org/Person" class="author">'
          + '        <span>' + instance.i18next.t("articlesection.author_label") + '</span>&nbsp;'
          + '        <a class="prdk-link" href="/profile/' + article.userId + '" target="_blank">'
          + '          <span itemprop="name" class="js-author-name author-name"></span>'
          + '        </a>'
          + '      </div>'
          + '    </div>'
          + '    <div class="dialogue-summary narrow left-duck">'
          + '      <div class="summary">'
          + '        <div class="current-content flex-wrap">'
          + '          <div class="w100"><b>' + instance.i18next.t('articlesection.summary_label') + '</b></div>'
          + '          <div class="vertical-spacer small"></div>'
          + '          <div class="article-hyperlink">' + ((article.summary && article.summary.length > 0) ? article.summary.linkify() : instance.i18next.t('articlesection.no_details')) + '</div>'
          + '        </div>'
          + '      </div>'
          + '    </div>'
          + '    <div class="js-article-tags edit-tags chips-initial"></div>'
          + '  </div>'
          + '</li>';

        const articleItem = $(articleItemHtml);
        articleItem.data('articleId', article.id);
        const articleItemBody = articleItem.find('.collapsible-body');

        instance._addEditableBehaviour(article, articleItem);

        const blocksArea = $('<div id="' + instance.htmlId + 'ArticleItem' + article.id + 'Blocks"></div>');
        articleItemBody.find('.dialogue-summary').after(blocksArea);

        articleItem.find('#' + itemHeaderId).on('click', async function() {
            // Only load the list of blocks if not aleady done so. So either if there already are blocks or the button
            // for adding new blocks is there (since it will be added by this function), the list has already been
            // received from the service. If there are no blocks however, there simply are none yet.
            if (blocksArea.children().length > 0 || articleItemBody.find('#newBlockButtonWrapper' + article.id).length > 0) {
                return; // already loaded
            }

            $(document).trigger("loader:on", ['', 'transparent', blocksArea[0]]);

            let blocksPromise = instance.chatService.getArticleBlocks(article.id);
            blocksPromise.then(function(blocks) {
                blocks.forEach(function(answer) {
                    let text = answer.text;
                    instance._appendArticleBlock(answer, blocksArea);
                });
            })
            .catch(function(error) {
                if (error.status != 404) {
                    instance.log.error('Error when retrieving blocks for article ' + article.id, error);
                }

                return error;
            });

            let authorPromise = instance.identityService.getPublicUserData(article.userId);
            authorPromise.then(function(user) {
                articleItem.find('.js-author-name').text(user.nickname);
            })
            .catch(function(error) {
                instance.error('User data of author could not be retrieved.', error);
                articleItem.find('.js-author-block').addClass('hide');
                return error;
            });

            Promise.allSettled([blocksPromise, authorPromise]).finally(function() {
                $(document).trigger("loader:off");
            });
        });

        var chipsElement = articleItem.find('.js-article-tags');
        if (article.tags && article.tags.forEach) {
            article.tags.forEach(function(chip) {
                chipsElement.append(`<div class="chip">${chip}</div>`);
            });
        }

        return articleItem;
    }

    /**
     * If an article item has been created by the current user this method should be called to
     * enable editing of the article.
     */
    _addEditableBehaviour(article, articleItem) {
        const instance = this;

        let headerButtonConfig = [];

        if (article.status == 'PUBLISHED') {
            headerButtonConfig.push(
                { icon: 'link', func: function() {
                    var host = "https://www.produck.de";

                    window.open(`${host}/quackref/article/${article.id}/`, '_blank');
                }}
            );
        }

        if (article.status == 'SUGGESTED') {
            headerButtonConfig.push({
                icon: 'playlist_add',
                tooltip: 'articlesection.tooltip_suggest_improvement',
                func: instance._openSuggestionModal.bind(instance, article.id)
            });

            headerButtonConfig.push({
                icon: 'playlist_add_check',
                tooltip: 'articlesection.tooltip_accept_article',
                func: function() {
                    instance._updateArticle(instance.chatService.acceptArticle.bind(instance.chatService), article.id, {html: instance.i18next.t('articlesection.toast_article_accepted')})
                    .then(instance.updateView.bind(instance));
                }
            });
        }


        let headerButtons = new monstecLib.ActionButtons(headerButtonConfig, 'left');
        headerButtons.attachTo(instance.htmlId + 'ArticleActionButtons' + article.id, articleItem);
    }

    _openSuggestionModal(articleId) {
        const instance = this;
        var sendModal = $('body').find('#sendArticleSuggestionForImprovalModel');

        if (!sendModal || sendModal.length == 0) {
            var modalBox = $('<div id="sendArticleSuggestionForImprovalModel" class="modal dynamic-modal signin-modal">'
                +   '<div class="modal-content">'
                +     '<h4>' + instance.i18next.t('articlesection.suggestion_for_improvment_modal_headline') + '</h4>'
                +     '<div class="input-field password-box col s12 m12 l12">'
                +       '<label>' + instance.i18next.t('articlesection.your_message') + '</label>'
                +       '<textarea class="js-text-input materialize-textarea chat-title-field" name="suggestionText"></textarea>'
                +     '</div>'
                +     '<div class="js-number-of-chars-hint number-of-chars-label"></div>'
                +   '</div>'
                +   '<div class="modal-footer">'
                +     '<button class="js-send-button option-one modal-close waves-effect waves-teal btn-flat" disabled="disabled">' + instance.i18next.t('general.send') + '</button>'
                +     '<button class="js-cancel-button option-two modal-close waves-effect waves-teal btn-flat">' + instance.i18next.t('general.cancel') + '</button>'
                +   '</div>'
                + '</div>');

            $('body').append(modalBox);

            let textField = modalBox.find('.js-text-input');
            let sendButton = modalBox.find('.js-send-button');

            instance.utils.addCharacterCounter(textField, modalBox.find('.js-number-of-chars-hint'), instance.MIN_CHARACTERS_SUG_FOR_IMP, instance.MAX_CHARACTERS_SUG_FOR_IMP);
            textField.on('input', function() {
                let text = textField.val();
                if (!!text && text.length >= instance.MIN_CHARACTERS_SUG_FOR_IMP && text.length <= instance.MAX_CHARACTERS_SUG_FOR_IMP) {
                    sendButton.prop('disabled', false);
                } else {
                    sendButton.prop('disabled', true);
                }
            });
            M.textareaAutoResize(textField);

            sendButton.on('click', function() {
                $(document).trigger("loader:on");

                instance.chatService.suggestArticleImprovement(sendModal.data('articleId'), textField.val())
                .then(function() {
                    textField.val('');
                    M.textareaAutoResize(textField);
                    M.toast({html: instance.i18next.t('articlesection.toast_suggestion_for_improvement_sent')});
                    instance.updateView();
                })
                .catch(function() {
                    M.toast({html: instance.i18next.t('toasts.error')});
                })
                .finally(function() {
                    $(document).trigger("loader:off");
                });
            });

            modalBox.find('.js-cancel-button').on('click', function() {
                textField.val('');
                M.textareaAutoResize(textField);
            });

            sendModal = modalBox;
        }

        sendModal.data('articleId', articleId);

        sendModal.modal({
            dismissible: false
        });

        sendModal.modal('open');
    }

    /**
     * Performs an update request to the service that is intended to update the parts
     * of an article object that are included in 'params'.
     *
     * @param {*} action a function that performs the server request of the update
     * @param {*} params the article data, that should be updated as JSON, for example { "id": 1, "title": "new title"}
     * @param {*} tostParams a parameter object for the materializecss function M.toast, for example {html: 'Hurray'}
     */
    async _updateArticle(action, params, toastParams) {
        var instance = this;

        return action(params)
            .then(function () {
                $(document).trigger("loader:off");
                if (toastParams) {
                    M.toast(toastParams);
                }

                return true;
            })
            .catch(function(errorResponse) {
                $(document).trigger("loader:off");
                instance.log.debug('Update of article failed!', errorResponse);
                if (errorResponse.status == 400) {
                    M.toast({html: instance.i18next.t('toasts.data_invalid')});
                } else {
                    M.toast({html: instance.i18next.t('toasts.error')});
                }

                return false;
            });
    }

    _appendArticleBlock(block, blocksArea) {
        const instance = this;
        instance.utils.linkifyText();

        let blockElement = $(
            '<div class="js-article-block dialogue-summary narrow center-duck-light" data-block-id="' + block.id + '">'
            + '  <div class="summary">'
            + '    <div class="current-content">'
            + '      <div class="question-hyperlink">'
            +          block.text.linkify()
            + '      </div>'
            + '    </div>'
            + '  </div>'
            + '</div>'
        );

        blocksArea.append(blockElement);
    }
}
