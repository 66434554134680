/* global monstecLib */
/* global M */

export default class DealsHandler {
    constructor(externalUtils) {
        this.utils = externalUtils;
        this.ads = adsObj; //array in deals.json
        this.adsCache;
        this.i18next = monstecLib.i18next;

        this.log = new monstecLib.Log(1);
    }

    initialise(targetElem) {
        let instance = this;

        if (
            instance.utils.checkExpirationDate(
                instance.ads[0].expirationDateCampaign
            )
        ) {
            instance.initDealsOverviewAndControls(targetElem);
            instance._extendAdNote();
        } else {
            let noDealsTxt =
                "<div class='vertical-spacer big'></div><span class='w100 flex-box just-center fs-14' style>Leider haben wir aktuell keine Angebote</span><div class='vertical-spacer big'></div>";
            targetElem.html(noDealsTxt);
        }
    }

    /**
     * Creates the actual content of the article overview shop page, which shows all articles
     * in blocks.
     *
     * @param targetElem the object with all deals included
     * @param sorting the optional sorting of the items
     * @param number the number of items to be returned
     */
    initDealsOverviewAndControls(targetElem, sorting, number) {
        let instance = this;
        let dealsBlockHTML = "";
        let adsItems = Object.values(instance.ads[0].adsItems);
        let tagsArr = [];

        let adsArray;

        if (sorting) {            
            let filteredAdsItems = adsItems.filter((item) => item.lpd !== "");
            adsArray = instance._sortItems(filteredAdsItems, sorting, number);
        } else {
            adsArray = adsItems;
        }

        // Limit the results to the specified number
        if (number) {
            adsArray = adsArray.slice(0, number);
        }

        // Create the HTML for the deals
        adsArray.forEach((el, index) => {
            let itemId = index + 1;
            dealsBlockHTML += instance._createSingleItem(el, itemId);

            if (el.tag) {
                tagsArr.push(el.tag.slice(1, -1).split(/[, ]+/));
            }
        });

        // Append the HTML to the target element
        targetElem.append(dealsBlockHTML);
        instance.initFilterTags(Array.from(tagsArr));
        instance.filterFunction();
    }

    _sortItems(adsArray, sorting, number) {
        switch (sorting) {
            case "HIGHEST_DISCOUNT":
                // Sort by highest discount based on lpd (lowest percent values first)
                return adsArray.sort((a, b) => a.lpd - b.lpd);

            case "LIMITED_OFFERS_AND_DISCOUNT":
                const offerPattern = /^(Befristetes Angebot|\d{1,4}(€-Coupon|% -Coupon))/;

                function isLimitedOffer(title) {
                    return offerPattern.test(title);
                }
            
                let limitedOffers = [];
                let otherOffers = [];
            
                // Custom filter that stops once the required number of items is reached
                adsArray.some((item) => {
                    if (isLimitedOffer(item.title)) {
                        limitedOffers.push(item);
                    } else {
                        otherOffers.push(item);
                    }
            
                    // Stop collecting if we've reached the desired number of items
                    return number && limitedOffers.length >= number;
                });
            
                // Sort limited offers by title
                limitedOffers.sort((a, b) => a.title.localeCompare(b.title));
            
                // Only process otherOffers if we need more items to reach 'number'
                if (number == null || limitedOffers.length < number) {
                    // Sort other offers by lpd value
                    otherOffers.sort((a, b) => parseFloat(a.lpd) - parseFloat(b.lpd));
                }
            
                let combinedResults = [...limitedOffers, ...otherOffers];
            
                return combinedResults;

            default:
                // If no matching sorting is specified, return the array as is
                return adsArray;
        }
    }

    _createSingleItem(array, id) {
        let instance = this;

        let shortname = array.title.substring(0, 100);
        let expiredClass = instance.utils.checkExpirationDate(
            array.expirationDate
        )
            ? ""
            : "disabled";

        let priceEle =
            array.price && array.price.length > 0
                ? '<span class="item__price btn ' +
                  expiredClass +
                  '" data-position="bottom" itemprop="price" content="' +
                  array.price +
                  '" data-tooltip="Angebotspreis">' +
                  array.price +
                  ' &euro;</span><meta itemprop="priceCurrency" content="EUR">'
                : "";

        //optional variables, not necessarily available for every product rrp (recommended retail price)
        let calcDiscount =
                Math.round(((array.rrp - array.price) / array.rrp) * 100) * -1,
            discount =
                calcDiscount < 0 && isFinite(calcDiscount)
                    ? '<div class="item-extras-wrapper">' +
                      '<div class="item-extras"><span class="discount">' +
                      calcDiscount +
                      "%</span></div> " +
                      "</div>"
                    : "",
            rrp = array.rrp
                ? '<div class="uvp">UVP&nbsp;<span>' +
                  Math.round(array.rrp).toFixed(2) +
                  " &euro;</span></div>"
                : "";

        let itemHtml =
            '<div class="item active ' +
            expiredClass +
            '" data-dealid="' +
            id +
            '" data-tags="' +
            array.tag +
            '" itemprop="itemListElement" itemscope itemtype="http://schema.org/Product">' +
            '<a class="item-link" itemprop="url" target="_blank" href="' +
            array.link +
            '" >' +
            '<img itemprop="image" class="item__image" alt="' +
            array.title +
            ' - Angebot" src="' +
            array.img +
            '" loading="lazy" />' +
            '<div class="item-content-wrapper">' +
            '<h2 itemprop="name" class="item__title fs-14">' +
            shortname +
            "</h2>" +
            "</div>" +
            '<div itemprop="offers" class="price__wrapper">' +
            rrp +
            priceEle +
            "</div>" +
            discount +
            "</a></div>";

        return itemHtml;
    }

    /**
     *dynamically build the filter tags on shop.html
     */
    initFilterTags(tagsArray) {
        let instance = this;

        //console.log(tagsArray);

        //flattens the array by merging all items into one array
        let mergedArray = tagsArray.reduce(
            (accumulator, currentValue) => accumulator.concat(currentValue),
            []
        );

        //console.log(mergedArray);

        // ES6 method to build new set from category Array reduced by duplicates
        let filteredArray = [...new Set(mergedArray)];
        //console.log("filteredArray: ", filteredArray);

        // deletes all empty entries from array list after filter process
        let reducedFilterArray = filteredArray.filter(
            (item) => item.length > 0
        );

        //sorts array by tag"
        reducedFilterArray.sort((a, b) => a.localeCompare(b));

        //console.log("filteredArray2: ", reducedFilterArray)

        instance.fillFilterTags(reducedFilterArray);
    }

    fillFilterTags(array) {
        var chips = "";

        for (var i = 0; i < array.length; i++) {
            var tagName = array[i];
            chips +=
                '<div class="chip "><a data-name=' +
                tagName +
                ">" +
                tagName +
                "</a></div>";
        }

        var chip =
            '<div class="chip filter-off"><a>Filter zur&#xFC;cksetzen</a></div>';

        $("#filter-tags").append(chips).append(chip);

        var elem = document.querySelector("#container-articles .collapsible");
        var instance = M.Collapsible.init(elem);
    }

    /**
     *find all items with selected tagName e.g. Xiaomi and form new array with according articleIds
     *hide all elements which match $item[data-tag="articleId"]
     */
    filterFunction() {
        let instance = this;

        $(".chip").on("click", function () {
            var tagName = $(this).find("a").data("name");
            $(this).toggleClass("filter-on");
            $(this).toggleTag("filter-on", tagName);
        });

        $.fn.extend({
            toggleTag: function (a, tagName) {
                var that = this;

                if ($(that).hasClass(a)) {
                    instance.resetFilter();

                    $(that)
                        .find("a")
                        .css({ "font-weight": "600" })
                        .css({ color: "white" });
                    $(that).css({
                        "background-color": "rgba(43, 187, 173, 1)",
                    });

                    instance.filterItems(tagName, "on");
                    $(".item").not(".filtered").removeClass("active");

                    $("html,body").animate(
                        {
                            scrollTop: $(".items-wrap").offset().top - 56,
                        },
                        "slow",
                        "swing"
                    );
                } else if (!$(that).hasClass(a)) {
                    $(that)
                        .find("a")
                        .css({ "font-weight": "" })
                        .css({ color: "" });
                    $(that).css({ "background-color": "" });

                    instance.filterItems(tagName, "off");
                }
            },
        });

        $(".chip.filter-off").on("click", function () {
            instance.resetFilter();
        });

        instance.setFilterOn();
    }

    //reset chips and filtered items
    resetFilter() {
        $(".item").addClass("active").removeClass("filtered");
        $(".chip").removeClass("filter-on").css({ "background-color": "" });
        $(".chip").find("a").css({ "font-weight": "" }).css({ color: "" });
    }

    // filters all items, which match the selected ID
    filterItems(tagName, status) {
        const instance = this;

        if (tagName) {
            let adsItem = Object.entries(instance.ads[0].adsItems);

            let adsItemRed = adsItem.filter((element) =>
                element[1].tag.toLowerCase().includes(tagName.toLowerCase())
            );
            let itemsToShowArray = adsItemRed.map((element) => element[0]);

            instance.switchItemsbyTags(itemsToShowArray, status);
        }
    }

    switchItemsbyTags(array, status) {
        for (var j = 0; j < array.length; j++) {
            if (status === "on") {
                $(".item[data-dealid=" + array[j] + "]").addClass("filtered");
            } else if (status === "off") {
                $(".item[data-dealid=" + array[j] + "]")
                    .removeClass("filtered")
                    .removeClass("active");
            }
        }
    }

    /*
     **When shop is loaded and filterCookie 'tagname', filter the results with the given tag
     */
    setFilterOn() {
        var filterCookie = Cookies.getJSON("filterOn");

        if (filterCookie) {
            var tagName = filterCookie.filterRes;
            var filterTag = $(
                '#filter-tags .chip a[data-name="' + tagName + '"]'
            );
            filterTag.trigger("click");

            //open filterArea to direct more attention to active filter
            var elem = document.querySelector(
                "#container-articles .collapsible"
            );
            var collapsible = M.Collapsible.getInstance(elem);
            collapsible.open();
        }
    }

    _extendAdNote() {
        let instance = this;
        let today = new Date().getDay();
        let time = today > 10 ? "08:15" : today > 20 ? "08:17" : "08:11";
        let adTimeAndRefInfo =
            '&nbsp;|&nbsp;<span class="darkco-grey">Stand: ' +
            time +
            ' Uhr (UTC). <a class="darkco-grey" href="#affiliate-note">Weitere Infos*</a></span>';
        $("#ad-status-info").append(adTimeAndRefInfo);

        let affiliateNote = instance.i18next.t('html.affiliate_note', { interpolation: { escapeValue: false } });

        $("#article-block").append(affiliateNote);
    }
}
