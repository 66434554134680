/* global monstecLib */
/* global M */

export default class ProfilePage {
    constructor(externalCookie, externalAuthenticator, externalUtils) {
        this.cookie = externalCookie;
        this.authenticator = externalAuthenticator;
        this.utils = externalUtils;
        this.portalSupport;
        this.register
        this.catalogueService = new monstecLib.CatalogueServiceAccess();

        let context = monstecLib.produckContext;
        context.setBean('catalogueService', this.catalogueService);
    }

    initialise(userId, chatIntegrationSuccessful) {
        var instance = this;
        instance.register = new monstecLib.Register(instance.authenticator);
        instance.portalSupport = new monstecLib.PortalSupport(instance.authenticator, instance.cookie);

        instance.cookie.saveLastVisitedPage(window.location.href);

        instance.register.initSignInForm(false, instance.cookie, initSiginCallback, false, ['#create-own-profile-lnk-sidenav', '#signin-lnk-sidenav']);

        function initSiginCallback(userData, questionMenuSideNav) {
            instance._callInitSignInLinks(userData, questionMenuSideNav, userId, chatIntegrationSuccessful)
        };

        instance._initMaterialize();
        instance.utils.styleShariff();
        instance.utils.initShareContent();
        instance.cookie.setCookieOkCookies();

        $('.text-wrapper li').each(function (i, ele) {
            instance.utils.linkifyDialogue($(ele), userId);
        });

        $('body').localize();
    }

    _initMaterialize() {
        // --- materializecss initialisation ---
        // side navigation for mobile
        var elems = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems, { edge: 'left', closeOnClick: true });
        // use height (defined via #navigation in CSS) of navigation bar as scroll offset
        elems = document.querySelectorAll('.scrollspy');
        M.ScrollSpy.init(elems, { scrollOffset: 0 });
        // --- end of materializecss initialisation ---
    }

    // opens chat on side nav link click
    _initContactExpertLink() {

        //toggle box with link in fixed side bar
        $('.nav-chat-lnk').click(() => {
            var chatTrigger = $('#produck-chat-block-home');
            if (!chatTrigger.hasClass('quacks-active')) {
                setTimeout(() => {
                    chatTrigger.toggleClass('quacks-active');
                }, 10);
            } else if (chatTrigger.hasClass('quacks-active')) {
                setTimeout(() => {
                    chatTrigger.toggleClass('quacks-passive');
                }, 10);
            }
        });
    }

    // informs user about non availability of chat option
    _initAlertForContactExpertLink() {
        var instance = this;

        $('.nav-chat-lnk').click(() => {
            //TODO show business hours in modal
            instance.utils.createSimpleAlertModal('Der Chat des Experten ist aktuell nicht aktiv. Bitte versuche es später erneut.');

        });
    }

    _callInitSignInLinks(userData, questionMenuSideNav, userId, chatIntegrationSuccessful) {
        var instance = this;
        var profileLink = $('#create-own-profile-lnk-sidenav');

        $('#mobile-view').append(questionMenuSideNav);

        if (userData === undefined) {
            // user not logged in
            var clickEvTargetArr = ['#ask-question-lnk-sidenav', '#create-article-lnk-sidenav', '#create-chat-lnk-sidenav', '#signin-button-sidenav'];

            clickEvTargetArr.forEach(function(target) {
                instance.register.initCallInstantOnSiteSignIn(target);
            });
            chatIntegrationSuccessful ? instance._initContactExpertLink() : instance._initAlertForContactExpertLink();

        } else {
            // user logged in
            var userRole = (userData.userRole === "EXPERT" || userData.userRole === "PROVIDER") ? 'xpert' : 'user';
            var signInLink = $('#signin-lnk-sidenav');
            signInLink.remove();

            if (userId !== userData.userId) {
                // user is on foreign profile site
                profileLink.attr('href', '/profile/'+userData.userId); // link to own profile
                profileLink.text('Mein Profil');
                chatIntegrationSuccessful ? instance._initContactExpertLink() : instance._initAlertForContactExpertLink();
            } else {
                // user is on own profile site
                profileLink.attr('href', '/profile-generator.html?cid='+userData.userId);
                profileLink.text('Profil bearbeiten');
                $('.nav-chat-lnk').attr('href', '/'+userRole+'.html');
                $('.nav-chat-lnk span').text('Mein Portal');
                $('#produck-chat-block-home').remove();

                var logoutBtn = '<a id="logout-btn-sidenav" class="waves-effect waves-light btn logout-user" title="Logout">'
                +'      <i class="material-icons">power_settings_new</i>'
                +'    </a>';

                $('.user-view').append(logoutBtn);

                function initLogout() {
                    var reloadUri = window.location.href;
                    $('#logout-btn-sidenav').on('click', () => instance.authenticator.logout(reloadUri));
                }

                initLogout();
            }

            var deepLinks = ['#ask-question-lnk-sidenav', '#create-article-lnk-sidenav', '#create-chat-lnk-sidenav'];
            instance.portalSupport.initLinkNavigation(deepLinks, userData);
        }
    };

}
