/* global monstecLib */
/* global M */

import i18next from 'i18next';

export default class ProductHandler {
    constructor() {
        /** (a) show products (b) buy, (c) discuss (d) amazon
        * 2 = yes / 1 = no
        * status 2221 == show, buy, discuss, no amazon / 2121 == show, no buy, discuss, no amazon / 2111 == show, no buttons / 21112 == show, no buttons, pricewrapper / 1112 == no show, buy or discuss, amazon instead / 2211 == show, buy, nothing else / 2311 == show, instant buy, nothing else
        **/

        // TODO REMOVE THIS
        this.productArray =
          {
              684: {
                  "name": "SmartKat",
                  "customerId": "684",
                  "package": "2121",
                  "article": ['68410007','68410008','68410001','68410002','68410003','68410004','68410005','68410006']
              },
              53: {
                  "name": "Heinze",
                  "customerId": "53",
                  "package": "2121",
                  "article": ['11410003','11510001','11510002','10610002','10610001','10610007','10210037','10210038','10510001','10910009','11010001', '11210001'],
                  "paypal": "AVdWnN8rtYIGXXE1Lh9HnC2GPr_hG71qo3o2hh6xjMr3Y7X77xbnFTCb8eJsI_4x1Ixb8LQxyBnZ5XQd"
              },
              1: {
                  "name": "Zauner",
                  "customerId": "1",
                  "package": "2121",
                  "article": ['11410003','11510001','11510002','10610002','10610001','10610007','10210037','10210038','10510001','10910009','11010001', '11210001'],
                  "paypal": "AVdWnN8rtYIGXXE1Lh9HnC2GPr_hG71qo3o2hh6xjMr3Y7X77xbnFTCb8eJsI_4x1Ixb8LQxyBnZ5XQd"
              },
              2: {
                  "name": "SmartKat Expertenteam",
                  "customerId": "2",
                  "package": "2311",
                  "article": ['68410007','68410008','68410001','68410002','68410003','68410004','68410005','68410006'],
                  "paypal": "AVdWnN8rtYIGXXE1Lh9HnC2GPr_hG71qo3o2hh6xjMr3Y7X77xbnFTCb8eJsI_4x1Ixb8LQxyBnZ5XQd"
              },
              2127: {
                  "name": "ProDuck",
                  "customerId": "2127",
                  "package": "2121",
                  "article":['20170001','20170002','20170003','20170004','20170005','20170006','20170007','20170008','20170009','20170010','20170011','20170012','20170013','20170014','20170015','20170016','20170017','20170018','20170019','20170020','20170021'],
                  "paypal": "AVdWnN8rtYIGXXE1Lh9HnC2GPr_hG71qo3o2hh6xjMr3Y7X77xbnFTCb8eJsI_4x1Ixb8LQxyBnZ5XQd"
              },
              2132: {
                  "name": "MonsTec",
                  "customerId": "2132",
                  "package": "2121",
                  "article": ['11410003','11510001','11510002','10610002','10610001','10610007','10210037','10210038','10510001','10910009','11010001', '11210001'],
                  "paypal": "AVdWnN8rtYIGXXE1Lh9HnC2GPr_hG71qo3o2hh6xjMr3Y7X77xbnFTCb8eJsI_4x1Ixb8LQxyBnZ5XQd"
              },
              1103: {
                  "name": "Gummiwerk Meuselwitz",
                  "customerId": "1103",
                  "package": "1112",
                  "article": []
              },
              2017: {
                  "name": "Handwerker-Versand",
                  "customerId": "2017",
                  "package": "2121",
                  "article": ['20170001','20170002','20170003','20170004','20170005','20170006','20170007','20170008','20170009','20170010','20170011','20170012','20170013','20170014','20170015','20170016','20170017','20170018','20170019','20170020','20170021']
              },
              27: {
                  "name": "Grill Expertenteam",
                  "customerId": "2017",
                  "package": "2121",
                  "article": ['20170001','20170002','20170003','20170004','20170005','20170006','20170007','20170008','20170009','20170010','20170011','20170012','20170013','20170014','20170015','20170016','20170017','20170018','20170019','20170020','20170021']
              },
              2198: {
                  "name": "Tierklinik Schliersee",
                  "customerId": "2198",
                  "package": "2311",
                  "article": ['21980001','21980002', '21980003'],
                  "paypal": "AaKfU38VoHDifoKUiibtq43ovJmcPhlyFcWDKFwna6Vh_hatFHFZTDAjRaGXJmka3aJ7LwsyzmLYZxp7"
              },
              2203: {
                  "name": "Tierarzt Demo Account",
                  "customerId": "2203",
                  "package": "2311",
                  "article": ['22030001','22030002', '22030003']
              },
              2229: {
                  "name": "Praxis Klauengesundheit",
                  "customerId": "2229",
                  "package": "2311",
                  "article": ['22290001','22290002', '22290003']
              },
              2236: {
                  "name": "Tierarztpraxis Marion Ebner",
                  "customerId": "2236",
                  "package": "2311",
                  "article": ['22360001','22360002', '22360003']
              },
              2093: {
                  "name": "One",
                  "customerId": "2093",
                  "package": "2121",
                  "article": ['2093P00232']
              },
              2249: {
                  "name": "Tierarztpraxis Gauting",
                  "customerId": "2249",
                  "package": "2311",
                  "article": ['22490001','22490002', '22490003']
              }          };

        this.log = new monstecLib.Log(1);
    }

    initialiseContextBeans() {
        this.constants = new monstecLib.Constants();
        this.utils = monstecLib.produckContext.utils;
        this.authenticator = monstecLib.produckContext.authenticator;
        this.chatclient = monstecLib.produckContext.chatClient;
        this.chatsupport = monstecLib.produckContext.chatSupport;
        this.catalogueService = monstecLib.produckContext.catalogueService;
        this.identityService = monstecLib.produckContext.identityService;

        this.offerCache = {};
        this.bundleCache = {};
    }

    setSalesHandler(externalSaleshandler) {
        this.saleshandler = externalSaleshandler;
    }

    _hideTitleTag() {
        $('#short-description .tooltipped').hover((ev) => {
            var chatMessage = $(ev.target).parents(".chat-message");
            var title = chatMessage.attr('title');
            title ? chatMessage.data("org_title", title).attr('title', '') : '';
        }, (ev) => {
            var chatMessage = $(ev.target).parents(".chat-message");
            var orgTitle = chatMessage.data('org_title');
            orgTitle ? chatMessage.attr('title', orgTitle) : chatMessage.data('org_title', '');
        });
    }

    async _requestProducts() {
        const instance = this;
        let now = Date.now();

        // fetch products from server if they have not been fetched yet or if the fetched data is older than a quarter of an hour

        if (!instance.products || (instance.productsRetrievalTime && (now - instance.productsRetrievalTime > 900000))) {
            let offerList = await instance.catalogueService.getOffers();

            instance.products = {};
            instance.productsRetrievalTime = now;

            let size = 0;
            offerList.forEach(offer => {
                size++;
                instance.products[offer.product.mtin] = offer;
            });

            instance.products.size = size;
            instance.products.productsRetrievalTime = now;
        }

        return instance.products;
    }

    /**
     * Fetch the offers of a specific shop either from cache or from the server.
     *
     * @param {*} shopId the technical identifier of the user the shop is associated to, if not set the function returns
     *                   the same result as _requestProducts()
     */
    async _requestOffers(shopId) {
        const instance = this;
        let now = Date.now();
        if (!shopId) return this._requestProducts();

        let offerList = instance.offerCache[shopId];
        // if list of shop is not yet in cache or the list in the cache is too old, retrive offers from server
        if (!offerList || (offerList.productsRetrievalTime && (now - offerList.productsRetrievalTime > 900000))) {
            offerList = {};
            let serverOffers = await instance.catalogueService.getOffers(shopId);

            let size = 0;
            serverOffers.forEach(offer => {
                size++;
                offerList[offer.product.mtin] = offer;
            });

            offerList.productsRetrievalTime = now;
            offerList.size = size;
            instance.offerCache[shopId] = offerList;
        }

        return offerList;
    }

    // TODO refactor this away
    _transformOffer(offer) {
        let id = offer.id;
        let prodMtin = offer.product.mtin;
        let prodImg = offer.product.imageUrl;
        let prodTitle = offer.product.shortName;
        let prodLink = offer.product.detailsUrl;
        let prodPrice = (offer.price) ? offer.price.toString() : '';
        let prodShortDescr = offer.product.shortDescription;

        return {id, prodMtin, prodImg, prodTitle, prodLink, prodPrice, prodShortDescr };
    }

    async getBundle(userId) {
        const instance = this;
        let bundle = instance.bundleCache[userId];

        if (!bundle) {
            let bundleResponse = await instance.identityService.getBundle(userId);
            bundle = bundleResponse.bundle;
            instance.bundleCache[userId] = bundle;
        }

        return bundle;
    }

    async getOfferList(shopId) {
        const instance = this;
        if (shopId && isFinite(shopId)) {
            /* TODO REMOVE
            let offerSubset = [];
            if (instance.productArray[shopId] !== undefined) {
                var articleSelection = instance.productArray[shopId].article;
                for (var i = 0; i < articleSelection.length; i++) {
                    if (offerArray[articleSelection[i]]) {
                        offerSubset.push(offerArray[articleSelection[i]]);
                    } else {
                        instance.log.error('createProductSearchContainer: unknown article ', articleSelection[i]);
                    }
                }
            }
            */

            return await instance._requestOffers(shopId);

        } else {
            return await instance._requestProducts();
        }
    }

    async getProductDetails(mtin) {
        const instance = this;
        var offerArray = await instance._requestProducts();

        return instance._transformOffer(offerArray[mtin]);
    }

    /**
     * Get detailed information for an offer from cache or server respectively
     *
     * @param {number} offerId
     */
    async getOfferDetails(offerId, useCache = true) {
        const instance = this;

        let offer;
        if (useCache) {
            offer = instance.offerCache[offerId];
        }

        if (!offer) {
            offer = await instance.catalogueService.getOffer(offerId);
            instance.offerCache[offerId] = offer;
            instance.offerCache[offer.shopId + "|" + offer.product.mtin] = offer;
        }

        return offer;
    }

    async getOfferDetailsByMtin(providerId, MTINNr, useCache = true) {
        const instance = this;

        let offer;
        if (useCache) {
            offer = instance.offerCache[providerId + "|" + MTINNr];
        }

        if (!offer) {
            offer = await instance.catalogueService.getOfferByMtin(providerId, MTINNr);
            instance.offerCache[offer.id] = offer;
            instance.offerCache[offer.shopId + "|" + offer.product.mtin] = offer;
        }

        return offer;
    }

    /**
     * Transfers offer to the server for saving.
     *
     * @param {*} details object of the form:
     *                    {
     *                      offerId: [number],
     *                      shortName: [string],
     *                      shortDescription: [string],
     *                      imageUrl: [string],
     *                      detailsUrl: [string],
     *                    }
     */
    async saveOffer(details) {
        const instance = this;
        let offer = {};
        offer.product = {};

        // If the offer ID is not set create a new offer, if it is update an existing one.
        if (!details.offerId) {
            return instance.catalogueService.createProduct(details)
            .then(function(result){
                offer.product.id = result.productId;
                offer.price = details.price;
                return instance.catalogueService.createOffer(offer);
            });
        } else {
            offer.id = details.offerId;
            offer.price = details.price;
            offer.product.shortName = details.shortName;
            offer.product.shortDescription = details.shortDescription;
            offer.product.imageUrl = details.imageUrl;
            offer.product.detailsUrl = details.detailsUrl;
            return instance.catalogueService.updateOffer(offer);
        }
    }

    async uploadOfferBatch(file) {
        const instance = this;

        if (!file) {
            throw {code: 'data_missing'};
        }

        if (file.size > 10485760) {
            throw {code: 'too_many_bytes'};
        }

        if (!file.name.toLowerCase().endsWith(".csv")
                && !file.name.toLowerCase().endsWith(".txt")) {
            throw {code: 'unsupported_filetype'};
        }

        try {
            // Remove offers of current user from cache so that newly added product
            // are immediately listed in the search result.
            let userId = await instance.authenticator.getUser();
            delete instance.offerCache[userId];

            await instance.catalogueService.createOffers(file);
        } catch(e) {
            instance.log.debug('Offer batch could not be uploaded.', e);
            let error = {code: 'error'};

            if (e.responseText && e.responseText.length > 0) {
                error.details = e.responseText;
            }

            throw error;
        }
    }

    // Ajax Call for product infos from Amazon
    callAmznProd(number, callback, selPackages) {
        var instance = this;
        var uri = this.constants.apiHostChat + 'offers/hot';

        let itemNo = number ? number : 10;
        let style = selPackages ? selPackages : 1112;

        $.ajax({
            type: "GET",
            url: uri,
            success: function (content) {
                let itemsArray = [];

                for (let i = 0; i < itemNo; i++) {
                    let prodMTIN = 0;
                    let prodImg = content[i].imageUrl;
                    let prodTitle = content[i].productName.substring(0, 30) + '...';
                    let prodLink = content[i].productUrl;
                    let prodPrice = content[i].price.split(' ')[1];
                    let article = { prodMTIN, prodImg, prodTitle, prodLink, prodPrice };                    

                    itemsArray.push(article);                
                }

                if (callback) callback(itemsArray);
                return itemsArray
            }, error:
                function (data) {
                    // error handler
                    console.log('Offers endpoint could not be reached; received error code ' + data.status);
                }
        });
    }

    prepareItemforChat(offer, selPackages) {
        var instance = this;
        if (offer !== undefined) {
            var article = instance._transformOffer(offer);
            var createdItemElement = instance.buildSingleItem(article, selPackages);
            return createdItemElement;
        }
    }

    /**
    * detects item calls in chat and triggers visualisation of item in chat
    * @param {*} message defines the message to add
    * @param {*} partner defines the role in the chatlog
    * @param {*} correspondingChatlog refers to the right chat esp. needed for multiple chats
    */
    detectMtin(message) {
        var instance = this;
        var status = false;

        if (message.indexOf('MTIN') > -1) {
            var MTINNr = $(message.split('MTIN'))[1];

            status = {"result": true, "mtin": parseInt(MTINNr)};
        }

        return status;
    }

    async transformMTINS(chatlog) {
        var instance = this;

        if  (chatlog.find('.chat').last().hasClass("product-msg")) {
            var prodMessages = chatlog.find('.chat.product-msg .chat-message').last();
            var productArray = await instance._requestProducts();
            var flatArray = $.map(productArray, (value, mtin) => {
                return "MTIN" + mtin;
            });

            var chatMessageContent = $.grep($(prodMessages).html().split(" "), function(a){ return /^MTIN/.test(a); }); // make array from all products included in text

            for(var i = 0; i < chatMessageContent.length; i++) {
                var arrayPos = $.inArray(chatMessageContent[i], flatArray);  // compare if intersection among given mtin and inserted text

                // if input matches mtin-tag and item in message
                if (arrayPos > -1 && prodMessages.find(".item.active").length === 0) {
                    var MTIN = flatArray[arrayPos];
                    var MTINNr = MTIN.split('MTIN')[1];
                    var providerId = chatlog.parents('.cl').first().data('providerid');
                    this.log.debug('transformMTINS - PROVIDERID: ', providerId); //REMOVE
                    this.log.debug('transformMTINS - MTINNR: ', MTINNr); //REMOVE
                    var selPackages;
                    try {
                        selPackages = providerId ? await instance.getBundle(providerId) : 2111;
                        this.log.debug('transformMTINS - SELPACKAGES: ', selPackages); //REMOVE
                    } catch (e) {
                        selPackages = 2111;
                        console.log('PROVIDERID - ERROR: ', e);
                    }

                    var offer = productArray[MTINNr];
                    /* TODO use this after some more refactoring and when the complete loading of the offer list is refactored away
                       however it must be reworked because the providerId is not available in any case expecially if the expert is
                       the provider himself. Rather than the MTIN of the product a unique identifier of the offer should be used. */
                    /*
                    // Load the offer from the server that is associated to the current provider and is actually for
                    // the product identified by the given MTIN. It could happen that such an offer does not exist.
                    // That case will be handled below.
                    var offer;
                    try {
                        offer = await instance.getOfferDetailsByMtin(providerId, MTINNr);
                        this.log.debug('transformMTINS - OFFER: ', offer); //REMOVE
                    } catch (e) {
                        console.log('OFFER - ERROR: ', e);
                    }
                    */

                    var copiedItem = instance.prepareItemforChat(offer, selPackages);
                    var newChatMessage = MTIN + copiedItem;

                    prodMessages.html( function(_, html) {
                        return html.replace(MTIN, newChatMessage);
                    });

                    setTimeout(() => {
                        var messageId = prodMessages.data("messageid");

                        // for userside show paypal, if sender is provider or assigned to any provider, clientId given and product is a product, included in provider's article set
                        if ($('#user').length && providerId  && parseInt(selPackages) === 2311 && offer) {
                            instance._buttonAdder($(".chat-message[data-messageid=" + messageId + "] .item.active"));
                            let offerContainer = monstecLib.pageComponentRegistry.getComponent('customer-chat-offer-container');
                            //TODO Folgendes sollte bewirken, dass ein vom Experten gepushtes Produkt an den Anfang der Liste im Containr
                            // des Customers erscheint. Damit das wieder klappt muss das wahrscheinlich ein bisschen angepasst werden.
                            //TODO offerContainer.addItem(productArray, [MTINNr], selPackages);
                        }
                    }, 1000);
                }
            }
        }
        instance.chatsupport.scrollDown();

    }

    _buttonAdder(target) {
        var instance = this;

        if (!target.find(".btn-wrapper").children().length) {
            instance.saleshandler.createInstantPayButton(target);

            var elems = document.querySelectorAll('.tooltipped');
            M.Tooltip.init(elems);
            instance._hideTitleTag();
        }
    }

    //builds the items block based on shops's selected package
    buildSingleItem(offer, selPackages, icon) {
        //// optional variables, not necessarily available for every product
        //// TODO: UVP and discount discarded for first MVP
        //const deliveryInfo = '<div id="delivery-terms-personal-package" class="delivery-terms"><span class="tooltipped fas fa-truck" data-position="bottom" data-tooltip="Lieferzeiten: 1-5 Tage <br/>kostenlose Lieferung<br /> Preis inkl. gesetzl. MwSt.<br /> 30 Tage Rückgaberecht"></span></div>';
        //const shortDescr = '<div id="short-description" class="info-item"><span class="tooltipped fas fa-info-circle" data-position="bottom" data-tooltip="' + article.prodShortDescr + '"</span></div>';
        //var itemExtras = '';
        //var discountElem = '';

        //var calcDiscount = Math.round((article.rrp - article.prodPrice) / article.rrp * 100) * -1;
        //if (calcDiscount < 0 && isFinite(calcDiscount)) {
        //    itemExtras = '<div class="item-extras-wrapper">'
        //        + '<div class="item-extras"><span class="discount">' + calcDiscount + '%</span></span></div> '
        //        + '</div>';
        //}

        //if (article.rrp > 1) {
        //    discountElem = '<span class="uvp">UVP&nbsp;<span>' + article.rrp.toFixed(2) + ' &euro;</span></span>';
        //}

        let price =  offer.prodPrice ? parseFloat(offer.prodPrice.replace(/,/g, ".")).toFixed(2) : '';
        let brandIconString = typeof icon !== 'undefined' ? icon : '';
        let articlesHtml = '<a '
                  + ((!!offer.prodLink) ? 'href="' + offer.prodLink + '" ' : '')
                  + 'class="item active" data-offer-id="' + offer.id + '" data-mtin="' + offer.prodMtin + '" data-title="' + offer.prodTitle + '" target="_blank" data-price="' + price + '" rel="noopener nofollow">'
                  + '<img class="item__image" src="' + offer.prodImg + '" alt="' + offer.prodTitle + '" loading="lazy" />'
                  + '<div class="item-content-wrapper">'
                  + '<h2 class="item__title">' + offer.prodTitle + '</h2>'
                  + '</div>'
                  + brandIconString;

        let btnWrapper = '<div class="btn-wrapper">';
        let discussButton = '<span class="copy-to-chat-btn waves-effect btn tooltipped" data-position="bottom" data-tooltip="' + i18next.t("text.chat_discuss") + '">' + i18next.t("text.chat_discuss_txt") + '</span>';
        let buyButton = '<span class="buy-btn waves-effect btn tooltipped" data-position="bottom" data-tooltip="' + i18next.t("text.buy") + '">' + i18next.t("text.buy") + '</span>';
        let instantBuyButton = '<span class="instant-buy-btn waves-effect btn" data-position="bottom tooltipped" data-tooltip="' + i18next.t("order.submit_order") + '" data-price="' + offer.prodPrice + '">' + i18next.t("order.submit_order") + '</span>';
        let toOfferButton = '<span class="to-offer-btn waves-effect btn tooltipped" data-position="bottom" data-tooltip="' + i18next.t("text.chat_to_offer") + '">' + i18next.t("text.chat_to_offer") + '</span>';
        let editButton = '<span class="btn-floating btn-small waves-effect waves-light product-edit-btn disabled"  data-position="top" data-tooltip-content="'+i18next.t("text.product_edit")+'"><i class="material-icons">edit</i></span>';
        let priceAndShareWrapper = '<div class="price__wrapper"><span class="item__price btn waves-effect waves-light" data-position="top" data-tooltip-content="' + i18next.t("text.send_partner") + '">' + price + ' €<i class="material-icons">reply</i></span></div>';
        let productInformation = '<div class="info__wrapper"><div id="short-description" class="info-item"><span class="tooltipped fas fa-info-circle" data-position="bottom" data-tooltip="' + offer.prodShortDescr + '"></span></div><div class="price">Preis&nbsp;<span>' + price + ' € inkl. MwSt.</span></div></div>';
        let closeDivAndATag = '</div></a>';
        let closeATag = '</a>';        


        // status 2221 == show, buy, discuss, no amazon / 2121 == show, no buy, discuss, no amazon / 2111 == show, no buttons / 21112 == show, no buttons, pricewrapper / 1112 == no show, buy or discuss, amazon instead / 2211 == show, buy, no discuss and no amazon / 1111 == nothing > no container showed at all
        if(parseInt(selPackages) === 2221) {
            return articlesHtml + btnWrapper + discussButton + buyButton + closeDivAndATag;
        } else if (parseInt(selPackages) === 1112) {
            return articlesHtml + btnWrapper + toOfferButton + closeDivAndATag;
        } else if (parseInt(selPackages) === 2211) {
            return articlesHtml + btnWrapper + buyButton + closeDivAndATag;
        } else if (parseInt(selPackages) === 2311) {
            return articlesHtml + btnWrapper + "</div>" + productInformation + closeATag;
        } else if (parseInt(selPackages) === 21112) {
            return articlesHtml + editButton + priceAndShareWrapper + closeATag;
        } else if (parseInt(selPackages) === 2111) {
            return articlesHtml + closeATag;
        } else {
            return articlesHtml + btnWrapper + discussButton + closeDivAndATag;
        }
    }
}
