/* global monstecLib */
/* global M */
import i18next from "./localisation.js";
import Chart from "chart.js/auto";

export default class Canvas {
    constructor(chatServiceAccess, billingServiceAccess) {
        this.constants = new monstecLib.Constants();
        this.utils = new monstecLib.Utils();
        this.authenticator = monstecLib.produckContext.authenticator;
        this.chatService = chatServiceAccess;
        this.billingService = billingServiceAccess;
        this.setupUI();
        this.canvas = document.getElementById("viewsLineChart");
        this.ctx5 = this.canvas.getContext("2d");
        this.ctx5.lineWidth = 12;
        this.ctx5.lineCap = "round";
        this.ctx5.shadowBlur = 10;

        this.counterClockwise = true;
        this.circ = Math.PI * 2;
        this.quart = Math.PI / 2;
        this.startTime1 = 0;
        this.startTime2 = 0;
        this.startTime3 = 0;
        this.startTime4 = 0;
        this.duration1 = null;
        this.duration2 = null;
        this.duration3 = null;
        this.duration4 = null;

        this.valueOne = null;
        this.valueTwo = null;
        this.valueThree = null;
        this.valueFour = null;

        this.options = {
            useEasing: true,
            useGrouping: true,
            separator: ",",
            decimal: "0",
            prefix: "",
            suffix: "",
        };

        // Euro
        this.ctx5.font = "60px Philosopher";
        this.ctx5.fillStyle = "rgba(26, 221, 164, 1)";
        this.ctx5.fillText("", 280, 255);

        // Store Chart instances
        this.avgViewsChart = null;
        this.avgQuackityChart = null;
    }

    animate_rec(time) {
        if (!this.startTime1) {
            this.startTime1 = time;
        }

        var delta1 = Math.min(1, (time - this.startTime1) / this.duration1);
        var curPerc = ((2 * Math.PI) / 100) * (this.valueOne * delta1);
        var innerNumRec = this.anchorValue * delta1; // this record is equal valueOne is set as top anchor to 100% implying 360deg

        this.ctx5.strokeStyle = "rgba(115, 100, 164, 1)";
        this.ctx5.beginPath();
        this.ctx5.arc(250, 250, 200, -this.quart, curPerc - this.quart);
        this.ctx5.stroke();

        if (delta1 < 1) {
            requestAnimationFrame(this.animate_rec.bind(this));
            this.ctx5.clearRect(215, 180, 80, 30); //clear double input
        } else {
            this.startTime1 = null;
            this.ctx5.clearRect(215, 180, 80, 30); //clear double input
        }

        // valueOne
        this.ctx5.font = "25px Philosopher";
        this.ctx5.fillStyle = "rgba(115, 100, 164, 1)";
        this.ctx5.fillText(Math.round(innerNumRec), 220, 200);
    }

    startAnim_rec() {
        this.duration1 = 3000;
        requestAnimationFrame(this.animate_rec.bind(this));
    }

    animate_avg(time) {
        if (!this.startTime2) {
            this.startTime2 = time;
        }

        var delta2 = Math.min(1, (time - this.startTime2) / this.duration2);
        var curPerc = ((2 * Math.PI) / 100) * (this.valueTwo * delta2);
        var innerNumAvg = ((this.valueTwo * this.anchorValue) / 100) * delta2;

        this.ctx5.strokeStyle = "rgba(125, 131, 164, 1)";
        this.ctx5.beginPath();
        this.ctx5.arc(250, 250, 170, -this.quart, curPerc - this.quart);
        this.ctx5.stroke();

        if (delta2 < 1) {
            requestAnimationFrame(this.animate_avg.bind(this));
            this.ctx5.clearRect(215, 208, 80, 50); //clear double input
        } else {
            this.startTime2 = null;
            this.ctx5.clearRect(215, 208, 80, 50); //clear double input
        }

        // valueTwo
        this.ctx5.font = "30px Philosopher";
        this.ctx5.fillStyle = "rgba(125, 131, 164, 1)";
        this.ctx5.fillText(Math.round(innerNumAvg), 220, 230);
    }

    startAnim_avg() {
        this.duration2 = 3200;
        requestAnimationFrame(this.animate_avg.bind(this));
    }

    animate_inc(time) {
        if (!this.startTime3) {
            this.startTime3 = time;
        }

        var delta3 = Math.min(1, (time - this.startTime3) / this.duration3);
        var curPerc = ((2 * Math.PI) / 100) * (this.valueThree * delta3);
        var innerNumInc = ((this.valueThree * this.anchorValue) / 100) * delta3;

        this.ctx5.strokeStyle = "rgba(75, 181, 164, 1)";
        this.ctx5.beginPath();
        this.ctx5.arc(250, 250, 140, -this.quart, curPerc - this.quart);
        this.ctx5.stroke();

        if (delta3 < 1) {
            requestAnimationFrame(this.animate_inc.bind(this));
            this.ctx5.clearRect(215, 230, 80, 50); //clear double input
        } else {
            this.startTime3 = null;
            this.ctx5.clearRect(215, 230, 80, 50); //clear double input
        }

        // valueFour
        this.ctx5.font = "35px Philosopher";
        this.ctx5.fillStyle = "rgba(75, 181, 164, 1)";
        this.ctx5.fillText(Math.round(innerNumInc), 220, 260);
    }

    startAnim_inc() {
        this.duration3 = 3400;
        requestAnimationFrame(this.animate_inc.bind(this));
    }

    animate_tar(time) {
        if (!this.startTime4) {
            this.startTime4 = time;
        }

        var delta4 = Math.min(1, (time - this.startTime4) / this.duration4);
        var curPerc = ((2 * Math.PI) / 100) * (this.valueFour * delta4);
        var innerNumTar = ((this.valueFour * this.anchorValue) / 100) * delta4;

        this.ctx5.strokeStyle = "rgba(26, 221, 164, 1)";
        this.ctx5.beginPath();
        this.ctx5.arc(250, 250, 110, -this.quart, curPerc - this.quart);
        this.ctx5.stroke();

        if (delta4 < 1) {
            requestAnimationFrame(this.animate_tar.bind(this));
            this.ctx5.clearRect(220, 260, 100, 50);
        } else {
            this.startTime4 = null;
            this.ctx5.clearRect(220, 260, 100, 50);
        }

        // valueFour
        this.ctx5.font = "40px Philosopher";
        this.ctx5.fillStyle = "rgba(26, 221, 164, 1)";
        this.ctx5.fillText(Math.round(innerNumTar), 220, 290);
    }

    startAnim_tar() {
        this.duration4 = 3600;
        requestAnimationFrame(this.animate_tar.bind(this));
    }

    // initialize UI elements
    setupUI() {
        // requestAnimationFrame Shim
        var requestAnimationFrame =
            window.requestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.msRequestAnimationFrame;
        window.requestAnimationFrame = requestAnimationFrame;
    }

    async fillDashboard() {
        const instance = this;
        let noData = true;

        $(document).trigger("loader:on", [
            "",
            "transparent",
            document.getElementById("target1"),
        ]);

        if ($("#badges-overview").children().length > 0)
            $("#badges-overview").empty();

        let now = new Date();
        let startDate = new Date();
        startDate.setFullYear(now.getFullYear() - 1);
        let startDateString = startDate.toISOString();

        let requestObj = {
            referenceType: 0,
            pageSize: 500, //TODO add Pagination
            dateUntil: startDateString,
        };

        try {
            // type 0 for all active quacks
            let quacksData = await instance.chatService.getActiveQuacksData(
                requestObj
            );

            if (quacksData && quacksData.result && Array.isArray(quacksData.result) && quacksData.result.length > 0) {
                noData = false;
                initDashboard(quacksData);
            }
        } catch (e) {
            console.error(
                "Call for quacks overview has not been successful.",
                e
            );
            $(".news_feed").parent().hide();
        }

        try {
            let salesData60 =
                await instance.billingService.getAffiliateSalesOfXDays(60);
            let salesData30 =
                await instance.billingService.getAffiliateSalesOfXDays(30);

            let userId = await instance.authenticator.getUser();
            if (salesData60.length > 0) {
                instance.drawRecentSales(salesData60, userId);
                instance._drawSalesBadge(salesData60, "60 Tage");
                noData = false;
            }
            if (salesData30.length > 0) {
                instance._drawSalesBadge(salesData30, "30 Tage");
            }
        } catch (e) {
            console.error("Call to sales overview has not been successful.", e);
            $(".sales_feed").parent().hide();
        }

        function initNoDataFind() {
            let noDataHint = '<div id="no-data-active" class="no-result-indicator" style=""><h3 data-i18n="text.no_data_available"></h3></div>';
            $("#target1 .stats-block").removeClass("active");
            if (!$("#no-data-active").length) {
                $("#target1").append(noDataHint);
                $("#no-data-active h3").localize();
            }
        }

        function initDashboard(data) {
            if ($("#no-data-active").length) {
                $("#no-data-active").remove();
            }

            let analyticsData = instance._initDataAnalytics(data);

            const newsFeed = $(".news_feed");
            if (newsFeed.find('table').length) newsFeed.find('table').remove();

            instance.drawTableFromData(data.result, "newest");
            instance.drawTableFromData(data.result, "views");
            instance.drawTableFromData(data.result, "quackity");

            function setCanvasValue(value) {
                return value > 0 ? value : 0;
            }

            instance.anchorValue = analyticsData.totalViews; // set 100% or 360deg as anchor value
            instance.valueOne = setCanvasValue(
                (instance.anchorValue / instance.anchorValue) * 100
            );
            instance.valueTwo = setCanvasValue(
                (analyticsData.articleViews / instance.anchorValue) * 100
            );
            instance.valueThree = setCanvasValue(
                (analyticsData.chatViews / instance.anchorValue) * 100
            );
            instance.valueFour = setCanvasValue(
                (analyticsData.questionViews / instance.anchorValue) * 100
            );

            // start the canvas
            if (instance.anchorValue > 0) {
                instance.startAnim_rec();
                instance.startAnim_avg();
                instance.startAnim_inc();
                instance.startAnim_tar();
                noData = false;
            } else {
                $(".statistics-canvas-wrapper").hide();
            }

            $("#target1 .stats-block, #target1 .card-subheadline").addClass(
                "active"
            );
        }

        if (noData) {
            initNoDataFind();
            M.toast({ html: i18next.t("toasts.no_data_found") });
        }

        $(document).trigger("loader:off");
    }

    _drawSalesBadge(salesData, days) {
        var totalIncome = this.calcStats(salesData, "expertCommissionAmount");

        var iconEuro =
            '<i class="material-icons" style="font-size: 25px;">euro_symbol</i>';
        var focusValueTotalIncome = "Gesamteinnahmen <sup>" + days + "</sup>";
        var focusValueAvgIncome = "&#8709; Einnahmen <sup>" + days + "</sup>";

        this._createBadge(totalIncome.sum, iconEuro, focusValueTotalIncome);
        this._createBadge(totalIncome.avg, iconEuro, focusValueAvgIncome);
    }

    drawTableFromData(inputData, sortBy) {

        let tableSubHeadline = "";
        let newFeedItem = "";

        switch (sortBy) {
            case "views":
                inputData.sort((a, b) => b.views - a.views);
                tableSubHeadline = "Publications with the most views:";
                break;
            case "quackity":
                inputData = inputData.filter(item => item.referenceType === "ARTICLE" || item.referenceType === "CHAT");
                inputData.sort((a, b) => b.quackity - a.quackity);
                tableSubHeadline = "Publications with the highest rating:";
                break;
            case "newest":
                tableSubHeadline = "Newest publications:"; //default from the server
            default:
                // Default is the original order, assuming inputData is already sorted by newest
                break;
        }

        // find the last titles in quacks array
        for (var i = 0; i < inputData.length && i < 11; i++) {
            let quackity =
                inputData[i].quackity != null
                    ? inputData[i].quackity.toFixed(2)
                    : "-";
            var views =
                inputData[i].views != undefined ? inputData[i].views : "-";

            let href;
            href = "https://www.produck.de/quack/" + inputData[i].id + "/";
            var iconLink =
                '<i class="material-icons whiteco fs-14">open_in_new</i>';

            newFeedItem +=
                '<tr><td><a class="whiteco" target="_blank " href="' +
                href +
                '" >' +
                (i + 1) +
                ". " +
                inputData[i].title +
                iconLink +
                '</a></td><td class="text-center">' +
                inputData[i].referenceType +
                '</td><td class="text-center">' +
                views +
                '</td><td class="text-center">' +
                quackity +
                "</td></tr>";
        }

        var table =
            '<table class="striped highlight centered"><thead><th class="light-tealco">' +
            tableSubHeadline +
            "</th><th>Type</th><th>Views</th><th>Quackity</th><thead><tbody>" +
            newFeedItem +
            "</tbody></table>";

        $(".news_feed").append(table);
    }

    /**
     * Draws a table containing recent affiliate sales.
     *
     * @param {array} inputData
     */
    drawRecentSales(inputData, userId) {
        var instance = this;
        const feedTable = $(".sales_feed table");
        feedTable.empty();

        if (!inputData) {
            return;
        }

        if (!inputData.forEach) {
            console.log("ERROR - drawRecentSales: Input data is not an array!");
            return;
        }

        if (inputData.length > 0) {
            feedTable.append(
                "<thead><tr><th>Shop</th><th>Thema</th><th>Chat-Id</th><th>Datum</th><th>Provision (€)</th><th>Typ</th></tr></thead>"
            );
        } else {
            return;
        }

        inputData.forEach(function (sale) {
            var provName = sale.accountName
                .split("https://www.")
                .pop()
                .split("/")
                .shift();
            var affiliTopic = sale.chatTopic ? sale.chatTopic : "-";
            var affiliRef = sale.chatId ? sale.chatId : "Link";

            var creationTime = instance.utils.transformDate(
                new Date(sale.creationTime)
            );

            let newFeedItem =
                '<tbody><tr><td><a href="' +
                sale.accountName +
                '" target="_blank">' +
                provName.substring(0, 25) +
                "</a></td><td>" +
                affiliTopic.substring(0, 25) +
                '</td><td class="text-center">' +
                affiliRef +
                '</td><td class="text-center">' +
                creationTime.substr(0, 6) +
                '</td><td class="text-center">' +
                sale.expertCommissionAmount.toFixed(2) +
                "</td>" +
                "<td>";

            if (sale.expertId == userId) {
                newFeedItem +=
                    '<i class="material-icons income" data-i18n="[title]text.income">arrow_back</i>';
            } else {
                newFeedItem +=
                    '<i class="material-icons" data-i18n="[title]text.costs">arrow_forward</i>';
            }

            newFeedItem += "</td></tr></tbody>";

            feedTable.append(newFeedItem);
            $(".material-icons").localize();
        });

        feedTable.parent().parent().show();
    }

    getQuacksByDate(inputData) {
        var arraySevenDays = [];
        var arrayPrevSevenDays = [];
        var arrayThirtyDays = [];
        var arraySixtyDays = [];

        var currentDateMinusSixty = new Date();
        var currentDateMinusSeven = new Date();
        var currentDateMinusFourteen = new Date();
        var currentDateMinusThirty = new Date();
        currentDateMinusSixty.setDate(currentDateMinusSixty.getDate() - 60);
        currentDateMinusSeven.setDate(currentDateMinusSeven.getDate() - 7);
        currentDateMinusFourteen.setDate(
            currentDateMinusFourteen.getDate() - 14
        );
        currentDateMinusThirty.setDate(currentDateMinusThirty.getDate() - 30);

        // get all relevant values
        for (var value in inputData) {
            if (!inputData.hasOwnProperty(value)) {
                continue;
            }

            var chatDate = inputData[value].startTime;
            var transformedChatDate = new Date(chatDate);
            transformedChatDate.setDate(transformedChatDate.getDate());

            // get values for today
            if (
                transformedChatDate.getTime() >= currentDateMinusSixty.getTime()
            ) {
                arraySixtyDays.push(inputData[value]);
            }

            // get values for past 7 days
            if (
                transformedChatDate.getTime() >= currentDateMinusSeven.getTime()
            ) {
                arraySevenDays.push(inputData[value]);
            }

            // get values for past 14-7 days
            if (
                transformedChatDate.getTime() <=
                    currentDateMinusSeven.getTime() &&
                transformedChatDate.getTime() >=
                    currentDateMinusFourteen.getTime()
            ) {
                arrayPrevSevenDays.push(inputData[value]);
            }

            // get values for past 30 days
            if (
                transformedChatDate.getTime() >=
                currentDateMinusThirty.getTime()
            ) {
                arrayThirtyDays.push(inputData[value]);
            }
        }
        return {
            arraySevenDays,
            arrayPrevSevenDays,
            arrayThirtyDays,
            arraySixtyDays,
        };
    }

    calcStats(inputData, focus) {
        // get array of active quacks
        function buildTotalValuesArray(focus) {
            var array = [];

            // get all relevant values
            for (var value in inputData) {
                if (!inputData.hasOwnProperty(value)) {
                    continue;
                }
                var focusValue = eval("inputData[value]." + focus);

                // filter by specific focus value i.e. views
                var arrayReduc = function () {
                    // alternatively you can check if variable exists with typeof foo !== 'undefined'
                    // if( value ) checks null, undefined, NaN, empty string (""), 0, false
                    if (focusValue) {
                        array.push(focusValue);
                    }
                };

                arrayReduc();
            }

            var sum = calcSum(array);
            var avg = (sum / array.length).toFixed(2);

            return { sum, avg };
        }

        function calcSum(array) {
            var newValue =
                Math.round(
                    array.reduce(
                        (accumulator, currentValue) =>
                            accumulator + currentValue,
                        0
                    ) * 100
                ) / 100;
            return newValue;
        }

        return buildTotalValuesArray(focus);
    }

    _initDataAnalytics(data) {
        let instance = this;
        let totalViews = 0;
        let totalPublications = data.result.length;
        let totalQuackity = 0;
        let totalAnsweredQuestions = 0;
        let totalExternalViews = 0;
        let externalPublicationsCount = 0;
        const revenuePerView = 0.5; // 50 cents per click
        let estimatedRevenue = 0;

        const referenceTypeStats = {
            ARTICLE: {
                count: 0,
                views: 0,
                quackity: 0,
                externalCount: 0,
                externalViews: 0,
            },
            CHAT: {
                count: 0,
                views: 0,
                quackity: 0,
                externalCount: 0,
                externalViews: 0,
            },
            QUESTION: {
                count: 0,
                views: 0,
                answered: 0,
                quackity: 0,
                externalCount: 0,
                externalViews: 0,
            },
        };

        // Analyze the data
        data.result.forEach((item) => {
            const type = item.referenceType;
            const quackity = item.quackity || 0;
            const views = item.views || 0;

            // Update total counts
            totalViews += views;
            if (quackity) totalQuackity += quackity;
            estimatedRevenue += views * revenuePerView; // Calculate revenue for each item

            // Update reference type stats
            referenceTypeStats[type].count++;
            referenceTypeStats[type].views += views;
            if (quackity) referenceTypeStats[type].quackity += quackity;

            // Count external publications
            if (item.quackMode === "EXTERNAL") {
                externalPublicationsCount++;
                totalExternalViews += views;
                referenceTypeStats[type].externalCount++;
                referenceTypeStats[type].externalViews += views;
            }

            // Count answered questions
            if (type === "QUESTION" && item.status === "ANSWERED") {
                totalAnsweredQuestions++;
                referenceTypeStats.QUESTION.answered++;
            }
        });

        // Compute averages
        const averageQuackity = totalQuackity / totalPublications || 0;
        const averageViewsPerPublication = totalViews / totalPublications || 0;
        const publicationFrequencyperMonth = totalPublications / 12;

        const referenceTypeAverages = {
            ARTICLE: {
                averageViews:
                    referenceTypeStats.ARTICLE.views /
                        referenceTypeStats.ARTICLE.count || 0,
                averageQuackity:
                    referenceTypeStats.ARTICLE.quackity /
                        referenceTypeStats.ARTICLE.count || 0,
            },
            CHAT: {
                averageViews:
                    referenceTypeStats.CHAT.views /
                        referenceTypeStats.CHAT.count || 0,
                averageQuackity:
                    referenceTypeStats.CHAT.quackity /
                        referenceTypeStats.CHAT.count || 0,
            },
            QUESTION: {
                averageViews:
                    referenceTypeStats.QUESTION.views /
                        referenceTypeStats.QUESTION.count || 0,
            },
        };

        // Create badges
        function createBadge(description, value) {
            return (
                '<div class="chart-wrapper">' +
                '<div class="tooltipped badge" data-position="bottom" data-delay="50" data-tooltip="' +
                description +
                '">' +
                '<span class="flow-text w100">' +
                description +
                "</span>" +
                '<span class="flow-text w100">' +
                value +
                "</span>" +
                "</div></div>"
            );
        }

        const badges = {
            TOTAL: [], // To store overall badges
            QUESTION: [], // To store badges specific to questions
            ARTICLE: [], // To store badges specific to articles
            CHAT: [], // To store badges specific to chats
        };

        badges.TOTAL.push(createBadge("Total Publications", totalPublications));
        badges.TOTAL.push(
            createBadge(
                "Average Publications per Month",
                publicationFrequencyperMonth.toFixed(0) < 1
                    ? "< 1"
                    : publicationFrequencyperMonth.toFixed(0)
            )
        );
        badges.TOTAL.push(createBadge("Total Views", totalViews));
        badges.TOTAL.push(
            createBadge(
                "Average Views per Publication",
                totalViews > 10
                    ? averageViewsPerPublication.toFixed(0)
                    : "Not enough data"
            )
        );
        badges.TOTAL.push(
            createBadge(
                "Average Rating",
                totalPublications > 2
                    ? averageQuackity.toFixed(2)
                    : "Not enough data"
            )
        );
        badges.TOTAL.push(
            createBadge("External Publications", externalPublicationsCount)
        );
        badges.TOTAL.push(
            createBadge("Total External Views", totalExternalViews)
        );

        badges.TOTAL.push(
            createBadge(
                "Estimated Revenue (assuming 50 ct. p. click)",
                estimatedRevenue.toFixed(0)
            )
        );

        Object.keys(referenceTypeStats).forEach((type) => {
            const revenue = referenceTypeStats[type].views * revenuePerView;
            const externalViewsShare =
                (referenceTypeStats[type].externalViews /
                    referenceTypeStats[type].views) *
                    100 || 0;

            badges[type].push(
                createBadge(
                    `Total Publications`,
                    referenceTypeStats[type].count
                )
            );
            badges[type].push(
                createBadge(`Total Views:`, referenceTypeStats[type].views)
            );
            badges[type].push(
                createBadge(
                    `Average Views`,
                    referenceTypeStats[type].views > 2
                        ? referenceTypeAverages[type].averageViews.toFixed(0)
                        : "Not enough data"
                )
            );

            if (type !== "QUESTION") {
                badges[type].push(
                    createBadge(
                        `Average Rating`,
                        referenceTypeStats[type].count > 2
                            ? referenceTypeAverages[
                                  type
                              ].averageQuackity.toFixed(2)
                            : "Not enough data"
                    )
                );
                badges[type].push(
                    createBadge(
                        `External Publications`,
                        referenceTypeStats[type].externalCount
                    )
                );
                badges[type].push(
                    createBadge(
                        `External Views`,
                        referenceTypeStats[type].externalViews
                    )
                );

                badges[type].push(
                    createBadge(
                        "Share of External To Internal Views",
                        externalViewsShare.toFixed(2) + "%"
                    )
                );

                badges[type].push(
                    createBadge(`Estimated Revenue (assuming 50 ct. p. click)`, revenue.toFixed(0))
                );
            }

            if (type == "QUESTION") {
                badges[type].push(
                    createBadge("Answered Questions", totalAnsweredQuestions)
                );
            }
        });

        // Function to render badges in the HTML
        function renderBadges(category, containerId) {
            let badgesHtml = badges[category].join("");
            let totalBadgesContainer =
                '<div id="' +
                containerId +
                '" class="cluster-overview" ></div>';
            $("#badges-overview").append(totalBadgesContainer);

            let container = document.getElementById(containerId);
            if (container) {
                container.innerHTML =
                    '<h3 class="flex-box w100 just-center">' +
                    category +
                    "</h3>" +
                    badgesHtml;
            } else {
                console.error(`Container with ID "${containerId}" not found.`);
            }
        }

        // Render badges
        renderBadges("TOTAL", "total-badges-container");
        renderBadges("ARTICLE", "article-badges-container");
        renderBadges("CHAT", "chat-badges-container");
        renderBadges("QUESTION", "question-badges-container");

        instance._updateTagViews(data);

        // Ensure previous charts are destroyed and canvas is reset
        if (instance.avgViewsChart) {
            instance.avgViewsChart.destroy();
        }

        if (instance.avgQuackityChart) {
            instance.avgQuackityChart.destroy();
        }

        const ctx1 = document.getElementById("avgViewsChart").getContext("2d");
        const avgViewsData = [
            averageViewsPerPublication,
            referenceTypeAverages.ARTICLE.averageViews,
            referenceTypeAverages.CHAT.averageViews,
            referenceTypeAverages.QUESTION.averageViews,
        ];
        const avgViewsLabels = ["Total", "Articles", "Chats", "Questions"];
        instance.avgViewsChart = new Chart(ctx1, {
            type: "bar",
            data: {
                labels: avgViewsLabels,
                datasets: [
                    {
                        data: avgViewsData,
                        label: "",
                        backgroundColor: [
                            "#92d2cc",
                            "#f28e8e",
                            "#e37272",
                            "#a8e6cf",
                        ],
                        borderColor: [
                            "#92d2cc",
                            "#f28e8e",
                            "#e37272",
                            "#a8e6cf",
                        ],
                        borderWidth: 1,
                    },
                ],
            },
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: "Average Views per Publication",
                        color: "#ffffff",
                        font: {
                            size: 18,
                        },
                    },
                    legend: {
                        display: false,
                    },
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        grid: {
                            color: "#949494",
                        },
                        ticks: {
                            color: "white",
                        },
                    },
                    x: {
                        grid: {
                            color: "#949494",
                        },
                        ticks: {
                            color: "white",
                        },
                    },
                },
            },
        });

        const ctx2 = document
            .getElementById("avgQuackityChart")
            .getContext("2d");
        const avgQuackityData = [
            averageQuackity,
            referenceTypeAverages.ARTICLE.averageQuackity,
            referenceTypeAverages.CHAT.averageQuackity,
        ];
        const avgQuackityLabels = ["Total", "Articles", "Chats"];
        instance.avgQuackityChart = new Chart(ctx2, {
            type: "bar",
            data: {
                labels: avgQuackityLabels,
                datasets: [
                    {
                        label: "",
                        data: avgQuackityData,
                        backgroundColor: ["#92d2cc", "#f28e8e", "#e37272"],
                        borderColor: ["#92d2cc", "#f28e8e", "#e37272"],
                        borderWidth: 1,
                    },
                ],
            },
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: "Average Rating per Category",
                        color: "#ffffff",
                        font: {
                            size: 18,
                        },
                    },
                    legend: {
                        display: false,
                    },
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        grid: {
                            color: "#949494",
                        },
                        ticks: {
                            color: "white",
                        },
                    },
                    x: {
                        grid: {
                            color: "#949494",
                        },
                        ticks: {
                            color: "white",
                        },
                    },
                },
            },
        });

        let articleViews = referenceTypeStats.ARTICLE.views,
            chatViews = referenceTypeStats.CHAT.views,
            questionViews = referenceTypeStats.QUESTION.views;

        return { totalViews, articleViews, chatViews, questionViews };
    }

    _updateTagViews(data) {
        // Calculate tag views
        const tagViews = data.result.reduce((acc, item) => {
            if (Array.isArray(item.tags)) {
                const tagCluster = item.tags.join(", ");
                acc[tagCluster] = acc[tagCluster] || {
                    tags: item.tags,
                    views: 0,
                };
                acc[tagCluster].views += item.views;
            }
            return acc;
        }, {});

        // Get top 5 tag clusters by views
        const topTagClusters = Object.entries(tagViews)
            .sort((a, b) => b[1].views - a[1].views)
            .slice(0, 5);

        const topTagsStr = topTagClusters
            .map(([tagCluster, data]) => {
                const tagsStr = data.tags
                    .map(
                        (tagName) =>
                            `<div class="chip"><a target="_blank" href="https://produck.de/quacks?q=${tagName}">${tagName}</a></div>`
                    )
                    .join("");
                return `<div class="tag-cluster">${tagsStr}</div>`;
            })
            .join("");

        document.getElementById("tags-overview").innerHTML =
            '<div class="cluster-overview"><h3 class="flex-box w100 just-center">Favorite Tags</h3>' +
            topTagsStr +
            "</div>";
    }
}
