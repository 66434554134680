/* global monstecLib */
/* global M */
/* global MAX_OPEN_CHATS */ //defined in xpert.html
import i18next from 'i18next';

/**
 * This class provides common methods for pages that show a chat section.
 */
export default class ChatSupport {
    constructor(chatServiceAccess, externalChatClient, externalUtils, externalConstants, externalCookie) {
        this.chatService = chatServiceAccess;
        this.chatclient = externalChatClient;
        this.utils = externalUtils;
        this.cookie = externalCookie;
        this.constants = externalConstants;
        this.nameFunctionStatus = new Set();

        this.log = new monstecLib.Log(1);
    }

    setProductHandler(externalProducthandler) {
        this.producthandler = externalProducthandler;
    }

    //SET CHAT BEHAVIOR
    chatSettings() {
        var thisChatSupport = this;
        thisChatSupport.initEmojis();

        // CHAT CONTAINER
        function BtnAndScrollBar(texteditor, event) {
            const sendBtn = $('.cl.active .sendBtn');
            const attachBtn = $('.cl.active .attachBtn');

            if ($.trim(texteditor.value) !== '') {

                // keyboard navigation events
                if(event.which === 13) {
                    event.preventDefault();
                    thisChatSupport.sendAndDeleteMessage();
                }

                sendBtn.show();
                attachBtn.hide();
            } else {
                // hide send button & show attach_file when no text in textarea
                sendBtn.hide();
                attachBtn.show();
            }
        }

        var sendBtn = $('.cl.active .sendBtn');
        sendBtn.hide();

        sendBtn.mousedown(function (event) {
            thisChatSupport.sendAndDeleteMessage();
            BtnAndScrollBar($('#chatTextarea')[0], event);
        });

        $('.cl').find('.chatTextarea').each(function() {
            let currentTextArea = $(this);

            currentTextArea.on('focus change mousedown mouseout keyup', function (event) {
                BtnAndScrollBar(this, event);
            });

            currentTextArea.on('keydown', function (event) {
                BtnAndScrollBar(this, event);
                let blocker = currentTextArea.data('sendingBlocker');
                if (blocker) return;

                const chatId = $('.cl.active').data('chatid');
                thisChatSupport.chatclient.sendTypingInformation(chatId);
                currentTextArea.data('sendingBlocker', true);
                setTimeout(function() {
                    currentTextArea.data('sendingBlocker', false);
                }, 2000);
            });
        });

        // remove message alert, when user active on site, and mark messages as read
        $(document).on("mouseenter touchstart", ".cl.active", function() {
            const chatLog = $('.cl.active'); 
            const chatId = chatLog.data('chatid');
            const currentTab = $('a[data-chatid=' + chatId + ']');

            let messages = chatLog.find('.chat.user[data-message-id]');
            messages.each(function(index, msgContainer) {
                let status = msgContainer.getAttribute('data-status');
                if (status != 4) {
                    let messageId = msgContainer.getAttribute('data-message-id');
                    thisChatSupport.chatclient.wsSendMessageStatusUpdate('mmr', messageId);
                }
            });

            $(this).find('.chat_ul').children('.chat_li').remove();
            $(".thumb-nav__item[data-href='#target2'] .number-counter").remove();
            if (/\([\d]+\)/.test(document.title)) {
                var title = document.title.split(') ');
                document.title = title[1];
            }

            if  (currentTab.length) {
                currentTab.find('.tab_bubble').remove();
                thisChatSupport.utils.adjustTabIndicator(currentTab.closest('.tabs'), true);
            }

        });

        // remove message alert, when user active on site, and mark messages as read
        $(document).on("click", ".tab a", function() {
            const chatLog = $('.cl.active'); 
            const chatId = chatLog.data('chatid');
            const currentTab = $(this);

            let messages = chatLog.find('.chat.user[data-message-id]');
            messages.each(function(index, msgContainer) {
                let status = msgContainer.getAttribute('data-status');
                if (status != 4) {
                    let messageId = msgContainer.getAttribute('data-message-id');
                    thisChatSupport.chatclient.wsSendMessageStatusUpdate('mmr', messageId);
                }
            });

            setTimeout (function() {
                if(currentTab.hasClass('active')) {
                    $('.cl.active').find('.chat_ul').children('.chat_li').remove();
                    $(".thumb-nav__item[data-href='#target2'] .number-counter").remove();
                    currentTab.css({'background': ''});
                    currentTab.find('.tab_bubble').remove();
                    if (/\([\d]+\)/.test(document.title)) {
                        var title = document.title.split(') ');
                        document.title = title[1];
                    }

                    thisChatSupport.utils.adjustTabIndicator(currentTab.closest('.tabs'), true);
                }
            }, 5000);
        });

        thisChatSupport.enableDynamicHeightofTextarea($('.chatTextarea'));
    }

    initBrowserAdjust() {
        var instance = this;
        var browser = instance.utils.detectBrowser();
        var os = instance.detectOs();

        // First we get the viewport height and we multiply it by 1% to get a value for a vh unit
        let vh = $(window).innerHeight() * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        $(window).on('resize orientationchange', function () {
            let vh = window.innerHeight * 0.01;           
            document.documentElement.style.setProperty('--vh', `${vh}px`);
            setTimeout(() => {
                instance.scrollDown();
            }, 200);
        });

        if (browser === 'chrome' && os === 'android') {
            $('#target2').addClass('chrome');

            $(window).on('resize', function () {
                var value = instance._setChatStyle(this.initialHeight, this.initialWidth);
                this.initialHeight = value.newHeight;
                this.initialWidth = value.newWidth;
                instance.scrollDown();
            });
        } else if (browser === 'safari' && (os === "iphone" || os === "ipad")) {
            $('body#user').addClass('safari');

            //do adjustments to open keyboard
            $(document).on('click', '#searchInput, #chatTextarea', e => {
                var viewport = $("#viewport-meta");
                e.stopPropagation();
                $('body#user').addClass('iOs');
                viewport.attr("content", "width=device-width,initial-scale=1 maximum-scale= 1");
                instance.scrollDown();
            });

            //close Keyboard
            $(document).on('click', '.cl.active .sendBtn', () => {
                $('#chat_form').focusout();
                instance.sendAndDeleteMessage();
            });

            // reset style to "closed keyboard"
            $(document).on('focusout', '#chatTextarea, #searchInput', () => {
                $('body#user').removeClass('iOs');
            });
        }

        if (self !== top) {
            $('#target2').addClass('in-iframe');
            $('#user.body').addClass('iframe-body');
        }
    }

    // If previous height is smaller than new height, than keyboard was closed & vice versa - adjust style to fit in screen
    _setChatStyle (initialHeight, initialWidth) {
        var instance = this;
        var newHeight = $(window).innerHeight();
        var newWidth = $(window).innerWidth();
        const target = $('#target2');

        // fire when height changed, but width remains equal to take care for orientation change
        if (newHeight < initialHeight && newWidth === initialWidth) {
            target.removeClass('chrome');
            target.addClass('keyboard');
            $('.body').addClass('keyboard-active');
        } else {
            target.removeClass('keyboard');
            $('.body').removeClass('keyboard-active');
            target.addClass('chrome');
        }

        return {newHeight, newWidth};
    }

    scrollDownOnKeyBoardOpen() {
        var instance = this;

        $(window).on('resize orientationchange', function () {
            instance.scrollDown();
        });

        $(document).on('click', '#chatTextarea', e => {
            e.stopPropagation();
            instance.scrollDown();
        });
    }

    scrollBarTabs(tabs) {
        
        const scrollTabs = new PerfectScrollbar(tabs, {
            wheelSpeed: 0.7,
            wheelPropagation: false,
            suppressScrollY: true,
            swipeEasing: false,
            minScrollbarLength: 20,
            maxScrollbarLength: 80
        });
    }

    // increase textareas with content and reset
    enableDynamicHeightofTextarea(elem) {
        elem.on('focusin change input', function () {
            if ($.trim(this.value) !== '') {
                var maxHeight = 100;
                this.scrollHeight <= maxHeight ? maxHeight = this.scrollHeight : maxHeight = maxHeight;
                this.style.height = maxHeight + 'px';
            }
        });

        elem.on('change mousedown mouseout keydown keyup', function () {
            if ($.trim(this.value) === '') {
                this.style.height = '';
            }
        });
    }

    /**
     * Will scroll down the active chatlog as far as possible, useful after new content arrives in the chatlog.
     */
    scrollDown() {
        var chatlog = $('.cl.active .chatlog');

        function scroll() {
            setTimeout(() => {
                chatlog.animate({scrollTop: chatlog[0].scrollHeight}, 0);
            }, 0);
        }

        if(chatlog.length > 0){
            scroll();
        }

        $('#chat_tab_wrapper > .tabs > .tab').on('click', (scroll));
    }

    async checkChatCommissionability(chatId) {
        let response = await this.chatService.checkCommissionability(chatId);
        return (!!response.result);
    }

    /**
     * Stores a marker cookie to track users who had a chat with an expert.
     *
     * @param {number} chatId the technical identifier of the chat which triggered the marker action
     */
    async storeMarker(chatId) {
        this.log.debug('Storing produck marker cookie for chat ' + chatId);
        let data = await this.cookie.getRightStorage('produck_marker');

        // getRightStorage might return 'false' (observed) and who knows what else in the future
        if (!data || typeof(data) !== 'object') {
            data = {};
        }

        data.chatId = chatId;
        this.cookie.saveToRightStorage('produck_marker', data, 30);
    }

    /**
     * Fetch messages from library and provide callback for results.
     *
     * @param {number} chatId (optional) Specifies a specific chat to fetch messages for, if not given, the chatclient decides which messages to fetch,
     *                        which will be the last chat to have received a message (at least that was the situation when this was written).
     * @param {number} messageId (optional) The identifier of the message that triggered the websocket-notification to inform the client that there are new
     *                           messages; if given the messageId will be compared to the saved lastMessageId and if the messageId is smaller than lastMessageId
     *                           it has to be checked if the message is already in the chatlog corresponding to chatId. If not the chat messages have to be fetched
     *                           from messageId on and not from lastMessageId on.
     */
    getMessages(chatId, messageId) {
        var instance = this;
        var chatLog =  $(".cl[data-chatid=" + chatId + "] .chatlog.conversation-card");
        let fetchStartId = instance.getLastMessageId(chatLog);
        let includeStartId = false;

        if (messageId && !isNaN(Number(messageId))
                && messageId < fetchStartId && !this.isMessageAlreadyDisplayed(chatLog, messageId)) {
            fetchStartId = messageId;
            includeStartId = true;
        }    

        return instance.chatclient.loadMessages(fetchStartId, instance.addMessagesCallback.bind(instance), chatId, includeStartId);        
    }

    /**
     * Whenever a user opens an active chat on another device or in another window / tab a new websocket session
     * will be created that replaces any session already in use. Those replaced sessions will receive a message
     * that the chat is continued elsewhere. This function is used to signal that to the user and offer a possibility
     * to reactivate the current chat window and so replace other sessions.
     */
    signalChatReplacement() {
        const instance = this;
        instance.utils.createSimpleAlertModal(i18next.t('text.chat_application_replaced'), 'chatReplacedSignalModal', undefined, i18next.t('text.continue_chat')).then(function() {
            // determine identifiers of chats to reactivate
            $('.cl[data-chatid]').each(function(index, chatLog) {
                let userId = instance.chatclient.getUserId();
                let chatId = $(chatLog).attr('data-chatid');
                instance.chatclient.wsRegisterForChat(userId, chatId);
                instance.getMessages(chatId);
            });
        });
    }

    /**
     * Signals that the chat partner is typing.
     *
     * @param {number} userId for later use when there are more than two participants in a chat
     * @param {number} chatId the identifier of the chat to signal typing for
     */
    signalTyping(userId, chatId) {
        const typingSignal = $('.cl[data-chatid=' + chatId + ']').find('.chat-typing-signal');
        const xpertLevel = $('.cl[data-chatid=' + chatId + ']').find('.xpert_level');

        xpertLevel.hide();
        typingSignal.show();

        // Clear any previous timeout so that the signal does not flicker.
        let currentTimeout = typingSignal.data('timeout');
        if (currentTimeout) {
            clearTimeout(currentTimeout);
        }

        // Set timout for the signal so that the signal disappears after some time.
        typingSignal.data('timeout', setTimeout(function() {
            typingSignal.hide();
            xpertLevel.show();
        }, 3000));
    }

    async addMessagesCallback(messages) {
        const instance = this;
        let userId = await this.chatclient.getUserId();

        let unreadMsgCounter = 0;

        for (var m = 0; m < messages.length; m++) {
            instance.addMessageToContainer(messages[m], userId);

            if (!messages[m].read && userId !== messages[m].senderId) unreadMsgCounter += 1; 
        }

        unreadMsgCounter > 0 ? instance.callPushAlert(messages[0].chatId, unreadMsgCounter) : undefined;

        return true; // return something that will get wrapped in a promise
    }

    /**
     * initiates "add message" to chatlog
     *
     * @param {*} message the message to add
     * @param {*} userId the technical ID of the current user
     */
    async addMessageToContainer(message, userId) {
        var instance = this;
        var senderId = message.senderId;
        var chatId = message.chatId;
        var messageId = message.id;
        var chatLog =  $(".cl[data-chatid=" + chatId + "] .chatlog.conversation-card");

        if (instance.isMessageAlreadyDisplayed(chatLog, messageId)) {
            return;
        }

        //for incoming messages
        if (userId != message.senderId) {
            if (!message.delivered) {
                this.chatclient.wsSendMessageStatusUpdate('mmd', message.id);
            }
        }

        //var messageDate = instance.utils.transformTime(messageTimestamp);
        var partnerRight = 'chat xpert'; //reflects always the right - personal Role
        var portraitRight = null; //TODO dynamic image
        var partnerLeft = 'chat user'; //reflects always the left - partner Role
        var portraitLeft = null;//TODO dynamic image

        // alter message timestamp into a nice human readable format
        message.timestamp = new Date(message.timestamp).toDateString() + ' ' + new Date(message.timestamp).toLocaleTimeString();

        if (message.type === 'TEXT') {
            if (message.text === '#Teilen' || message.text === '#Share' || message.text === '#Chat beenden' || message.text === '#Close chat') {
                var btncloseid = 'chatClosingBtn';
                pretext.sendButton(btncloseid, message.text, chatLog.find('.chat-message').last());
            } else {
                if (senderId === userId) {
                    let messageContainer = await instance.addTextMessageToChatLog(partnerRight, portraitRight, message, chatLog, true, senderId);
                    messageContainer.setAttribute('data-message-id', messageId);
                } else {

                    //TODO bind robot-msg to new message type "robot-message"
                    if (!instance.nameFunctionStatus.has(chatId) && (message.text.indexOf("Du chattest mit") >= 0 || message.text.indexOf("You are chatting with") >= 0)) {
                        pretext.autoTextEvent(message.text, chatLog, 'robot-msg', message.timestamp);
                        var newNickname = message.text.indexOf("Du chattest mit") >= 0 ? message.text.split('Du chattest mit ')[1] : message.text.split('You are chatting with ')[1];
                        var correspondingChat = $(".cl[data-chatid=" + chatId + "] .xpert_name.xpertside");
                        var correspondingTab = $(".tab a[data-chatid=" + chatId + "]");
                        var correspondingShareBtn = $(".chat-overview-collapsible-body tr[data-chatid=" + chatId + "] td").first();

                        correspondingChat.html(newNickname);
                        correspondingTab.html(newNickname);
                        correspondingShareBtn.html(newNickname);

                        instance.nameFunctionStatus.add(chatId);
                       
                        instance.chatclient.wsSendMessageStatusUpdate('mmr', message.id); //set read status to prevent alert

                        instance.utils.adjustTabIndicator($('#chat_tab_wrapper .tabs'), true, true, 0);

                    } else {
                        let messageContainer = await instance.addTextMessageToChatLog(partnerLeft, portraitLeft, message, chatLog, false, senderId);
                        messageContainer.setAttribute('data-message-id', messageId);

                        if (message.read) {
                            messageContainer.setAttribute('data-status', 4);
                        } else if (message.delivered) {
                            messageContainer.setAttribute('data-status', 3);
                        } else  {
                            messageContainer.setAttribute('data-status', 2);
                        }
                    }
                }
            }
        } else if (message.type === 'IMAGE') {
            instance.addImageMessageToChatLog(chatId, message.mediaId, messageId, (senderId === userId) ? partnerRight : partnerLeft);
        } else if (message.type === 'DOCUMENT') {
            instance.addDocumentMessageToChatLog(chatId, message.mediaId, message.filename, messageId,
                                                 (senderId === userId) ? partnerRight : partnerLeft);
        } else {
            instance.log.warn('Message ' + messageId + ' is not of a supported type, but ' + message.type + '.');
        }

        if (senderId !== userId && !message.read) {
            monstecLib.produckContext.iframeDispatcher.sendInfoAboutMessage();
        }

        // after the message has been added remember the id of the message as 'last message added' in the chat log
        instance.updateLastMessageMetaData(chatLog, messageId);
    }

    /**
     * Update the stored last message ID which must always be the identifier of the message last received or
     * last acknowledged when sent.
     *
     * @param {object} chatlog must be a JQuery-object
     * @param {number} messageId
     */
    updateLastMessageMetaData(chatLog, messageId) {
        let messageIdNumber = Number(messageId);
        if (isNaN(messageIdNumber)) {
            this.log.error('updateLastMessageMetaData - got illegal messageId: ', messageId);
            return;
        }

        this.log.debug("Updating last message data with message ", messageIdNumber);

        let lastMsgId = chatLog.attr('data-last-message-id');
        if (!!lastMsgId) {
            lastMsgId = Math.max(lastMsgId, messageIdNumber);
        } else {
            lastMsgId = messageIdNumber;
        }

        chatLog.attr('data-last-message-id', lastMsgId);
        chatLog.find(".chat-message").last().attr("data-messageid", lastMsgId);

        let displayedMessages = chatLog.data('displayed-messages');

        if (!displayedMessages) {
            displayedMessages = [];
            chatLog.data('displayed-messages', displayedMessages);
        }

        displayedMessages.push(messageIdNumber);
    }

    getLastMessageId(chatLog) {
        let lastMsgId = chatLog.attr('data-last-message-id');
        return lastMsgId;
    }

    /**
     * Checks if a certain message identified by messageId is displayed in the given chatLog.
     */
    isMessageAlreadyDisplayed(chatLog, messageId) {
        let displayedMessages = chatLog.data('displayed-messages');

        if (!!displayedMessages) {
            return displayedMessages.includes(messageId);
        } else {
            return false;
        }
    }

    /**
     * 
     * @param {*} chatId 
     * @param {*} imageId 
     * @param {*} messageId 
     * @param {*} partner 
     * @param {object} messageContainer optional html element to contain the image message
     */
    async addImageMessageToChatLog(chatId, imageId, messageId, partner, messageContainer) {
        const instance = this;

        if (!messageContainer) {
            const element = $('.cl[data-chatid=' + chatId + '] .chatlog');
            messageContainer = document.createElement('div');
            messageContainer.className = partner;
            messageContainer.setAttribute('data-message-id', messageId);
            instance.setMessageStatus(0, messageContainer);
            element[0].appendChild(messageContainer);
        } else {
            $(messageContainer).empty();
        }

        const imageContainer = document.createElement('div');
        imageContainer.className = "img-container";
        messageContainer.appendChild(imageContainer);

        return instance.chatService.getImage(imageId, messageId).then(function(imageData) {
            var blob = new Blob( [ imageData, { type: "application/octet-stream" } ]);
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL( blob );

            let image = new Image();
            image.className = ('materialboxed');
            image.src = imageUrl;

            imageContainer.appendChild(image);

            var elems = image;
            var materialboxOptions = {
                'onOpenStart': function () { $('#slide-out, #right_menu').css({ "z-index": "-1" }); },
                'onCloseEnd': function () { $('#slide-out, #right_menu').css({ "z-index": "" }); },
            };
            var instances = M.Materialbox.init(elems, materialboxOptions);

            image.onload = function() {
                instance.scrollDown();
            };
        });
    }

    async addDocumentMessageToChatLog(chatId, documentId, filename, messageId, partner, messageContainer) {
        const instance = this;

        if (!messageContainer) {
            const element = $('.cl[data-chatid=' + chatId + '] .chatlog');
            messageContainer = document.createElement('div');
            messageContainer.className = partner;
            messageContainer.setAttribute('data-message-id', messageId);
            instance.setMessageStatus(0, messageContainer);
            element[0].appendChild(messageContainer);
        } else {
            $(messageContainer).empty();
        }

        let linkContainer = $('<p>');
        linkContainer.attr('class', 'chat-message');
        linkContainer.attr('data-messageid', messageId);

        let documentLink = $('<a>');
        documentLink.attr('class', 'document-link');
        linkContainer.append(documentLink);

        documentLink.append('<i class="tiny material-icons">file_download</i>');

        let documentSpan = $('<span>');
        documentSpan.append((!!filename) ? filename : documentId);
        documentLink.append(documentSpan);

        $(messageContainer).append(linkContainer);

        documentLink.click(function() {
            return instance.chatService.getDocument(documentId, messageId).then(function(documentData) {
                var blob = new Blob( [ documentData, { type: "application/octet-stream" } ]);
                var urlCreator = window.URL || window.webkitURL;
                var dataUrl = urlCreator.createObjectURL( blob );
                
                documentLink.unbind('click');
                documentLink.attr('target', '_blank');
                documentLink.attr('href', dataUrl);
                documentLink.attr('download', (!!filename) ? filename : (documentId));
                setTimeout(function() {
                    var clickEvent = new MouseEvent("click", {
                        "view": window,
                        "bubbles": true,
                        "cancelable": false
                    });
                    documentLink[0].dispatchEvent(clickEvent);
                }, 200);
            });
        });
    }

    /**
     * Sets the message status icon of a message.
     *
     * @param {*} status the status to be set
     *                   0: sending
     *                   1: sending failed
     *                   2: sent
     *                   3: deliverd
     *                   4: read
     * @param {*} containerRef either an id identifying a html element or an html element
     */
    setMessageStatus(status, containerRef) {
        let icon;
        let title;
        let extraStyle = "";

        switch (status) {
            case 0:
                icon = 'data_usage';
                title = i18next.t('text.msg_status.0');
                break;
            case 1:
                icon = 'close';
                extraStyle = 'color: rgba(178,0,35,.9);';
                title = i18next.t('text.msg_status.1');
                break;
            case 2:
                icon = 'file_uploaded';
                // For some reason that icon leads to a much greater width in auto-mode, so the width
                // has to be adapted.
                extraStyle = 'width: 15px;';
                title = i18next.t('text.msg_status.2');
                break;
            case 3:
                icon = 'check';
                title = i18next.t('text.msg_status.3');
                break;
            case 4:
                icon = 'remove_red_eye';
                title = i18next.t('text.msg_status.4');
                break;
            default:
                this.log.error("setMessageStatus got invalid status ", status);
                return;
        }

        let jqContainer = $(containerRef);
        jqContainer.attr('data-status', status);
        
        let msgStatusElement = jqContainer.find('.chat-message-status');
        msgStatusElement.empty();
        msgStatusElement.append('<i class="material-icons tiny"'
            + ((extraStyle.length > 0) ? (' style="' + extraStyle + '"') : "") + ' title="'+ title +'">'
            + icon + '</i>');
    }

    markMessageDelivered(chatId, messageId) {
        let chatLog =  $(".cl[data-chatid=" + chatId + "] .chatlog.conversation-card");
        let messageContainer = chatLog.find('.chat[data-message-id=' + messageId + ']');
        this.setMessageStatus(3, messageContainer);
    }

    markMessageRead(chatId, messageId) {
        let chatLog =  $(".cl[data-chatid=" + chatId + "] .chatlog.conversation-card");
        let messageContainer = chatLog.find('.chat[data-message-id=' + messageId + ']');
        this.setMessageStatus(4, messageContainer);
    }

    //triggers creating a copy of the sent message in own chatlog
    async sendTextMessageSelf(text){
        let instance = this;
        let partnerRight = 'chat xpert'; //reflects allways the right - personal Role
        let portraitRight = null; //TODO dynamic image
        let message = {};
        let userId = await instance.chatclient.getUserId();
        message.text = text;
        message.timestamp = new Date().toDateString() + ' ' + new Date().toLocaleTimeString();

        //var messageDate = instance.utils.transformTime(messageTimestamp);
        var chatLog = $('.cl.active .chatlog.conversation-card');

        var messageContainer = await instance.addTextMessageToChatLog(partnerRight, portraitRight, message, chatLog, true, userId);

        instance.chatclient.sendMessage(message.text) // send message to server
            .then(function(messageId) {
                instance.updateLastMessageMetaData(chatLog, messageId);
                messageContainer.setAttribute('data-message-id', messageId);
                instance.setMessageStatus(2, messageContainer);
            })
            .catch(function () {
                instance.setMessageStatus(1, messageContainer);
            });

        $(document).one('click', ".thumb-nav__item[href='#target2']", function () {
            instance.scrollDown(); //autoscroll to end of xpert chatlog on chat container click
        });
    }

    // adds message to chatlog
    addTextMessageToChatLog(partner, portrait, message, targetChat, setStatus, senderId) {
        var instance = this;
        const messageContainer = document.createElement('div'); // Container for textmessage
        const textContainer = document.createElement('p'); // container for text itself
        //const avatarContainer = document.createElement('div'); // creating personal avatar
        //const img = document.createElement('img');

        instance.utils.linkifyText();
        messageContainer.className = partner; // type decision for now hardcoded
        textContainer.className = 'chat-message';
        textContainer.innerHTML = message.text.linkify(false, senderId);
        textContainer.title = message.timestamp;
        messageContainer.appendChild(textContainer);

        if (setStatus) {
            const statusContainer = document.createElement('div');
            statusContainer.className = 'chat-message-status';
            messageContainer.appendChild(statusContainer);
            if (message.read) {
                instance.setMessageStatus(4, messageContainer);
            } else if (message.delivered) {
                instance.setMessageStatus(3, messageContainer);
            } else if (message.id) {
                instance.setMessageStatus(2, messageContainer);
            } else {
                instance.setMessageStatus(0, messageContainer);
            }
        }

        targetChat[0].appendChild(messageContainer);    // append new messageContainer to chatlog container which is identified by its class

        var mtinCheck = instance.producthandler.detectMtin(message.text, partner);
        if (mtinCheck.result) {
            messageContainer.setAttribute("data-mtin", mtinCheck.mtin);
            messageContainer.classList.add("product-msg");
            instance.producthandler.transformMTINS(targetChat);
        }

        instance.transformEmojis(targetChat);
        instance.scrollDown();
        return messageContainer;
    }

    sendAndDeleteMessage() {
        var textarea = $('.cl.active .chatTextarea');
        var text = textarea.val();
        this.sendTextMessageSelf(text);
        textarea.val(''); // clear textarea after mousedown on #sendBtn
        setTimeout(() => {
            textarea.focus();
        }, 0);
    }

    initEmojis() {
        var instance = this;
        var emojiBtn = $('.cl.active .emojiBtn');
        var emojiCard = $('.cl.active .emoji-card')
        var text = $('.cl.active .chatTextarea');
        var emoji = $('.cl.active .emoji');
        var os = this.detectOs();

        $('#target2').on( 'mousedown keyup', function(e) {
            // click outside emoji-elements closes emoji-selection

            if (!emojiBtn.is(e.target) && emojiBtn.has(e.target).length === 0 && !emojiCard.is(e.target) && emojiCard.has(e.target).length === 0){
                emojiCard.hide();
                emojiBtn.removeClass('open')
                emojiBtn.addClass('closed')
            } //open and close emoji-selection when click target emojiBtn or its descendants
            else if (emojiBtn.is(e.target) || emojiBtn.has(e.target).length > 0) {
                // focus on editor with workaround set timeout
                setTimeout(() => {
                    text.focus();
                }, 0);

                if (emojiBtn.hasClass('open')) {
                    emojiCard.hide();
                    emojiBtn.removeClass('open');
                    emojiBtn.addClass('closed');
                } else {
                    emojiCard.show();
                    emojiBtn.removeClass('closed')
                    emojiBtn.addClass('open');
                }
            }
        });

        //set new text
        emoji.click(function () {
            var emojiName = $(this).attr('name');
            var cursorLocation = instance.utils.returnCursorPosition(text);
            var initialText =  text.val();
            var newText  = initialText.substr(0, cursorLocation.pos) + emojiName + initialText.substr(cursorLocation.pos + cursorLocation.selLength);
            var newCursorLocation = cursorLocation.pos + emojiName.trim().length;
            
            text.val(newText);
            text.prop('selectionEnd', newCursorLocation);
            text.focus();
        });
    }

    transformEmojis(chatlog) {
        var emojiArray = $('.emoji').map(function(){
            return $(this).attr('name');
        }).get();

        var currentMessages = chatlog.find('p.chat-message');
        var chatMessageContent = $.grep((currentMessages).last().html().split(" "), function(a){ return /^:/.test(a) }); // make array from all smileys included in text

        for(var i = 0; i < chatMessageContent.length; i++) {
            var arrayPos = $.inArray(chatMessageContent[i], emojiArray);  // compare if intersection among given smileys-tags and inserted text

            // if input matches smiley-tag...
            if (arrayPos > -1) {
                var emojiName = emojiArray[arrayPos];
                var emojiNameSplit = emojiName.split(':')[1];

                currentMessages.last().html ( function (_, html) {
                    return html.replace(emojiName, '<icon class="fas fa-' + emojiNameSplit + '"></icon>')
                });
            }
        }
    }   


    detectOs () {
        var Os = (function(agent){
            switch(true){
                case agent.indexOf("android") > -1: return "android";
                case agent.indexOf("iphone") > -1: return "iphone";
                case agent.indexOf("ipad") > -1: return "ipad";
                default: return "other";
            }
        })(window.navigator.userAgent.toLowerCase());

        return Os;
    }

    /**
     * Assign special functionality to the items of the navigation menu.
     */
    assignExpertNavigationFunctionality() {
        let requestListNavLink = $('#chat-requests-mobile a');
        requestListNavLink.on('click', function() {
            document.title = i18next.t('text.slogan');
        });
    }

    extendChat(chatId, nickname, userId, providerId, setActive) {
        var instance = this;
        const liNmbr = $('#chat_tab_wrapper .tabs > .tab').length + 1;
        const ul = document.createElement('ul');
        const li = document.createElement('li');
        const a = document.createElement('a');
        const chat = document.getElementById('target2');
        const tabWrapper = document.getElementById('chat_tab_wrapper');

        ul.className = 'tabs';
        li.className = 'tab col s3';

        $('.cl, #chat_tab_wrapper > .tabs > .tab a').removeClass('active');

        if (!!tabWrapper) {
            if (liNmbr < (MAX_OPEN_CHATS + 1)) {
                $('#chat_tab_wrapper > ul.tabs > li.tab').first().clone().appendTo('#chat_tab_wrapper ul.tabs')[0];
                $('.cl').first().clone().appendTo(chat);
                $('.cl').last().find('.chatlog.conversation-card .chat').remove();
                $('#chat_tab_wrapper').css({'display': 'flex'});
                $('.chat_wrapper').removeClass('onechat');
            } else {
                var textBody = i18next.t('text.maximum_no_chats')
                instance.utils.createSimpleAlertModal(textBody);
                return false;
            }
        } else {
            const div = document.createElement('div');

            div.id = 'chat_tab_wrapper';
            chat.appendChild(div);

            var createTab = function() {
                div.appendChild(ul);
                ul.appendChild(li);
                li.appendChild(a);
            };

            createTab();
            $('#no-chat-active').hide();
            $('.cl').first().clone().appendTo(chat);
            $('#chat_tab_wrapper').css({'display': 'none'});
            $('.chat_wrapper').addClass('onechat');
        }

        var tabs = $('#chat_tab_wrapper .tabs');
        var lastTab = tabs.find('.tab').last();
        var lastChatlog = $('.cl').last();
        var navIndex = "cl" + liNmbr;

        lastTab
            .attr('data-val', navIndex)
            .find('a').attr('href', "#" + navIndex).attr('data-chatid', chatId).text(nickname).addClass('active'); // active is requiredto 

        lastChatlog
            .attr('id', navIndex).attr('data-chatid', chatId)
            .attr('data-chatpartner', userId)
            .find('.chat-online-signal').attr('id', 'chat-online-signal' + chatId);

        if (providerId) $('.cl').last().attr('data-providerid', providerId);

        // init chat-overview in right collapsible menu
        var overviewTableString = "<tr data-chatid="+chatId+" data-href='#target2'><td>"+nickname+"</td><td><a class='btn waves-effect waves-light share-item disabled'>"+i18next.t("text.send")+"</a></td></tr>";
        $('.chat-overview-collapsible-body table').append(overviewTableString);        

        let chatFooter = $('.cl').last().find('.chat_wrapper .chat_footer');
        chatFooter.children('.attachBtn').attr('id', 'chatAttachButton' + chatId);
        chatFooter.find('input[type=file]').attr('id', 'chatAttachInput' + chatId);

        let chatFileUpload = new monstecLib.ChatFileUpload(chatId, 'chatAttachInput' + chatId, 'chatAttachButton' + chatId, instance);

        M.Tabs.init(tabs);
        if (setActive) tabs.tabs('select', navIndex);

        instance.scrollDown();
        instance.scrollBarTabs(tabs[0]);
        instance.chatSettings();
    }

    /**
    * Manages the notification for unread messages
    */
    callPushAlert(chatId, counter) {
    
        var instance = this;
        let chatlog =  $('.cl[data-chatid=' + chatId + ']');
        let currentTab = $('a[data-chatid=' + chatId + ']');
        let chatUl = chatlog.find('.chat_ul');

        function countNewMessages() {
            
            var countAll = 0;
            var chatArray = $('.cl').map(function(){
                return $(this).find('.chat_bubble');
            }).get();

            for (var i = 0; i < chatArray.length; i++) {
                countAll +=  +chatArray[i].text();
            }
            var sum = countAll++;
            return sum;
        }

        if(chatUl.children().length === 0) {
            alertChatter();
        } else {
            let currentBubble = chatlog.find('.chat_bubble');
            let tabBubble = currentTab.find('.tab_bubble');
            let newMessages = +countNewMessages() + 1;
            let count = +currentBubble.text() + 1;
            
            currentBubble.text(count);
            tabBubble.text(count);

            var title = document.title.split(')');
            document.title = '(' + newMessages + ') ' + title[1];

            $(".thumb-nav__item[data-href='#target2'] .number-counter").remove();
            $(".thumb-nav__item[data-href='#target2']").append('<span class="number-counter">' + newMessages + '</span>');

            //if (count > 1 && count < 3) { //OLD Version
            if (count > 1) {
                instance.utils.controlSoundPlay();
            }
        }

        function alertChatter(){

            let chatList = document.createElement('li');
            let chatBubble = document.createElement('span');
            let tabBubble = document.createElement('span');

            chatList.className = 'chat_li';
            chatBubble.className = 'chat_bubble';
            tabBubble.className = 'tab_bubble';
            counter ? chatBubble.textContent = counter : chatBubble.textContent = '1';
            chatList.appendChild(chatBubble);
            chatUl.prepend(chatList);

            let newMessages = countNewMessages();

            if (document.title.indexOf('(') === -1) {
                document.title = '(' + newMessages + ') ' + document.title;
            }
            else {
                let title = document.title.split(') ');
                document.title = '(' + newMessages + ') ' + title[1];
            }


            let newMsgCounted =  counter ? counter : '1';
            tabBubble.textContent = newMsgCounted;
            $(".thumb-nav__item[data-href='#target2'] .number-counter").remove();
            $(".thumb-nav__item[data-href='#target2']").append('<span class="number-counter">' + newMsgCounted + '</span>');
            currentTab.append(tabBubble);
            instance.utils.controlSoundPlay();
        }
        
        const tabs = currentTab.parents('.tabs');

        if (tabs.length) instance.utils.adjustTabIndicator(tabs, true);
    }
}
