/* global monstecLib */
import i18next from "./localisation.js";
import 'js-cookie';

// see https://developer.paypal.com/docs/checkout/

// the variable paypal will be valid if the paypal checkou.js script is included in the page
/**
 *
 * @param {string} htmlElementIdSelector an HTML ID lik '#paypal-button'
 * @param {number} amount the amount of the paypal transaction the button will trigger
 * @param {string} customer information on the customer (name, address, e-mail...)
 * @param {Array} positions particular positions of the order, a position consists of article and quantity
 */

export default class SalesHandler {
    constructor(externalCookie, externalChatClient, externalUtils, externalProductHandler, catalogueServiceAccess, externalIdentityService) {
        var instance = this;
        this.cookie = externalCookie;
        this.chatClient = externalChatClient;
        this.utils = externalUtils;
        this.producthandler = externalProductHandler;
        this.catalogueService = catalogueServiceAccess;
        this.identityService = externalIdentityService;

        this.paypalClientIdCache = {};

        this.log = new monstecLib.Log(1);
    }

    /*
    ** This fnc calls all fncs which need to be ready to start the sales process and is called on click on the buy button
    */
    initSalesReadyFunctions() {
        var instance = this;
        instance.buildCartOnLoad();
        instance.initOrderProcess();
        instance.initTermsAndDataConfirmationForm();
        instance.initPayment();
        instance.loadClickHandler();
    }

    async buildCartOnLoad() {
        var instance = this;
        var cartString = await instance.cookie.getRightStorage('__cart_complete__');

        if (!jQuery.isEmptyObject(cartString)) {
            instance.buildAndAnimateCart(cartString);
        }
    }

    loadClickHandler() {
        var instance = this;
        // Empties the shopping cart
        $('#empty-cart-button, #empty-cart-button-checkout').click(() => instance.clearCart());

        // Updates the shopping cart
        $("#update-cart-button").click(() => instance.buildCart());

        $(".checkout.btn").click(function () {
            instance.showNextStep("#order-container-4");
            //allways scroll to top of viewport
            window.scrollTo(0, 0);
        });

        $('#order-container-3 #back-to-shop-link').click(() => instance.switchToMainContainer(0));
        $('#order-container-4 #back-to-cart-link').click(() => instance.showNextStep("#order-container-3"));

    }


    /*
**Show a form to user after clicking the buy button and hide chatlog
*/
    initOrderProcess() {
        var instance = this;

        $(document).on('click', '.buy-btn', async function(e) {
            e.stopImmediatePropagation();
            e.preventDefault();

            var buyNotification = i18next.t('order.buy_notification');
            var itemMTIN = $(this).closest('.item').data('mtin');
            var time = new Date().toDateString() + ' ' + new Date().toLocaleTimeString();
            var buyNotificationString = '<div class="chat xpert"><div class="chat-message" title="'+time+'">'+ buyNotification + ': MTIN ' + itemMTIN +'</p></div>'

            //this is the message and the trigger to show product for chatpartner
            instance.chatClient.sendMessage(buyNotification + ': MTIN ' + itemMTIN);

            var activeChat = $('.cl.active .chatlog.conversation-card');
            activeChat.append(buyNotificationString);

            var cart = await instance.getCartObject();

            // creates cart object if not existing yet, while quantity is for now set to 1 statically
            instance.calcItemQuantity(cart, itemMTIN, 1);
            instance.cookie.saveToRightStorage("__cart_complete__", cart, 30);

            instance.buildAndAnimateCart(cart);
            instance.switchToMainContainer(2);
            instance.showNextStep('#order-container-3');
        });       
    }

    async createInstantPayButton(targetItem) {
        var instance = this;
        instance.log.debug('createInstantPayButton ', targetItem);
        var advicingExpert = await instance.getAdvicingExpert();
        var btnIndex = $(".btn-wrapper").length;

        var clientId = instance.paypalClientIdCache[advicingExpert.providerId];
        if (!clientId) {
            try {
                clientId = await instance.identityService.getPaypalClientId(advicingExpert.providerId);
                instance.paypalClientIdCache[advicingExpert.providerId] = clientId;
            } catch(e) {
                instance.log.error('Paypal client ID for provider ' + advicingExpert.providerId + ' could not be obtained.', e);
                return;
            }
        }

        instance.log.debug('Using paypal Client ID ', clientId);

        function collectItemData(target) {
            var item = $(target),
            itemMTIN = JSON.stringify(item.data("mtin")),
            itemName = item.data("title"),
            itemPrice = item.data("price"),
            itemDescr = item.data("description") ? item.data("description") : undefined,
            itemObj = {},
            customer = { "firstName": "Anonymous Customer", "lastName": "", "street": "Online Service Delivery", "houseNumber": "" , "city": "-", "zipCode": "99999"},
            itemId = 'pay-btn-'+ item.data("mtin") + '-' + btnIndex;
            item.find('.btn-wrapper').attr("id", itemId);

            itemObj = { "name": itemName, "text": itemDescr, "quantity": 1, "price": itemPrice, "sum": itemPrice, "article": itemMTIN };      

            var itemArray = [itemObj];
            
            instance.createPaypalButton(itemId, itemPrice, customer, advicingExpert, itemArray, clientId, "instant-order");
        }

        if (targetItem.length > 1) {
            targetItem.each(function (index, item) {
                collectItemData(item, clientId);
            });
        } else {
            collectItemData(targetItem, clientId);
        }
    }

    // directs you to the according container (0 = home, 1 = login, 2 = info)
    switchToMainContainer(containerNumber) {

        var containers = [].slice.call(document.querySelectorAll('.container')),
            containersCount = containers.length,
            nav = document.querySelector('nav'),
            pageTriggers = [].slice.call(nav.children),
            isAnimating = false, current = 0;

        pageTriggers[containerNumber].click();
    }

    /**
    * merges the quantity of items in cart and on the site
    * parseInt cuts decimal places, so take parseFloat in case decimal places are of importance
    */
    calcItemQuantity(obj, key, quantity) {
        typeof obj[key] === 'undefined' ? obj[key] = parseInt(quantity) : obj[key] = parseInt(obj[key]) + parseInt(quantity); // jshint ignore:line
    }

    /**
    * Fetches the cart content from the localStorage in a secure way so that there will be an object
    * in any case and never 'undefined'.
    */
    async getCartObject() {
        var instance = this;
        var cartString = await instance.cookie.getRightStorage('__cart_complete__');

        if (!jQuery.isEmptyObject(cartString)) {
            return cartString;
        } else {
            return {}; // empty cart
        }
    }

    async buildCart(cart) {
        var instance = this,
        totalPrice = await instance.initPriceCalc(),
        priceContainer = $(".total-price");

        instance.assembleCartOverview(cart);

        priceContainer.empty();
        priceContainer.append(i18next.t('order.cart_value') + " " + totalPrice.toFixed(2) + "&nbsp;&euro;*");

        if (!jQuery.isEmptyObject(cart)) {
            $(".sales-step .checkout.btn").removeClass("disabled");
        }
    }

    buildAndAnimateCart(cart) {
        var instance = this;
        instance.buildCart(cart);
        var navCart = $(".thumb-nav__item.container-3 .material-icons");
        navCart.css("color", "rgb(38, 166, 154)");
        // Only do the shake if JQuery UI is actually loaded this is not guanranteed since it is loaded asyncronously.
        if (jQuery.ui) {
            navCart.delay(200).effect('shake', { direction: "left", times: 4, distance: 4 }, 500);
        }
    }

    async assembleCartOverview(itemsInCart) {
        var instance = this,
        tbody = '<tbody id="order-table-body"></tbody>',
        orderList = $(".order-list");

        if (itemsInCart && orderList.children().length > 0) {
            orderList.empty();
        }

        orderList.append(tbody);

        if (itemsInCart) {
            for (var articleId in itemsInCart) {
                if (!itemsInCart.hasOwnProperty(articleId)) continue;

                var article = await instance.producthandler.getProductDetails(articleId);
                var price = Math.round(parseFloat(article.prodPrice * 100)) / 100;

                var trow = '<tr><td class="product-sel"><a href="' + article.prodLink + '" target="_blank">' + article.prodTitle
                    + '</a></td><td class="pieces-sel"><div class="price-wrapper"><span class="price" data-sum="'
                    + price + '">' + price.toFixed(2) + ' &#8364;</span></div><div class="image-div"><a href="' + article.prodLink + '" target="_blank"><img src="'
                    + article.prodImg + '"/></a></div><div class="amount-in-cart"><input class="in-cart-pieces pcs" data-tag="' + articleId + '" type="number" min="0" max="10" step="1" value="' + itemsInCart[articleId] + '"/></div></td></tr>';
                orderList.append(trow);
            }
            instance.deleteAndAddSingleItems(itemsInCart);
        }
    }

    deleteAndAddSingleItems(cart) {
        var instance = this;

        $('.in-cart-pieces.pcs').one('input', async function () {

            var updateItem = '<div class="edit-item update"><a id="edit-item-button" class="edit-button waves-effect waves-light"><i class="material-icons">check</i></a></div>';
            var deleteItem = '<div class="edit-item delete"><a id="delete-item-button" class="edit-button waves-effect waves-light"><i class="material-icons">delete_forever</i></a></div>';

            if (!$(this).siblings('.edit-item').length) {
                $(this).parent('.amount-in-cart').append(updateItem).append(deleteItem);
                $('#cart-checkout').addClass('disabled');
            }

            var articleId = $(this).data('tag');
            function updateItems(newValue) {
                cart[articleId] = newValue;
                instance.cookie.saveToRightStorage("__cart_complete__", cart, 30);
                $('.edit-item').remove();
                instance.buildCart(cart);
                $('#cart-checkout').removeClass('disabled');
            }

            function deleteItems() {
                if (confirm(i18next.t('order.really_remove1'))) {
                    delete cart[articleId];
                    instance.cookie.saveToRightStorage("__cart_complete__", cart, 30);
                    $('.edit-item').remove();
                    instance.buildCart(cart);
                }
                else {
                    return;
                }
            }

            var inputElem = $(this);
            $('.edit-item.update').click(function () {
                var newValue = parseInt(inputElem.val());

                if (newValue === 0) {
                    deleteItems();
                } else if (isNaN(newValue) || newValue === "undefined") {
                    var textBody = i18next.t('order.amount');;
                    instance.utils.createSimpleAlertModal(textBody);
                } else {
                    updateItems(newValue);
                }
            });

            $('.edit-item.delete').click(function () {
                deleteItems();
            });

        });
    }


    async createPaypalButton(htmlElementIdSelector, totalPrice, customer, expert, positions, ppClientId, orderType) {
        var instance = this;
        var payeeNote;
        if(expert) {
            payeeNote = i18next.t("order.advisor_note1") + expert.name + i18next.t("order.advisor_note2") + expert.expertId;
        } else {
            expert = {expertId: "kein Chat aktiv", name: "kein Berater aktiv"};
            payeeNote = i18next.t("order.thanks_for_buy");
        }

        var preparedItems = null;

        if (positions) {
            preparedItems = positions.map((x) => {
                var preparedItem = {};
                preparedItem.name = x.name;
                preparedItem.description = x.text;
                preparedItem.quantity = x.quantity;
                preparedItem.price = x.price;
                preparedItem.tax = '0.19';
                preparedItem.sku = '1';
                preparedItem.currency = 'EUR';
                return preparedItem;
            });
        }

        paypal.Button.render({ // jshint ignore:line
            // Configure environment
            // directives of the build preprocessing done by gulp-preprocess
            env: 'production',
            client: {
                production: ppClientId.paypalClientId
            },
            // Customize button (optional)
            locale: 'de_DE',
            style: {  // https://developer.paypal.com/docs/checkout/how-to/customize-button/#button-styles
                shape: 'rect',
                size: 'medium',
                color: 'blue',
                label: 'paypal',
                tagline: 'false'
            },
            // Set up a payment
            payment: async function (data, actions) {
                return actions.payment.create({
                    transactions: [{
                        amount: {
                            total: totalPrice,
                            currency: 'EUR'
                        },
                        description: "Eine Bestellung über Produck.de",
                        custom: "Beraten von Experte: " + expert.name + ", Expert-Id: " + expert.expertId + ", Chat-Id: " + expert.chatId + ", Provider-Id: " + expert.providerId ? expert.providerId : "-" ,
                        note_to_payee: payeeNote,
                        invoice_number: JSON.stringify(new Date()),
                        item_list: {
                            items: preparedItems,
                            shipping_address: {
                                recipient_name: customer.firstName + ' ' + customer.lastName,
                                line1: customer.street + ' ' + customer.houseNumber,
                                //line2: 'Unit #34',
                                city: customer.city,
                                country_code: 'DE',
                                postal_code: customer.zipCode
                            }
                        }
                    }]
                });
            },
        /* disable collection of shipping addresses
        experience: {
            input_fields: {
            no_shipping: 1
            }
        },*/
        // Execute the payment
        onAuthorize: async function (data, actions) {
            var paymentFuture = actions.payment.get();               

            var processMultiOrder = await instance.showFinishOrderSection(function() {
                instance.placeOrder(totalPrice, customer, expert, positions, function() {
                    paymentFuture.then(function() {
                        return actions.payment.execute()
                            .then(function () {
                                window.location.replace('/order-submitted.html');
                                instance.cookie.removeRightStorage('__cart_complete__');
                            });
                    });
                });
            },
            function() {
                instance.hideFinishOrderSection(instance);
                delete actions.payment;
            });

            var processInstantOrder = await instance.instantFinishOrder(function() {
                instance.placeOrder(totalPrice, customer, expert, positions, function() {
                    paymentFuture.then(function() {
                        return actions.payment.execute()
                            .then(function (paymentDetails) {
                                var btnWrapper = $("#"+htmlElementIdSelector);
                                btnWrapper.addClass("payment-complete").empty();
                                var infoWrapper = btnWrapper.siblings(".info__wrapper");
                                infoWrapper.remove();
                                var buyNotificationSelf = i18next.t('order.buy_confirmation_self');
                                var buyNotificationPartner = i18next.t('order.buy_confirmation_partner');
                                var price =  i18next.t('order.total');
                                var time =  i18next.t('text.time');
                                var itemMTIN = btnWrapper.closest('.item.active').data('mtin');
                                var timeOfDay = new Date().toDateString() + ' ' + new Date().toLocaleTimeString();
                                var confirmMsg = "<span class='order-confirm-msg'>" + buyNotificationSelf + "</span>";

                                //this is the message and the trigger to show product for chatpartner
                                instance.chatClient.sendMessage(buyNotificationPartner + preparedItems[0].name + ', ' + price + ': ' + preparedItems[0].price + ' €, ' + time + ': ' + timeOfDay + ', MTIN' + itemMTIN);

                                btnWrapper.append(confirmMsg);

                                var orderData = {
                                    'acc': expert.providerId,
                                    'cht': expert.chatId,
                                    'ref': paymentDetails.cart,
                                    'amt': parseInt(preparedItems[0].price),
                                    'cur': 'EUR'
                                }

                                var urlPath = "https://api.produck.de/billing/sale";
                                
                                $.ajax({
                                    type: 'POST',
                                    url: urlPath,
                                    data: JSON.stringify(orderData),
                                    contentType: "application/json; charset=utf-8"
                                }).done(function() {
                                    console.log( "Sale tracked successfully." );
                                }).fail(function(error) {
                                    console.log( "Tracking sale failed: ", error);
                                });   
                            });
                    });
                });
            },
            function() {
                //var failureMsg = "Transaktion abgebrochen."; //TODO I18Next
                //htmlElementIdSelector.append(failureMsg)
                delete actions.payment;
            });

            orderType ? processInstantOrder : processMultiOrder;
                        
        },
        onCancel: function (data, actions) {
            // Show a cancel page or return to cart
            window.alert('Payment has been cancelled!');
        },
        onError: function (err) {
            // Show an error page here, when an error occurs
            instance.log.error(err);
            window.alert('An error occurred during payment!');
        }
    }, htmlElementIdSelector);
}

/**
 * Shows the sales step identified by 'container'.
 */
showNextStep(container) {

    $(".con-wrapper.active").removeClass("active").addClass("inactive");
    $(container).addClass("active").removeClass("inactive");

    //allways scroll to top of viewport
    window.scrollTo(0, 0);
}

/**
 * Deletes all information gathered during shopping process
 */
clearCart() {
    var instance = this;
    var message = decodeURI(i18next.t('order.really_remove2'));
    if (confirm(message)) {
        $(".order-list").empty();
        $(".total-price").text("");
        $(".total-price").data("sum", 0);
        $(".thumb-nav__item.container-3 .material-icons").css("color", "");
        instance.cookie.removeRightStorage('__cart_complete__')
    }
}

async initPriceCalc() {
    var instance = this;
    var totalPrice = null;
    var itemsInCart = await instance.getCartObject();

    var totalPriceArray = [];

    for (var articleId in itemsInCart) {
        if (!itemsInCart.hasOwnProperty(articleId)) continue;
        var article = await instance.producthandler.getProductDetails(articleId);

        totalPriceArray.push(parseFloat(article.prodPrice) * itemsInCart[articleId]);
    }

    totalPrice = Math.round(totalPriceArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0) * 100) / 100;

    return totalPrice;
}

/**
 * Initialises the form by that the customer will confirm user data and terms.
 */
initTermsAndDataConfirmationForm() {
    var instance = this;
    var form = $("#delivery-form");
    var checkbox = $("#terms-checkbox");
    var input = form.find("input").not("#phone");
    var actPayBtn = form.find("#activate-payment-btn");

    // control terms-togglebox
    checkbox.on("change", function () {
        if (checkbox.prop("checked")) {
            $(this).addClass("valid");
        } else {
            $(this).removeClass("valid");
        }
    });

    var validateInput = function () {
        var isValid = input.toArray()
        .every(element => $("#" + element.id).hasClass("valid"));
        var isNotEmpty = input.toArray()
        .every(element => $("#" + element.id).val().length > 0);

        if (isValid && isNotEmpty) {
            actPayBtn.removeClass("disabled");
            actPayBtn.addClass("active");
        } else {
            actPayBtn.removeClass("active");
            actPayBtn.addClass("disabled");
        }

        // Additionally to validating the input the payment choice area has to be hidden in case the user
        // has actually changed data. In that case he has to hit the "confirm data"-button again first.
        instance.hidePaymentChoiceArea();
    };

    input.on("click change blur", validateInput);

    // terms have to be accepted again for every reload
    checkbox.prop("checked", false);

    // Trigger validation of the text input fields at initialisation time in case of prefilled form items.
    input.toArray().forEach(field => {
        if (field.type === "text" || field.type === "email") {
            if (field.value !== "") {
                if (field.checkValidity()) {
                    $(field).addClass("valid");
                } else {
                    $(field).addClass("invalid");
                }
            }
        }
    });
}

check(a, b) {
    return a.hasClass(b);
}

async createPaymentChoiceArea() {
    var instance = this;
    var totalPrice = await instance.initPriceCalc();

    // Remove an old Paypal button if there is one
    if ($("#paypal-button").children().length > 0) {
        $("#paypal-button").children().remove();
    }

    var customer = instance.extractCustomerFromForm();
    var invoicePositions = await instance.gatherInvoicePositions();
    var advicingExpert = await instance.getAdvicingExpert();

    $("#paypal-button, #pay-advance").css('display', 'flex');
    instance.createPaypalButton("#paypal-button", totalPrice, customer, advicingExpert, invoicePositions);
}

hidePaymentChoiceArea() {
    // Remove an old Paypal button if there is one
    if ($("#paypal-button").children().length > 0) {
        $("#paypal-button").children().remove();
    }

    $("#paypal-button, #pay-advance").hide();
}

initPayment() {
    var instance = this;
    $(document).on("click", ".payment.active", function () {
        //instance.addShippingtoTotalPrice();
        instance.createPaymentChoiceArea();

        $('html, body').animate({ scrollTop: $('#terms-checkbox').offset().top }, 500);
    });

    $(document).on("click", '#pay-adv-button', () => instance.orderByPaymentInAdvance()); // pay-adv-button is defined in finishOrderDialoge.njk
}

/**
 * Sends order information to the shop server.
 *
 * @param {*} customer contact information of the customer
 * @param {*} expert id and nickname of the advisor that when online order submitted
 * @param {*} positions articles the customer ordered inluding quantity
 * @param {Function} callback will be called when the order has successfully been
 */
placeOrder(totalPrice, customer, expert, positions, callback) {
    var instance = this;
    var orderAsString = JSON.stringify({
        order: { customer: customer, expert: expert, positions: positions, total: totalPrice }
    });

    //Delete this part, when pay in adv ready
    if (callback) {
        callback();
    }

    /*
    $.ajax({
        type: "POST",
        url: "/service/order.php",
        contentType: "application/json; charset=UTF-8",
        data: orderAsString,
        success: function (response) {
            // this is hardcoded in the order.php to verify that we succesfully processed the order
            // and that not just some random valid HTML page is shown
            if (response === 'have a nice day!') {
                console.log("Order successfully submitted to server: ", response);
                if (callback) {
                    callback();
                }
            }
            else {
                console.log("Order could not be processed by server!", response);
                window.location.replace('/order-failed.html');
            }
        },
        error: function (response) {
            console.log("Order could not be delivered to server!", response);
            window.location.replace('/order-failed.html');
        }
    });*/
}


instantFinishOrder(buyFunction) {
    var instance = this;
    buyFunction();
}

/**
 * Shows the section where the buyer can finish the purchase.
 *
 * @param {Function} buyButtonFunction function that will be called when the buyer hits the final buy confirmation button
 * @param {Function} cancelButtonFunction function that will be called when the buyer hits the final buy cancellation button
 */
showFinishOrderSection(buyButtonFunction, cancelButtonFunction) {
    var instance = this;

    function addToCart(itemName, price) {
        $("#shopping-cart-table tr")
        .first()
        .clone()
        .appendTo($("#order-overview-container table tbody"));
        $("#shopping-cart-table tr .product-sel")
        .last()
        .text(itemName);
        $("#shopping-cart-table tr .pieces-sel .price-wrapper")
        .last()
        .text(price.toFixed(2) + "€")
        .data("sum", price);
        $("#shopping-cart-table tr .pieces-sel .price-wrapper .image-div")
        .last()
        .remove();
        $("#shopping-cart-table tr .pieces-sel span")
        .last()
        .text("1");
    }

    // add shopping cart content to finish buy section
    $("#shopping-cart-table").clone().appendTo($("#order-overview-container"));

    async function substituteInputFields() {

        var cart = await instance.getCartObject();
        for (var articleId in cart) {
            if (!cart.hasOwnProperty(articleId)) {
                continue;
            }
            var inputField = $("#order-container-5 #shopping-cart-table tr .pieces-sel .amount-in-cart input[data-tag=" + articleId + "]");
            var pieces = inputField.val();

            inputField.replaceWith("<span class='pcs' data-tag=" + articleId + ">" + pieces + "</span>");
        }
    }

    // add shipping information to finish buy section
    var customer = instance.extractCustomerFromForm();
    $("#customer-data-overview").append(
    '<li class="customer-detail collection-item avatar"><span>'
        + customer.firstName + "&nbsp;" + customer.lastName
        + "</span></li>"
    );
    $("#customer-data-overview").append(
    '<li class="customer-detail collection-item avatar"><span>'
        + customer.street + "&nbsp;" + customer.houseNumber
        + "</span></li>"
    );
    $("#customer-data-overview").append(
    '<li class="customer-detail collection-item avatar"><span>'
        + customer.zipCode + "&nbsp;" + customer.city
        + "</span></li>"
    );
    $("#customer-data-overview").append(
    '<li class="customer-detail collection-item avatar"><span>'
        + customer.email
        + "</span></li>"
    );
    $("#customer-data-overview").append(
    '<li class="customer-detail collection-item avatar"><span>'
        + customer.telephone
        + "</span></li>"
    );

    // hide all other sections except for the contact form so that the user is not able
    // to change an already authenticated payment.
    substituteInputFields();
    instance.showNextStep("#order-container-5", ".progressbar > li.payment");

    /** bind the callback methods to the section's buttons
    * if function is not called with brackets at the end, this in the next function will direct to the click-class, therefore instance has to be be conveyed in some cases, otherwise callback will not get right instance
    */
    $("#confirm-final-buy-button, #confirm-final-buy-button-bottom").click(buyButtonFunction);
    $("#cancel-final-buy-button, #cancel-final-buy-button-bottom").click(() => cancelButtonFunction(instance));
    $("html, body").animate({ scrollTop: $("#order-container-5").offset().top }, 300);
}

orderByPaymentInAdvance() {
    var instance = this;
    instance.showFinishOrderSection( async function() {
        var totalPrice = instance.initPriceCalc();
        var customer = instance.extractCustomerFromForm();
        var invoicePositions = await instance.gatherInvoicePositions();
        var advicingExpert = await instance.getAdvicingExpert();
        instance.placeOrder(totalPrice, customer, advicingExpert, invoicePositions, function () {
            window.location.replace('/order-submitted.html');
            instance.cookie.removeRightStorage('__cart_complete__');
        });
    }, instance.hideFinishOrderSection);
}

async getAdvicingExpert() {
    var instance = this;
    var chatPartner =  $('.cl.active').data('chatpartner');
    var chatId =  $('.cl.active').data('chatid');
    var providerId =  $('.cl.active').data('providerid');
    var expertRef;

    if (chatPartner) {
        let advicingExpertNickname;
        try {
            let advicingExpert = await instance.identityService.getPublicUserData(chatPartner);
            advicingExpertNickname = advicingExpert.nickname;
        } catch (e) {
            instance.log.error('Nickname of advising expert could not be fetched from ProDuck.', e);
        }
        expertRef = {expertId: chatPartner, name: advicingExpertNickname, chatId: chatId, providerId: providerId};
    }

    instance.log.debug('Advising expert information: ', expertRef);
    return expertRef;
}

/**
 * This function will make a transition of the page from a state in that the order could be finished
 * to a state just before reaching that section. It is meant to be called when a user aborts the payment process and
 * wether wants to cancel the whole order or change it.
 */
hideFinishOrderSection(instance) {
    instance.hidePaymentChoiceArea();
    $(".con-wrapper").not("#order-container-3").addClass('inactive').removeClass("active");
    instance.emptyFinishOrderSection();
    $("#order-container-3").addClass("active").removeClass('inactive');
    window.scrollTo(0, 0);
}

emptyFinishOrderSection() {
    $("#customer-data-overview .customer-detail").remove();
    $("#order-overview-container").empty();
    $('#order-container-5 .table-wrapper #shipping-cell').text("");
    $('#order-container-5 .table-wrapper #total-cell').text("").data('sum', "");

    $("#confirm-final-buy-button").off();
    $("#cancel-final-buy-button").off();
    $("#confirm-final-buy-button-bottom").off();
    $("#cancel-final-buy-button-bottom").off();
}

/**
 * Returns an Object containing customer information.
 */
extractCustomerFromForm() {
    var customer = {};
    customer.firstName = $("#first_name").val();
    customer.lastName = $("#last_name").val();
    customer.street = $("#street").val();
    customer.houseNumber = $("#house_number").val();
    customer.city = $("#city").val();
    customer.zipCode = $("#zip").val();
    customer.email = $("#email").val();
    customer.telephone = $("#phone").val();
    return customer;
}

/**
 * Extracts all products that are currently stored in the local storage and returns an array of corresponding invoice positions.
 */
async gatherInvoicePositions() {
    var instance = this;
    // All currently selected items of the order are stored on the local storage of the browser in a data
    // structure that reflects the process of choosing the items. That means it's something like an array
    // of objects that contain arrays of items. This structure has to be flattend in order to be able to hand over a list of
    // positions to the Paypal button and ultmiately to the MonsTec order server.
    // The 'cart' in the local storage is divided into three categories which are also reflected in the
    // html view of the shopping cart. So in order to be consequent here, the positions list will also reflect
    // there categories. So at first extract all objects of the particular category and store them in corresponding arrays.

    var invoicePositions = [];
    var cartWizard = await instance.cookie.getRightStorage("__cart_complete__");

    if (cartWizard) {
        var collector = Object.create(null);

        for (var articleId in cartWizard) {
            if (!Object.prototype.hasOwnProperty.call(cartWizard, articleId)) continue;
            var article = await instance.producthandler.getProductDetails(articleId);

            if (!collector[articleId]) {
                collector[articleId] = {
                    name: article.prodTitle,
                    quantity: 0,
                    price: parseFloat(article.prodPrice), sum: 0,
                    article: articleId
                };
                invoicePositions.push(collector[articleId]);
            }
            collector[articleId].quantity += parseInt(cartWizard[articleId]);
            collector[articleId].sum += parseFloat(article.prodPrice) * cartWizard[articleId];
        }
    }

    var getTotalPrice = await instance.initPriceCalc();

    var shippingFee = instance.addShippingtoTotalPrice(getTotalPrice);
    var shippingPosition = { name: "Shipping",  quantity: 1, price: shippingFee, sum: shippingFee, article: "10010001" };

    if (shippingPosition.price > 0) {
        invoicePositions.push(shippingPosition);
    }
    return invoicePositions;
}

async addShippingtoTotalPrice(cartPrice) {
    var instance = this;

    var euro = decodeURI("\u20AC");
    var addShipping = 0;

    //set threshold for free shipping here
    //currently set to free shipping
    if (cartPrice >= 0) {
        addShipping = 0;
    }
    else {
        addShipping = 4.99;
    }

    var sumServPackandDelivery = Math.round((cartPrice + addShipping) * 100) / 100;

    $('#order-container-5 .table-wrapper #shipping-cell').text(addShipping.toFixed(2) + euro);
    $('#order-container-5 .table-wrapper #total-cell').text(sumServPackandDelivery.toFixed(2) + ' ' + euro).data('sum', sumServPackandDelivery);

    return addShipping;
}
}
