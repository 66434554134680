/* global monstecLib */
/* global M */
import ObjectForm from '../common/objectform';

/**
 * Input form for creating new articles.
 *
 * Implement 'sendAction' for an instance of this class to define the behaviour of the
 * current ArticleForm object when clicking the send button.
 *
 * Implement 'onSendingSuccessful' to react on the successful delivery of the article
 * to the destination.
 */
export default class ArticleForm extends ObjectForm {
    /**
     * Construct a new form that can then be attached to an element.
     */
    constructor() {
        super();
        this.MIN_CHARACTER_COUNT_SUMMARY = 10;
        this.MAX_CHARACTER_COUNT_SUMMARY = 5000;

        this.i18next = monstecLib.i18next;
        this.authenticator = monstecLib.produckContext.authenticator;
        this.chatService = monstecLib.produckContext.chatService;

        function addTag(chipsElement) {
            let textNodeOfNewChip = chipsElement.find('.chip').last()[0].childNodes[0];
            let textOfNewChip = textNodeOfNewChip.data;
            textNodeOfNewChip.data = textOfNewChip.trim().replaceAll(/[^a-zA-Z0-9äöüÄÖÜß\.-]+/g, '-');
        }

        this.chipsOptions = {
            'placeholder': this.i18next.t('toasts.chips'),
            'limit': 5,
            'onChipAdd': function(chips) {addTag(chips);}
        };

        this.log = new monstecLib.Log(1);
    }

    /**
     * Attaches the current instance to a specific element. That element must include
     * the article-form-nunjucks-template and should ideally be a div.
     *
     * @param {string} htmlId identifies the container element that includes the article form
     */
    attachTo(htmlId) {
        const instance = this;

        instance.htmlId = htmlId;
        let container = $('#' + htmlId);
        instance.container = container;

        // Localise form labels before initialising the input elements. This is necessary
        // so that MaterializeCSS actually uses the resolved localisation properties for
        // the select-elements for example.
        container.localize();

        let articleFormCloser = instance.container.find('#newArticleFormCloser');
        articleFormCloser.on('click', () => instance.container.removeClass('active'));

        let titleInput =  container.find('.js-article-title');
        titleInput.attr('id', htmlId + 'TitleInput');
        titleInput.siblings('label').attr('for', titleInput.attr('id'));
        instance.titleInput = titleInput;

        // init language selection
        let languageSelect = container.find('.js-language-select');
        languageSelect.attr('id', htmlId + 'LanguageInput');
        languageSelect.siblings('label').attr('for', languageSelect.attr('id'));

        var lngOptions = {
            'classes': 'edit-language'
        };

        instance.languageSelect = languageSelect;
        instance.languageSelectM = M.FormSelect.init(languageSelect, lngOptions)[0];

        // initialise editing area for detailed information
        let summaryTextarea = container.find('textarea');
        summaryTextarea.attr('id', htmlId + 'TextInput');
        instance.summaryTextarea = summaryTextarea;

        instance.utils.addCharacterCounter(summaryTextarea, undefined, instance.MIN_CHARACTER_COUNT_SUMMARY, instance.MAX_CHARACTER_COUNT_SUMMARY, true);
        M.textareaAutoResize(summaryTextarea);

        // init tag module
        let tags = container.find('.chips');
        tags.attr('id', htmlId + 'TagsInput');
        instance.tagsInput = M.Chips.init(tags, instance.chipsOptions)[0];

        // init template selection
        let templateSearchField = container.find('.js-article-template-select');
        templateSearchField.attr('id', htmlId + 'TemplateInput');
        templateSearchField.siblings('label').attr('for', templateSearchField.attr('id'));

        instance.chatService.getArticleTemplateChoices('').then(function(choiceArray) {
            let autocompleteData = {};
            choiceArray.forEach(function(choice) {
                autocompleteData[choice.name] = null;
            });

            var templateSearchFieldOptions = {
                data: autocompleteData,
                'minLength': 3
            };

            instance.templateSearchField = templateSearchField;
            instance.templateSearchFieldM = M.Autocomplete.init(templateSearchField, templateSearchFieldOptions)[0];
        });

        // init type selection
        let typeSelect = container.find('.js-article-type-select');
        typeSelect.attr('id', htmlId + 'TypeInput');
        typeSelect.siblings('label').attr('for', typeSelect.attr('id'));

        var typeOptions = {
            'classes': 'edit-language'
        };

        typeSelect.on('change', function() {
            if (typeSelect.val() === 'SUGGESTED') {
                instance.container.find('.js-suggestion-only').removeClass('hide');
            } else {
                instance.container.find('.js-suggestion-only').addClass('hide');
            }
        });

        instance.typeSelect = typeSelect;
        instance.typeSelectM = M.FormSelect.init(typeSelect, typeOptions)[0];

        // initialise recipient selection
        let recipientSelect = container.find('.js-recipient-select');
        recipientSelect.attr('id', htmlId + 'TypeInput');
        recipientSelect.siblings('label').attr('for', recipientSelect.attr('id'));

        var recipientOptions = {
            'classes': 'edit-language'
        };

        instance.recipientSelect = recipientSelect;
        instance.recipientSelectM = M.FormSelect.init(recipientSelect, recipientOptions)[0];

        // initalise word-count fields
        let wordCountMinInput = container.find('.js-word-count-min-input');
        wordCountMinInput.attr('id', htmlId + 'WordCountMinInput');
        wordCountMinInput.siblings('label').attr('for', wordCountMinInput.attr('id'));
        instance.wordCountMinInput = wordCountMinInput;

        let wordCountMaxInput = container.find('.js-word-count-max-input');
        wordCountMaxInput.attr('id', htmlId + 'WordCountMaxInput');
        wordCountMaxInput.siblings('label').attr('for', wordCountMaxInput.attr('id'));
        instance.wordCountMaxInput = wordCountMaxInput;

        // initialise sendButton
        let sendButton = container.find('button');
        sendButton.attr('id', htmlId + 'SendButton');
        sendButton.on('click', function(e) {
            $(document).trigger("loader:on", ['', 'transparent', document.getElementById('targetArticle')]);
            instance._onSendButtonClicked(e);
        });
    }

    /**
     * Handles the click on the send button, by calling the externally defined send action and processing the
     * return value of that action.
     */
    async _onSendButtonClicked(e) {
        const instance = this;
        e.preventDefault();
        e.stopImmediatePropagation();

        if (instance.sendAction) {
            try {
                let article = instance._createArticleFromFields();
                let response = await instance.sendAction(article);

                if (response)  {
                    let status = response.status;

                    if (status == 'ok' || status == 200 || status == 201) {
                        instance._emptyForm();
                        M.toast({html: instance.i18next.t('toasts.data_updated')});

                        if (instance.onSendingSuccessful) {
                            instance.onSendingSuccessful(response);
                        }

                    } else if (status == 400) {
                        instance._processFieldErrorsFromServer(response);
                    }
                }

                $(document).trigger("loader:off");

            } catch (error) {
                $(document).trigger("loader:off");
                M.toast({html: instance.i18next.t('toasts.error')});
                instance.log.error('Send action yielded an unexpected error:', error);
            }
        } else {
            instance.log.warn('No sendAction defined!');
            $(document).trigger("loader:off");
        }
    }

    /**
     * Collects information from the input fields on the form to build an article object.
     */
    _createArticleFromFields() {
        const instance = this;
        let article = {};

        try {
            article.title = instance.titleInput.val();
            article.language = instance.languageSelect.val();

            let summary = instance.summaryTextarea.val();
            if (!!summary && summary.length > 0) {
                article.summary = summary;
            }

            let tagsData = instance.tagsInput.chipsData;
            if (!!tagsData || Array.isArray(tagsData)) {
                let actualTags = tagsData
                    .filter(tuple => !!tuple.tag)
                    .map(tuple => tuple.tag.trim().replaceAll(/[^a-zA-Z0-9äöüÄÖÜß\.-]+/g, '-'));

                article.tags = actualTags;
            }

            article.status = instance.typeSelect.val();
            if (article.status === 'SUGGESTED') {
                article.recipientId = instance.recipientSelect.val();
                article.wordCountMin = instance.wordCountMinInput.val();
                article.wordCountMax = instance.wordCountMaxInput.val();
            }

            article.templateName = instance.templateSearchField.val();

        } catch (error) {
            instance.log.error('Could not extract values for article: ', error);
        }

        instance.log.debug('Formed the following article object from the input fields:', article);
        return article;
    }

    _emptyForm() {
        const instance = this;
        let inputElements = instance.container.find('input, textarea');

        inputElements.each(function(index, element) {
            let formElement = $(element);
            formElement.removeClass("valid invalid").css("height", "").val(null);
            M.updateTextFields();
        });

        // re-init selects
        if (instance.languageSelectM) {
            var lngOptions = {
                'classes': 'edit-language'
            };
            instance.languageSelectM.destroy();
            instance.languageSelect.val("de");
            instance.languageSelectM = M.FormSelect.init(instance.languageSelect, lngOptions)[0];
        }

        if (instance.templateSelectM) {
            var templateOptions = {
                'classes': 'edit-language'
            };
            instance.templateSelectM.destroy();
            instance.templateSelect.val("DRAFT");
            instance.templateSelectM = M.FormSelect.init(instance.templateSelect, templateOptions)[0];
        }

        if (instance.typeSelectM) {
            var typeOptions = {
                'classes': 'edit-language'
            };
            instance.typeSelectM.destroy();
            instance.typeSelect.val("DRAFT");
            instance.typeSelectM = M.FormSelect.init(instance.typeSelect, typeOptions)[0];
        }

        if (instance.recipientSelectM) {
            var recipientOptions = {
                'classes': 'edit-language'
            };
            instance.recipientSelectM.destroy();
            instance.recipientSelect.val("DRAFT");
            instance.recipientSelectM = M.FormSelect.init(instance.recipientSelect, recipientOptions)[0];
        }

        // re-initialise tags
        instance.tagsInput.destroy();
        let tags = $('#' + instance.htmlId + 'TagsInput');
        instance.tagsInput = M.Chips.init(tags, instance.chipsOptions)[0];
    }
}