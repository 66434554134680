/* global M */
/* global monstecLib */
import i18next from 'i18next';

/**
 * Identifier of container must be given in constructor.
 * This container must have children with the following HTML-identifiers:
 * - provChatTemplate: the template that will be used to display a single chat
 * - provChatMessageTemplate: the template that will be used for particular messages
 * - prov-chat-overview: the container that will include all chat items (currently must have <ul> as direct child)
 */
export default class ProviderChatsView {
    constructor(containerId) {
        this.containerId = containerId;
        this.chatService = monstecLib.produckContext.chatService;
        this.utils = monstecLib.produckContext.utils;
    }

    async buildChatList(page) {
        const instance = this;
        if (!page || page < 1) page = 1;

        let template = $('#' + instance.containerId + ' #provChatTemplate'); // see provchats.html
        const chatOverview = $('#' + instance.containerId + ' #prov-chat-overview');
        const listContainer =  chatOverview.find('ul[class=collapsible]');
        const paginationList =  chatOverview.find('ul[class=pagination]');

        // if a no content hint is shown, remove it since it might be obsolete
        chatOverview.find('.no-result-indicator').remove();

        listContainer.hide();
        $(document).trigger("loader:on", ['', 'transparent', document.getElementById('targetProvChats')]);
        listContainer.empty();
        paginationList.empty();

        try {
            let chats = await instance.chatService.getChatList({'type': 5, 'page': page});

            if (!!chats && chats.numElements > 0){
                // build pagination element
                instance.utils.buildPagination(paginationList, chats.numPages, chats.page, instance.buildChatList.bind(instance));

                // list chats
                chats.result.forEach(chat => {
                    let chatContainer = template.clone();
                    // remove the id 'provChatTemplate' from the clone
                    chatContainer.removeAttr('id');
                    let header = chatContainer.find('.collapsible-header');
                    let chatBody = chatContainer.find('.collapsible-body');

                    header.attr('id', 'quack-header-' + chat.id);
                    header.attr('data-chat-id', chat.id);
                    header.attr('data-chat-date', instance.utils.formatDate(chat.startTime));
                    header.attr('data-chat-views', ((!!chat.views) ? chat.views : 0));

                    header.find('.chat-id').append(chat.id);
                    header.find('.chat-topic').append(chat.topic);

                    let chatSwitch = header.find('.chat-switch');
                    chatSwitch.attr('data-state', 0);
                    chatSwitch.click(function(event) {
                        event.preventDefault();
                        event.stopPropagation();
                        if (chatSwitch.attr('data-state') == 0) {
                            chatSwitch.attr('data-state', 1);
                            instance.showCurrentMessages(chat.id);
                        } else {
                            instance.showOriginalMessages(chat.id);
                            chatSwitch.attr('data-state', 0);
                        }
                    });

                    header.click(function() {
                        // First remove all switch-original-current-elements so that any visible such element gets removed
                        // when a header is clicked that element is not on.
                        listContainer.find('.chat-switch').hide();

                        // Now the chat-switch of the header that has actually been clicked has to be shown.
                        // If the collapsible body is currently invisible it is about to be shown and so the switch element has
                        // to be shown as well.
                        // TODO move to global.js as soon as the chat-image-branch is merged
                        function isVisible(jQueryElement) {
                            return (jQueryElement.css('display') !== 'none');
                        }

                        if (!isVisible(chatBody)) {
                            chatSwitch.show();
                        }
                    });

                    chatBody.attr('data-chat-id', chat.id);
                    chatBody.attr('data-expert-id', chat.expertId);

                    header.click(function() {
                        instance.showOriginalMessages(chat.id);
                    });

                    listContainer.append(chatContainer);
                });
            } else {
                let noContentHint = $('<div class="no-result-indicator"><h3 data-i18n="text.no_chats_yet"></h3></div>');
                chatOverview.append(noContentHint);
                noContentHint.find('h3').localize();

                M.toast({html: i18next.t('text.no_chats')});
            }
        } catch(error) {
            console.log('ERROR - communication with server faild: ', error);
            M.toast({html: i18next.t('text.no_chats')});
        }

        $(document).trigger("loader:off");
        listContainer.show();
    }

    async showOriginalMessages(chatId) {
        const instance = this;

        try {
            let messages = await instance.chatService.getChatMessageOriginals(chatId);
            instance._integrateMessages(chatId, messages, i18next.t('text.original_version'));
        } catch (error) {
            instance._integrateNoMessagesHint(chatId);
        }
    }

    async showCurrentMessages(chatId) {
        const instance = this;

        try {
            let messages = await instance.chatService.getChatMessages(chatId);
            instance._integrateMessages(chatId, messages, i18next.t('text.current_version'));
        } catch (error) {
            instance._integrateNoMessagesHint(chatId);
        }
    }

    /**
     * Internal function that handles the display of chat messages.
     *
     * @param {string} type in this context type is a descriptive string that characterises the chat whether it is the original version
     *                      or the one that has may have been altered by the expert
     */
    _integrateMessages(chatId, messages, type) {
        const instance = this;
        let messagesWrapper = $('#' + instance.containerId + ' #prov-chat-overview .collapsible-body[data-chat-id="'+ chatId +'"]');
        let expertId = parseInt(messagesWrapper.attr('data-expert-id'));
        messagesWrapper.empty();

        const header = messagesWrapper.siblings('.collapsible-header');
        let additionalInfoDisplay = $('<div class="stats-wrapper"><span class="type stats">' + type + '</span>'
                                      + '<span class="views stats" title="' + instance.i18next.t("text.views") +'">' + header.attr('data-chat-views') + '<i class="material-icons">visibility</i></span>'
                                      + '<span class="timestamp stats" title="' + instance.i18next.t("text.date_of_creation") +'">' + header.attr('data-chat-date') + '</span>');

        messagesWrapper.append(additionalInfoDisplay);

        if (messages.length > 0) {
            messages.forEach(message => {
                let template = $('#' + instance.containerId + ' #provChatMessageTemplate'); // see provchats.njk
                let messageContainer = template.clone();
                messageContainer.removeAttr('id');

                let messageSenderIdentifyingClass = (message.senderId === expertId) ? 'right-duck' : 'left-duck';
                var author = (message.senderId === expertId) ? i18next.t('text.xpert') : 'Ducky';

                messageContainer.addClass(messageSenderIdentifyingClass);
                messageContainer.find('.author-name').append(author);
                messageContainer.find('.question-hyperlink').append(message.text);

                messagesWrapper.append(messageContainer);
            });
        } else {
            instance._integrateNoMessagesHint(chatId);
        }
    }

    _integrateNoMessagesHint(chatId) {
        const instance = this;
        let messagesWrapper = $('#' + instance.containerId + ' #prov-chat-overview .collapsible-body[data-chat-id="'+ chatId +'"]');
        messagesWrapper.empty();
        messagesWrapper.append('<div class="dialogue-summary narrow">Keine Nachrichten gefunden.</div>');
    }
}
