/*
 * Container class for conversational search procedure.
 */
/* global monstecLib */
import i18next from "../localisation.js";

export default class Wizard {
    constructor(externalCookie, externalAuthenticator, externalRegister) {

        this.cookie = externalCookie;
        this.authenticator = externalAuthenticator;
        this.register = externalRegister;
        this.portalSupport = new monstecLib.PortalSupport(this.authenticator, this.cookie);

        /*
        * Format einer Frage:
        * 'Category': {
        *     'question': 'Fragetext',
        *     'option': 'Antworttext'
        * }
        */
        this.wizardArray = {
            target_group: {
                "question": "Wie möchtest du ProDuck nutzen",
                "option_1": { "answer": "Als Shop oder Webseite", "tag": "enterprise", "type": "exclusive", "image": "https://cdn.pixabay.com/photo/2018/04/13/19/43/smartphone-3317374__340.jpg" },
                "option_2": { "answer": "Als Experte, Autor und Berater", "tag": "expert", "type": "exclusive", "image": "/assets/img/info/expert.png" },
                "option_3": { "answer": "Als Kunde", "tag": "customer", "type": "exclusive", "image": "https://cdn.pixabay.com/photo/2018/03/06/08/59/online-3202912__340.jpg" },
                "option_4": { "answer": "Sonstiges", "tag": "misc", "type": "exclusive", "image": "" },
            },
            enterprise: {
                "question": "Welches Angebot von ProDuck möchtest du nutzen?",
                "option_1": { "answer": "Live Chat zum Einbinden", "tag": "callFnc_embed_live_chat", "type": "combinable", "image": "/assets/img/docu/Expertenchat_am_POS.PNG" },
                "option_2": { "answer": "Experten für meinen Kundenservice", "tag": "callFnc_experts_as_a_service", "type": "combinable", "image": "/assets/img/info/expert.png" },
                "option_3": { "answer": "Content Marketing für meine Seite", "tag": "callFnc_content_marketing", "type": "combinable", "image": "/assets/img/docu/quack-styled.PNG" },
                "option_4": { "answer": "Produkte oder Dienstleistungen im Chat verkaufen", "tag": "callFnc_chat_shop", "type": "combinable", "image": "/assets/img/Shopping-Function-Desk.PNG" },
                "option_5": { "answer": "Firmenprofil anlegen", "tag": "callFnc_profile_provider", "type": "combinable", "image": "/assets/img/docu/shop-profile.png" },
                "option_6": { "answer": "Die App herunterladen", "tag": "callFnc_produck_app", "type": "combinable", "image": "https://cdn.pixabay.com/photo/2016/12/09/11/33/smartphone-1894723__340.jpg" },
                "option_7": { "answer": "Anleitungen und Hilfestellungen finden", "tag": "callFnc_docu_provider", "type": "combinable", "image": "https://cdn.pixabay.com/photo/2016/11/18/11/17/social-1834016__340.png" },
                "option_8": { "answer": "Nichts davon", "tag": "callFnc_contact_support", "type": "exclusive", "image": "" },
            },
            expert: {
                "question": "Was möchtest du als Experte auf ProDuck tun?",
                "option_1": { "answer": "Beiträge für Affiliate Marketing publizieren", "tag": "callFnc_publish_content_affiliate", "type": "combinable", "image": "/assets/img/docu/quack-styled.PNG" },
                "option_2": { "answer": "Als Freelancer-Autor für ProDuck schreiben", "tag": "callFnc_freelancer_author", "type": "combinable", "image": "/assets/img/info/experts-chatting.jpg" },
                "option_3": { "answer": "Beiträge als Gastautor publizieren", "tag": "callFnc_publish_content_guest_author", "type": "combinable", "image": "/assets/img/info/gastautoren.png" },
                "option_4": { "answer": "Kunden beraten und Provision erhalten", "tag": "callFnc_become_cust_service_xpert", "type": "combinable", "image": "/assets/img/docu/get_chat_request.jpg" },
                "option_5": { "answer": "Produktfragen aus der Community beantworten", "tag": "callFnc_answer_question", "type": "combinable", "image": "/assets/img/docu/docu-illustration.png" },
                "option_6": { "answer": "Ich suche Anleitungen und Hilfestellungen", "tag": "callFnc_docu_expert", "type": "combinable", "image": "https://cdn.pixabay.com/photo/2020/07/25/19/47/cellphone-5437668__340.png" },
                "option_7": { "answer": "Ich möchte die App herunterladen", "tag": "callFnc_produck_app", "type": "combinable", "image": "https://cdn.pixabay.com/photo/2016/11/18/11/17/social-1834016__340.png" },
                "option_8": { "answer": "Expertenprofil mit eigenem Chat anlegen", "tag": "callFnc_profile_expert", "type": "combinable", "image": "/assets/img/docu/Profile-Function.PNG" },
                "option_8": { "answer": "Nichts davon", "tag": "callFnc_contact_support", "type": "exclusive", "image": "" },
            },
            customer: {
                "question": "Was möchtest du als Kunde auf ProDuck tun?",
                "option_1": { "answer": "Eine Produktfrage an die Community stellen", "tag": "callFnc_ask_question", "type": "combinable", "image": "https://cdn.pixabay.com/photo/2015/10/31/12/00/question-1015308__340.jpg" },
                "option_2": { "answer": "Eine Produktfrage an einen Experten stellen", "tag": "callFnc_chat", "type": "combinable", "image": "/assets/img/info/expert.png" },
                "option_3": { "answer": "Produktfragen aus der Community beantworten", "tag": "callFnc_answer_question", "type": "combinable", "image": "https://cdn.pixabay.com/photo/2015/11/03/08/53/idea-1019753__340.jpg" },
                "option_4": { "answer": "Mich allgemein zu einem Produkt beraten lassen", "tag": "callFnc_chat", "type": "combinable", "image": "/assets/img/Shopping-Function-Desk.PNG" },
                "option_5": { "answer": "Einen Beitrag bewerten", "tag": "callFnc_rate_quack", "type": "combinable", "image": "https://cdn.pixabay.com/photo/2013/07/12/19/22/hitch-154664__340.png" },
                "option_6": { "answer": "Einen Beitrag kommentieren", "tag": "callFnc_comment_quack", "type": "combinable", "image": "https://cdn.pixabay.com/photo/2016/12/07/09/27/fax-1889009__340.jpg" },
                "option_7": { "answer": "Meine Chatverläufe sehen", "tag": "callFnc_register", "type": "combinable", "image": "/assets/img/docu/chat-history.png" },
                "option_8": { "answer": "Ein Profil anlegen", "tag": "callFnc_profile_customer", "type": "combinable", "image": "/assets/img/docu/Profile-Function.PNG" },
                "option_9": { "answer": "Ich möchte die App herunterladen", "tag": "callFnc_produck_app", "type": "combinable", "image": "https://cdn.pixabay.com/photo/2016/12/09/11/33/smartphone-1894723__340.jpg" },
                "option_10": { "answer": "Ich suche Anleitungen und Hilfestellungen", "tag": "callFnc_docu_customer", "type": "combinable", "image": "https://cdn.pixabay.com/photo/2016/11/18/11/17/social-1834016__340.png" },
                "option_11": { "answer": "Nichts davon", "tag": "callFnc_contact_support", "type": "exclusive", "image": "" },
            },
            misc: {
                "question": "Was möchtest du auf ProDuck tun?",
                "option_1": { "answer": "Ich möchte Partner von ProDuck werden", "tag": "callFnc_become_partner", "type": "combinable", "image": "https://cdn.pixabay.com/photo/2017/07/13/08/02/shaking-hands-2499612__340.jpg" },
                "option_2": { "answer": "Ich möchte Investor von ProDuck werden", "tag": "callFnc_become_investor", "type": "combinable", "image": "https://cdn.pixabay.com/photo/2018/05/17/13/03/business-angel-3408523__340.jpg" },
                "option_3": { "answer": "Ich möchte einen Artikel über ProDuck schreiben", "tag": "callFnc_press_release", "type": "combinable", "image": "https://cdn.pixabay.com/photo/2015/10/12/20/10/journalist-985073__340.jpg" },
                "option_4": { "answer": "Ich suche die Developer Guidelines", "tag": "callFnc_docu_developer", "type": "combinable", "image": "/assets/img/docu/docu-developer-guide.png" },
                "option_5": { "answer": "Ich möchte die App Downloaden für Kunden", "tag": "callFnc_produck_app", "type": "combinable", "image": "https://cdn.pixabay.com/photo/2016/12/09/11/33/smartphone-1894723__340.jpg" },
                "option_6": { "answer": "Nichts davon", "tag": "callFnc_contact_support", "type": "exclusive", "image": "" },

            }
        }

        /*
        ** this object defines which action should finally be triggered by which tag
        ** fncCall - boolean - defines if a fnc should be called
        ** infoBox - boolean - defines if an info box should be created
        */
        this.fncIndex = {
            embed_live_chat: {
                fncCall: false,
                infoBox: true,
                id: "live-chat-box",
                href: '/product-chat.html',
                headline: 'Live Chat',
                description: 'Der Live Chat kann in wenigen Minuten in externe Seiten integriert werden und bietet eine Vielzahl an Funktionen kostenlos. Im Folgenden findest du eine Übersicht der zentralen Funktionen und die Anleitung zum Einbau.',   
            },
            experts_as_a_service: {
                fncCall: false,
                infoBox: true,
                id: "experts-as-a-service-box",
                href: '/service-excellence.html',
                headline: 'Experts As A Service',
                description: 'Mit Experts As A Service unterstützen unsere erfahrensten Nutzer und geprüfte Experten deine Kunden 24/7 beim Shoppen.',   
            },
            content_marketing: {
                fncCall: false,
                infoBox: true,
                id: "content-marketing-box",
                href: '/service-excellence.html#chat-to-website',
                headline: 'Content Marketing',
                /*description: 'Du möchtest schnell und flexibel dein Content Marketing steigern. Wir erstellen Artikel und vermieten diese zeitlich flexibel an dich. Die Artikel sind dann so lange auf deiner Seite, wie du sie benötigst.'*/
                description: 'Du möchtest mit wenig Aufwand dein Content Marketing steigern? Unsere Experten erstellen Artikel, die auf deine Produkte verlinken oder sogar komplett auf deiner Shop-Seite gehostet werden.'
            },
            chat_shop: {
                fncCall: false,
                infoBox: true,
                id: "chat-shop-box",
                href: '/product-chat.html#chat-shop-feature',
                headline: 'Produktchat',
                description: 'Mit dem Produktchat kannst du Produkte oder Dienstleistungen auf ProDuck erstellen und direkt im Chat verkaufen. Vom Onlineshop bis zum Tierarzt, die Kombination aus Beratung und Vertrieb kann vielseitig eingesetzt werden.',   
            },  
            produck_app: {
                fncCall: false,
                infoBox: true,
                id: "dwnld-app-box",
                href: '/docu/expert.html#appinstall',
                headline: 'ProDuck App',
                description: 'Mit der ProDuck App bleibst du immer und überall auf dem Laufenden. Ob Chatanfragen, Antworten auf deine Fragen oder neue Einnahmen - Die ProDuck App informiert dich, wenn etwas passiert.',   
            },
            docu_customer: {
                fncCall: false,
                infoBox: true,
                id: "docu-customer-box",
                href: '/docu/customer.html',
                headline: 'Anleitungen und Tutorials für Kunden',
                description: 'In unserer Docu findest du Anleitungen und Video-Tutorials, die dir die Anwendung von ProDuck erklären.',
                linkText: 'Zur Doku',
            },
            docu_expert: {
                fncCall: false,
                infoBox: true,
                id: "docu-expert-box",
                href: '/docu/expert.html',
                headline: 'Anleitungen und Tutorials für Experten',
                description: 'In unserer Docu findest du Anleitungen und Video-Tutorials, die dir die Anwendung von ProDuck erklären.',
                linkText: 'Zur Doku',
            },
            docu_provider: {
                fncCall: false,
                infoBox: true,
                id: "docu-provider-box",
                href: '/docu/provider.html',
                headline: 'Anleitungen und Tutorials für Firmen',
                description: 'In unserer Docu findest du Anleitungen und Video-Tutorials, die dir die Anwendung von ProDuck erklären.',
                linkText: 'Zur Doku',
            },
            docu_developer: {
                fncCall: false,
                infoBox: true,
                id: "docu-developer-box",
                href: '/docu/develover.html',
                headline: 'Anleitungen und Tutorials für Entwickler',
                description: 'In unserer Docu findest du Anleitungen und Video-Tutorials, die dir die Funktionen von ProDuck erklären.',
                linkText: 'Zur Doku',
            },
            publish_content_affiliate: {
                fncCall: false,
                infoBox: true,
                id: "publish_content_affiliate-box",
                href: '/expert.html',
                headline: 'Affiliate Marketing',
                description: 'Baue dein eigenes Affiliate Marketing mit ProDuck auf und nutze die Reichweite, um deine Affiliate Einnahmen auf ein neues Level zu bringen: Als Experte kannst du auf ProDuck neue Artikel erstellen, Fragen beantworten oder Chats empfangen und zusammen mit deinen Affiliate Links publizieren.',
            },
            freelancer_author: {
                fncCall: false,
                infoBox: true,
                id: "freelancer_author-box",
                href: '/gastautoren.html#job-announcement',
                headline: 'Freelancer Autor',
                description: 'Schreibe als freier Autor Artikel für ProDuck mit festem Wortpreis.'
            },
            publish_content_guest_author: {
                fncCall: false,
                infoBox: true,
                id: "publish_content_guest_author-box",
                href: '/gastautoren.html',
                headline: 'Gastautoren',
                description: 'Publiziere noch heute als Gastautor auf ProDuck. Mit dem ProDuck CMS kannst du leicht Content publizieren und eigene Affiliate Links integrieren.'
            },
            become_cust_service_xpert: {
                fncCall: false,
                infoBox: true,
                id: "apply-as-service-xpert-box",
                href: '/contact.html',
                headline: 'Beratung gegen Provision',
                description: 'Als qualifizierter Experte auf ProDuck, erhältst du die Chance, Kunden von externen Shops bei ihrem Einkauf zu unterstützen und im Gegenzug Provision für jeden Verkauf zu erhalten. Bei Interesse, kontaktiere uns über das nachfolgende Formular.',
                linkText: 'Kontaktformular öffnen'
            },
            become_investor: {
                fncCall: false,
                infoBox: true,
                id: "apply-as-investor-box",
                href: '/contact.html',
                headline: 'Investor werden',
                description: 'Du hast Interesse, an einer finanziellen Beteiligung an einem der schnellst wachsenden Produkt-Hotspots Deutschlands? Kontaktiere uns über das nachfolgende Kontaktformular. Wir freuen uns auf ein erstes Kennenlernen.',
                linkText: 'Kontaktformular öffnen'
            },
            become_partner:  {
                fncCall: false,
                infoBox: true,
                id: "apply-as-partne-box",
                href: '/contact.html',
                headline: 'Partner werden',
                description: 'Du siehst eine mögliche Synergie zwischen deinem Unternehmen und ProDuck? Kontaktiere uns über folgendes Kontaktformular, mit einer kurzen Beschreibung deines Kooperationsvorhabens. Wir freuen uns auf ein erstes Kennenlernen.',
                linkText: 'Kontaktformular öffnen'
            },
            press_release: {
                fncCall: false,
                infoBox: true,
                id: "press-release-box",
                href: '/contact.html',
                headline: 'Presse & Co.',
                description: 'Du findest das Angebot und die Entwicklung von ProDuck spannend und möchtest darüber berichten? Kontaktiere uns über unser Kontaktformular, mit einer kurzen Beschreibung deines Anliegens. Wir freuen uns auf deine Kontaktaufnahme. Grundlegendes Material für eine Veröffentlichung findest du unter https://www.produck.de/assets/tmp/.',
                linkText: 'Kontaktformular öffnen'
            },
            profile_customer: {
                fncCall: true,
                fncName: this._registerAndSaveProfileLocation,
                infoBox: true,
                id: "profile-customer-box",
                href: '',
                headline: 'Profil anlegen',
                description: 'Als Kunde kannst du aktuell leider kein Profil anlegen. Registriere dich als Experte oder Shop bzw. Provider, um die Profilfunktion nutzen zu können.',
                linkText: 'Jetzt Registrieren',
            },
            profile_expert: {
                fncCall: true,
                fncName: this._registerAndSaveProfileLocation,
                infoBox: true,
                id: "profile-expert-box",
                href: '',
                headline: 'Profil anlegen',
                description: 'Erstelle dir ein individuelles Profil, auf dem du z.B. Neuigkeiten, Trends oder Produktvorschläge vorstellen kannst. Optional kannst du deinen Besuchern deinen persönlichen ProDuck Chat anbieten und so dein Affiliate Marketing auf ein neues Level bringen. Das Profil kannst du dir nach deiner kostenlosen Registrierung anlegen.',
                linkText: 'Jetzt Registrieren',
            },
            profile_provider: {
                fncCall: true,
                fncName: this._registerAndSaveProfileLocation,
                infoBox: true,
                id: "profile-provider-box",
                href: '',
                headline: 'Firmenprofil anlegen',
                description: 'Erstelle ein individuelles Profil für deinen Shop oder deine Webseite und bewirb so dein Unternehmen, einzelne Produkte oder Neuigkeiten über Suchmaschinen und soziale Medien. Nutze optional den ProDuck Chat, um mit interessierten Kunden in Kontakt zu treten. Ein Profil kannst du dir nach deiner kostenlosen Registrierung anlegen.',
                linkText: 'Jetzt Registrieren',
            },
            contact_support: {
                fncCall: false,
                infoBox: true,
                id: "contact-support-box",
                href: '/contact.html',
                headline: 'Kundenservice',
                description: 'Du benötigst persönliche Hilfe, Beratung oder möchtest ein Problem melden. Dann kontaktiere unseren Kundenservice über folgendes Kontaktformular.',
                linkText: 'Kontaktformular öffnen'
            },
            chat: {
                fncCall: false,
                infoBox: true,
                id: "start-live-chat-box",
                href: '/profile/2127',
                headline: 'Chatten',
                description: 'Du möchtest mit uns chatten? Kein Problem! Kontaktiere uns über unseren Chat im Produck Profil.',
                linkText: 'Zum ProDuck Chat'
            },
            answer_question: {
                fncCall: true,
                fncName: this._registerAndSaveAnswerQuestionLocation,
                infoBox: true,
                id: "answer-question-box",
                href: '',
                headline: 'Fragen beantworten',
                description: 'Um Fragen auf ProDuck beantworten zu können, musst du als Kunde oder Experte registriert sein. Du findest alle gegenwärtigen Fragen in deinem Portal im Menüpunkt "Fragen" oder kannst diese direkt im passenden Beitrag beantworten. Zur kostenlosen Registrierung gelangst du über folgenden Link.',
                linkText: 'Jetzt Registrieren'
            },
            ask_question: {
                fncCall: true,
                fncName: this._registerAndSaveRaiseQuestionLocation,
                infoBox: true,
                id: "ask-question-box",
                href: '',
                headline: 'Fragen stellen',
                description: 'Um Fragen auf ProDuck stellen zu können, musst du als Kunde oder Experte registriert sein. Eine Frage kannst du dann in deinem Portal im Menüpunkt "Fragen" stellen. Zur kostenlosen Registrierung gelangst du über folgenden Link.',
                linkText: 'Jetzt Registrieren'
            },
            rate_quack: {
                fncCall: true,
                fncName: this._register,
                infoBox: true,
                id: "rate-quack-box",
                href: '',
                headline: 'Beitrag bewerten',
                description: 'Um einen Beitrag auf ProDuck zu bewerten, musst du als Kunde oder Experte registriert sein. Du kannst den Beitrag anschließend nach deinem Login direkt unterhalb des Textes bewerten. Zur kostenlosen Registrierung gelangst du über folgenden Link.',
                linkText: 'Jetzt Registrieren'
            },
            comment_quack: {
                fncCall: true,
                fncName: this._register,
                infoBox: true,
                id: "comment-quack-box",
                href: '',
                headline: 'Beitrag kommentieren',
                description: 'Um einen Beitrag auf ProDuck zu kommentieren, musst du als Kunde oder Experte registriert sein. Du kannst den Beitrag anschließend nach deinem Login direkt unterhalb des Textes kommentieren. Zur kostenlosen Registrierung gelangst du über folgenden Link.',
                linkText: 'Jetzt Registrieren'
            },
            register: {
                fncCall: true,
                fncName: this._register,
                infoBox: true,
                id: "register-box",
                href: '',
                headline: 'Registrieren',
                description: 'Funktionen, wie das Stellen und Beantworten von Fragen, die Übersicht geführter Chats und viele weitere stehen dir nach Registrierung in unserem Portal zur Verfügung. Zur kostenlosen Registrierung gelangst du über folgenden Link.',
                linkText: 'Jetzt Registrieren'
            },
            defaultMsg: function () { return alert('Keine passenden Inhalte gefunden.')}
        }
    }

    initializeWizard() {
        var instance = this;
        //set initial questions
        var obligatoryQuestions = [];
        instance.cookie.storeSelectionToLocalStorage("__question_modules__", obligatoryQuestions);

        // build question card and lead through process dynamically
        instance._buildWizardCard(instance.wizardArray.target_group);
        instance._initWizard();

        $("ul.progressbar li").click(instance._navigationHandlerWizard);

        $('#wizard-btn-wrapper .continue.btn').on("click", function () {
            instance._initNextQuestion();
            var elmnt = document.getElementById("container-wizard");
            elmnt.scrollIntoView();

            $('#container-wizard h1').remove();
        });

        var wizardItems = localStorage.getItem('__destination_confi__');

        instance._showWizardInitialSettings(wizardItems);
    }


    _buildWizardCard(category) {

        var options = $('#options');
        options.empty();
        var answerArray = $.map(category, x => x.answer);
        var imageArray = $.map(category, x => x.image);
        var tagArray = $.map(category, x => x.tag);
        var typeArray = $.map(category, x => x.type);

        function createAnswerBlock(j) {

            var itemElem = $('#options .item').last();
            var answerElem = $('#options .item__title').last();
            var imgElem = $('#options .item__image').last();

            var answer = answerArray[j];
            var tag = tagArray[j];
            var type = typeArray[j];
            var img;
            answerElem.text(answer);

            function hideImg() {
                imgElem.remove();
                itemElem.addClass('noanim');
            }

            if (tag.indexOf('none') > -1) {
                hideImg();            }
            else {
                img = imageArray[j];
                img ? imgElem.attr('src', img).attr('alt', answer) : hideImg();
            }

            itemElem.attr('data-tag', tag);
            itemElem.attr('data-type', type);
            itemElem.addClass('wizard');
        }

        var questionContainer = '<a class="item active"><img class="item__image" alt="answer" /><h2 class="item__title"></h2></a>';

        for (var j = 0; j < answerArray.length; j++) {
            options.append(questionContainer);
            createAnswerBlock(j);
        }

        var questionElem = $('#wizard-headline');
        var question = category.question;
        questionElem.html(question);
    }

    _highlightSelection(elem) {

        $(elem).toggleClass('selected');

        $.fn.extend({
            toggleIcon: function (a) {

                var icon = "<i class='material-icons'>check</i>";

                if ($(elem).hasClass(a)) {
                    $(elem)
                      .append(icon);
                } else if (!$(elem).hasClass(a)) {
                    $(elem).find('.material-icons').remove();
                }
                return this;
            }
        });

        $(elem).toggleIcon("selected");
    }

    _initWizard() {
        var instance = this;
        $('.item').on('click', function () {
            instance._highlightSelection(this);
            $(this).siblings('.item[data-type="exclusive"]').removeClass('selected').find('.material-icons').remove();
        });
        $('.item[data-type="exclusive"]').on('click', function () {
            $(this).siblings().removeClass('selected').find('.material-icons').remove();
        });
    }

    _initNextQuestion() {
        var instance = this;
        var openQuestionModules = instance._adjustArrayinStorage('openQuestionModules', localStorage.getItem('__question_modules__'));
        var destinationItems = instance._adjustArrayinStorage('destinationItems', localStorage.getItem('__destination_confi__'));
        // ES6 method to remove duplicates, because set has just unique values
        let uniqueDestinationItems;

        // the data tag can be "article tag" or none; article tag directs to the next question
        var dataArray = $('.item.selected').map(function () {
            return $(this).data('tag');
        });

        for (var i = 0; i < dataArray.length; i++) {
            if (dataArray[i].indexOf('callFnc_') > -1) {
                destinationItems.push(dataArray[i].split('callFnc_').pop());
                uniqueDestinationItems = [...new Set(destinationItems)];
                instance.cookie.storeSelectionToLocalStorage("__destination_confi__", uniqueDestinationItems);
            }
            else if (dataArray[i].indexOf('none') > -1) {
                console.log('no subsequent question');
            }
            else {
                openQuestionModules.push(dataArray[i]);
            }
        }

        //proceed as long as further questions in queue
        if (openQuestionModules !== 'undefined' && openQuestionModules.length > 0) {
            instance._buildWizardCard(instance.wizardArray[openQuestionModules[0]]);
            openQuestionModules.shift();
            instance._initWizard();
            instance.cookie.storeSelectionToLocalStorage("__question_modules__", openQuestionModules);
        }
        else {
            var infoBoxes = '';

            uniqueDestinationItems.forEach((fnc) => {
                
                if (instance.fncIndex[fnc].infoBox) {
                    var boxDetails = instance.fncIndex[fnc];
                    infoBoxes += instance._buildFeatureBox(boxDetails.id, boxDetails.href, boxDetails.headline, boxDetails.description, boxDetails.linkText);
                } else {
                    return (instance.fncIndex['defaultMsg'])();
                }
            });

            function callInitFunctions() {
                uniqueDestinationItems.forEach((fnc) => {

                    if (instance.fncIndex[fnc].fncCall) {
                        var functionToExecute = (instance.fncIndex[fnc].fncName)(instance.fncIndex[fnc].id, instance);
                    }
                })
            }

            var newPromise = new Promise((resolve, reject) => {
                instance._buildWizardOutcomeCard(infoBoxes, resolve);
            });

            newPromise.then((result) => {
                callInitFunctions();
            });
        }
    }

    _buildWizardOutcomeCard(infoBoxes, resolve) {
        $(document).trigger("loader:on", ['', 'transparent']);

        let featureBlock = $('<section id="four-box-overview" class="con-wrapper section-cl" ><h2 class="fst-headline dark">Deine persönliche Linkübersicht</h2>' + infoBoxes + '</section>');

        setTimeout(() => {
            $(document).trigger("loader:off");

            featureBlock.insertAfter($('#container-wizard'));
            $('#container-wizard').hide();
            resolve('success');
        }, 1000 );        
    }

    _buildFeatureBox(id, href, headline, description, linkText) {

        var linkText = linkText ? linkText : "Mehr Erfahren";
        var href = href ? 'href="' + href + '"' : '';

        //console.log('_buildFeatureBox' + href, linkText);

        let featureBox = '<div class="flex-wrapper multi-items" id="'+ id +'">'
            +'      <div class="main-categories">'
            +'        <a class="main-categories-content-wrapper" '+ href +' target="_blank">'
            +'          <h2>'+ headline +'</h2>'
            +'          <span class="info-text">'+ description +'</span>'
            +'          <span class="link-text">'+ linkText +'</span>'
            +'        </a>'
            +'      </div>'
            +'    </div>';

        return featureBox;
    }

    _register(id, instance) {

        var clickTarget = $('#' + id).find('.link-text');

        if (id) {
            clickTarget.on('click', () => {
                instance.register.callInstantOnSiteSignIn(this);
            });
        } else {
            instance.register.callInstantOnSiteSignIn('reload');
        }
    }

    async _setPortalLocation(targetLocation, targetTab) {
        await this.portalSupport.saveSessLocation({'location': targetLocation, 'targetTab': targetTab});
    }

    async _registerAndSaveAnswerQuestionLocation(id, instance) {

        var targetLocation = 'targetQuestion';
        var targetTab = 'answerableQuestionsSection';
        await instance._setPortalLocation(targetLocation, targetTab);

        instance._register(id, instance);
    }

    async _registerAndSaveRaiseQuestionLocation(id, instance) {

        var targetLocation = 'targetQuestion';
        var targetTab = 'answerableQuestionsSection';
        await instance._setPortalLocation(targetLocation, targetTab);

        instance._register(id, instance)
    }

    async _registerAndSaveProfileLocation(id, instance) {

        var targetLocation = 'target8';
        await instance._setPortalLocation(targetLocation);

        instance._register(id, instance)
    }

    _adjustArrayinStorage(arrayName, storageContent) {

        if (storageContent && storageContent !== 'undefined' && storageContent.length > 0) {
            arrayName = JSON.parse(storageContent);
        }
        else {
            arrayName = [];
        }
        return arrayName;
    }

    _showWizardInitialSettings(wizardItems) {
        if (wizardItems) {
            $('#startAnewModal').modal({
                dismissible: false
            });
            $('#startAnewModal').modal('open');

            //wipes the previous configuration items
            $('#fullCartModalDeleteCartLink').click(function () {
                localStorage.removeItem('__destination_confi__');
            });  
        }
    }

    _navigationHandlerWizard() {
        var pack = $(this).data("package");
        var conWrapperSalesStep = $('.con-wrapper.sales-step[data-package="' + pack + '"]');

        if ($(this).hasClass("active")) {

            $(this).removeClass("active");
            $(this)
              .nextAll()
              .removeClass("active");

            //if ($('#container-4, #container-5').hasClass('active')) {
            //    hidePaymentChoiceArea();
            //    emptyFinishOrderSection();
            //}

            $(".con-wrapper").removeClass('active').addClass('inactive');
            conWrapperSalesStep.removeClass("inactive").addClass("active");
        }

        if ($(this).hasClass("completed") && !$(this).hasClass("active")) {

            // var callSettingModal = settingModal();

            $(this).addClass("active");
            $(this).prevAll().addClass("active");

            $(".con-wrapper").removeClass('active').addClass('inactive');
            conWrapperSalesStep.removeClass("inactive").addClass("active");

            if (conWrapperSalesStep.attr('id') === 'container-wizard') {
                var wizardItems = localStorage.getItem('__destination_confi__');

                //set initial questions   
                instance._showWizardInitialSettings(wizardItems);
                $('#cart-overview').empty();
                var obligatoryQuestions = ["main_category", "controller", "access_point"];
                instance.cookie.storeSelectionToLocalStorage("__question_modules__", obligatoryQuestions);
                instance._buildWizardCard(instance.wizardArray.operating_system);
                instance_initWizard();
            }
        }
    }    
}
