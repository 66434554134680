/* global monstecLib */
/* global M */
import ServiceSupport from './servicesupport';

/**
 * Provides access to the chat service by creating a REST client and providing some
 * quick access methods for retrieving data.
 */
export default class ChatServiceAccess extends ServiceSupport {
    constructor(authenticatorSingleton) {
        super();
        this.constants = new monstecLib.Constants();
        this.authenticator = authenticatorSingleton;
        this.restClient = this._createRestClientForChatService();
    }

    initialiseContextBeans() {
        this.authenticator = monstecLib.produckContext.authenticator;
    }

    /**
     * Creates a REST Client that enables you to contact and work with the chat service.
     */
    _createRestClientForChatService() {
        let restBuilder = new monstecLib.RestClientBuilder();
        let restApi = restBuilder.createRestClient(this.constants.apiHostChat);

        // configure resources to use
        restApi.res('chat');
        restApi.chat.res('edit');
        restApi.chat.res('public');
        restApi.chat.res('ack');
        restApi.chat.res('request');
        restApi.chat.res('commissionable');
        restApi.chat.request.res('all');
        restApi.res('chatcomment');
        restApi.res('chats');
        restApi.chats.res('active');
        restApi.chats.res('requested');
        restApi.res('quack');
        restApi.res('quacks');
        restApi.quacks.res('external');
        restApi.res('message');
        restApi.message.res('public');
        restApi.message.res('edit');
        restApi.message.res('insert');
        restApi.res('messages');
        restApi.messages.res('edit');
        restApi.res('image');
        restApi.messages.res('base64');
        restApi.messages.res('original');
        restApi.res('rating');
        restApi.rating.res('chat');
        restApi.rating.res('answer');
        restApi.rating.res('article');
        restApi.res('question');
        restApi.question.res('edit');
        restApi.question.res('public');
        restApi.res('questions');
        restApi.res('questioncomment');
        restApi.res('answer');
        restApi.answer.res('edit');
        restApi.answer.res('public');
        restApi.res('answers');
        restApi.res('answercomment');
        restApi.res('article');
        restApi.article.res('public');
        restApi.article.res('publish');
        restApi.article.res('unpublish');
        restApi.article.res('outsource');
        restApi.article.res('preaccept');
        restApi.article.res('accept');
        restApi.res('articles');
        restApi.articles.res('suggested');
        restApi.res('articleblock');
        restApi.articleblock.res('public');
        restApi.articleblock.res('position');
        restApi.res('articleblocks');
        restApi.articleblocks.res('edit');
        restApi.res('articlerequest');
        restApi.articlerequest.res('accept');
        restApi.articlerequest.res('revoke');
        restApi.res('articlerequests');
        restApi.articlerequests.res('all');
        restApi.res('articlecomment');
        restApi.res('articleimprovement');
        restApi.res('articletemplate');
        restApi.res('articletemplates');
        restApi.articletemplates.res('choices');
        restApi.res('quacktoken');
        restApi.quacktoken.res('all');
        restApi.res('settings');
        restApi.settings.res('pushNotifications');
        restApi.settings.res('language');

        return restApi;
    }

    async _getAccessToken() {
        try {
            return await this.authenticator.getAccessToken();
        } catch (error) {
            this.authenticator.promptForRelogin();
            return;
        }
    }

    async checkHealth() {
        const instance = this;
        let accessToken = await this._getAccessToken();

        return $.ajax({
            url: this.constants.apiHostChat.slice(0,-1) + 'actuator/health',
            type: 'GET',
            cache: false,
            headers: {'Authorization': 'Bearer ' + accessToken},
            error: function(response) {
                console.log('Could not retrieve information: ', response);
            }
        });
    }

    /**
     * The retrieved chats can be narrowed down by the filter given as argument.
     * For example it can have the proprties 'type' and 'topic' to refine the search. For more parameters and
     * details see the service endpoint documentation.
     *
     * @param {object} restriction set of variables that define the requested data
     */
    async getActiveQuacksData(restriction) {
        const instance = this;
        //userId
        // lang: isoCode
        // referenceType:
        //  - 0: ALL (quacks, is default)
        //  - 1: CHATS (only published chats formerly known as quacks)
        //  - 2: ARTICLE (only article quacks)
        //  - 3: QUESTION (only question questions)
        // targetType:
        //  - 0: ALL quack types (is default)
        //  - 1: INTERNAL Quacks
        //  - 2: EXTERNAL Quacks        
        // sort:
        //  - 0: newest chat first (is default)
        //  - 1: oldest chat first
        // quackId
        // title
        // dateUntil:
        //  - datetime
        // pageSize: how many items per Page
        // page: how many pages

        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.quacks.get(restriction).then(function (data) {
            return data;
        }, function (errorReason) {
            if(errorReason.status != 404) {
                console.log("Chat request failed. Server returned status " + errorReason.status);
            } else {
                return [];
            }
        });
    }

    async getActiveChatsData(restriction) {
        const instance = this;
        // type:
        //  - 0: ALL (quacks and chats)
        //  - 1: STANDARD (only chats that are not marked as quacks)
        //  - 2: QUACK (only quacks)
        // sort:
        //  - 0: newest chat first
        //  - 1: oldest chat first
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.chats.get({'type': restriction}).then(function (data) {
            return data;
        }, function (errorReason) {
            if(errorReason.status != 404) {
                console.log("Chat request failed. Server returned status " + errorReason.status);
            } else {
                return [];
            }
        });
    }

    /**
     * The retrieved chats can be narrowed down by the filter given as argument.
     * For example it can have the proprties 'type' and 'topic' to refine the search. For more parameters and
     * details see the service endpoint documentation.
     *
     * @param {object} filter a filter to narrow down the search
     */
    async getChatList(filter) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();
        return instance.restClient.chats.get(filter);
    }

    /**
     * Returns a promise that will either provide active chats or nothing.
     *
     * @returns a promise that will provide an array of chat objects in the success case
     */
    async getActiveChats(chatRole) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.chats.active.get({'type': chatRole});
    }

    async getChatRequestList() {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.chats.requested.get();
    }

    /**
     * Removes a specific chat request from view.
     *
     * @param {*} requestId the technical identifier of the request to remove
     */
    async removeRequest(requestId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.chat.request(requestId).delete();
    }

    /**
     * Removes all expired request targeting the current user from view.
     */
    async removeExpiredRequests() {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.chat.request.all.delete();
    }

    async acknowledgeChat(chatId, wsSessionId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        let payload = {};
        payload.chatId = chatId;

        if (wsSessionId) {
            payload.pcSessionId = wsSessionId;
        }

        return instance.restClient.chat.ack.get(payload);
    }

    async checkCommissionability(chatId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.chat.commissionable.get({"chatId": chatId});
    }

    /**
     * Directly create a finished chat in the server without actually requesting one.
     *
     * @param {object} chat a chat object having at least the topic set.
     *                      {
     *                         topic: [string],
     *                         product: [string],
     *                         link: [string],
     *                         language: [string],
     *                         tags: [array|string],
     *                      }
     */
    async createChat(chat) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.chat.post(chat);
    }

    /**
     * Updates the topic of a specified chat.
     *
     * @param {number} chatId the technical identifier of the chat to update
     * @param {string} topic the new topic of the chat
     * @param {string} domain the new domain of the chat
     */
    async updateChat(params) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.chat.put(params);
    }

    /**
     * Deletes a specific chat.
     *
     * @param {number} chatId the technical identifier of the chat to delete
     */
    async deleteChat(chatId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.chat(chatId).delete();
    }


    /**
     * Retrieve a chat in a state that is not secured for public viewing.
     *
     * @param {*} chatId the question to retrieve
     */
    async getChatForEditing(chatId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.chat.edit.get({'id': chatId});
    }

    async getPublicChatData(chatId) {
        const instance = this;
        return instance.restClient.chat.public.get({'id': chatId});
    }

    async getPublicMessageData(messageId) {
        const instance = this;
        return instance.restClient.message.public.get({'id': messageId});
    }

    async getChatMessages(chatId, lastId, includeStartId) {
        const instance = this;
        let payload = {};
        payload.chatId = chatId;
        if (lastId) {
            payload.messageId = lastId;
            payload.includeStartId = !!includeStartId;
        }

        instance.restClient.accessToken = await this._getAccessToken();
        return instance.restClient.messages.get(payload);
    }

    async getChatMessagesForEditing(chatId, lastId) {
        const instance = this;
        let payload = {};
        payload.chatId = chatId;
        if (lastId) payload.messageId = lastId;

        instance.restClient.accessToken = await this._getAccessToken();
        return instance.restClient.messages.edit.get(payload);
    }

    async getChatMessageOriginals(chatId) {
        const instance = this;
        let payload = {};
        payload.chatId = chatId;

        instance.restClient.accessToken = await this._getAccessToken();
        return instance.restClient.messages.original.get(payload);
    }

    async getChatMessageForEditing(messageId) {
        const instance = this;
        let payload = {};
        payload.messageId = messageId;

        instance.restClient.accessToken = await this._getAccessToken();
        return instance.restClient.message.edit.get(payload);
    }

    async insertTextMessage(chatId, senderId, text, precedingMessage) {
        const instance = this;
        let payload = {};
        payload.chatId = chatId;
        payload.senderId = senderId;
        payload.text = text;
        payload.precedingMessageId = precedingMessage;

        instance.restClient.accessToken = await this._getAccessToken();
        return instance.restClient.message.insert.post(payload);
    }

    async postMessage(chatId, userId, text) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.message.post({ "chatId": chatId, "senderId": userId, "text": text });
    }

    async sendImageMessage(chatId, text, file, progressCallback, successCallback, errorCallback) {
        const instance = this;
        let formData = new FormData();
        formData.append('chatId', chatId);
        if (!!text) formData.append('text', text);
        formData.append('image', file);

        return instance.sendFormData(formData, instance.constants.apiHostChat + 'image', progressCallback, successCallback, errorCallback);
    }

    async sendImageMessageBase64(chatId, text, imageCode, progressCallback, successCallback, errorCallback) {
        const instance = this;
        let formData = new FormData();
        formData.append('chatId', chatId);
        if (!!text) formData.append('text', text);
        formData.append('image', imageCode);

        return instance.sendFormData(formData, instance.constants.apiHostChat + 'image/base64', progressCallback, successCallback, errorCallback);
    }

    async sendDocumentMessage(chatId, text, file, progressCallback, successCallback, errorCallback) {
        const instance = this;
        let formData = new FormData();
        formData.append('chatId', chatId);
        if (!!text) formData.append('text', text);
        formData.append('document', file);

        return instance.sendFormData(formData, instance.constants.apiHostChat + 'document', progressCallback, successCallback, errorCallback);
    }

    async getImage(imageId, messageId) {
        const instance = this;
        let payload = {};
        payload.imageId = imageId;
        payload.messageId = messageId;

        let accessToken = await instance._getAccessToken();

        return $.ajax({
            url: this.constants.apiHostChat + 'image',
            type: 'GET',
            data: payload,
            cache: false,
            dataType: 'binary',
            processData: false,
            responseType: 'arraybuffer',
            headers: {'Authorization': 'Bearer ' + accessToken,
                      'X-Requested-With': 'XMLHttpRequest'},
            error: function(response) {
                console.log('Could not retrieve image: ', response);
            }
        });
    }

    async getDocument(documentId, messageId) {
        const instance = this;
        let payload = {};
        payload.documentId = documentId;
        payload.messageId = messageId;

        let accessToken = await this._getAccessToken();

        return $.ajax({
            url: this.constants.apiHostChat + 'document',
            type: 'GET',
            data: payload,
            cache: false,
            dataType: 'binary',
            processData: false,
            responseType: 'arraybuffer',
            headers: {'Authorization': 'Bearer ' + accessToken,
                      'X-Requested-With': 'XMLHttpRequest'},
            error: function(response) {
                console.log('Could not retrieve document: ', response);
            }
        });
    }

    /**
     * Updates the text of a specified message of a chat.
     *
     * @param {number} messageId the technical identifier of the chat message to update
     */
    async updateChatMessage(messageId, text) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.message.put({ "id": messageId, "text": text});
    }

    /**
     * Deletes a specified message of a chat.
     *
     * @param {number} messageId the technical identifier of the chat message to delete
     */
    async deleteChatMessage(messageId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.message(messageId).delete();
    }

    async postChatAsQuack(data) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.quack.post(data, 'application/json');
    }

    /**
     * Sends a new comment on a chat to the service.
     */
    async addChatComment(chatId, text) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        let data = {};
        data.chatId = chatId;
        data.text = text;

        return instance.restClient.chatcomment.post(data, 'application/json');
    }

    /**
     * Sends an update of a comment on a chat to the service.
     */
    async updateChatComment(commentId, text) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        let chatComment = {};
        chatComment.id = commentId;
        chatComment.text = text;

        return instance.restClient.chatcomment.put(chatComment, 'application/json');
    }

    /**
     * Sends a request to delete a spedific chat comment to the service.
     */
    async deleteChatComment(commentId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.chatcomment(commentId).delete();
    }

    /**
     * Retrieves the rating of the logged in user for a specific chat.
     */
    async getChatRating(chatId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.rating.chat.get({'chatId': chatId});
    }

    /**
     * Enables the user to rate a chat-quack.
     *
     * @param {*} chatId the chat to rate
     * @param {*} value the actual rating value
     */
    async addChatRating(chatId, value) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        let data = {};
        data.chatId = chatId;
        data.value = value;

        return instance.restClient.rating.chat.post(data, 'application/json');
    }

    /**
     * Retrieves the rating of the logged in user for a specific answer.
     */
    async getAnswerRating(answerId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.rating.answer.get({'answerId': answerId});
    }

    /**
     * Enables the user to rate an answer to a question-quack.
     *
     * @param {*} answerId the answer to rate
     * @param {*} value the actual rating value
     */
    async addAnswerRating(answerId, value) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        let data = {};
        data.answerId = answerId;
        data.value = value;

        return instance.restClient.rating.answer.post(data, 'application/json');
    }

    /**
     * Retrieves the rating of the logged in user for a specific article.
     */
    async getArticleRating(articleId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.rating.article.get({'articleId': articleId});
    }

    /**
     * Enables the user to rate an article-quack.
     *
     * @param {*} articleId the article to rate
     * @param {*} value the actual rating value
     */
    async addArticleRating(articleId, value) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        let data = {};
        data.articleId = articleId;
        data.value = value;

        return instance.restClient.rating.article.post(data, 'application/json');
    }

    /**
     * Creates a new question.
     *
     * @param {object} question a question object having at least the topic and the product name set.
     *                      {
     *                         topic: [string],
     *                         productName: [string],
     *                         text: [string],
     *                         language: [string],
     *                         tags: [array|string],
     *                      }
     */
    async createQuestion(question) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.question.post(question);
    }

    /**
     * Updates an existing question.
     *
     * @param {*} params the properties to update on the question
     */
    async updateQuestion(params) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.question.put(params);
    }

    /**
     * Retrieve a question in a state that is not secured for public viewing.
     *
     * @param {*} questionId the question to retrieve
     */
    async getQuestionForEditing(questionId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.question.edit.get({'id': questionId});
    }

    async getPublicQuestionData(questionId) {
        const instance = this;
        return instance.restClient.question.public.get({'id': questionId});
    }

    /**
     * Deletes a specific question.
     *
     * @param {number} questionId the technical identifier of the question to delete
     */
    async deleteQuestion(questionId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.chat(questionId).delete();
    }

    /**
     * Get the list of questions that can currently be answered from the server.
     */
    async getAnswerableQuestions(filter) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.questions.get(filter);
    }

    /**
     * Sends a new comment on a question to the service.
     */
    async addQuestionComment(questionId, text) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        let data = {};
        data.questionId = questionId;
        data.text = text;

        return instance.restClient.questioncomment.post(data, 'application/json');
    }

    /**
     * Sends an update of a comment on a question to the service.
     */
    async updateQuestionComment(commentId, text) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        let questionComment = {};
        questionComment.id = commentId;
        questionComment.text = text;

        return instance.restClient.questioncomment.put(questionComment, 'application/json');
    }

    /**
     * Sends a request to delete a spedific question comment to the service.
     */
    async deleteQuestionComment(commentId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.questioncomment(commentId).delete();
    }

    /**
     * Send a new answer to a specific question.
     *
     * @param {*} questionId the question the answer is intended to be for
     * @param {*} text the actual answer text
     */
    async createAnswer(questionId, text) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        let answer = {};
        answer.questionId = questionId;
        answer.text = text;

        return instance.restClient.answer.post(answer);
    }

    /**
     * Update an existing answer to a specific question.
     *
     * @param {*} answerId the answer to update
     * @param {*} text the actual answer text
     */
    async updateAnswer(answerId, text) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        let answer = {};
        answer.id = answerId;
        answer.text = text;

        return instance.restClient.answer.put(answer);
    }

    /**
     * Retrieve an answer in a state that is not secured for public viewing.
     *
     * @param {*} answerId the answer to retrieve
     */
    async getAnswerForEditing(answerId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.answer.edit.get({'id': answerId});
    }

    async getPublicAnswerData(answerId) {
        const instance = this;
        return instance.restClient.answer.public.get({'id': answerId});
    }

    /**
     * Retrieve all answers for a specific questoin.
     *
     * @param {Number} questionId the technical identifier of the question to retrieve answers for
     */
    async getAnswers(questionId) {
        const instance = this;
        return instance.restClient.answers.get({'questionId': questionId});
    }

    /**
     * Sends a new comment on an answer to the service.
     */
    async addAnswerComment(answerId, text) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        let data = {};
        data.answerId = answerId;
        data.text = text;

        return instance.restClient.answercomment.post(data, 'application/json');
    }

    /**
     * Sends an update of a comment on an answer to the service.
     */
    async updateAnswerComment(commentId, text) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        let questionComment = {};
        questionComment.id = commentId;
        questionComment.text = text;

        return instance.restClient.questioncomment.put(questionComment, 'application/json');
    }

    /**
     * Sends a request to delete a spedific answer comment to the service.
     */
    async deleteAnswerComment(commentId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.answercomment(commentId).delete();
    }

    /**
     * Retrieve an article in a state that is not secured for public viewing.
     *
     * @param {*} articleId the answer to retrieve
     */
    async getArticleForEditing(articleId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.article.get({'id': articleId});
    }

    async getPublicArticleData(articleId) {
        const instance = this;
        return instance.restClient.article.public.get({'id': articleId});
    }

    /**
     * Creates a new article.
     *
     * @param {object} article an article object having at least the title set.
     *                      {
     *                         title: [string],
     *                         summary: [string],
     *                         language: [string],
     *                         tags: [array|string],
     *                      }
     */
    async createArticle(article) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.article.post(article);
    }

    /**
     * Updates an existing article.
     *
     * @param {*} params the properties to update on the article
     */
    async updateArticle(params) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.article.put(params);
    }

    /**
     * Publishes an existing article.
     *
     * @param {*} articleId the technical identifier of the article to publish
     */
    async publishArticle(articleId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.article.publish.put(articleId);
    }

    /**
    * Outsources an existing article.
    *
    * @param {*} articleId the technical identifier of the article to publish
    * @param {*} domain the domain the article is provided for
    */
    async outsourceArticle(articleId, domain) {
        const instance = this;

        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.article.outsource.put({id: articleId, domain: domain});
    }

    /**
     * Revoles the published-status from an existing article.
     *
     * @param {*} articleId the technical identifier of the article to unpublish
     */
    async unpublishArticle(articleId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.article.unpublish.put(articleId);
    }

    /**
     * Accepts an existing article suggestion.
     *
     * @param {*} articleId the technical identifier of the article to accept
     */
    async acceptArticleSuggestion(articleId) {
        const instance = this;
        instance.restClient.accessToken = await instance._getAccessToken();

        let params = {};
        params.id = articleId;

        return instance.restClient.article.preaccept.put(params);
    }

        /**
     * Accepts an existing article ideally immediatedly after article is accomplished in order to allow final publication.
     *
     * @param {*} articleId the technical identifier of the article to accept
     */
    async acceptArticle(articleId) {
        const instance = this;
        instance.restClient.accessToken = await instance._getAccessToken();

        let params = {};
        params.id = articleId;

        return instance.restClient.article.accept.put(params);
    }

    /**
     * Deletes an existing article rendering it invisible to all users including the creator.
     *
     * @param {*} articleId the technical identifier of the article to delete
     */
    async deleteArticle(articleId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.article(articleId).delete();
    }

    /**
     * Get a list of articles of the authenticated user.
     */
    async getArticles(filter) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.articles.get(filter);
    }

    /**
     * Get the list of article suggestions available to the authenticated user.
     */
    async getSuggestedArticles(filter) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.articles.suggested.get(filter);
    }

    /**
     * Send a new block to a specific article.
     *
     * @param {*} articleId the article the block is intended to be for
     * @param {*} text the actual block content
     */
    async createArticleBlock(articleId, text) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        let block = {};
        block.articleId = articleId;
        block.text = text;

        return instance.restClient.articleblock.post(block);
    }

    /**
     * Update a specific existing article block.
     *
     * @param {*} blockId the article block to update
     * @param {*} text the actual block content
     */
    async updateArticleBlock(blockId, text) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        let block = {};
        block.id = blockId;
        block.text = text;

        return instance.restClient.articleblock.put(block);
    }

    /**
     * Get a single article block in a not sanitised fashion.
     */
    async getArticleBlockForEditing(blockId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.articleblock.get({"id": blockId});
    }

    async getPublicArticleBlockData(blockId) {
        const instance = this;
        return instance.restClient.articleblock.public.get({'id': blockId});
    }

    /**
     * Deletes an existing article block completely.
     *
     * @param {*} blockId the technical identifier of the article block to delete
     */
    async deleteArticleBlock(blockId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.articleblock(blockId).delete();
    }

    /**
     * Repositions an article block by switching the position with the block above.
     *
     * @param {*} blockId the article block to update
     */
    async moveArticleBlockUp(blockId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.articleblock.position.put({id: blockId, direction: 0});
    }

    /**
     * Repositions an article block by switching the position with the block above.
     *
     * @param {*} blockId the article block to update
     */
    async moveArticleBlockDown(blockId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.articleblock.position.put({id: blockId, direction: 1});
    }

    /**
     * Get all blocks of a specific article
     *
     * @param {*} articleId the technical identifier of the article to retrieve blocks for
     */
    async getArticleBlocks(articleId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.articleblocks.get({'articleId': articleId});
    }

    /**
     * Get all blocks of a specific article in their raw unescaped and unsafe form.
     *
     * @param {*} articleId the technical identifier of the article to retrieve blocks for
     */
    async getArticleBlocksForEditing(articleId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.articleblocks.edit.get({'articleId': articleId});
    }

    /**
     * Creates a new article.
     *
     * @param {object} request an article request object having at least the title and summary set.
     *                      {
     *                         title: [string],
     *                         summary: [string],
     * *                       instructions: [string],
     *                         language: [string],
     *                         tags: [array|string],
     *                         wordCountMin: [integer],
     *                         wordCountMax: [integer]
     *                      }
     */
    async createArticleRequest(request) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.articlerequest.post(request);
    }

    /**
     * Updates an existing article request.
     *
     * @param {*} params object containing the properties to update on the article
     */
    async updateArticleRequest(params) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.articlerequest.put(params);
    }

    /**
     * Sends a request to delete a spedific article request to the service.
     */
    async deleteArticleRequest(requestId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.articlerequest(requestId).delete();
    }

    /**
     * Accepts an existing article request, so that the accepting author can then start writing the article
     *
     * @param {number} articleRequestId the identifier of the article request to accept
     */
    async acceptArticleRequest(articleRequestId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.articlerequest.accept.put(articleRequestId);
    }

    /**
     * Revokes an accepted article request from the current author, so that it can be accepted by other authors.
     *
     * @param {number} articleRequestId the identifier of the article request to revoke
     */
    async revokeArticleRequest(articleRequestId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.articlerequest.revoke.put(articleRequestId);
    }

    /**
     * Get a list of article requests of the authenticated user.
     */
    async getOwnArticleRequests(filter) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.articlerequests.get(filter);
    }

    /**
     * Get a list of all open article requests. This does require the permission VERIFIED_AUTHOR.
     */
    async getAllArticleRequests(filter) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.articlerequests.all.get(filter);
    }

    /**
     * Sends a new comment on an article to the service.
     */
    async addArticleComment(articleId, text) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        let data = {};
        data.articleId = articleId;
        data.text = text;

        return instance.restClient.articlecomment.post(data, 'application/json');
    }

    /**
     * Sends an update of a comment on an article to the service.
     */
    async updateArticleComment(commentId, text) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        let articleComment = {};
        articleComment.id = commentId;
        articleComment.text = text;

        return instance.restClient.articlecomment.put(articleComment, 'application/json');
    }

    /**
     * Sends a request to delete a spedific article comment to the service.
     */
    async deleteArticleComment(commentId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.articlecomment(commentId).delete();
    }

    /**
     * Sends a new suggestion for improvment for an article to the service.
     */
    async suggestArticleImprovement(articleId, text) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        let data = {};
        data.articleId = articleId;
        data.text = text;

        return instance.restClient.articleimprovement.post(data, 'application/json');
    }

    /**
     * Sends article template data to the service that will create a new persisted template if the
     * data is valid.
     */
    async createArticleTemplate(template) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.articletemplate.post(template, 'application/json');
    }

    /**
     * Sends parts of an article template to the service that will update the persisted template
     * with valid new data.
     */
    async updateArticleTemplate(template) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.articletemplate.put(template, 'application/json');
    }

    /**
     * Sends a request to delete a spedific article template to the service.
     */
    async deleteArticleTemplate(templateId) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.articletemplate(templateId).delete();
    }

    /**
     * Get own article templates
     */
    async getArticleTemplates(filter) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.articletemplates.get(filter);
    }

    /**
     * Get a list of article templates filtered by name phrase.
     *
     * @param {*} searchPhrase a part of the name of the article template you are looking for
     */
    async getArticleTemplateChoices(searchPhrase) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.articletemplates.choices.get({'name': searchPhrase});
    }

    async getQuackToken() {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.quacktoken.get();
    }

    async getQuackTokensAndDomains() {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.quacktoken.all.get();
    }

    async generateQuackToken(domain) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.quacktoken.put({'domain': domain});
    }

    async deleteQuackToken(token) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.quacktoken(token).delete();
    }

    async getSettings() {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.settings.get();
    }

    async saveNotificationSettings(settings) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.settings.pushNotifications.put(settings);
    }

    async saveLanguageSettings(languageCode) {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        console.log({"cs - language": languageCode});

        return instance.restClient.settings.language.put({"language": languageCode});
    }

    async getLanguageSettings() {
        const instance = this;
        instance.restClient.accessToken = await this._getAccessToken();

        return instance.restClient.settings.language.get();
    }
}
