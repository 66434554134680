/* global monstecLib */
/* global M */
import i18next from "./localisation.js";

export default class ChatFileUpload {
    /**
     * Adds actual upload behaviour to a file input field.
     *
     * @param {number} htmlIdUpload the html ID of a file input element
     * @param {number} htmlIdTrigger the html ID of any element that should trigger the file input on click
     */
    constructor(chatId, htmlIdUpload, htmlIdTrigger, chatSupport) {
        const instance = this;
        instance.chatId = chatId;

        instance.MAX_WIDTH = 1920;
        instance.MAX_HEIGHT = 1080;

        instance.chatSupport = chatSupport;
        instance.chatService = monstecLib.produckContext.chatService;
        instance.utils = monstecLib.produckContext.utils;

        instance.htmlIdUpload = htmlIdUpload;

        $('#' + htmlIdTrigger).click(function() {
            $('#' + htmlIdUpload).click();
        });

        $('#' + htmlIdUpload).on('change', function(evt) {
            var files = evt.target.files;
            var file = files[0];
            
            instance.upload();
            //instance.resizeImage(file);
        });
    }

    resizeImage(file) {
        const instance = this;
        if (window.File && window.FileReader && window.FileList && window.Blob) {
            if (file) {
                var reader = new FileReader();
                // Set the image once loaded into file reader
                reader.onload = function(e) {
                    var img = document.createElement("img");
                    img.src = e.target.result;

                    img.onload = function(e) {
                        try {
                            let widthFactor;
                            let heightFactor;
                            let scaleFactor = 1;

                            if (img.width > img.height) {
                                // landscape
                                widthFactor = instance.MAX_WIDTH / img.width;
                                heightFactor = instance.MAX_HEIGHT / img.height;
                            } else {
                                // portrait or square
                                widthFactor = instance.MAX_HEIGHT / img.width;
                                heightFactor = instance.MAX_WIDTH / img.height;
                            }

                            if (widthFactor < 1 || heightFactor < 1) {
                                scaleFactor = Math.min(widthFactor, heightFactor);
                            }

                            let dataurl;
                            if (scaleFactor < 1) {
                                // first pass
                                scaleFactor = Math.cbrt(scaleFactor);

                                var tempCanvas = document.createElement('canvas');
                                tempCanvas.width = img.width * scaleFactor;
                                tempCanvas.height = img.height * scaleFactor;
                                
                                var ctx = tempCanvas.getContext('2d');
                                ctx.imageSmoothingEnabled = true;
                                ctx.imageSmoothingQuality = 'high';
                                ctx.drawImage(img, 0, 0, tempCanvas.width, tempCanvas.height);

                                // second pass
                                let secondWidth = tempCanvas.width * scaleFactor;
                                let secondHeight = tempCanvas.height * scaleFactor;
                                ctx.drawImage(tempCanvas, 0, 0, secondWidth, secondHeight);
                                
                                // third pass
                                let targetWidth = secondWidth * scaleFactor;
                                let targetHeight = secondHeight * scaleFactor;
                                var targetCanvas = document.createElement('canvas');
                                var targetCx = targetCanvas.getContext('2d');
                                targetCanvas.width = targetWidth;
                                targetCanvas.height = targetHeight;
                                targetCx.drawImage(tempCanvas, 0, 0, secondWidth, secondHeight, 0, 0, targetWidth, targetHeight);

                                dataurl = targetCanvas.toDataURL(file.type);
                            } else {
                                dataurl = e.target.result;
                            }

                            instance.chatService.sendImageMessageBase64(1, null, dataurl, instance._progress);
                        } catch (e) {
                            console.log('ERROR: Failed scaling, falling back to full upload.', e);
                            instance.upload();
                        }
                    };
                };

                reader.readAsDataURL(file);
            }

        } else {
            console.log('The File APIs are not fully supported in this browser, falling back to full upload.');
            instance.upload();
        }
    }

    upload() {
        const instance = this;

        // TODO add option to add a text to the file
        
        const file = $('#' + instance.htmlIdUpload).get(0).files[0];
    
        if (file.size > 10485760) {
            M.toast({html: i18next.t('toasts.too_many_bytes')});
            return;
        }

        // fileCategory can be 0 for image or 1 for document
        let fileCategory = -1;
        if (file.name.toLowerCase().endsWith(".jpg")
                || file.name.toLowerCase().endsWith(".jpeg")
                || file.name.toLowerCase().endsWith(".png")) {
            fileCategory = 0;
        } else if (file.name.toLowerCase().endsWith(".pdf")) {
            fileCategory = 1;
        } else {
            M.toast({html: i18next.t('toasts.unsupported_filetype')});
            return;
        }

        const chatlog = $('.cl[data-chatid=' + instance.chatId + '] .chatlog');
        const messageContainer = document.createElement('div'); // Container for textmessage
        messageContainer.className = 'chat xpert';
        chatlog[0].appendChild(messageContainer);

        const loaderContainer = document.createElement('div');
        loaderContainer.className = "chat-message";
        messageContainer.appendChild(loaderContainer);

        instance.utils.addLoader(loaderContainer);
        instance.chatSupport.scrollDown();

        function completionTask(messageId) {
            var chatLog =  $(".cl[data-chatid=" + instance.chatId + "] .chatlog.conversation-card");
            instance.chatSupport.updateLastMessageMetaData(chatLog, messageId);
            instance.chatSupport.setMessageStatus(2, messageContainer);
        }

        function failTask(errorResponse) {
            let toastMessage = i18next.t('toasts.' + JSON.parse(errorResponse.responseText).status, 'X');
            if (!!toastMessage && toastMessage !== 'X') {
                M.toast({html: toastMessage});
            } else {
                M.toast({html: i18next.t('toasts.upload_failed')});
            }

            $(messageContainer).remove();
        }

        // call the suitable upload function depending on file type
        if (fileCategory == 0) {
            instance.chatService.sendImageMessage(instance.chatId, null, file, instance._progess)
            .then(function(response) {
                instance.chatSupport.addImageMessageToChatLog(instance.chatId, response.imageId,
                    response.messageId, 'chat xpert', messageContainer)
                .then(function() { completionTask(response.messageId); });
            })
            .catch(function(errorResponse) { failTask(errorResponse); });
        } else if (fileCategory == 1) {
            instance.chatService.sendDocumentMessage(instance.chatId, null, file, instance._progess)
            .then(function(response) {
                instance.chatSupport.addDocumentMessageToChatLog(instance.chatId, response.documentId, file.name,
                     response.messageId, 'chat xpert', messageContainer)
                .then(function() { completionTask(instance.chatId, response.messageId); });
            })
            .catch(function(errorResponse) { failTask(errorResponse); });
        }
    }

    _progress(e) {
        if (e.lengthComputable) {
            $('#progress_percent').text(Math.floor((e.loaded * 100) / e.total));
            $('progress').attr({value:e.loaded,max:e.total});
        }
    }
}
