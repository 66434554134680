/* global monstecLib */
import i18next from './localisation.js';
import 'url-search-params-polyfill';

export default class Credentials {
    constructor(identityServiceAccess) {
        if (!identityServiceAccess) {
            console.log('ERROR: PasswordReset - IdentityServiceAccess not set!');
        }

        this.identityService = identityServiceAccess;
    }

    /**
     * Defines the trigger of the password reset process and binds it to a form element.
     *
     * @param {*} form the form element that will trigger the password reset process by its submit event.
     */
    async updatePw(uid) {
        var instance = this;
        var form = $('#update-password');

        form.bind('submit', function (e) {
            e.preventDefault();
            var currentPw = $("#confirm-password").val();
            var newPw = $("#set-password1").val();

            instance.identityService.changePassword(uid, currentPw, newPw).then(function () {
                M.toast({html: i18next.t('toasts.password_change_success')});
                $("#confirm-password").val("");
                $("#set-password1").val("");
                $("#set-password2").val("");
            }, function (xhr) {
                console.log("PW change request failed. Server returned status " + xhr.status);
                if (xhr.status == 401) {
                    M.toast({html: i18next.t('toasts.password_incorrect')});
                } else {
                    M.toast({html: i18next.t('toasts.password_change_fail')});
                }
            });
        });
    }

    async updateNick(uid) {
        var instance = this;
        var form = $('#update-nickname');
        // var uri = this.constants.apiHostIdentity + 'user';

        form.bind('submit', function (e) {
            e.preventDefault();
            var nickname = $("#nickname").val();

            instance.identityService.changeNickname(uid, nickname).then(function() {
                M.toast({html: i18next.t('toasts.username_change_success')});
            }, function (errorReason) {
                console.log("Username change request failed. Server returned status " + errorReason.status);
                M.toast({html: i18next.t('toasts.username_change_fail')});
            });
        });
    }

   
 
    /**
     * Helper function that determines and shows if the passwords the user entered in the input
     * fields for the new password and the repetition of it are equal.
     */    
    matchPasswords() {
        $("#set-password1").focusout(function() {
            $('.helper-text').css({'display':'block'});
        });

        $("#set-password2, #set-password1").focusout(function() {
            var pwInput1 = $("#set-password1").val().trim();
            var pwInput2 = $("#set-password2").val().trim();

            if((pwInput1.length < 8)){
                $('#set-password1').addClass('invalid');
            }
            else if (pwInput1 != pwInput2) {
                $('#set-password2').addClass('invalid');
            }
            else {
                $('#set-password2').attr('class', 'valid password');
                $('#submit-pw').attr('class', 'btn waves-effect waves-light');
            }
        });
    }


}
