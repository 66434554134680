/* global monstecLib */
/* global M */
import ObjectList from '../common/objectlist';

/**
 * Article list for "My Articles".
 */
export default class ArticleRequestList extends ObjectList {
    constructor(synchronousPermissionChecker) {
        super();
        this.currentUserId = synchronousPermissionChecker.getUser();
        this.noResultsProperty = 'articlesection.no_articles';

        this.authChecker = synchronousPermissionChecker;

        this.chatService = monstecLib.produckContext.chatService;
        this.identityService = monstecLib.produckContext.identityService;

        this.log = new monstecLib.Log(1);
    }

    /**
     * Determines for a single list item, if it is editable or not.
     *
     * @param {object} articleRequest the current object, i.e. list item
     */
    _determineIfItemEditable(articleRequest) {
        let status = articleRequest.status;
        let isCreator = articleRequest.userId == this.currentUserId;

        return status === 'REQUESTED' && isCreator;
    }

    /**
     * Determines for a single list item, if it can be accepted by the current user.
     *
     * @param {object} articleRequest the current object, i.e. list item
     */
    _determineIfItemAcceptable(articleRequest) {
        const instance = this;
        let status = articleRequest.status;
        let isCreator = articleRequest.userId == this.currentUserId;

        return status === 'REQUESTED' && !isCreator && instance.authChecker.checkPermission(instance.authChecker.permCat.ACCEPT_ARTICLE_REQUEST);
    }

    /**
     * Determines for a single list item, if it can be revoked from the current author by the current user.
     *
     * @param {object} articleRequest the current object, i.e. list item
     */
    _determineIfItemRevokable(articleRequest) {
        const instance = this;
        let status = articleRequest.status;
        let isOwner = articleRequest.ownerId == this.currentUserId;

        return status === 'DRAFT' && isOwner && instance.authChecker.checkPermission(instance.authChecker.permCat.REVOKE_ARTICLE_REQUEST);
    }

    /**
     * Determines for a single list item, if it can be deleted by the current user.
     *
     * @param {object} articleRequest the current object, i.e. list item
     */
    _determineIfItemDeletable(articleRequest) {
        const instance = this;
        let status = articleRequest.status;
        let isOwner = articleRequest.ownerId == this.currentUserId;

        instance.log.debug('deletePermission', instance.authChecker.checkPermission(instance.authChecker.permCat.DELETE_ARTICLE_REQUEST));

        return status === 'REQUESTED' && isOwner && instance.authChecker.checkPermission(instance.authChecker.permCat.DELETE_ARTICLE_REQUEST);
    }

    /**
     * Creates a DOM-element that will display a single article-request in the list of article-requests.
     *
     * @param {*} articleRequest the article-request to create a list item for
     */
    _createListItem(articleRequest, isEditable) {
        const instance = this;

        let summary =  articleRequest.summary;
        let instructions = articleRequest.instructions;

        let itemHeaderId = instance.htmlId + 'ArticleItemHeader' + articleRequest.id;

        console.log('articlerequest STATUS: ', articleRequest.status);

        let statusChip = (articleRequest.status == "PUBLISHED" || articleRequest.status == "EXTERNAL") ? ('<div class="chip status-chip ' + articleRequest.status + '" title="veröffentlicht am ' + instance.utils.formatDate(articleRequest.publicationTime) + '"><a href="/quackref/article/' + articleRequest.id + '/" target="_blank">' + instance.i18next.t('"articlesection.status_label_' + articleRequest.status + '"') + '</a></div>') : '<div class="chip status-chip ' + articleRequest.status + '" title="' + instance.utils.formatDate(articleRequest.creationTime) + '">' + instance.i18next.t('articlesection.status_label_' + articleRequest.status) + '</div>';

        let views = (articleRequest.status == "PUBLISHED" || articleRequest.status == "EXTERNAL") ? '<span class="views stats" title="' + instance.i18next.t("text.views") +'">' + articleRequest.views + '<i class="material-icons">visibility</i></span>' : '';

        instance.utils.linkifyText();

        let articleItemHtml = '<li class="js-collapsible-item" data-article-id="' + articleRequest.id + '">'
          + '  <div class="js-collapsible-header collapsible-header object-list-item" id="' + itemHeaderId + '" data-article-id="' + articleRequest.id + '" >'
          + '    <div class="article-info-header simple-vertical-content">'
          + '      <span id="' + itemHeaderId + 'Title" class="object-title current-content bold">' + articleRequest.title + '</span>'
          + '      <div class="simple-horizontal-content">'
          +         statusChip
          + '      </div>'
          + '    </div>'
          + '    <div id="' + instance.htmlId + 'ArticleActionButtons' + articleRequest.id + '"></div>'
          + '  </div>'
          + '  <div class="collapsible-body" data-article-id="' + articleRequest.id + '" data-user-id="' + articleRequest.userId + '">'
          + '    <div class="stats-wrapper">'
          + '      <span class="stats" title="' + instance.i18next.t("articlesection.word_count_min_label") + '">' + ((!!articleRequest.wordCountMin) ? articleRequest.wordCountMin : '&dash;') + '<i class="material-icons">subject</i>&nbsp;min</span>'
          + '      <span class="stats" title="' + instance.i18next.t("articlesection.word_count_max_label") + '">' + ((!!articleRequest.wordCountMax) ? articleRequest.wordCountMax : '&dash;') + '<i class="material-icons">subject</i>&nbsp;max</span>'
          + views
          + '      <span class="timestamp stats" title="' + instance.i18next.t("text.date_of_creation") + '">' + instance.utils.formatDate(articleRequest.creationTime) + '</span>'
          + '    </div>'
          + '    <div class="js-author-block author-block">'
          + '      <div itemprop="author" itemscope itemtype="https://schema.org/Person" class="author">'
          + '        <span>' + instance.i18next.t("articlesection.accepted_by") + '</span>&nbsp;'
          + '        <a class="prdk-link" href="/profile/' + articleRequest.authorId + '" target="_blank">'
          + '          <span itemprop="name" class="js-author-name author-name"></span>'
          + '        </a>'
          + '        <span class="js-acceptance-time"></span>'
          + '      </div>'
          + '    </div>'
          + '    <div class="dialogue-summary narrow center-duck-light">'
          + '      <div class="js-summary-action-button"></div>'
          + '      <div class="summary">'
          + '        <div class="current-content flex-wrap">'
          + '          <div class="w100"><b>' + instance.i18next.t('articlesection.summary_label') + '</b></div>'
          + '          <div class="vertical-spacer small"></div>'
          + '          <div class="js-summary-source article-hyperlink">' + ((summary && summary.length > 0) ? summary.linkify() : instance.i18next.t('articlesection.no_details')) + '</div>'
          + '        </div>'
          + '      </div>'
          + '    </div>'
          + '    <div class="js-article-tags edit-tags chips-initial"><input class="input edit-tags-input" placeholder="' + instance.i18next.t('toasts.chips') + '"></div>'
          + '    <div class="input-field language-select-wrapper">'
          + '      <select id="languageSelect" class="js-language-select edit-language">'
          + '        <option value="de"' + ((articleRequest.language == 'de') ? ' selected="selected"' : '') + '>' + instance.i18next.t('text.ger') + '</option>'
          + '        <option value="en"' + ((articleRequest.language == 'en') ? ' selected="selected"' : '') + '>' + instance.i18next.t('text.eng') + '</option>'
          + '      </select>'
          + '      <label>' + instance.i18next.t('text.language') + '</label>'
          + '    </div>'
          + '    <div class="dialogue-summary narrow center-duck-light">'
          + '      <div class="js-instructions-action-button"></div>'
          + '      <div class="summary">'
          + '        <div class="current-content flex-wrap">'
          + '          <div class="w100"><b>' + instance.i18next.t('articlesection.instructions_label') + '</b></div>'
          + '          <div class="vertical-spacer small"></div>'
          + '          <div class="js-instructions-source article-hyperlink">' + ((instructions && instructions.length > 0) ? instructions.linkify() : instance.i18next.t('articlesection.no_details')) + '</div>'
          + '        </div>'
          + '      </div>'
          + '    </div>'
          + '  </div>'
          + '</li>';

        const articleItem = $(articleItemHtml);
        articleItem.data('articleId', articleRequest.id);
        const articleItemBody = articleItem.find('.collapsible-body');

        instance._addTitleActions(articleRequest, articleItem, itemHeaderId, isEditable);

        if (isEditable) {
            instance._makeSummaryAndInstructionsEditable(articleRequest, articleItemBody);
        }

        articleItem.find('#' + itemHeaderId).on('click', async function() {

            if (articleRequest.status === "DRAFT") {
                let authorPromise = instance.identityService.getPublicUserData(articleRequest.authorId);
                authorPromise.then(function(user) {
                    articleItem.find('.js-author-name').text(user.nickname);
                })
                .catch(function(error) {
                    instance.error('User data of author could not be retrieved.', error);
                    articleItem.find('.js-author-block').addClass('hide');
                    return error;
                });
            } else {
                articleItem.find('.js-author-block').addClass('hide');
            }
        });

        return articleItem;
    }

    /**
     * Initialises the input element for the tags of an article.
     * NOTE! This has to be called after the list item has been added to the DOM. Otherwise
     * the materielizecss-initialisation-methods will not work.
     *
     * @param {*} listItem the article list item to initialise tags input for
     * @param {*} article the article data object corresponding to the given list item
     * @param {*} isEditable defines whether the content of the chips element can be changed by the current user
     */
    _initTagsInputOnItem(listItem, article, isEditable) {
        const instance = this;
        var chipsElement = listItem.find('.js-article-tags');

        if (!isEditable) {
            // remove the input element if the chips are not editable
            chipsElement.empty();
            for (var tagIndex in article.tags) {
                if (!article.tags.hasOwnProperty(tagIndex)) continue;
                chipsElement.append(`<div class="chip">${article.tags[tagIndex]}</div>`);
            }

            return;
        }

        // create a data object that is compatible with the Chips-form-element of materializecss
        // it must be an array of the form
        // [{tag: 'tag1'},{tag: 'tag2'}, {tag: 'tag3'}] (or even more than three)
        // the materializecss Chips-form-element will be initialised with this object meaning, that
        // the indluded tags will immediatley been shown and the form-element also adds new tags to
        // the array, whenever the user enters a new tag, or removes tags, whenever the user deletes
        // a tag.
        var chips = [];
        for (var tag in article.tags) {
            if (!article.tags.hasOwnProperty(tag)) continue;
            chips.push({tag: article.tags[tag]});
        }

        function updateArticleRequestTags() {
            // currently there is a bug in MaterializeCSS, see
            // https://github.com/Dogfalo/materialize/issues/6317
            // This bug prevents an empty array given to a Chips-elemnt from being updated
            // correspondingly to the user input. Therefore if the array is empty here, it
            // has to be checked, if there is actually no chip to send to the server
            var tags;
            if (!chips || chips.length < 1) {
                tags = [];
                var chipElements = listItem.find('.js-article-tags .chip');
                chipElements.each(function(index, elem) {
                    var textNode = $(elem).contents().first();
                    if (!textNode) return;
                    var tag = textNode.text();
                    if (tag && tag.length > 0) {
                        tags.push(tag.trim().replaceAll(/[^a-zA-Z0-9äöüÄÖÜß\.-]+/g, '-'));
                    }
                });
            } else {
                tags = chips.map(obj => {
                    let newTag = obj.tag.trim().replaceAll(/[^a-zA-Z0-9äöüÄÖÜß\.-]+/g, '-');
                    return newTag;
                });
            }

            var params = { "id": article.id, "tags": tags};
            instance._updateArticleRequest(params, {html: instance.i18next.t('toasts.tags_updated')});
        }

        function addTag(chipsElement) {
            let textNodeOfNewChip = chipsElement.find('.chip').last()[0].childNodes[0];
            let textOfNewChip = textNodeOfNewChip.data;
            textNodeOfNewChip.data = textOfNewChip.trim().replaceAll(/[^a-zA-Z0-9äöüÄÖÜß\.-]+/g, '-');
            updateArticleRequestTags();
        }

        var chipsOptions = {
            'data': chips,
            'placeholder': instance.i18next.t('toasts.chips'),
            'limit': 5,
            'onChipAdd': function(chips) {addTag(chips);},
            'onChipDelete': function() {updateArticleRequestTags();}
        };

        M.Chips.init(chipsElement, chipsOptions); // hint: returns instances
    }

    /**
     * Initialises the input element for the language of an article.
     * NOTE! This has to be called after the list item has been added to the DOM. Otherwise
     * the materielizecss-initialisation-methods will not work.
     *
     * @param {*} listItem the article list item to initialise language select for
     * @param {*} articleRequest the article request data object corresponding to the given list item
     */
    _initLanguageSelectOnItem(listItem, articleRequest, isEditable) {
        const instance = this;

        function updateArticleLanguageUpdate() {
            var iso = listItem.find('.js-language-select').val();
            var params = { "id": articleRequest.id, "language": iso};
            instance._updateArticleRequest(params, {html: instance.i18next.t('toasts.language_updated')});
        }

        var languageElement = listItem.find('.js-language-select');

        if (isEditable) {
            languageElement.on('change', updateArticleLanguageUpdate);
        } else {
            languageElement.attr('disabled', 'disabled');
        }

        M.FormSelect.init(languageElement, {'classes': 'edit-language'});
    }


    _addTitleActions(articleRequest, articleRequestItem, itemHeaderId, isEditable) {
        const instance = this;

        // title element
        let titleElement = articleRequestItem.find('#' + itemHeaderId + 'Title');
        const titleEditField = new monstecLib.InPlaceEditField(titleElement);

        let titleButtonConfig = [];

        if (instance._determineIfItemDeletable(articleRequest)) {
            titleButtonConfig.push(
                {
                    icon: 'delete_forever',
                    tooltip: 'articlesection.tooltip_delete_article_request',
                    func: function() {
                        let articleRequestElementHeader = $('.js-collapsible-header[data-article-id=' + articleRequest.id +']');
                        articleRequestElementHeader.css({'background-color': 'rgba(178,0,35,.6) !important'});

                        var headline = instance.i18next.t('articlesection.delete_article_request');
                        var text = instance.i18next.t('articlesection.delete_article_request_confirmation');
                        var optionOne = instance.i18next.t('text.yes_delete');
                        var optionTwo = instance.i18next.t('text.no');

                        //creates a suitable modal
                        instance.utils.createModal($('body'), headline, text, optionOne, optionTwo, 'deleteArticleRequestModal');

                        // call delete function if delete gets confirmed
                        $('#deleteArticleRequestModal .modal-close.option-one').on('click', function() {
                            instance.chatService.deleteArticleRequest(articleRequest.id)
                            .then(function() {
                                articleRequestElementHeader.parent('.js-collapsible-item').remove();
                            })
                            .catch(function(errorResponse) {
                                articleRequestElementHeader.css({'background': ''});
                                instance.log.debug('Deleting article request failed!', errorResponse);
                                if (errorResponse.status == 400) {
                                    M.toast({html: instance.i18next.t('toasts.data_invalid')});
                                } else {
                                    M.toast({html: instance.i18next.t('toasts.error')});
                                }
                            });
                            $('#deleteArticleRequestModal').remove();
                        });

                        // reset all conditions if delete gets declined
                        $('#deleteArticleRequestModal .modal-close.option-two').on('click', function() {
                            articleRequestElementHeader.css({'background': ''});
                            $('#deleteArticleRequestModal').remove();
                        });
                    }
                }
            );
        }

        if (instance._determineIfItemRevokable(articleRequest)) {
            titleButtonConfig.push(
                {
                    icon: 'replay',
                    tooltip: 'articlesection.tooltip_revoke_article_request',
                    func: function() {
                        let articleRequestElementHeader = $('.js-collapsible-header[data-article-id=' + articleRequest.id +']');
                        articleRequestElementHeader.css({'background-color': 'rgba(178,0,35,.6) !important'});

                        var headline = instance.i18next.t('articlesection.revoke_article_request');
                        var text = instance.i18next.t('articlesection.revoke_article_request_confirmation');
                        var optionOne = instance.i18next.t('text.yes');
                        var optionTwo = instance.i18next.t('text.no');

                        //creates a suitable modal
                        instance.utils.createModal($('body'), headline, text, optionOne, optionTwo, 'revokeArticleRequestModal');

                        // call revoke function if revoke gets confirmed
                        $('#revokeArticleRequestModal .modal-close.option-one').on('click', function() {
                            instance.chatService.revokeArticleRequest(articleRequest.id).then(function() {
                                articleRequestElementHeader.parent('.js-collapsible-item').remove();
                            }).catch(function(errorResponse) {
                                instance.log.debug('Revoking article request failed!', errorResponse);
                                if (errorResponse.status == 400) {
                                    M.toast({html: instance.i18next.t('toasts.data_invalid')});
                                } else {
                                    M.toast({html: instance.i18next.t('toasts.error')});
                                }
                            });
                            $('#revokeArticleRequestModal').remove();
                        });

                        // reset all conditions if delete gets declined
                        $('#revokeArticleRequestModal .modal-close.option-two').on('click', function() {
                            articleRequestElementHeader.css({'background': ''});
                            $('#revokeArticleRequestModal').remove();
                        });
                    }
                }
            );
        }

        if (instance._determineIfItemAcceptable(articleRequest)) {
            titleButtonConfig.push(
                {
                    icon: 'playlist_add_check',
                    tooltip: 'articlesection.tooltip_accept_article_request',
                    func: instance._openAcceptanceModal.bind(instance, articleRequest.id)
                }
            );
        }

        if (isEditable) {
            titleButtonConfig.push(
                {
                    icon: 'edit', func: function() {
                        titleEditField.onCommit = async function() {
                            let articleUpdate = {};
                            articleUpdate.id = articleRequest.id;
                            articleUpdate.title = titleEditField.getValue();

                            try {
                                return await instance._updateArticleRequest(articleUpdate, {html: instance.i18next.t('toasts.title_updated')});
                            } catch (errorResponse) {
                                return false;
                            }
                        };

                        titleEditField.replaceSource();
                }}
            );
        }

        if (titleButtonConfig.length > 0) {
            let titleButtons = new monstecLib.ActionButtons(titleButtonConfig, 'left');
            titleButtons.attachTo(instance.htmlId + 'ArticleActionButtons' + articleRequest.id, articleRequestItem);
        }
    }

    _makeSummaryAndInstructionsEditable(articleRequest, articleItemBody) {
        const instance = this;

        let summaryElement = articleItemBody.find('.js-summary-source');
        const summaryEditField = new monstecLib.InPlaceEditField(summaryElement, false);

        summaryEditField.onGetContent = async function() {
            let rawArticle = await instance.chatService.getArticleForEditing(articleRequest.id);
            return rawArticle.summary;
        };

        let summaryButtonConfig = [
            {
                icon: 'edit', func: function() {
                    summaryEditField.onCommit = async function() {
                        let articleUpdate = {};
                        articleUpdate.id = articleRequest.id;
                        articleUpdate.summary = summaryEditField.getValue();

                        try {
                            return await instance._updateArticleRequest(articleUpdate, {html: instance.i18next.t('toasts.data_updated')});
                        } catch (errorResponse) {
                            return false;
                        }
                    };

                    summaryEditField.replaceSource();
                }
            }
        ];

        let summaryButtons = new monstecLib.ActionButtons(summaryButtonConfig, 'left');
        summaryButtons.attachTo('js-summary-action-button', articleItemBody);

        // instructions element
        let instructionsElement = articleItemBody.find('.js-instructions-source');
        const instructionsEditField = new monstecLib.InPlaceEditField(instructionsElement, true);

        instructionsEditField.onGetContent = async function() {
            let rawArticle = await instance.chatService.getArticleForEditing(articleRequest.id);
            return rawArticle.instructions;
        };

        let instructionsButtonConfig = [
            {
                icon: 'edit', func: function() {
                    instructionsEditField.onCommit = async function() {
                        let articleUpdate = {};
                        articleUpdate.id = articleRequest.id;
                        articleUpdate.instructions = instructionsEditField.getValue();

                        try {
                            return await instance._updateArticleRequest(articleUpdate, {html: instance.i18next.t('toasts.data_updated')});
                        } catch (errorResponse) {
                            return false;
                        }
                    };

                    instructionsEditField.replaceSource();
                }
            }
        ];

        let instructionButtons = new monstecLib.ActionButtons(instructionsButtonConfig, 'left');
        instructionButtons.attachTo('js-instructions-action-button', articleItemBody);
    }

    _openAcceptanceModal(articleRequestId) {
        const instance = this;
        var headline = instance.i18next.t('articlesection.modal_accept_article_request');
        var text = instance.i18next.t('articlesection.modal_accept_article_request_text');
        var optionOne = instance.i18next.t('general.yes');
        var optionTwo = instance.i18next.t('general.cancel');
        instance.utils.createModal($('body'), headline, text, optionOne, optionTwo, 'acceptArticleRequestModal');

        // call delete function if delete gets confirmed
        $('#acceptArticleRequestModal .modal-close.option-one').on('click', function() {
            $(document).trigger("loader:on");
            instance.chatService.acceptArticleRequest(articleRequestId).then(function() {
                instance._navigateUserToArticle(articleRequestId);
                $(document).trigger("loader:off");
            }, function (error) {
                $(document).trigger("loader:off");
                M.toast({html: instance.i18next.t('toasts.error')});
                instance.log.error('Acceptance failed: ', error);
            });
            $('#acceptArticleRequestModal').remove();
        });

        // reset all conditions if delete gets declined
        $('#acceptArticleRequestModal .modal-close.option-two').on('click', function() {
            $('#acceptArticleRequestModal').remove();
        });
    }

    _navigateUserToArticle(articleRequestId) {
        setTimeout(function () {
            monstecLib.pageComponentRegistry.getComponent('article-section-navigation').select('myArticlesSubSection');
            setTimeout(function() {
                $('#myArticlesListArticleItemHeader' + articleRequestId).trigger('click');
            }, 100);
        }, 200);
    }

    /**
     * Performs an update request to the service that is intended to update the parts
     * of an article request object that are included in 'params'.
     *
     * @param {*} params the article request data, that should be updated as JSON, for example { "id": 1, "title": "new title"}
     * @param {*} tostParams a parameter object for the materializecss function M.toast, for example {html: 'Hurray'}
     */
    async _updateArticleRequest(params, toastParams) {
        var instance = this;

        return instance.chatService.updateArticleRequest(params)
            .then(function () {
                $(document).trigger("loader:off");
                if (toastParams) {
                    M.toast(toastParams);
                }

                return true;
            })
            .catch(function(errorResponse) {
                $(document).trigger("loader:off");
                instance.log.debug('Update of article request failed!', errorResponse);
                if (errorResponse.status == 400) {
                    M.toast({html: instance.i18next.t('toasts.data_invalid')});
                } else {
                    M.toast({html: instance.i18next.t('toasts.error')});
                }

                return false;
            });
    }
}
