/* global monstecLib */

export default class QuackListPage {
    constructor() {
        this.cookie = new monstecLib.Cookie();
        this.utils = new monstecLib.Utils();
        this.authenticator = new monstecLib.Authenticator(this.cookie);
    }

    initialise() {
        var instance = this;
        instance.register = new monstecLib.Register(instance.authenticator);
        instance.lazyload = new monstecLib.LazyLoad();

        instance.register.initSignInForm(false, instance.cookie, false, true, ['.instant-reg-btn']);

        // prefill search field with current search text in the URL of the current location
        let searchQuery = instance.utils.getParameterByName('q');
        let quackSearchField = $('#quack-search-field');
        let resetSearchBtn = $('#quack-reset-search-button');

        if (!!searchQuery && searchQuery.length > 0) {
            quackSearchField.val(searchQuery.substring(0, 100).trim());
        }

        resetSearchBtn.on('click', function() {
            if (!!searchQuery && searchQuery.length > 0) {
                window.location.href = '/quacks';
            } else if (!searchQuery && quackSearchField.val()) {
                quackSearchField.val('');
                quackSearchField.focus();
            }
        });

        this.cookie.saveLastVisitedPage(window.location.href);
        instance.lazyload.lazyloaderInit();
        instance.utils.initLoader();
        instance.utils.initShareContent();
        instance.utils.styleShariff();
        instance.cookie.setCookieOkCookies();

        $('[data-i18n]').localize();

    }

    initDealOverview (targetElem) {
        var instance = this;
        instance.dealsHandler = new monstecLib.DealsHandler(instance.utils);
        instance.dealsHandler.initDealsOverviewAndControls(targetElem, 'LIMITED_OFFERS_AND_DISCOUNT', 9);
    }

    adjustCarouselHeight() {
        var carouselItems = $(".article-carousel-cycle");

        function setUniformCardHeight(targetElem) {
            var maxHeight = 0;
            var carouselElem = $(targetElem).find($(".cycle-step"));

            $(carouselElem).each((i, el) => {
                var itemHeight = $(el).outerHeight();
                if (itemHeight > maxHeight) {
                    maxHeight = itemHeight;
                }
            });

            $(targetElem).height(maxHeight + 150);
            $(carouselElem).height(maxHeight);
        }

        $(carouselItems).each((i, el) => setUniformCardHeight(el));
    }
}