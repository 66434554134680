/* global monstecLib */

import i18next from "./localisation.js";

export default class Authenticator {
    constructor(cookieManager) {
        this.cookieManager = cookieManager;
        this.constants = new monstecLib.Constants();
        this.utils = new monstecLib.Utils();
        this.permissionCache = undefined;

        this.permCat = this._definePermissionCatalogue();

        this.log = new monstecLib.Log(1);
    }

    _definePermissionCatalogue() {
        let cat = {};
        cat.ACCESS_CUSTOMER_VIEW = '10001';
        cat.ACCESS_EXPERT_VIEW = '10002';
        cat.UPGRADE_TO_PROVIDER = '10003';
        cat.READ_ORIGINAL_CHATS = '30004';
        cat.RATE_CHAT = '30005';
        cat.ADD_CHAT_COMMENT = '30006';
        cat.WRITE_ANSWER = '32002';
        cat.RATE_ANSWER = '32003';
        cat.RATE_ARTICLE = '34002';
        cat.RECEIVE_ARTICLE_SUGGESTION = '34003';
        cat.CREATE_ARTICLE_TEMPLATE = '34004';
        cat.CREATE_ARTICLE_REQUEST = '34005';
        cat.ACCEPT_ARTICLE_REQUEST = '34006';
        cat.UPGRADE_TO_VERIFIED_AUTHOR = '34007';
        cat.REVOKE_ARTICLE_REQUEST = '34008';
        cat.DELETE_ARTICLE_REQUEST = '34009';
        cat.DELETE_ARTICLE = '35001';
        cat.RECEIVE_ARTICLE_SUBMISSIONS = '35002';
        cat.WRITE_QUACK = '38001';
        cat.CREATE_QUACK_TOKEN = '38002';
        cat.READ_QUACK_TOKEN = '38003';
        cat.DELETE_QUACK_TOKEN = '38004';
        cat.CREATE_EXTERNAL_WEBSITES = '39001';
        cat.READ_EXTERNAL_WEBSITES = '39002';
        cat.DELETE_EXTERNAL_WEBSITES = '39003';
        cat.CREATE_AFFILIATE_LINK = '40001';
        cat.DEFINE_CUSTOM_AFFILIATE_PARAMETER = '40003';
        cat.WRITE_OFFER = '50003';
        cat.REVIEW_CHATS = '80001';
        cat.REVIEW_QUESTIONS = '80002';
        cat.REVIEW_ARTICLES = '80003';
        cat.CHECK_SERVICE_HEALTH = '90001';
        return cat;
    }

    decodeJwt(token) {
        let b64DecodeUnicode = str =>
            decodeURIComponent(
                Array.prototype.map.call(atob(str), c =>
                    '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                ).join('')
            );

        let parseJwt = token =>
            JSON.parse(
                b64DecodeUnicode(
                    token.split('.')[1].replace('-', '+').replace('_', '/')
                )
            );

        let tokenDecoded = JSON.stringify(parseJwt(token));
        return tokenDecoded;
    }

    /**
     * Takes an authentication token (access token) and extracts the contained infromatino, which
     * will be returned as an object of the form {expiration, userId, userRole, userName}.
     *
     * @param {string} token
     */
    getTokenParam(token){
        let parsedToken = JSON.parse(this.decodeJwt(token));

        // get expiration of token
        let expiration = parsedToken.exp * 1000;
        let userId = parsedToken.uid;
        let userRole = parsedToken.uar;
        let permissions = parsedToken.authorities;
        let userName = parsedToken.user_name;

        return {expiration, userId, userRole, userName, permissions};
    }

    /**
     * TODO Moved over from reg_basic, has to be refactored a lot
     *
     * @param {*} credentials
     * @param {*} uri
     * @param {*} isLogin
     */
    authenticate(credentials, uri, isLogin){
        var thisAuthenticator = this;
        var headers;
        var contentType;
        var payload;
        var failureLink;

        if (isLogin) {
            var USERNAME = "xconsult_frontend";
            var PASSWORD = "ynNmpWovRB76";
            headers = {"Authorization": "Basic " + btoa(USERNAME + ":" + PASSWORD)}
            contentType = "application/x-www-form-urlencoded";
            payload = credentials;
            payload.grant_type = "password" ;
            failureLink = "log";
        } else {
            headers = false;
            contentType = "application/json; charset=utf-8";
            payload = JSON.stringify(credentials);
            failureLink = "reg";
        }

        return $.ajax({
            method: "POST",
            url: uri,
            crossDomain: true,
            contentType: contentType,
            data: payload,
            output: JSON,
            headers: headers,
            success: function (data) {
                if(isLogin){
                    var accessToken = data.access_token;
                    var authData = thisAuthenticator.getTokenParam(accessToken);
                    var refresh = data.refresh_token;

                    console.log('Authentication has been successful, storing login cookies.');
                    thisAuthenticator.cookieManager.setSessionCookie(authData);
                    thisAuthenticator.permissionCache = authData.permissions;
                    thisAuthenticator.initLoginCookie('sess_au', 'accToken', accessToken);
                    thisAuthenticator.initLoginCookie('sess_re', 'refToken', refresh);
                } else {
                    //registration process initiated, verify link has been sent
                    window.location.replace('/signup-info.html');
                }
            },
            error: function (jqXHRequest) {
                // error handler
                thisAuthenticator.cookieManager.clearCookie();

                if (jqXHRequest.status === 400){
                    $('#server-response').attr('class', 'invalid');
                    $('#helper-text').css({'display':'block'});
                    if (!isLogin) {
                        let textBody = i18next.t("text.register_notification_1");
                        thisAuthenticator.utils.createSimpleAlertModal(textBody);
                    }
                } else if (jqXHRequest.status === 404 && !isLogin) {
                    let textBody = i18next.t("text.register_notification_2");
                    thisAuthenticator.utils.createSimpleAlertModal(textBody);
                }
                else {
                    window.location.replace('/failure-' + failureLink + '.html');
                }
            }
        });
    }

    /**
     * Delete all authentication cookies and so end user's session. If an URI is provided
     * the user will be redirected to that URI after logout.
     *
     * @param {string} uri optional target where to redirect the user after logout
     */
    async logout(uri){
        var instance = this;
        instance.log.debug('Logging out.');

        try {
            await instance.cookieManager.clearEntireStorage();
            instance.permissionCache = undefined;
        } catch (e) {
            instance.log.error('Logout failed!', e);
            return;
        }

        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({'targetFunc': 'logout'}));
        }

        if (uri) {
            instance.log.debug('Logout complete, redirecting to:', uri);
            window.location.replace(uri);
        }
    }

    /**
     * Checks if user is currently authenticated. The method returns a promise. If the promise gets resolved, the user
     * will be authenticated. If the promise gets rejected, he will not. If there is no valid access token then the
     * method will look for a valid refresh token and use it to obtain a new access token. The promise will resolve if
     * the retrieval is successful. Otherwise the promise will be rejected.
     *
     * Important note: This method uses JQuery-promises!
     */
    async checkAuthenticationStatus() {
        var instance = this;
        var accessCookie = await instance.cookieManager.getRightStorage('sess_au');
        var refreshCookie = await instance.cookieManager.getRightStorage('sess_re');
        var dfd;

        if (accessCookie) {
            console.log('Access-token found, trying to set login status');
            dfd = $.Deferred();

            try {
                dfd.resolve(accessCookie.accToken);
            } catch (err) {
                dfd.reject(err);
            }

            return dfd.promise();

        } else if (refreshCookie) { //check if token expired
            console.log('Refresh-token found, trying to get new access token...');
            var result = this._refreshToken(refreshCookie.refToken); //refresh accessToken
            result.then(function() {
                window.location.reload(true); //TODO refactor this away!
            })
            .catch(function() {
                console.log('Failed to use refresh cookie for obtaining new access cookie.');
            });
            return result;

        } else {
            console.log('No access and no refresh token found, user is not authenticated.');
            dfd = $.Deferred();
            dfd.reject({'status':'no-auth-tokens'});
            return dfd.promise();
        }
    }

    //sets login status on chat.html
    async setLogInStatus(status) {
        var instance = this;
        var userRole = await instance.getUserRole();
        var accessStateExpert = await instance.checkPermission(instance.permCat.ACCESS_EXPERT_VIEW);

        if (accessStateExpert) {
            if(status && userRole !== 'TEMPORARY') {
                $('.thumb-nav > .container-2 > span').html('<i class="material-icons">person</i>');
                $('#container-2').hide();

                let profileBtn =  $('.thumb-nav > .container-2');
                profileBtn.addClass('xpert');
                profileBtn.attr('href', '/xpert.html');
            }
        } else {
            if(status && userRole !== 'TEMPORARY') {

                $('.thumb-nav > .container-2 > span').html('<i class="material-icons">person</i>');
                $('#container-2').hide();

                let profileBtn =  $('.thumb-nav > .container-2');
                profileBtn.addClass('customer');
                profileBtn.attr('href', 'user.html');
            }
        }
    }

    initLoginCookie(cookieName, identifier, token) {
        // set token expiration
        var tokenExp = this._expiration(token);
        var data = {};
        data[identifier] = token;

        this.cookieManager.saveToRightStorage(cookieName, data, tokenExp);
    }

    /**
     * If a user is currently authenticated and the session cookie is set, this function will determine the userId and return it.
     * But if no user is authenticated the result will be undefined.
     *
     * @returns a number representing the technical Id of the currently authenticated user or undefined
     */
    async getUser() {
        var userId;
        var instance = this;
        var produckCookie = await instance.cookieManager.getRightStorage('produck');

        if (produckCookie && produckCookie.userId) {
            userId = produckCookie.userId;
        }

        return userId;
    }

    /**
     * If a user is currently authenticated and the session cookie is set, this function will determine the role of the user and return it.
     * But if no user is authenticated the result will be undefined.
     *
     * @returns a number representing the technical Id of the currently authenticated user or undefined
     */
    async getUserRole() {
        var userRole;
        var instance = this;

        var produckCookie = await instance.cookieManager.getRightStorage('produck');

        if (produckCookie && produckCookie.userRole) {
            userRole = produckCookie.userRole;
        } else {
            var accessToken = await instance.getAccessToken(true);
            if (accessToken) {
                let authData = instance.getTokenParam(accessToken);
                if (authData.uar) {
                    userRole = authData.uar;
                }
            }
        }

        return userRole;
    }

    /**
     * If a user is currently authenticated and the session cookie is set, this function will determine the user's nickname and return it.
     * But if no user is authenticated the result will be undefined.
     *
     * @returns a string representing the nickname of the currently authenticated user
     */
    async getUserNickname() {
        var nickname;
        var instance = this;
        var produckCookie = await instance.cookieManager.getRightStorage('produck');

        if (produckCookie && produckCookie.nickname) {
            nickname = produckCookie.nickname;
        }

        return nickname;
    }

    /**
     * Asynchronously get the current user's permission.
     * NOTE that this function returns a promise, so don't use it in boolean expressions witouht waiting for
     * the result!
     */
    async getUserPermissions() {
        let instance = this;

        try {
            if (instance.permissionCache) {
                return instance.permissionCache;
            }

            var produckCookie = await instance.cookieManager.getRightStorage('produck');
            if (produckCookie && produckCookie.permissions) {
                instance.permissionCache = produckCookie.permissions;
                return instance.permissionCache;
            }

            var accessToken = await instance.getAccessToken(true);
            if (accessToken) {
                let authData = instance.getTokenParam(accessToken);
                if (authData.permissions) {
                    instance.permissionCache = authData.permissions;
                    return instance.permissionCache;
                }
            }
        } catch (e) {
            if (e.status != 'no-refresh-token') {
                instance.log.info('Permission check negative because of exception: ', e);
            }
        }

        // If no permission information can be found in cookies and cache then the it is assumed
        // that the user has no permissions.
        return [];
    }

    /**
     * Checks if the currently authenticated user has a specific permission / authority.
     * This method will return 'false' if no user is authenticated.
     * NOTE that this function returns a promise, so don't use it in boolean expressions without waiting for
     * the result!
     *
     * @param {string} permission the permission to check for
     */
    async checkPermission(permission) {
        let permissions = await this.getUserPermissions();
        let result = permissions.indexOf(permission) >= 0;
        this.log.debug('Permission %s has been checked with result [%s].', permission, result);
        return result;
    }

    /**
     * Returns an object that provides a function checkPermission like the authenticator itself. However
     * the checker will cache the permissions internally so that it can be used in a synchronous context, i.e.
     * the function checkPermission of the checker is not async. This is useful whenever many permissions have
     * to be checked consecutively.
     */
    async getSynchronousPermissionChecker() {
        const instance = this;
        let checker = {};
        checker.userId = await this.getUser();
        checker.permissions = await this.getUserPermissions();

        checker.checkPermission = function(permission) {
            let result =  (this.permissions.indexOf(permission) >= 0);
            instance.log.debug('Permission %s has been checked with result [%s].', permission, result);
            return result;
        }.bind(checker);

        checker.getUser = function() {
            return this.userId;
        };

        checker.permCat = instance.permCat;

        return checker;
    }

    /**
     * Try to retrieve a valid access token.
     *
     * @param {boolean} refresh if true the authenticator will try to use a refresh token to get a new access
     *                          token if none can be obtained directly.
     *
     * @return  a promise, that will yield the access token, if it can be resolved
     */
    async getAccessToken(refresh = true) {
        var instance = this;
        var accessTokenCookie = await instance.cookieManager.getRightStorage('sess_au');
        if (accessTokenCookie) {
            return accessTokenCookie.accToken;
        } else {
            if (refresh) {
                return instance.refreshAccessToken();
            } else {
                console.log('No access token found and the refresh option is not chosen.');
                return new Promise(function(resolve, reject) {
                    reject({'status':'no-access-token'});
                });
            }
        }
    }

    /**
     * Tries to use a refresh token to obtain a new access token.
     *
     * @return a promise, that will yield the access token, if it can be resolved
     */
    async refreshAccessToken() {
        var instance = this;
        var refreshCookie = await instance.cookieManager.getRightStorage('sess_re');
        if (refreshCookie) {
            return this._refreshToken(refreshCookie.refToken);
        } else {
            console.log('No refresh token found to refresh access token.');
            var dfd = $.Deferred();
            dfd.reject({'status':'no-refresh-token'});
            return dfd.promise();
        }
    }

    _refreshToken(token) {
        var instance = this;
        var USERNAME = "xconsult_frontend";
        var PASSWORD = "ynNmpWovRB76";

        return $.ajax({
            method: 'POST',
            url: instance.constants.apiHostOauth + 'token',
            crossDomain: true,
            headers: {"Authorization": "Basic " + btoa(USERNAME + ":" + PASSWORD)},
            dataType: 'json',
            data: "grant_type=refresh_token&refresh_token=" + token
        })
        .then(function(data) {
            console.log('Successfully obtained new access token.');
            var auth = data.access_token;
            var refresh = data.refresh_token;
            instance.initLoginCookie('sess_au', 'accToken', auth);
            instance.initLoginCookie('sess_re', 'refToken', refresh);
            instance.setLogInStatus(true);
            return auth;
        });
    }

    _expiration(token) {
        var timeInDays = this.utils.calcDaysFromNow(this.getTokenParam(token).expiration);
        return timeInDays;
    }

    clearAuthenticationData() {
        this.cookieManager.clearEntireStorage();
        this.permissionCache = undefined;
    }

    /**
     * Informs the user that his session has timed out and leads him to the login page.
     */
    promptForRelogin() {
        $(document).trigger("loader:off");
        this.utils.createSimpleAlertModal(i18next.t("modals.session_ended"))
        .then(function() {
            // if website is executed in app-webview send signal to logout
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(JSON.stringify({'targetFunc': 'logout'}));
            } else {
                window.location.replace('/login.html');
            }
        });

        // remove any remaining session cookies (they tend to live on after expiring as "session cookies")
        this.clearAuthenticationData();
    }
}
