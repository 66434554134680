/* global monstecLib */
/* TODO refactor usage of global variables
 * This class uses global variables defined in chat.html. It can be hard to find errors when using this kind of
 * "design". Instead the respective values / objects should be given by constructor arguments or via set-methods.
 */
import i18next from 'i18next';

export default class UserChat {
    /**
     * Constructor
     *
     * @param {number} mode Defines whether the pool (0), a specific group (1) or a specific expert (2) will be requested.
     *                      Defaults to 0, illegal values will be treated as 0.
     * @param {number} targetId defines the group or expert depending on the mode
     * @param {*} externalChatClient object that handles chats
     * @param {*} externalChatSupport object that is a bridge between the chat client and the view / page
     * @param {*} externalAuthenticator object that handles authentication with the services
     */
    constructor(mode, targetId, customerId, externalChatClient, externalChatSupport, externalAuthenticator, externalIdentityService, externalCookie,
                externalUtils, externalProfileHandler, externalProductHandler, externalWsClient) {
                    this.mode = (mode >= 0 && mode <= 2) ? mode : 0;
                    this.targetId = targetId;
                    this.chatclient = externalChatClient;
                    this.customerId = customerId;
                    this.chatSupport = externalChatSupport;
                    this.authenticator = externalAuthenticator;
                    this.identityService = externalIdentityService;
                    this.cookie = externalCookie;
                    this.utils = externalUtils;
                    this.profiledatahandler = externalProfileHandler;
                    this.productHandler = externalProductHandler;
                    this.wsClient = externalWsClient;
                    var instance = this;

                    this.log = new monstecLib.Log(1);

                    this.chatclient.addReconnectAction(async function() {
                        let chatId = $('.cl').attr('data-chatid');

                        if (!chatId || chatId < 0) {
                            // Do not reconnect undefined or canceled chats. The cl-container of canceled chats gets a data-chatid-attribute-value
                            // of -1, see click handler on '.leave_chat, #chatClosingBtn' in indexpage.js/publicchats.js.
                            instance.log.debug(`Encountered chatId ${chatId} in reconnect action. No chat will be reconnected for the following container:`, $('.cl'));
                            return;
                        }

                        instance.chatSupport.getMessages(chatId);
                        
                        try {
                            let isCommissionable = await instance.chatSupport.checkChatCommissionability(chatId);
                            if (isCommissionable) {
                                instance.chatSupport.storeMarker(chatId);
                            }
                        } catch(e) {
                            console.error("Chat status could not be checked.", e);
                        }
                    });

                    // trigger request for chat
                    function submitChatRequest (e){
                        $('#searchInput').addClass('validate');
                        M.updateTextFields();

                        var validInput = $('#searchInput')[0].checkValidity();

                        if(validInput) {
                            $(document).trigger("loader:on");
                            $(e).attr("disabled", "disabled");
                            $('body#user').removeClass('iOs');

                            var inputValue = $('#searchInput').val();

                            if ($.trim(inputValue).length > 0) {
                                var promise = instance.chatclient.requestChat(inputValue, instance.mode, instance.targetId, instance.customerId);

                                promise.then(function() {
                                    //animate background slogan
                                    $('.intro-wrapper ').css({"z-index": "101"});
                                    $('.intro__subtitle, .intro__description').fadeTo(4000, 0);
                                    $(document).trigger("loader:off");

                                    setTimeout(() => {
                                        $('.intro-wrapper ').hide(); // produck branding is smoothly diminished
                                    }, 4000);

                                    instance.showChat();
                                }).catch(function(error) {
                                    $(document).trigger("loader:off");

                                    console.log('ERROR - Could not request chat', error);
                                    if (error == 'request_to_self') {
                                        let textBody = i18next.t("text.request_to_self");
                                        instance.utils.createSimpleAlertModal(textBody);
                                        // The promise may get rejected however the request itself has been successful. This may happen if no
                                        // expert is online or a specifically chosen expert has configured an away mode that declines incoming
                                        // requests
                                    } else if (error.status !== 204 && error.status !== 200) {
                                        // If there is a "real" error, reload the page.
                                        instance.log.error('Chat registration process or authentication failed.');
                                        let textBody = i18next.t("text.request_process_err");
                                        instance.utils.createSimpleAlertModal(textBody).then(() => {
                                            window.location.href = '/chat.html' + ((customerId) ? ('?cid=' + customerId) : '');
                                        });
                                    }
                                });
                            }
                        } else {
                            $('#searchInput').addClass('invalid');
                        }
                    }

                    // prevent page reload through form submit
                    $('#searchForm').submit(function(e){
                        e.preventDefault();
                    });

                    // prevent titles in input field to show
                    $('#searchInput').hover((e) => {
                        var searchInput = $(e.target);
                        var title = searchInput.attr('title');
                        title ? searchInput.data("input_title", title).attr('title', '') : ''; 
                    }, (e) => {
                        var searchInput = $(e.target);
                        var orgTitle = searchInput.data('input_title');
                        orgTitle ? searchInput.attr('title', orgTitle) : searchInput.data('org_title', '');
                    });
                
                    // submit request on click
                    $("#requestButton").on('click', e => {
                        submitChatRequest(e.currentTarget);                        
                    });

                    // submit request on keypress return
                    $('#searchForm').keypress(function(e) {
                        if (e.keyCode === 13) {
                            submitChatRequest($('#requestButton'));
                        }
                    });
                    $('.leave_chat, #chatClosingBtn').on('click', function() {
                        console.log('CLICKHANDLER 2', ev);
                    });

                    // handles all transformations on active side when closing chat
                    $(document).on('click', '.leave_chat, #chatClosingBtn', function(ev) {

                        console.log('CLICKHANDLER 3', ev);


                        var chatClosingButton = this;
                        var cl = $(chatClosingButton).parents('.cl');
                        // The JQuery data-function can be used to retrieve data-* attributes
                        var chatId = cl.data('chatid'); // has to be saved, since it will be set to -1 later on

                        instance.chatclient.wsStopChat(chatId);
                        cl.data('chatid', '-1').attr('data-chatid', '-1'); // -1 cancels the connection between to chatpartners,
                        // this does change the data-chatid (node element) and the data-object

                        let signal = $('#chat-online-signal' + chatId + ' .inner-circle');
                        signal.removeClass('signal-online').attr("title", i18next.t('text.closed'));

                        if ($('body#user').length > 0) {
                            instance.showClosingScreen();
                        }
                    });

                    // start a new chat >> meaning page reload
                    $(document).on('click', '.refresh_chat', function() {
                        var params = '';

                        if (instance.mode == 1) {
                            params = '?cid=' + instance.targetId + '&gid=' + instance.targetId;
                        } else if (instance.mode == 2) {
                            params = '?cid=' + instance.targetId;
                        }

                        window.location.href = 'chat.html' + params;
                    });

                    $(document).on('click', '#xpert-profile-desk, #xpert-profile-mobile', function() {
                        instance.displayXpertProfile();
                    });

                    $(document).click(function(ev){
                        var serviceProclaimer = $('#service-proclaimer-container');
                        var serviceProclaimerTrigger = $('#service-proclaimer-container .open-triangle');
                        var serviceProclaimerCloser = $('#service-proclaimer-container .close-window');

                        if (serviceProclaimerTrigger.is(ev.target)) {
                            serviceProclaimer.toggleClass('open');
                            $('#landingPage').css({"filter": "blur(2px)"});
                        }
                        else if(!serviceProclaimer.is(ev.target) && serviceProclaimer.has(ev.target).length === 0) {
                            serviceProclaimer.removeClass('open');
                            $('#landingPage').css({"filter": ""});
                        }
                    });

                    $('#service-proclaimer-container .close-window').click(function() {
                        var serviceProclaimer = $('#service-proclaimer-container');
                        serviceProclaimer.removeClass('open');
                        $('#landingPage').css({"filter": ""});
                    });
                }

    async showChat() {
        var instance = this;
        // hide search field section
        $('#landingPage, #service-proclaimer-container, #language-setter').addClass('inactive');     

        // show chat
        $('.main-container').addClass('active');
        $('div.trigger-row').addClass('chat--active');
        instance.chatSupport.chatSettings();
        //instance.chatSupport.scrollBarChatlog();

        let chatLog = $('.cl').last();
        let chatId = chatLog.attr('data-chatid');
        let chatFooter = chatLog.find('.chat_wrapper .chat_footer');
        chatFooter.children('.attachBtn').attr('id', 'chatAttachButton' + chatId);
        chatFooter.find('input[type=file]').attr('id', 'chatAttachInput' + chatId);

        let chatFileUpload = new monstecLib.ChatFileUpload(chatId, 
            'chatAttachInput' + chatId, 'chatAttachButton' + chatId, instance.chatSupport);

        /* setTimeout(() => {
             $("#chatTextarea").focus();
             // focus on editor
         }, 250);*/
    }

    async uChatReady() {
        var instance = this;
        var target = window.location.href.substr(-5);
        var thumbnav2 = $('.thumb-nav__item.container-2');
        var container2 = $('.container.theme-2');
        var uchatBody = $('document.body');

        const productOfferContainer = new monstecLib.ProductOfferContainer();
        productOfferContainer.attachTo('customer-chat-offer-container');
        monstecLib.pageComponentRegistry.register('customer-chat-offer-container', productOfferContainer);

        var support = { animations: Modernizr.cssanimations },
            animEndEventNames = { 'WebkitAnimation': 'webkitAnimationEnd', 'OAnimation': 'oAnimationEnd', 'msAnimation': 'MSAnimationEnd', 'animation': 'animationend' },
            animEndEventName = animEndEventNames[Modernizr.prefixed('animation')],
            onEndAnimation = function (el, callback) {
                var onEndCallbackFn = function (ev) {
                    if (support.animations) {
                        if (ev.target !== this) return;
                        this.removeEventListener(animEndEventName, onEndCallbackFn);
                    }
                    if (callback && typeof callback === 'function') { callback.call(); }
                };
                if (support.animations) {
                    el.addEventListener(animEndEventName, onEndCallbackFn);
                }
                else {
                    onEndCallbackFn();
                }
            };

        var containers = [].slice.call(document.querySelectorAll('.container')),
            containersCount = containers.length,
            nav = document.querySelector('nav'),
            pageTriggers = [].slice.call(nav.children),
            isAnimating = false, current = 0;


        function init() {
            resetScroll();
            // disable scrolling
            window.addEventListener('scroll', noscroll);
            // set current page trigger
            classie.add(pageTriggers[current], 'thumb-nav__item--current');
            // set current container
            classie.add(containers[current], 'container--current');
            // initialize events
            initEvents();
        }


        function initEvents() {
            // slideshow navigation
            pageTriggers.forEach(function (pageTrigger) {
                pageTrigger.addEventListener('click', function (ev) {

                    if (!$('.thumb-nav > .container-2').hasClass('xpert') && !$('.thumb-nav > .container-2').hasClass('customer')) {
                        ev.preventDefault();
                    }
                    navigate(this);
                });
            });

            //loads products into container
            $(document).one('click', 'button.cartBtn', function () {
                productOfferContainer.initProdContainer();
            });

            // open each container's content area when clicking on the respective trigger button
            containers.forEach(function (container, index, arr) {

                $(document).on('click', 'button#button-three', function () {
                    toggleContent(arr[2], this);
                });

                $(document).on('click', 'button.cartBtn', function () {
                    toggleContent(arr[0], this);
                });
            });


            // keyboard navigation events
            document.addEventListener('keydown', function (ev) {
                var keyCode = ev.keyCode || ev.which,
                    isContainerOpen = containers[current].getAttribute('data-open') === 'open',
                    editorFocus = $('#searchInput, .chatTextarea, #email-login, #password-login').is(':focus');

                switch (keyCode) {
                    // left key
                    case 37:
                        if (current > 0 && !isContainerOpen && !editorFocus) {
                            navigate(pageTriggers[current - 1]);
                        }
                        break;
                        // right key
                    case 39:
                        if (current < containersCount - 1 && !isContainerOpen && !editorFocus) {
                            navigate(pageTriggers[current + 1]);
                        }
                        break;
                }
            });

            // touch navigation events
            /*  document.addEventListener('touchstart', function(ev) {
                  console.log('swipe');
    
                  var touchsurface = containers[current],
                  startX,
                  startY,
                  dist,
                  threshold = 150, //required min distance traveled to be considered swipe
                  allowedTime = 200, // maximum time allowed to travel that distance
                  elapsedTime,
                  startTime,
                  isContainerOpen = containers[current].getAttribute('data-open') === 'open'
    
                  function handleswipe(isrightswipe){
                      if (isrightswipe) {
                          console.log("right");
                          if (current > 0 && !isContainerOpen) {
                              navigate(pageTriggers[current - 1]);
                          }
                      }
                  }
                  function handleswipeleft(isleftswipe){
                      console.log('left');
                      if (current < containersCount - 1 && !isContainerOpen) {
                          navigate(pageTriggers[current + 1]);
                      }
                  }
    
    
                  touchsurface.addEventListener('touchstart', function(e){
                      var touchobj = e.changedTouches[0]
                      dist = 0
                      startX = touchobj.pageX
                      startY = touchobj.pageY
                      startTime = new Date().getTime() // record time when finger first makes contact with surface
                      e.preventDefault()
                  }, false)
    
                  touchsurface.addEventListener('touchmove', function(e){
                      e.preventDefault() // prevent scrolling when inside DIV
                  }, false)
    
                  touchsurface.addEventListener('touchend', function(e){
                      var touchobj = e.changedTouches[0]
                      dist = touchobj.pageX - startX // get total dist traveled by finger while in contact with surface
                      elapsedTime = new Date().getTime() - startTime // get time elapsed
                      // check that elapsed time is within specified, horizontal dist traveled >= threshold, and vertical dist traveled <= 100
                      var swiperightBol = (elapsedTime <= allowedTime && dist >= threshold && Math.abs(touchobj.pageY - startY) <= 100)
                      handleswipe(swiperightBol)
    
                      var swipeleftBol = (elapsedTime <= allowedTime && dist >= threshold && Math.abs(touchobj.pageY - startY) <= 100)
                      handleswipeleft(swipeleftBol)
    
                      e.preventDefault()
                  }, false)
    
              }, false); */

            //generating deeplink for login and aboutus page
            if(target === 'login'){
                pageTriggers[1].click(); //switch to login
                setTimeout(function(){
                    $("label[for='email-login'], label[for='password-login']").addClass('active');
                    $('#email-login').focus();
                }, 0); //trigger label slide out of input
            }
            else if(target === '#info'){
                pageTriggers[2].click();
            }

            // submit name on keypress return
            $(document).on('keypress', '.user-conversation-name', (e) => {

                if (e.keyCode === 13) {
                    $('#sendButtonInChat').click();
                }
            });
        }
        
        function fadeSlogan() {
            var fadeout = function() {
                $('.intro__subtitle, .intro__description').fadeTo(1000, 0);                
            };

            var fadein = function () {
                $('.intro__subtitle, .intro__description').fadeTo(1000, 1);
            };

            var isActive1 = $('#container-2').hasClass('container--animInRight');
            var isActive2 = $('#container-2').hasClass('container--animInLeft');

            if (isActive1 || isActive2){
                fadeout();
            } else if (!isActive1 && !isActive2){
                fadein();
            }
        }

        function navigate(pageTrigger) {
            var oldcurrent = current,
                newcurrent = pageTriggers.indexOf(pageTrigger);

            if (isAnimating || oldcurrent === newcurrent) return;
            isAnimating = true;

            // reset scroll
            allowScroll();
            resetScroll();
            preventScroll();

            var currentPageTrigger = pageTriggers[current],
                nextContainer = document.getElementById(pageTrigger.getAttribute('data-container')),
                currentContainer = containers[current],
                dir = newcurrent > oldcurrent ? 'left' : 'right';

            classie.remove(currentPageTrigger, 'thumb-nav__item--current');
            classie.add(pageTrigger, 'thumb-nav__item--current');

            // update current
            current = newcurrent;

            // add animation classes
            classie.add(nextContainer, dir === 'left' ? 'container--animInRight' : 'container--animInLeft');
            classie.add(currentContainer, dir === 'left' ? 'container--animOutLeft' : 'container--animOutRight');
            fadeSlogan();

            onEndAnimation(currentContainer, function () {
                // clear animation classes
                classie.remove(currentContainer, dir === 'left' ? 'container--animOutLeft' : 'container--animOutRight');
                classie.remove(nextContainer, dir === 'left' ? 'container--animInRight' : 'container--animInLeft');

                // clear current class / set current class
                classie.remove(currentContainer, 'container--current');
                classie.add(nextContainer, 'container--current');

                isAnimating = false;
            });

            ////activation of logout btn and filling of profile when container2 active
            //var initUserProfile = async function(){
            //    var containerTwoActive1 = $('#container-2').hasClass('container--animInRight');
            //    var containerTwoActive2 = $('#container-2').hasClass('container--animInLeft');
            //    var statusLoggedIn = await instance.cookie.getRightStorage('sess_au');
            //    var userData = await instance.cookie.getRightStorage('produck');

            //    if (!!statusLoggedIn && $('#user').length && userData.userRole === "CUSTOMER" && (containerTwoActive1 || containerTwoActive2)){
            //        $('thumb-nav__item.container-2').attr('href', 'user.html')
            //        $('.resetbox').remove();
            //    }
            //};

            //initUserProfile();
        }

        // show hidden content section
        function toggleContent(container, trigger) {
            if (classie.has(container, 'container--open')) {
                classie.remove(container, 'container--open');
                classie.remove(trigger, 'trigger--active');
                classie.remove(nav, 'thumb-nav--hide');
                container.setAttribute('data-open', '');
                preventScroll();
            }
            else {
                classie.add(container, 'container--open');
                classie.add(trigger, 'trigger--active');
                classie.add(nav, 'thumb-nav--hide');
                container.setAttribute('data-open', 'open');
                allowScroll();
            }
        }

        // scroll functions
        function resetScroll() { document.body.scrollTop = document.documentElement.scrollTop = 0; }
        function preventScroll() {
            window.addEventListener('scroll', noscroll);
            $('.body').removeClass('scroll-active')
        }

        function allowScroll() {
            window.removeEventListener('scroll', noscroll);
            $('.body').addClass('scroll-active');
        }

        function noscroll() {
            window.scrollTo(0, 0);
        }

        init();

        // Hide chat on load if user is not authenticated, otherwise check if there is an active chat for the
        // user and load it into the chat section.
        if (isAuthenticated) { // defined in chat.html
            instance.chatclient.reconnectChats(0).then(function(response) {
                if (response && response.length > 0) {
                    // Saving the chatId to the associated container in the DOM
                    $('.cl').attr('data-chatid', response[0].id).attr('data-chatpartner', response[0].expertId).attr('data-providerId', response[0].providerId);
                    $('.cl').find('.chat-online-signal').attr('id', 'chat-online-signal' + response[0].id);
                    instance.chatclient.chatId = response[0].id;
                    instance.chatclient.nomChatId = response[0].id;

                    instance.identityService.getPublicUserData(response[0].expertId).then((userData) => {
                        instance.cookie.updateChatHeader(userData);
                    });

                    instance.authenticator.getUser().then(function(userId) {
                        instance.chatclient.setUserId(userId);
                    });

                    instance.showChat();
                    instance.chatSupport.getMessages(response[0].id);

                    if (response[0].commissionable) {
                        // aggressive strategy to make sure that a marker cookie is set, when it should
                        instance.chatSupport.storeMarker(response[0].id);
                    }

                } else {
                    throw "no valid response from server";
                }
            }).catch(function() {
                // $('#target2').hide(); // hide chat window
                $('#searchInput').focus(); // focus on search field
            });
        } else {
            // $('#target2').hide(); // hide chat window
            $('#searchInput').focus(); // focus on search field
        }

        function initShowSharingSection() {
            $(document).on('click', '#sharingBtn', function () {
                instance.showClosingScreen();
            });

            $(document).on('click', '.close_window > a', function () {
                $(this).closest('.chatlog.active').removeClass('active');
                $('#chatlog').show();
            });
        }

        initShowSharingSection();
        instance.setLanguage();
    }

    /**
     * Function that reacts on 'ice'-websocket messages; it is intended for the use with the configuration object 
     * of the chat-client.
     * 
     * @param {*} chatId will be given by the webocket-message-payload but is not used here
     * @param {*} userId the technical identifier of the user that has left the chat
     */
    async onChatEndedByChatPartner(chatId, userId) {
        var instance = this;

        let currentUser;
        try {
            currentUser = await instance.authenticator.getUser();
        } catch (e) {
            currentUser = -1;
        }

        var partnerName = $('.cl.active .userside').text();
        var textBody = (currentUser != userId) ? partnerName + i18next.t("modals.chat_left") : i18next.t("modals.chat_left_sibling_session");
        instance.utils.createSimpleAlertModal(textBody);
        instance.showClosingScreen();
    }

    showClosingScreen() {
        $('#chat_closing').addClass('active');
        $('#chatlog').hide();
        $(".progress-notification").remove();
        $('.leave_chat').addClass('refresh_chat').attr('title', 'Neuer Chat');
        $('.leave_chat > i').html('refresh');
        $('.refresh_chat').removeClass('leave_chat');
    }

    setLanguage() {
        var instance = this;

        //switch language
        $(document).on('click', '.lng-de', function () {

            i18next.changeLanguage("de", function(err, t) {$("body").localize();});
            instance.cookie.saveToRightStorage('i18next', i18next.language, 7300);
        });

        $(document).on('click', '.lng-en', function () {

            i18next.changeLanguage("en", function(err, t) {$("body").localize();});
            instance.cookie.saveToRightStorage('i18next', i18next.language, 7300);
        });
    }

    // displays chat partner online status on user site
    initWsHandler() {
        var instance = this;
        instance.wsClient.onClose = function () {
            $('.chat-online-signal[id] .inner-circle').each(function(index, signal) {
                $(signal).removeClass('signal-online');
                $(signal).addClass('signal-offline').attr("title", "offline");;
            });
        };
    }
}
