/* global monstecLib */
/* global M */

import i18next from "../localisation.js";

/**
 * Section that enables users to create and view articles.
 *
 * When using this class it is mandatory to include the template "article.html" in the element
 * the instance of this class will be attached to.
 */
export default class ArticleSection {
    /**
     * Creates a new instance of this section.
     *
     * @param {object} syncPermissioChecker a commonly used synchronous permission checker
     */
    constructor(syncPermissioChecker) {
        this.authChecker = syncPermissioChecker;

        this.i18next = monstecLib.i18next;
        this.utils = monstecLib.produckContext.utils;
        this.chatService = monstecLib.produckContext.chatService;
        this.identityService = monstecLib.produckContext.identityService;
        this.chatSupport = monstecLib.produckContext.chatSupport;

        this.log = new monstecLib.Log(1);
    }

    /**
     * Attaches the current instance to a specific element and sets all necessary handlers on sub-elements.
     * This function should not do heavy work. Put data initialisation code in the function 'initialise' and
     * call it when the user actually gets to see the section the first time.
     * Best just do the work in 'attachTo' that is absolutely necessary to make the component reachable to
     * the user.
     *
     * @param {string} htmlId identifies the container element that will form the question section
     */
    attachTo(htmlId) {
        const instance = this;
        instance.log.debug(
            "Attaching article section to element having the ID " + htmlId
        );

        instance.htmlId = htmlId;
        instance.container = $("#" + htmlId);

        let tabs = $("#articleSectionNavigation");
        instance.articleSectionNavigation = M.Tabs.init(tabs)[0];
        monstecLib.pageComponentRegistry.register(
            "article-section-navigation",
            instance.articleSectionNavigation
        );
        instance.chatSupport.scrollBarTabs(tabs[0]);

        instance._createMyArticlesSubSection();
        instance._createSuggestedArticlesSubSection();
        instance._createMyArticleRequestsSubSection();
        instance._createAllArticleRequestsSubSection();
        instance._createArticleTemplatesSubSection();
    }

    /**
     * Makes the component ready to be viewed by the user.
     *
     * @param {boolean} fetchMyArticlesOnInit Defaults to true; define whether the list
     *    of "my articles" will be fetched on initialisation. This is generally useful when
     *    showing the section so that it will be filled with content immediately. However there a
     *    cases when the myArticles-subsection is not the one that is shown initially. For example
     *    when the page is reloaded an the user has been on another tab, which is going to be shown
     *    after the reload. Then of course "my articles" do not have to be loaded. Furthermore
     *    if a mechanism is used to directly access a certain subsection and that one is the
     *    myArticles-subsection it might happen, that the article-list will be fetched twice
     *    depending on how the subsection is accessed. Then this parameter can also be used to
     *    prevent that.
     */
    initialise(fetchMyArticlesOnInit = true) {
        const instance = this;

        if (!instance.initialised) {
            instance._initArticleFilter(instance.myArticlesList);
            instance._initMyArticleRequestsFilter(
                instance.myArticleRequestsList
            );
            instance._initAllArticleRequestsFilter(
                instance.allArticleRequestsList
            );
            instance._initArticleSuggestionFilter(
                instance.suggestedArticlesList
            );

            if (fetchMyArticlesOnInit) {
                instance.myArticlesList.updateView();
            }

            instance.initialised = true;
        }
    }

    /*
     * Creats a sub-section containing a list of the current user's own articles including a filter and
     * controls for publishing an article.
     */
    _createMyArticlesSubSection() {
        const instance = this;

        $("#saveBulkArticlePublicationMode").on("click", function (ev) {
            var publicationMode = $("#articlePublicationModeSelect").val();
            var checkedArticles = $(".js-item-state-input:checkbox:checked");

            if (checkedArticles.length === 0 && publicationMode !== null) {
                instance.utils.createSimpleAlertModal(
                    instance.i18next.t("articlesection.select_article")
                );
                return;
            } else if (publicationMode === null && checkedArticles.length > 0) {
                instance.utils.createSimpleAlertModal(
                    instance.i18next.t("articlesection.select_action")
                );
                return;
            } else if (
                publicationMode === null &&
                checkedArticles.length === 0
            ) {
                instance.utils.createSimpleAlertModal(
                    instance.i18next.t(
                        "articlesection.select_article_and_action"
                    )
                );
                return;
            } else {
                let articlePromises = [];
                let promise;
                let toast;

                if (publicationMode === "PUBLISHED") {
                    checkedArticles.each(function () {
                        var articleId = $(this)
                            .closest(".collapsible-header")
                            .data("article-id");
                        promise =
                            instance.chatService.publishArticle(articleId);
                        toast = {
                            html: instance.i18next.t(
                                "articlesection.article_published"
                            ),
                        };
                        articlePromises.push(promise);
                    });

                    resolvePromiseAll();
                } else if (publicationMode === "EXTERNAL") {
                    function getArticleMap() {
                        let articleMap = {};
                        checkedArticles.each(function () {
                            var articleId = $(this)
                                .closest(".collapsible-header")
                                .data("article-id");
                            var articleTitle = $(
                                "#myArticlesListArticleItemHeader" +
                                    articleId +
                                    "Title"
                            ).text();

                            let article = {
                                id: articleId,
                                title: articleTitle,
                            };

                            articleMap[articleId] = article;
                        });
                        return articleMap;
                    }

                    function createDomainSelectionModal(tokenMap, itemMap) {
                        let optionOne = i18next.t("general.save"),
                            optionTwo = i18next.t("general.cancel");

                        let selectDropdown = "";

                        let selected =
                            tokenMap.length == 0 ? 'selected="selected"' : "";

                        tokenMap.forEach((item) => {
                            selectDropdown +=
                                '<option value="' +
                                item.domain +
                                '"' +
                                selected +
                                ">" +
                                item.domain +
                                "</option>";
                        });

                        let articleSelectModule = "";
                        Object.entries(itemMap).forEach(
                            ([key, item], index) => {
                                articleSelectModule +=
                                    "<p>" +
                                    i18next.t(
                                        "articlesection.choose_target_site_for",
                                        { title: item.title }
                                    ) +
                                    "</p>" +
                                    '<div class="input-field domain-select-wrapper" data-article-id="' +
                                    item.id +
                                    '">' +
                                    '<select id="selectDomain_' +
                                    index +
                                    '">' +
                                    selectDropdown +
                                    "</select>" +
                                    "</div>";
                            }
                        );

                        const modal =
                            '<div id="domainSelectionModal" class="modal dynamic-modal">' +
                            '<div class="modal-content">' +
                            articleSelectModule +
                            "</div>" +
                            '  <div class="modal-footer">' +
                            '    <a class="option-one modal-close waves-effect waves-teal btn-flat" tabindex="1">' +
                            optionOne +
                            "</a>" +
                            '    <a class="option-two modal-close waves-effect waves-teal btn-flat" tabindex="2">' +
                            optionTwo +
                            "</a>" +
                            "  </div>" +
                            "</div>";

                        $("body").append(modal);

                        var elems = $("#domainSelectionModal").find("select");
                        M.FormSelect.init(elems);

                        $("#domainSelectionModal").modal({
                            dismissible: false,
                            onCloseEnd: instance.utils._removeUnusedModalLayers,
                        });

                        $("#domainSelectionModal").modal("open");
                    }

                    //get all tokens and domains for user
                    instance.chatService
                        .getQuackTokensAndDomains()
                        .then(function (tokenMap) {
                            let articleObj = getArticleMap();
                            console.log(
                                "Fetching token successful: ",
                                tokenMap
                            );
                            // just open select modal, if multiple domains available
                            createDomainSelectionModal(tokenMap, articleObj);
                            articlePromisesInit(articleObj);
                        })
                        .catch(function (error) {
                            console.log("Quack token service error: ", error);
                            instance.utils.createSimpleAlertModal(
                                i18next.t("text.no_domain")
                            );
                        });

                    function collectDomainValues(articleObj) {
                        $("#domainSelectionModal")
                            .find(".input-field")
                            .each(function () {
                                let article_id = $(this).data("article-id");
                                let domain = $(this).find("select").val();
                                articleObj[article_id].domain = domain;
                            });
                        return articleObj;
                    }

                    function articlePromisesInit(articleMap) {
                        let submitBtn = $("#domainSelectionModal").find(
                            ".option-one"
                        );
                        let cancelBtn = $("#domainSelectionModal").find(
                            ".option-two"
                        );

                        submitBtn.on("click", () => {
                            let articleObj = collectDomainValues(articleMap);
                            toast = {
                                html: instance.i18next.t(
                                    "articlesection.article_outsourced"
                                ),
                            };

                            Object.values(articleObj).forEach((item) => {
                                promise = instance.chatService.outsourceArticle(
                                    item.id,
                                    item.domain
                                );
                                articlePromises.push(promise);
                            });
                            resolvePromiseAll();
                            $("#domainSelectionModal").remove();
                        });
                        cancelBtn.on("click", () => {
                            $("#domainSelectionModal").remove();
                        });
                    }
                } else {
                    checkedArticles.each(function () {
                        var articleId = $(this)
                            .closest(".collapsible-header")
                            .data("article-id");
                        promise =
                            instance.chatService.unpublishArticle(articleId);
                        toast = {
                            html: instance.i18next.t(
                                "articlesection.article_unpublished"
                            ),
                        };
                    });

                    submitPromise(promise);
                }

                function resolvePromiseAll() {
                    $(document).trigger("loader:on");
                    
                    Promise.all(articlePromises)
                        .then(function () {
                            $(document).trigger("loader:off");
                            M.toast(toast);
                            instance.myArticlesList.updateView();
                            return "success";
                        })
                        .catch(function (reason) {
                            $(document).trigger("loader:off");                            
                            
                            if (reason.status === 400) {
                                let errorMessage = '';

                                if (reason.responseText && reason.responseText.includes('article too short')) {
                                    errorMessage += "On or more articles in your selection are too short and do not meet our quality standards. Please add more content to reach at least 200 words.\n<br/>";
                                }
                                
                                if (reason.responseText && reason.responseText.includes('article unformatted')) {
                                    errorMessage += "One or more articles in your selection lack sufficient formatting and do not meet our quality standards. Please enhance your content by adding HTML tags to create a better experience for your readers.\n</br> Tip: You can also ask ChatGPT to style your content by using the <a href=\"/docu/styleguide.html\" target=\"_blank\">ProDuck Style Guide</a>";
                                }
                                
                                if (!errorMessage) {
                                    errorMessage = "Invalid article submission. Please review your content.";
                                }
                                
                                instance.utils.createSimpleAlertModal(errorMessage);
                            } else {
                                instance.utils.createSimpleAlertModal("An error occurred while submitting your article. Please try again. In case the error persists, do not hesitate to contact us via the <a target=\"_blank\" href=\"/contact.html\">contact form</a>.");
                            }
                            
                            M.toast({
                                html: instance.i18next.t("toasts.error"),
                            });
                            instance.log.error(
                                "update failed because ",
                                reason
                            );                            

                            return "error";
                        });
                }
            }
        });

        var quackModeSelect = $("#quack-target-select");
        M.FormSelect.init(quackModeSelect);

        let myArticlesList = new monstecLib.ArticleList(
            instance.authChecker.getUser(),
            false
        );
        instance.myArticlesList = myArticlesList;
        myArticlesList.attachTo("myArticlesList");

        let myArticlesListDataProvider = async function (filter) {
            try {
                filter.user = instance.authChecker.getUser();
                let response = await instance.chatService.getArticles(filter);
                return response;
            } catch (error) {
                if (error.status == 404) {
                    // This would show on page load when there are no articles. Since there is no
                    // connection to any event clear to the user, the notification has been commented
                    // out. It can be reactivated when there is a proper solution, for example
                    // triggering the first load of articles when the user clicks the article-nav-item
                    //M.toast({html: instance.i18next.t('toasts.no_data_found')});
                    return {};
                } else {
                    instance.log.error(
                        "Could not retrieve list of articles.",
                        error
                    );
                    M.toast({ html: instance.i18next.t("toasts.error") });
                    return {};
                }
            }
        };

        myArticlesList.setDataProvider(myArticlesListDataProvider);
        $("#myArticlesNavItem").on("click", function () {
            myArticlesList.updateView();
        });

        instance.articleForm = new monstecLib.ArticleForm();
        instance.articleForm.attachTo("newArticleFormWrapper");

        instance.articleForm.sendAction = async function (article) {
            try {
                let response = await instance.chatService.createArticle(
                    article
                );
                return response;
            } catch (error) {
                if (error.status != 400) {
                    instance.log.error(
                        "Error wehen sending article to service: ",
                        error
                    );
                } else {
                    return error;
                }
            }
        };

        instance.articleForm.onSendingSuccessful = function () {
            instance.articleForm.close(true);
            myArticlesList.updateView();
        };

        $("#createArticleButton").on("click", () => {
            instance.articleForm.open(true);
        });
    }

    /*
     * Creates a sub-section containing a list of article suggestions made by authors to the
     * current user including a filter.
     */
    _createSuggestedArticlesSubSection() {
        const instance = this;

        if (
            instance.authChecker.checkPermission(
                instance.authChecker.permCat.RECEIVE_ARTICLE_SUGGESTION
            ) ||
            instance.authChecker.checkPermission(
                instance.authChecker.permCat.RECEIVE_ARTICLE_SUBMISSION
            )
        ) {
            let suggestedArticlesList = new monstecLib.ArticleSuggestionList(
                instance.authChecker.getUser(),
                false
            );
            instance.suggestedArticlesList = suggestedArticlesList;
            suggestedArticlesList.attachTo("suggestedArticlesList");

            let suggestedArticlesListDataProvider = async function (filter) {
                try {
                    filter.user = instance.authChecker.getUser();
                    let response =
                        await instance.chatService.getSuggestedArticles(filter);
                        if (response.numElements > 0) {

                            const userIdArray = response.result.map(
                                (user) => user.userId
                            );
                            
                            response["usersData"] =
                            await instance.identityService.getUsersData(
                                userIdArray
                            );
                            
                            instance.log.debug(
                                "Final Articles Response with UsersData: ",
                                userIdArray,
                                response
                            );
                        } else {
                            instance.log.debug(
                                "No articles suggestions"
                            );
                        }

                    return response;
                } catch (error) {
                    if (error.status == 404) {
                        // This would show on page load when there are no articles. Since there is no
                        // connection to any event clear to the user, the notification has been commented
                        // out. It can be reactivated when there is a proper solution, for example
                        // triggering the first load of articles when the user clicks the article-nav-item
                        //M.toast({html: instance.i18next.t('toasts.no_data_found')});
                        return {};
                    } else {
                        instance.log.error(
                            "Could not retrieve list of suggested articles.",
                            error
                        );
                        M.toast({ html: instance.i18next.t("toasts.error") });
                        return {};
                    }
                }
            };

            suggestedArticlesList.setDataProvider(
                suggestedArticlesListDataProvider
            );
            $("#suggestedArticlesNavItem").on("click", function () {
                suggestedArticlesList.updateView();
            });
        } else {
            $("#suggestedArticlesList").hide();
            $("#suggestedArticlesNavItem").hide();
        }
    }

    /*
     * Creates a sub-section for the article requests of the current user to verfified authors.
     */
    _createMyArticleRequestsSubSection() {
        const instance = this;

        if (
            instance.authChecker.checkPermission(
                instance.authChecker.permCat.CREATE_ARTICLE_REQUEST
            )
        ) {
            let myArticleRequestsList = new monstecLib.ArticleRequestList(
                instance.authChecker
            );
            instance.myArticleRequestsList = myArticleRequestsList;
            myArticleRequestsList.attachTo("myArticleRequestsList");

            let myArticleRequestsDataProvider = async function (filter) {
                try {
                    filter.user = instance.authChecker.getUser();
                    let response =
                        await instance.chatService.getOwnArticleRequests(
                            filter
                        );
                    return response;
                } catch (error) {
                    if (error.status == 404) {
                        return {};
                    } else {
                        instance.log.error(
                            "Could not retrieve list of article requests.",
                            error
                        );
                        M.toast({ html: instance.i18next.t("toasts.error") });
                        return {};
                    }
                }
            };

            myArticleRequestsList.setDataProvider(
                myArticleRequestsDataProvider
            );
            $("#myArticleRequestsNavItem").on("click", function () {
                myArticleRequestsList.updateView();
            });

            let articleRequestForm = new monstecLib.ArticleRequestForm();
            articleRequestForm.attachTo("articleRequestForm");

            articleRequestForm.sendAction = async function (request) {
                try {
                    let response =
                        await instance.chatService.createArticleRequest(
                            request
                        );
                    return response;
                } catch (error) {
                    if (error.status != 400) {
                        instance.log.error(
                            "Error when sending article request to service: ",
                            error
                        );
                    } else {
                        return error;
                    }
                }
            };

            articleRequestForm.onSendingSuccessful = function () {
                articleRequestForm.close(true);
                myArticleRequestsList.updateView();
            };

            $("#createArticleRequestButton").on("click", () => {
                articleRequestForm.open(true);
            });
        } else {
            $("#myArticleRequestsList").hide();
            $("#myArticleRequestsNavItem").hide();
        }
    }

    /*
     * Creates a subsection for verified authors to view the article requests of authorised users.
     */
    _createAllArticleRequestsSubSection() {
        const instance = this;

        if (
            instance.authChecker.checkPermission(
                instance.authChecker.permCat.ACCEPT_ARTICLE_REQUEST
            )
        ) {
            let allArticleRequestsList = new monstecLib.ArticleRequestList(
                instance.authChecker
            );
            instance.allArticleRequestsList = allArticleRequestsList;
            allArticleRequestsList.attachTo("allArticleRequestsList");

            let allArticleRequestsDataProvider = async function (filter) {
                try {
                    filter.user = instance.authChecker.getUser();
                    let response =
                        await instance.chatService.getAllArticleRequests(
                            filter
                        );
                    return response;
                } catch (error) {
                    if (error.status == 404) {
                        return {};
                    } else {
                        instance.log.error(
                            "Could not retrieve list of article requests.",
                            error
                        );
                        M.toast({ html: instance.i18next.t("toasts.error") });
                        return {};
                    }
                }
            };

            allArticleRequestsList.setDataProvider(
                allArticleRequestsDataProvider
            );
            $("#allArticleRequestsNavItem").on("click", function () {
                allArticleRequestsList.updateView();
            });
        } else {
            $("#allArticleRequestsList").hide();
            $("#allArticleRequestsNavItem").hide();
        }
    }

    /*
     * Creates a sub-section for viewing and editing a user's own article templates.
     */
    _createArticleTemplatesSubSection() {
        const instance = this;

        if (
            instance.authChecker.checkPermission(
                instance.authChecker.permCat.CREATE_ARTICLE_TEMPLATE
            )
        ) {
            let articleTemplateList = new monstecLib.ArticleTemplateList(
                instance.authChecker.getUser(),
                false
            );
            instance.articleTemplateList = articleTemplateList;
            articleTemplateList.attachTo("articleTemplatesList");

            let articleTemplatesListDataProvider = async function (filter) {
                try {
                    filter.user = instance.authChecker.getUser();
                    let response =
                        await instance.chatService.getArticleTemplates(filter);
                    return response;
                } catch (error) {
                    if (error.status == 404) {
                        // This would show on page load when there are no article templates. Since there is no
                        // connection to any event clear to the user, the notification has been commented
                        // out. It can be reactivated when there is a proper solution, for example
                        // triggering the first load of articles when the user clicks the article-nav-item
                        //M.toast({html: instance.i18next.t('toasts.no_data_found')});
                        return {};
                    } else {
                        instance.log.error(
                            "Could not retrieve list of article templates.",
                            error
                        );
                        M.toast({ html: instance.i18next.t("toasts.error") });
                        return {};
                    }
                }
            };

            articleTemplateList.setDataProvider(
                articleTemplatesListDataProvider
            );
            $("#articleTemplatesNavItem").on("click", function () {
                articleTemplateList.updateView();
            });

            let newTemplateModal = new monstecLib.ArticleTemplateModal(
                "createArticleTemplateModal"
            );
            newTemplateModal.initialise();

            newTemplateModal.onSuccess = function () {
                articleTemplateList.updateView();
            };

            $("#createArticleTemplateButton").on("click", function () {
                newTemplateModal.open();
            });
        } else {
            $("#articleTemplateList").hide();
            $("#articleTemplatesNavItem").hide();
        }
    }

    /**
     * Initialises the filter controls of the my articles subsection
     */
    _initArticleFilter(articleList) {
        const instance = this;
        let filterWrapper = instance.container.find("#articleFilterWrapper");

        //initialise button for showing / hiding the chat-filter
        $("#showArticleFilterButton").on("click", function () {
            const filterWrapper = $("#articleFilterWrapper");
            let symbol = $(this).find("i");
            if (symbol.text() == "keyboard_arrow_down") {
                symbol.text("keyboard_arrow_up");
                filterWrapper.slideDown("slow");
            } else {
                symbol.text("keyboard_arrow_down");
                filterWrapper.slideUp("slow");
            }
        });

        const articleFilterDateUntilInput = M.Datepicker.init(
            $("#articleFilterDateUntilInput"),
            instance.utils.getStandardDatePickerConfig()
        );

        const filterArticles = function () {
            let title = $("#articleFilterTitleInput").val();
            let date = articleFilterDateUntilInput[0].date;

            let filter = {
                page: 1,
            };

            if (title) filter.title = title;
            if (date) filter.date = date.toISOString();

            articleList.setFilter(filter);
            articleList.updateView();
        };

        const applyButton = filterWrapper.find(".apply-filter-button");
        applyButton.on("click", filterArticles);
        filterWrapper.on(
            "keypress",
            "#articleFilterTitleInput, #articleFilterDateUntilInput",
            (e) => {
                if (e.key == "Enter") applyButton.click();
            }
        );

        filterWrapper.find(".clear-filter-button").click(function () {
            filterWrapper.find("#articleFilterTitleInput").val("");
            filterWrapper.find("#articleFilterDateUntilInput").val("");
            articleFilterDateUntilInput[0].date = undefined;
            articleList.resetFilter();
            articleList.updateView();
        });
    }

    /**
     * Initialises the filter controls of the my article requests subsection
     */
    _initMyArticleRequestsFilter(articleRequestList) {
        const instance = this;
        const filterWrapper = instance.container.find(
            "#articleRequestFilterWrapper"
        );

        //initialise button for showing / hiding the chat-filter
        $("#showArticleRequestFilterButton").on("click", function () {
            let symbol = $(this).find("i");
            if (symbol.text() == "keyboard_arrow_down") {
                symbol.text("keyboard_arrow_up");
                filterWrapper.slideDown("slow");
            } else {
                symbol.text("keyboard_arrow_down");
                filterWrapper.slideUp("slow");
            }
        });

        const articleRequestFilterDateUntilInput = M.Datepicker.init(
            $("#articleRequestFilterDateUntilInput"),
            instance.utils.getStandardDatePickerConfig()
        );

        const filterArticleRequests = function () {
            let title = $("#articleRequestFilterTitleInput").val();
            let date = articleRequestFilterDateUntilInput[0].date;

            let filter = {
                page: 1,
            };

            if (title) filter.title = title;
            if (date) filter.date = date.toISOString();

            articleRequestList.setFilter(filter);
            articleRequestList.updateView();
        };

        const applyButton = filterWrapper.find(".apply-filter-button");
        applyButton.on("click", filterArticleRequests);
        filterWrapper.on(
            "keypress",
            "#articleRequestFilterTitleInput, #articleRequestFilterDateUntilInput",
            (e) => {
                if (e.key == "Enter") filterArticleRequests();
            }
        );

        filterWrapper.find(".clear-filter-button").on("click", function () {
            filterWrapper.find("#articleRequestFilterTitleInput").val("");
            filterWrapper.find("#articleRequestFilterDateUntilInput").val("");
            articleRequestFilterDateUntilInput[0].date = undefined;
            articleRequestList.resetFilter();
            articleRequestList.updateView();
        });
    }

    /**
     * Initialises the filter controls of the all article requests subsection
     */
    _initAllArticleRequestsFilter(articleRequestList) {
        const instance = this;
        const filterWrapper = instance.container.find(
            "#allArticleRequestFilterWrapper"
        );

        const articleRequestFilterDateUntilInput = M.Datepicker.init(
            $("#allArticleRequestFilterDateUntilInput"),
            instance.utils.getStandardDatePickerConfig()
        );

        const filterArticleRequests = function () {
            let title = $("#allArticleRequestFilterTitleInput").val();
            let date = articleRequestFilterDateUntilInput[0].date;

            let filter = {
                page: 1,
            };

            if (title) filter.title = title;
            if (date) filter.date = date.toISOString();

            articleRequestList.setFilter(filter);
            articleRequestList.updateView();
        };

        const applyButton = filterWrapper.find(".apply-filter-button");
        applyButton.on("click", filterArticleRequests);
        filterWrapper.on(
            "keypress",
            "#allArticleRequestFilterTitleInput, #allArticleRequestFilterDateUntilInput",
            (e) => {
                if (e.key == "Enter") filterArticleRequests();
            }
        );

        filterWrapper.find(".clear-filter-button").on("click", function () {
            filterWrapper.find("#allArticleRequestFilterTitleInput").val("");
            filterWrapper
                .find("#allArticleRequestFilterDateUntilInput")
                .val("");
            articleRequestFilterDateUntilInput[0].date = undefined;
            articleRequestList.resetFilter();
            articleRequestList.updateView();
        });
    }

    /**
     * Initialises the filter controls of the suggested articles subsection
     */
    _initArticleSuggestionFilter(articleList) {
        const instance = this;
        let filterWrapper = instance.container.find(
            "#articleSuggestionFilterWrapper"
        );
        const articleSuggestionFilterDateUntilInput = M.Datepicker.init(
            $("#articleSuggestionFilterDateUntilInput"),
            instance.utils.getStandardDatePickerConfig()
        );

        const filterSuggestions = function () {
            let title = $("#articleSuggestionFilterTitleInput").val();
            let date = articleSuggestionFilterDateUntilInput[0].date;

            let filter = {
                page: 1,
            };

            if (title) filter.title = title;
            if (date) filter.date = date.toISOString();

            articleList.setFilter(filter);
            articleList.updateView();
        };

        const applyButton = filterWrapper.find(".apply-filter-button");
        applyButton.on("click", filterSuggestions);
        filterWrapper.on(
            "keypress",
            "#articleSuggestionFilterTitleInput, #articleSuggestionFilterDateUntilInput",
            (e) => {
                if (e.key == "Enter") applyButton.click();
            }
        );

        filterWrapper.find(".clear-filter-button").click(function () {
            filterWrapper.find("#articleSuggestionFilterTitleInput").val("");
            filterWrapper
                .find("#articleSuggestionFilterDateUntilInput")
                .val("");
            articleSuggestionFilterDateUntilInput[0].date = undefined;
            articleList.resetFilter();
            articleList.updateView();
        });
    }
}
