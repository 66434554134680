/* global monstecLib */
/* global M */
import ObjectForm from '../common/objectform';

/**
 * Input form for creating new questions.
 *
 * Implement 'sendAction' for an instance of this class to define the behaviour of the
 * current QuestionForm object when clicking the send button.
 * 
 * Implement 'onSendingSuccessful' to react on the successful delivery of the question
 * to the destination.
 */
export default class QuestionForm extends ObjectForm {
    /**
     * Construct a new form that can then be attached to an element.
     */
    constructor() {
        super();
        this.i18next = monstecLib.i18next;

        function addTag(chipsElement) {
            let textNodeOfNewChip = chipsElement.find('.chip').last()[0].childNodes[0];
            let textOfNewChip = textNodeOfNewChip.data;
            textNodeOfNewChip.data = textOfNewChip.trim().replaceAll(/[^a-zA-Z0-9äöüÄÖÜß\.-]+/g, '-');
        }

        this.chipsOptions = {
            'placeholder': this.i18next.t('toasts.chips'),
            'limit': 5,
            'onChipAdd': function(chips) {addTag(chips);}
        };

        this.log = new monstecLib.Log(1);
    }

    /**
     * Attaches the current instance to a specific element. That element must include
     * the question-form-nunjucks-template and should ideally be a div.
     *
     * @param {string} htmlId identifies the container element that includes the question form
     */
    attachTo(htmlId) {
        const instance = this;

        instance.htmlId = htmlId;
        instance.container = $('#' + htmlId);
        instance.form = this.container.find('form');

        let questionFormCloser = instance.container.find('#newQuestionFormCloser');
        questionFormCloser.on('click', () => instance.container.removeClass('active'));

        let inputs = this.form.find('input');

        let topicInput =  $(inputs[0]);
        topicInput.attr('id', htmlId + 'TopicInput');
        topicInput.siblings('label').attr('for', topicInput.attr('id'));
        instance.topicInput = topicInput;

        let productNameInput =  $(inputs[1]);
        productNameInput.attr('id', htmlId + 'ProductNameInput');
        productNameInput.siblings('label').attr('for', productNameInput.attr('id'));
        instance.productNameInput = productNameInput;

        // init language selection
        let languageSelect = this.form.find('select');
        languageSelect.attr('id', htmlId + 'LanguageInput');
        languageSelect.siblings('label').attr('for', languageSelect.attr('id'));

        var lngOptions = {
            'classes': 'edit-language'
        };

        instance.languageSelect = languageSelect;
        instance.languageSelectM = M.FormSelect.init(languageSelect, lngOptions)[0];

        // initialise editing area for detailed information
        let detailsTextarea = this.form.find('textarea');
        detailsTextarea.attr('id', htmlId + 'TextInput');
        instance.detailsTextarea = detailsTextarea;

        M.textareaAutoResize(detailsTextarea);
        instance.utils.addWswgBar(detailsTextarea);

        // init tag module
        let tags = this.form.find('.chips');
        tags.attr('id', htmlId + 'TagsInput');
        instance.tagsInput = M.Chips.init(tags, instance.chipsOptions)[0];

        // initialise sendButton
        let sendButton = this.form.find('button');
        sendButton.attr('id', htmlId + 'SendButton');
        sendButton.on('click', function(e) {
            instance._onSendButtonClicked(e);
        });
    }

    /**
     * Handles the click on the send button, by calling the externally defined send action and processing the
     * return value of that action.
     */
    async _onSendButtonClicked(e) {
        const instance = this;
        e.preventDefault();
        e.stopImmediatePropagation();

        if (instance.sendAction) {
            try {
                let question = instance._createQuestionFromFields();
                let response = await instance.sendAction(question);

                if (response)  {
                    let status = response.status;

                    if (status == 'ok' || status == 200 || status == 201) {
                        instance._emptyForm();
                        M.toast({html: instance.i18next.t('toasts.data_updated')});

                        if (instance.onSendingSuccessful) {
                            instance.onSendingSuccessful(response);
                        }

                    } else if (status == 400) {
                        instance._processFieldErrorsFromServer(response);
                    }
                }
            } catch (error) {
                M.toast({html: instance.i18next.t('toasts.error')});
                instance.log.error('Send action yielded an unexpected error:', error);
            }
        } else {
            instance.log.warn('No sendAction defined!');
        }
    }

    /**
     * Collects information from the input fields on the form to build a question object.
     */
    _createQuestionFromFields() {
        const instance = this;
        let question = {};

        try {
            question.topic = instance.topicInput.val();
            question.productName = instance.productNameInput.val();
            question.language = instance.languageSelect.val();

            let details = instance.detailsTextarea.val();
            if (!!details && details.length > 0) {
                question.text = details;
            }

            let tagsData = instance.tagsInput.chipsData;
            if (!!tagsData || Array.isArray(tagsData)) {
                let actualTags = tagsData
                    .filter(tuple => !!tuple.tag)
                    .map(tuple => tuple.tag.trim().replaceAll(/[^a-zA-Z0-9äöüÄÖÜß\.-]+/g, '-'));

                question.tags = actualTags;
            }
        } catch (error) {
            instance.log.error('Could not extract values for question: ', error);
        }

        instance.log.debug('Formed the following question object from the input fields:', question);
        return question;
    }

    _emptyForm() {
        const instance = this;
        let inputElements = instance.container.find('input, textarea');

        inputElements.each(function(index, element) {
            let formElement = $(element);
            formElement.removeClass("valid invalid").css("height", "").val(null);
            M.updateTextFields();
        });

        // re-init language select
        if (instance.languageSelectM) {
            var lngOptions = {
                'classes': 'edit-language'
            };
            instance.languageSelectM.destroy();
            instance.languageSelect.val("de");
            instance.languageSelectM = M.FormSelect.init(instance.languageSelect, lngOptions)[0];
        }

        // re-initialise tags
        instance.tagsInput.destroy();
        let tags = $('#' + instance.htmlId + 'TagsInput');
        instance.tagsInput = M.Chips.init(tags, instance.chipsOptions)[0];
    }
}