/* global monstecLib */
/* global M */

import i18next from 'i18next';
import 'js-cookie';

export default class ProfileDataHandler {
    constructor(identityServiceAccess, externalCookie, externalAuhtenticator, externalChatService, externalUtils) {
        this.identityService = identityServiceAccess;
        this.cookie = externalCookie;
        this.authenticator = externalAuhtenticator;
        this.chatService = externalChatService;
        this.utils = externalUtils;
        this.constants = new monstecLib.Constants();

        this.log = new monstecLib.Log(1);
    }

    async setUserName() {
        var instance = this;

        var sessionAuthCookie = await instance.cookie.getRightStorage('sess_au');
        if(!!sessionAuthCookie){

            var produckCookie = await instance.cookie.getRightStorage('produck');

            instance.identityService.getUserData(produckCookie.userId).then(function(result) {

                function input(primary, secondary, fallback) {
                    return primary || secondary || fallback;
                }

                $("#user-name .name").text(i18next.t('text.hello') + ' ' + input(result.firstName, result.nickname, 'No Name'));
                $('.data_descr.phone').html(input(result.contactMobile, ' - '));
                $('.data_descr.contact-email').html(input(result.contactEmail, ' - '));

                const defaultPortrait = "/assets/img/icons/ducky_portrait_placeholder_transparent_xs_borderless.png";

                if (result.portraitImg || produckCookie?.userData?.portraitImg) {
                    let portraitImg = input(result.portraitImg, produckCookie?.userData?.portraitImg, defaultPortrait);
                    $('.user-view .menu-portrait').attr('src', portraitImg);
                } else {
                    $('.user-view .menu-portrait').attr('src', defaultPortrait);
                }
            });
        }
    }

    /*
     * this function calls all information either about the expert or user and fills the profile overview
     */
    async displayUserProfile(){
        var instance = this;
        var sessionAuthCookie = await instance.cookie.getRightStorage('sess_au');
        $(document).trigger("loader:on", ['', 'transparent', document.getElementById('target5')]);

        function input(data) {
            return (data ? data : ' - ');
        }

        function inputDate(data) {
            return (data ? instance.utils.transformDate(new Date(data)) : ' - ');
        }

        if(!!sessionAuthCookie){

            var dataCookie =  await instance.cookie.getRightStorage('produck');
            var uid = dataCookie.userId;
            var uRole = dataCookie.userRole;
            var email = dataCookie.userName;

            //contact email and phone already loaded on page load

            let populateProfileTemplate = function(result) {
                $('.data_descr.nickname').html(input(result.nickname));
                $('.data_descr.custid').html(uid + '<div class="share"><i class="material-icons">share</i></div>');
                $('.data_descr.custid').attr('data-id', uid);
                $('.data_descr.first-name').html(input(result.firstName));
                $('.data_descr.last-name').html(input(result.lastName));
                $('.role').html(uRole);
                $('.data_descr.self_descr').html(input(result.longDescr));
                $('.data_descr.portrait, .user-view .menu-portrait').attr('src', result.portraitImg ? result.portraitImg : "/assets/img/android-chrome-192x192.png");
                $('.data_descr.age').html(inputDate(result.birthDate));
                $('.data_descr.affiliation').html(inputDate(result.memberSince));
                $('.data_descr.profession').html(input(result.occupation));
                $('.data_descr.email').html(email);
                $('.data_descr.company').html(input(result.company));
                $('.data_descr.website').html(input(result.website));
                $('.edit-profile .profile-generator-link').attr('href', '/profile-generator.html?cid='+uid);
                $('.edit-profile .profile-link').attr('href', '/profile/'+uid);

                result.userId = uid;

                instance.cookie.saveExtendedUserDataInCookie(result);

                function addSpecialityTags() {
                    if (input(result.tags).length > 0 && input(result.tags).indexOf(' -') !== 0) {
                        var specialityTags = (input(result.tags));
                        var tagBox = '';

                        specialityTags.forEach(function(tag) {
                            tagBox += '<div class="panel_block speciality">'+ tag +'</div>';
                        });

                        $('#profile .specialities').empty().append(tagBox);
                    }
                }

                addSpecialityTags();
            };

            instance.identityService.getPublicProfileData(uid).then(function(result) {
                populateProfileTemplate(result);
            }).catch(function() {
                // in case the user does not have a complete profile yet still the data from the user details endpoint can be used
                instance.identityService.getUserData(uid).then((result) => populateProfileTemplate(result));
            });
                        
            instance.utils.initShareContent();
        }
        $(document).trigger("loader:off");
    }

    /*
     * this function calls all information for a specific id and fills the public profile overview
     */
    displayPublicUserProfile(profileObj, uid){
        var instance = this;

        function input(result, alternative) {
            return (result ? result : (alternative ? alternative : ''));
        }

        var persMetaTags = "<title>"+input(profileObj.title) + " " + input(profileObj.firstName) + " " + input(profileObj.lastName) + " &#10072; " + input(profileObj.specDescr)+ " &#10072; Produck</title>"
                           + "<meta name='description' content='Profilseite von " + input(profileObj.firstName) + " " + input(profileObj.lastName)+ " &#10072; " + input(profileObj.uRole) + " &#10072; " + input(profileObj.longDescrHl) + "' />";
        $('head').prepend(persMetaTags);

        $('.nav-background').attr('src', input(profileObj.backgroundImg, '/assets/img/profiles/prdk_bgrd_profile_pattern_2.jpg'));
        $('.xpert_level .role').html(input(profileObj.uRole));
        $('.data_descr.custid').html(uid + '<div class="share"><i class="material-icons">share</i></div>');
        $('.data_descr.custid').attr('data-id', uid);
        $('.data_descr.affiliation').html(profileObj.memberSince ? instance.utils.transformDate(new Date(input(profileObj.memberSince))) : instance.utils.transformDate(new Date()));

        if ($('body').hasClass('profile-generator')) {

            // value format
            $('.data_descr.title').val(input(profileObj.title));
            $('.data_descr.first-name').val(input(profileObj.firstName));
            $('.data_descr.last-name').val(input(profileObj.lastName));
            $('.data_descr.nickname').val(input(profileObj.nickname));
            $('.data_descr.profession').val(input(profileObj.occupation));
            $('.data_descr.company').val(input(profileObj.company));
            $('.data_descr.contact-email').val(input(profileObj.contactEmail));
            $('.data_descr.phone').val(input(profileObj.contactMobile));
            $('.data_descr.website').val(input(profileObj.website));
            $('.data_descr.spec-descr').val(input(profileObj.specDescr));
            $('.data_descr.portrait, .sidenav .nav-portrait').attr('src', input(profileObj.portraitImg, "/assets/img/android-chrome-192x192.png"));
            $('.data_descr[data-value="portraitImg"]').val(input(profileObj.portraitImg, "/assets/img/android-chrome-192x192.png"));
            $('.data_descr[data-value="backgroundImg"]').val(input(profileObj.backgroundImg, "/assets/img/profiles/prdk_bgrd_profile_pattern_2.jpg"));
            $('.long-descr-hl input').val(input(profileObj.longDescrHl));
            $('.long-descr-txt').val(input(profileObj.longDescr));
            $('.sidenav .nav-chat-lnk').attr('href', 'https://www.produck.de/chat.html?cid=' + uid);
            $('#profile').append('<style>#profile::before{background-image:url('+input(profileObj.backgroundImg, "/assets/img/profiles/prdk_bgrd_profile_pattern_2.jpg")+')}</style>');

        } else {

            $('.xpert_level .nickname').append(' ' + input(profileObj.nickname));
            $('.data_descr.first-name').html(input(profileObj.firstName));
            $('.data_descr.last-name').html(input(profileObj.lastName));
            $('.data_descr.profession').html(input(profileObj.occupation));
            $('.data_descr.contact-email').html(input(profileObj.contactEmail));
            $('.data_descr.phone').html(input(profileObj.contactMobile));
            $('.data_descr.company').val(input(profileObj.company));
            $('.data_descr.website').html('<a target="_blank" rel="noopener noreferrer ugc" href="'+input(profileObj.website)+'">' + input(profileObj.website) + '</a>');
            $('.data_descr.spec-descr').html(input(profileObj.specDescr));
            $('.data_descr.portrait, .sidenav .nav-portrait').attr('src', input(profileObj.portraitImg, "/assets/img/android-chrome-192x192.png"));
            $('.data_descr.full-name, .white-text.name').html(input(profileObj.title) + ' ' + input(profileObj.firstName) + ' ' + input(profileObj.lastName));
            $('.long-descr-hl h4').html(input(profileObj.longDescrHl));
            $('.long-descr-txt').html(input(profileObj.longDescr));
            $('.sidenav .nav-chat-lnk').attr('href', 'https://www.produck.de/chat.html?cid=' + uid);
            $('#profile').append('<style>#profile::before{background-image:url('+input(profileObj.backgroundImg)+')}</style>');
            // append tags
            if (profileObj.tags){
                Object.values(profileObj.tags).forEach(value => {
                    $('#profile .content').append('<div class="chip"><a target="_blank" rel="noopener noreferrer ugc" href="'+input(profileObj.website)+'">'+ value +'</a></div>');

                });
            }
        }

        instance.initTags({"profile": profileObj.tags});
        profileObj.userId = uid;
        instance.utils.initShareContent();
    }

    // builds all attached blocks, not profile itself (profile is defined in html)
    buildProfileOverview(profileObj) {
        var instance = this;
        var sectionBlock = '';
        var sectionNav = '';
        var blockTitles = {};

        if (profileObj.profileBlock) {

            var string = profileObj.profileBlock;
            var preParsedBlock = string.replace(/&#34;/g, '"');

            for (var i = 0; i < JSON.parse(preParsedBlock).length; i++) {

                var profileBlock = JSON.parse(preParsedBlock)[i];

                var blockType = profileBlock.type,
                 blockTitle =  profileBlock.title,
                 blockBackgroundImg =  profileBlock.backgroundImg,
                 blockH2 = profileBlock.h2,
                 blockH3 = profileBlock.h3,
                 blockH4 = profileBlock.h4,
                 blockImg = profileBlock.img,
                 blockText = profileBlock.text,
                 blockTags = profileBlock.tags;

                var blockObj = {blockType, blockTitle, blockBackgroundImg, blockH2, blockH3, blockH4, blockImg, blockText, blockTags};

                sectionBlock += instance.buildSingleBlock(blockObj).block;
                sectionNav += instance.buildSingleBlock(blockObj).navElems;
                blockTitles[blockTitle] = blockTags;
            }
        }

        $('.profile-block').append(sectionBlock);
        $(sectionNav).insertAfter($('#sidenav-profile'));

        instance.initTags(blockTitles);
        instance.utils.addWswgBar($('.text-wrapper textarea.wswg-enabled'));
        instance.utils.initImgUpld($('input.img-enabled'));
        instance.utils.initShadowAndSideNavZIndex();
    }

    initTags(tagArray) {
        // init given tags and input field for each section or, if no tags available, init just input field

        let chipsOptions = {
            'limit': 5,
            'onChipAdd': function() {console.log('tag added');},
            'onChipDelete': function() {console.log('tag deleted');}};
        let chipsElement = $('.content-wrapper').find('.edit-tags');

        if(tagArray !== undefined) {
            Object.entries(tagArray).forEach(([key, val]) => {

                var dataObj = [];

                if (val) {
                    Object.values(val).forEach(value => {
                        dataObj.push({"tag": value});
                    });
                }

                function addTag(chipsElement) {
                    let textNodeOfNewChip = chipsElement.find('.chip').last()[0].childNodes[0];
                    let textOfNewChip = textNodeOfNewChip.data;
                    textNodeOfNewChip.data = textOfNewChip.trim().replaceAll(/[^a-zA-Z0-9äöüÄÖÜß\.-]+/g, '-');
                }

                if (key.length > 0 && dataObj.length > 0 && val[0].length > 0){
                    let idTag = key.toLowerCase();
                    chipsElement = $('#'+ idTag + ' .content-wrapper').find('.edit-tags');
                    chipsOptions = {
                        'data': dataObj,
                        'limit': 5,
                        'onChipAdd': function(chips) {addTag(chips);}
                    };
                } else if (key.length > 0 && dataObj.length === 0) {
                    let idTag = key.toLowerCase();
                    chipsElement = $('#'+ idTag + ' .content-wrapper').find('.edit-tags');
                    chipsOptions = {
                        'limit': 5,
                        'onChipAdd': function(chips) {addTag(chips);}
                    };
                }
                M.Chips.init(chipsElement, chipsOptions);
            });
        }
    }

    buildSingleBlock(blockObj) {
        var instance = this;
        let sectionId = '',
            whiteSpace = /(&nbsp;|&#160;| )+/g,
            block;

        if ($('body').hasClass('profile-generator')) {

            sectionId = blockObj.blockTitle.replace(whiteSpace, "-");

            let blockTagsString = initInputTags(blockObj.blockTags),
                transparency = blockObj.blockBackgroundImg.length ? 'transparent' : '',
                chipsOptions = initInputTags(blockObj.blockTags).chipsOptions,
                h2Block = '<h3><input placeholder="- Überschrift 1 -" value="'+ blockObj.blockH2 +'" data-value="h2" /></h3>',
                h3Block = '<h3><input placeholder="- Überschrift 2 -" value="'+ blockObj.blockH3 +'" data-value="h3"/></h3>',
                h4Block = '<li class="bold"><input placeholder="- Überschrift 3 -" value="'+ blockObj.blockH4 +'" data-value="h4" /></li>',
                mediaBlockInp = blockObj.blockType === 'media' ? '<input class="data_descr img-enabled" placeholder="'+i18next.t('text.media_url')+'" value="'+blockObj.blockImg+'" data-value="img" />' : '',
                mediaBlock = blockObj.blockType === 'media' ? '<div class="media-frame"><img alt="media" title="Medieninhalt" src="'+ blockObj.blockImg +'" /></div>' : '',
                blockTbd1 = blockObj.blockType === 'text' ? h3Block : mediaBlock,
                blockTbd2 = blockObj.blockType === 'text' ? h4Block : h3Block;

            block = '<section id="'+sectionId.toLowerCase()+'" class="section-cl narrow-section scrollspy alter-style" data-value="'+blockObj.blockType+'">'
                     +'<style>#'+sectionId.toLowerCase()+'::before{background-image:url('+blockObj.blockBackgroundImg+')}</style>'
                       + '<div class="content-wrapper text-content ' + transparency + '">'
                       + '<div class="headline">'
                         + h2Block
                         + blockTbd1
                       + '</div>'
                       + '<div class="content">'
                          + '<div class="text-wrapper">'
                            + '<ul>'
                                + blockTbd2
                                + '<textarea class="wswg-enabled" data-length="2000" maxlength="2000" oninput=\'this.style.height = "";this.style.height = this.scrollHeight + "px"\' placeholder="Text" data-value="text">'+ blockObj.blockText +'</textarea>'
                                + '  <div class="js-visual-input-area in-place-edit-field-visual hide"></div>'
                              + '</ul>'
                            + '</div>'
                                 + blockTagsString
                                 + mediaBlockInp
                                 + '<input class="data_descr img-enabled" data-value="backgroundImg" placeholder="'+i18next.t('text.background_pic_url')+'" value="'+blockObj.blockBackgroundImg+'" data-value="backgroundImg"/>'
                                 + '<input class="data_descr" data-value="title" placeholder="'+i18next.t('text.section_title')+'" value="'+blockObj.blockTitle+'" data-value="title"/>'
                                 + '</div>'
                       + '</div>'
                       + '<div class="close-wrapper"><a class="delete-section"><i class="material-icons">delete_forever</i></a></div>'
                      + '</section>';
        } else {

            sectionId = blockObj.blockTitle.replace(whiteSpace, "-");

            let blockTagsString = blockObj.blockTags ? buildTags(blockObj.blockTags) : '',
                transparency = blockObj.blockBackgroundImg.length ? 'transparent' : '',
                blockTbd1 = blockObj.blockType === 'media' ? '<div class="media-frame"><img alt="media" title="Medieninhalt" src="'+ blockObj.blockImg +'" /></div>' : '<h3>'+ blockObj.blockH3 +'</h3>',
                blockTbd2 = blockObj.blockType === 'media' ? '<h3>'+ blockObj.blockH3 +'</h3>' : '<h4>'+ blockObj.blockH4 +'</h4>';

            block = '<section id="'+sectionId.toLowerCase()+'" class="section-cl narrow-section scrollspy alter-style">'
                    +'<style>#'+sectionId.toLowerCase()+'::before{background-image:url('+blockObj.blockBackgroundImg+')}</style>'
                      + '<div class="content-wrapper text-content ' + transparency + '">'
                      + '<div class="headline">'
                        + '<h2>' + blockObj.blockH2 + '</h2>'
                        + blockTbd1
                      + '</div>'
                      + '<div class="content">'
                        + '<div class="text-wrapper">'
                          + '<ul>'
                            + '<li class="bold">'
                              + blockTbd2
                            + '</li>'
                            + '<li>' + blockObj.blockText + '</li>'
                          + '</ul>'
                        + '</div>'
                       + blockTagsString
                      + '</div>'
                    + '</div>'
                  + '</section>';
        }

        function buildTags(blockTagObj) {
            var blockTagElem = '';

            for (var i = 0; i < blockTagObj.length; i++) {

                blockTagElem += '<div class="chip">'
                                   +  '<a>'+ blockTagObj[i] +'</a>'
                               + '</div>';
            }
            return blockTagElem;
        }

        // this function is used to fill the input in the profile generator
        function initInputTags(blockTagObj) {

            var blockTagElem = '<div class="edit-tags chips-initial chips input-field"><input class="input edit-tags-input" placeholder="'+i18next.t('toasts.chips')+'"></div>';

            return blockTagElem;
        }

        var navElems = '<li><a class="sidenav-close" href="#'+sectionId.toLowerCase()+'">'+blockObj.blockTitle+'</a></li>';

        return {block, navElems};
    }

    /**
    * add a new section
    * @param blocktype = media or text block
    */
    addSection(blockType) {

        let countAll = $(".profile-block").children().length; //set individual title for block
        var profileData = {
            0: {
                'nickname': 'Dagobert-Musterente', 'title': '', 'firstName': '', 'lastName': '', 'memberSince': '', 'occupation': '', 'website': '', 'backgroundImg': '', 'portraitImg': '', 'consulCount': '0', 'specDescr': '', 'longDescrHl': '', 'longDescr': '', 'profileBlock': '[{"type":"'+blockType+'","title":"","backgroundImg":"","h2":"","h3":"","h4":"","img":"","text":"","tags":[], "title":"Titel-'+ countAll +'"}]'
            }
        };
        var newSection =  this.buildProfileOverview(profileData[0]);
        $(".profile-block").append(newSection);

    }

    initSectionPreview() {
        var profileObj = this.collectUserData();
        profileObj.id = 0;
        profileObj.memberSince = "2017-10-09T09:09:09";

        sessionStorage.setItem('dataObj', JSON.stringify(profileObj));
        window.open('/profile.html?cid=preview', 'sub', 'height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
    }

    checkNicknameCredentials() {
        var instance = this;
        var nicknameWrapper = $("#user_name_wrapper");

        let register = new monstecLib.Register(instance.authenticator);
        let checkUri = instance.constants.apiHostIdentity + "registration/check";

        if ($("#user_name")[0].reportValidity()) {
            register.checkCredentials(nicknameWrapper, checkUri, "nickname");
        }
    }

    async submitProfileData(customerId) {
        var instance = this;

        var profileObj = this.collectUserData();

        async function getLocation(customerId) {

            let url = "/";
            let specificPage = await instance.cookie.getSpecificVisitedPage();

            if (!!specificPage) {
                url = specificPage;
                await instance.cookie.deleteSpecificVisitedPage(); //no longer required after first redirect
            } else {
                let url = 'https://produck.de/profile/'+customerId;
            }

            return url;
        }

        async function initUpdateNickname() {

            var userData = await instance.identityService.getPublicUserData(customerId);
            let oldNickname = userData.isEmptyObject ? "" : userData.nickname;
            var newNickname = profileObj.nickname;

            if (newNickname && newNickname !== oldNickname) {
                instance.identityService.changeNickname(customerId, newNickname).then(function() {

                    M.toast({html: i18next.t('toasts.username_change_success')});
                    getLocation(newNickname).then((destination) => { location.assign(destination) });
                    
                }, function (errorReason) {
                                        
                    console.log("Username change request failed. Server returned status " + errorReason.status);

                    if (errorReason.status === 400){

                        let textBody = i18next.t("text.register_notification_1");
                        M.toast({html: i18next.t('toasts.username_change_fail')});
                        instance.utils.createSimpleAlertModal(textBody);
                    }
                });
            } else {
                getLocation(newNickname).then((destination) => { location.assign(destination) });
            }
        }

        return instance.identityService.saveProfileData(profileObj).then(

        async function(result) {

            await initUpdateNickname();
            $(document).trigger("loader:off");
            console.log('data retrieval succeeded', result);
            M.toast({html: i18next.t('toasts.data_updated')});

        },
        function(error) {
            $(document).trigger("loader:off");
            var responseTxt = 'Keine';
            if (error.response) {
                responseTxt = error.response.replace(/_/g, " ").replace("]", "").replace("[", "");
            };

            var textBody = 'Daten konnten nicht aktualisiert werden. Fehlermeldung: ' + responseTxt + ' ('+ error.status +')';
            instance.utils.createSimpleAlertModal(textBody);

            instance.log.error('submitProfileData - saving profile failed ', error.status, error.response);
        });
    }

    collectUserData() {
        var instance = this,
            profileObj = {},
            sectionBlock = [];

        //crawl all profile data
        $('.coll-data').each(function(i, ele) {
            profileObj[$(this).attr('data-value')] = $(this).is('input') || $(this).is('textarea') ? $(this).val().trim() : $(this).text().trim();
        });




        // crawl all content section data
        $('.section-cl').not("#profile").each(function() {

            var sectionBlockObj = {};

            $(this).find('input, textarea').each(function() {
                sectionBlockObj[$(this).attr('data-value')] = $(this).val().trim();
            });
            sectionBlockObj.type = $(this).data('value');
            sectionBlockObj.tags = getTags($('#' + $(this).attr('id')));
            sectionBlock.push(sectionBlockObj);
        });

        function getTags (target) {
            // collect tags for transmission to server
            // As soon https://github.com/Dogfalo/materialize/issues/6317 is fixed in MaterializeCSS the
            // following can be replaced something that directly uses the data array of the chips element
            var tags = [];
            target.find('.chip').each(function(index, chip) {
                var textNode = $(chip).contents().first();
                if (!textNode) return;
                var tag = textNode.text();
                if (tag && tag.length > 0) {
                    tags.push(tag);
                }
            });
            return tags;
        }

        profileObj.tags = getTags($('#profile'));
        profileObj.profileBlock = JSON.stringify(sectionBlock);

        return profileObj;
    }


    async initAccountChanges() {
        var instance = this;

        $('#delete-account').on('click', () => {
    
            let target = $('body'),
                headline = i18next.t('settings.account_delete'),
                textBody =  i18next.t('settings.account_delete_forever'),
                optionOne = i18next.t('general.yes'),
                optionTwo = i18next.t('general.cancel'),
                htmlId = 'deleteAccountModal';

            instance.utils.createModal(target, headline, textBody, optionOne, optionTwo, htmlId);
                
            let submitBtn = $('#' + htmlId).find('.option-one');
            let cancelBtn = $('#' + htmlId).find('.option-two');

            submitBtn.click(() => { $(document).trigger("loader:on", ['', 'transparent']); instance.deleteAccount(); });
            cancelBtn.click(() => { instance.utils.createSimpleAlertModal(i18next.t('settings.account_delete_abort')); });
        });

        if (await instance.authenticator.checkPermission(instance.authenticator.permCat.UPGRADE_TO_PROVIDER)) {

            $('#upgradeProviderBlock').removeClass('hide');

            $('#upgradeAccountProviderButton').on('click', () => {
                window.location.href = '/signup-prov.html';
            });
        } else {
            $('#upgradeProviderBlock').remove();
    }
   
    if (await instance.authenticator.checkPermission(instance.authenticator.permCat.UPGRADE_TO_VERIFIED_AUTHOR)) {    
   
        $('#upgradeAuthorBlock').removeClass('hide');
    
        $('#upgradeAccountAuthorButton').on('click', () => {   
    
            let target = $('body'),
                headline = i18next.t('settings.application_status'),
                textBody =  i18next.t('settings.application_status_request'),
                optionOne = i18next.t('general.yes'),
                optionTwo = i18next.t('general.no'),
                htmlId = 'verifiedAuthorUpgradeModalOne';
    
            instance.utils.createModal(target, headline, textBody, optionOne, optionTwo, htmlId);
            initModalOptions();
        });

        function initModalOptions() {

            let cancelStep = i18next.t('general.cancel');

            // author has confirmation from ProDuck stuff and can proceed without application
            $('#verifiedAuthorUpgradeModalOne .option-one').on('click', () => {

                let termsAndCondStatement = i18next.t('text.terms_opt_in_author', { confirmBtnText: i18next.t('text.request_activation') }),
                    targetOptOne = $('body'),
                    headlineOptOne = i18next.t('settings.author_activation'),
                    optionOneOptOne = i18next.t('settings.author_activation_confirm'),
                    htmlIdOptOne = 'verifiedAuthorUpgradeWithConfirmation';
                          
                let textBodyOptOne = '<div class="js-verify-author-form input-field col s12 m12 l12">'
                    +'      <p>'
                    +'        <label>'
                    +'          <input id="authorTaCCheckbox" class="filled-in js-verify-cb" type="checkbox" name="checkbox" tabindex="4" />'
                    +'          <span class="confirm_text fs-14">' + termsAndCondStatement +'</span>'
                    +'        </label>'
                    +'      </p>'
                    +'    </div>';   
                                          
                instance.utils.createModal(targetOptOne, headlineOptOne, textBodyOptOne, optionOneOptOne, cancelStep, htmlIdOptOne);

                let form = $('.js-verify-author-form'),
                    checkbox = $('.js-verify-cb'),
                    submitBtn = $('#' + htmlIdOptOne).find('.option-one');
                submitBtn.addClass('disabled');

                instance.utils.validateForm(form, checkbox, submitBtn);

                // confirm AGBs and submit verified author request
                submitBtn.on('click', async () => {     

                    var sessionAuthCookie = await instance.cookie.getRightStorage('sess_au');
                    if(!!sessionAuthCookie){

                        $(document).trigger("loader:on", ['', 'transparent']);

                        var produckCookie = await instance.cookie.getRightStorage('produck');
                        var contactForm = new monstecLib.ContactForm(instance.utils, instance.identityService);

                        instance.identityService.getUserData(produckCookie.userId).then((result) => {

                            function input(value, alternative) {
                                return (value ? value : (alternative ? alternative : '-'));
                            }

                            let defaultMessage = "Freischaltungsantrag als verifizierter Autor von Nutzer mit ID: " + produckCookie.userId + ". Mit diesem Antrag hat der Nutzer die AGBs für Autoren akzeptiert.";
                            let data = {firstName: input(result.firstName, 'noFirstName'), lastName: input(result.lastName, 'noLastName'), email: input(result.contactEmail, produckCookie.userName), phone: input(result.contactMobile, '123345'), website: input(result.website, 'https://www.NoWebSite.de'), company: input(result.company, 'noCompany'), message: defaultMessage, newsletter: false };

                            contactForm.sendForm(data, false);
                        })
                        .catch(function(err) {
                            console.log('Request for activation failed: ', err);
                            $(document).trigger("loader:off");
                        });
                    }
                });
            });

            // author has no confirmation from ProDuck stuff and is forwarded to application form
            $('#verifiedAuthorUpgradeModalOne .option-two').on('click', () => {
    
                let targetOptTwo = $('body'),
                    headlineOptTwo = i18next.t('settings.application_submit'),
                    optionOneOptOne = i18next.t('settings.application_submit_confirm'),
                    textBodyOptTwo = i18next.t('settings.application_submit_descr', { buttonText: i18next.t('settings.application_submit_confirm') }), 
                    htmlIdOptTwo = 'verifiedAuthorUpgradeWoConfirmation';
                                          
                instance.utils.createModal(targetOptTwo, headlineOptTwo, textBodyOptTwo, optionOneOptOne, cancelStep, htmlIdOptTwo);
                let submitBtn = $('#' + htmlIdOptTwo).find('.option-one');

                //forward to application site
                submitBtn.on('click', () => {      
                    window.open('/application.html', "_blank") || window.location.replace('/application.html');
                });    
            });
        }
    } else {
        $('#upgradeAuthorBlock').remove();
}
}
    

async deleteAccount() {
    var instance = this;
    var uid = (await instance.cookie.getRightStorage('produck')).userId;
    var token = (await instance.cookie.getRightStorage('sess_au')).accToken;
    
    $.ajax({
        method: 'DELETE',
        url: this.constants.apiHostIdentity + 'user',
        data: {"id": uid},
        contentType: 'application/x-www-form-urlencoded',
        dataType: "text",
        headers: {"Authorization": "Bearer " + token},
        success: function (data) {
            var textBody = "Dein Konto wurde erfolgreich gelöscht";
            instance.utils.createSimpleAlertModal(textBody);
            instance.cookie.clearEntireStorage();
            $(document).trigger("loader:off");
            window.location.replace('index.html');
        },
        error: function (data) {
            // error handler
            console.log('Account deletion failed, server returned error code ' + data.status);
            $(document).trigger("loader:off");
            var textBody = "Dein Konto konnte nicht gelöscht werden. Überprüfe deine Internetverbindung und versuche es noch einmal.";
            instance.utils.createSimpleAlertModal(textBody);
        },
    });
    return false;
}
}
