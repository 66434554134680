/* global monstecLib */
/* TODO refactor usage of global variables
 * This class uses global variables defined in chat.html. It can be hard to find errors when using this kind of
 * "design". Instead the respective values / objects should be given by constructor arguments or via set-methods.
 */
import i18next from "../localisation.js";

export default class UserChat {
    /**
     * Constructor
     *
     */
    constructor() {
                   
    }
}
