/* global monstecLib */
/* global M */
import ObjectList from "../common/objectlist";

/**
 * Article list for "My Articles".
 */
export default class ArticleList extends ObjectList {
    constructor(currentUserId) {
        super();
        this.currentUserId = currentUserId;
        this.noResultsProperty = "articlesection.no_articles";
        this.tutorialProperty = {
            link: "/docu/article.html#add-new-article",
            text: "articlesection.tutorial_link",
        };

        this.chatService = monstecLib.produckContext.chatService;
        this.identityService = monstecLib.produckContext.identityService;

        this.i18next = monstecLib.i18next;

        this.log = new monstecLib.Log(1);
    }

    /**
     * Determines for a single list item, if it is editable or not.
     *
     * @param {object} article the current object, i.e. list item
     */
    _determineIfItemEditable(article) {
        let status = article.status;
        let isCreator = article.userId == this.currentUserId;
        let isAuthor = article.authorId == this.currentUserId;

        return status !== "REQUESTED" && (isCreator || isAuthor);
    }

    /**
     * Creates a DOM-element that will display a single article in the list of articles.
     *
     * @param {*} article the article to create a list item for
     */
    _createListItem(article, isEditable) {
        const instance = this;

        let quackity = article.rating ? article.rating.toFixed(2) : "-";
        let ratingCount =
            quackity && article.ratingCount ? article.ratingCount : "";
        let views = article.views ? article.views : "0";
        let summaryTxt = article.summary;

        let countWordsArr = [{ text: summaryTxt }];

        let itemHeaderId = instance.htmlId + "ArticleItemHeader" + article.id;

        let quackModeStateClassAddition =
            article.status == "PUBLISHED"
                ? "public-produck"
                : article.status == "EXTERNAL"
                ? "public-external"
                : "";

        let domainAddOnExternal = article.status == "EXTERNAL" ? article.domain : '';

        let quackModeStateDisabeled =
            article.status != "PUBLISHED" &&
            article.status != "DRAFT" &&
            article.status != "EXTERNAL";

        let acceptedStatusChip = "";
        if (article.acceptanceTime) {
            acceptedStatusChip =
                '<div class="chip status-chip accepted" title="' +
                instance.utils.formatDate(article.acceptanceTime) +
                '">' +
                instance.i18next.t("articlesection.status_label_ACCEPTED") +
                "</div>";
        }

        let articleItemHtml =
            '<li class="js-collapsible-item">' +
            '  <div class="collapsible-header object-list-item" id="' +
            itemHeaderId +
            '" data-article-id="' +
            article.id +
            '" >' +
            '    <div class="article-info-header simple-vertical-content">' +
            '      <span id="' +
            itemHeaderId +
            'Title" class="object-title current-content bold">' +
            article.title +
            "</span>" +
            '      <div class="simple-horizontal-content">' +
            '        <div class="chip status-chip ' +
            article.status +
            '">' +
            instance.i18next.t(
                    "articlesection.status_label_" + article.status
            ) + domainAddOnExternal +
            "</div>" +
            acceptedStatusChip +
            "      </div>" +
            "    </div>" +
            '    <div class="simple-horizontal-content">' +
            '      <div id="' +
            instance.htmlId +
            "ArticleActionButtons" +
            article.id +
            '"></div>' +
            '      <div class="item-state ' +
            quackModeStateClassAddition +
            '" title="' +
            instance.i18next.t(
                "articlesection.publish_article_check_box_title"
            ) +
            '"><label>' +
            '        <input class="js-item-state-input filled-in" type="checkbox" ' +
            (quackModeStateDisabeled ? 'disabled="disabled"' : "") +
            "/><span></span></label>" +
            "      </div>" +
            "    </div>" +
            "  </div>" +
            '  <div class="collapsible-body" data-article-id="' +
            article.id +
            '" data-user-id="' +
            article.ownerId +
            '">' +
            '    <div class="stats-wrapper">' +
            '      <span class="quackity stats" title="' +
            instance.i18next.t("text.rating") +
            '">' +
            quackity +
            "&nbsp;(" +
            (ratingCount ? ratingCount : 0) +
            ")" +
            '<i class="material-icons">star_border</i></span>' +
            '      <span class="views stats" title="' +
            instance.i18next.t("text.views") +
            '">' +
            views +
            '<i class="material-icons">visibility</i></span>' +
            '      <span id="wordCount" class="stats" title="' +
            instance.i18next.t("text.words") +
            '"></span>' +
            '      <span class="timestamp stats" title="' +
            instance.i18next.t("text.date_of_creation") +
            '">' +
            instance.utils.formatDate(article.creationTime) +
            "</span>" +
            "    </div>" +
            '    <div class="js-author-block author-block hide">' +
            '      <div itemprop="author" itemscope itemtype="https://schema.org/Person" class="author">' +
            "        <span>" +
            instance.i18next.t("articlesection.accepted_by") +
            "</span>&nbsp;" +
            '        <a class="prdk-link" href="/profile/' +
            article.authorId +
            '" target="_blank">' +
            '          <span itemprop="name" class="js-author-name author-name"></span>' +
            "        </a>" +
            '        &nbsp;&dash;&nbsp;<span class="js-acceptance-time"></span>' +
            "      </div>" +
            "    </div>" +
            '    <div class="dialogue-summary narrow center-duck-dark">' +
            '      <div class="js-summary-action-button"></div>' +
            '      <div class="summary">' +
            '        <div class="current-content flex-wrap">' +
            '          <div class="w100"><b>' +
            instance.i18next.t("articlesection.summary_label") +
            "</b></div>" +
            '          <div class="vertical-spacer small"></div>' +
            '          <div class="js-summary-source article-hyperlink">' +
            (summaryTxt && summaryTxt.length > 0
                ? summaryTxt
                : instance.i18next.t("articlesection.no_details")) +
            "</div>" +
            "        </div>" +
            "      </div>" +
            "    </div>" +
            '    <div class="js-article-tags edit-tags chips-initial"><input class="input edit-tags-input" placeholder="' +
            instance.i18next.t("toasts.chips") +
            '"></div>' +
            '    <div class="input-field language-select-wrapper">' +
            '      <select id="languageSelect" class="js-language-select edit-language">' +
            '        <option value="de"' +
            (article.language == "de" ? ' selected="selected"' : "") +
            ">" +
            instance.i18next.t("text.ger") +
            "</option>" +
            '        <option value="en"' +
            (article.language == "en" ? ' selected="selected"' : "") +
            ">" +
            instance.i18next.t("text.eng") +
            "</option>" +
            "      </select>" +
            "      <label>" +
            instance.i18next.t("text.language") +
            "</label>" +
            "    </div>" +
            '    <div class="js-instructions-wrapper"></div>' +
            "  </div>" +
            "</li>";

        const articleItem = $(articleItemHtml);
        articleItem.data("articleId", article.id);
        const articleItemBody = articleItem.find(".collapsible-body");

        if (isEditable) {
            instance._addEditableBehaviour(
                article,
                articleItem,
                itemHeaderId,
                articleItemBody
            );
        }

        const blocksArea = $(
            '<div id="' +
                instance.htmlId +
                "ArticleItem" +
                article.id +
                'Blocks"></div>'
        );
        articleItemBody.find(".dialogue-summary").after(blocksArea);

        if (article.originType == "REQUEST" && !!article.instructions) {
            let instructionsWrapper = articleItemBody.find(
                ".js-instructions-wrapper"
            );

            let instructionsLink = $(
                `<a class="prdk-link modal-trigger" href="#myArticlesProviderInstructionsModal${
                    article.id
                }">${instance.i18next.t(
                    "articlesection.instructions_by_provider_label"
                )}</a>`
            );
            instructionsWrapper.append(instructionsLink);

            let instructionsModal = $(
                `<div id="myArticlesProviderInstructionsModal${article.id}" class="modal dynamic-modal text-modal beauty-scroll">` +
                    `  <div class="modal-content">` +
                    `    <h5>${instance.i18next.t(
                        "articlesection.instructions_label"
                    )}</h5>` +
                    `    <p>${article.instructions.linkify()}</p>` +
                    `    <p>` +
                    `       <span class="stats" title="${instance.i18next.t(
                        "articlesection.word_count_min_label"
                    )}">${instance.i18next.t(
                        "articlesection.word_count_min_label"
                    )}&#058;&nbsp;${
                        article.wordCountMin ? article.wordCountMin : "&dash;"
                    }</span><br>` +
                    `       <span class="stats" title="${instance.i18next.t(
                        "articlesection.word_count_max_label"
                    )}">${instance.i18next.t(
                        "articlesection.word_count_max_label"
                    )}&#058;&nbsp;${
                        article.wordCountMax ? article.wordCountMax : "&dash;"
                    }</span>` +
                    `  </p>` +
                    `  </div>` +
                    `  <div class="modal-footer">` +
                    `    <a href="#!" class="modal-close waves-effect waves-green btn-flat">${instance.i18next.t(
                        "text.close"
                    )}</a>` +
                    `  </div>` +
                    `</div>`
            );
            instructionsWrapper.append(instructionsModal);
            instructionsModal.modal();
        }

        // A click on the publishing mode checkbox must not trigger the collapsible. Since materializecss transforms the
        // checkpux input field into a combination of a span- and an input-tag inside a label-tag the propagation of
        // the click event must be stopped at the label-tag in order to prevent the collapsible from opening. The label-tag
        // will be the parent of the input-checkbox-field and therefore can be accessed by the JQuery-parent-function.
        articleItem
            .find(".js-item-state-input")
            .parent()
            .on("click", function (event) {
                event.stopImmediatePropagation();
            });

        articleItem.find("#" + itemHeaderId).on("click", async function () {
            // Only load the list of blocks if not aleady done so. So either if there already are blocks or the button
            // for adding new blocks is there (since it will be added by this function), the list has already been
            // received from the service. If there are no blocks however, there simply are none yet.
            if (
                blocksArea.children().length > 0 ||
                articleItemBody.find("#newBlockButtonWrapper" + article.id)
                    .length > 0
            ) {
                return; // already loaded
            }

            $(document).trigger("loader:on", [
                "",
                "transparent",
                blocksArea[0],
            ]);

            let promises = [];
            let blocksPromise = instance.chatService.getArticleBlocksForEditing(
                article.id
            );
            blocksPromise
                .then(function (blocks) {
                    blocks.forEach(function (answer) {
                        let text = answer.text;

                        countWordsArr.push({ text });
                        instance._appendArticleBlock(answer, blocksArea);
                    });
                })
                .catch(function (error) {
                    if (error.status != 404) {
                        instance.log.error(
                            "Error when retrieving blocks for article " +
                                article.id,
                            error
                        );
                    }

                    return error;
                });
            promises.push(blocksPromise);

            if (article.acceptanceTime && article.originType == "SUGGESTION") {
                let authorPromise = instance.identityService.getPublicUserData(
                    article.recipientId
                );
                authorPromise
                    .then(function (user) {
                        articleItem.find(".js-author-name").text(user.nickname);
                        articleItem
                            .find(".js-acceptance-time")
                            .text(
                                instance.utils.formatDate(
                                    article.acceptanceTime
                                )
                            );
                        articleItem
                            .find(".js-author-block")
                            .removeClass("hide");
                    })
                    .catch(function (error) {
                        instance.error(
                            "User data of author could not be retrieved.",
                            error
                        );
                        return error;
                    });

                promises.push(authorPromise);
            }

            Promise.allSettled(promises).finally(function () {
                instance._addNewBlockControls(articleItem);

                var words = instance.utils.countWordsInQuacks(countWordsArr);

                articleItem
                    .find("#wordCount")
                    .html(words + '<i class="material-icons">subject</i>');
                $(document).trigger("loader:off");
            });
        });

        return articleItem;
    }

    /**
     * Initialises the input element for the tags of an article.
     * NOTE! This has to be called after the list item has been added to the DOM. Otherwise
     * the materielizecss-initialisation-methods will not work.
     *
     * @param {*} listItem the article list item to initialise tags input for
     * @param {*} article the article data object corresponding to the given list item
     */
    _initTagsInputOnItem(listItem, article) {
        const instance = this;

        // create a data object that is compatible with the Chips-form-element of materializecss
        // it must be an array of the form
        // [{tag: 'tag1'},{tag: 'tag2'}, {tag: 'tag3'}] (or even more than three)
        // the materializecss Chips-form-element will be initialised with this object meaning, that
        // the indluded tags will immediatley been shown and the form-element also adds new tags to
        // the array, whenever the user enters a new tag, or removes tags, whenever the user deletes
        // a tag.
        var chips = [];
        for (var tag in article.tags) {
            if (!article.tags.hasOwnProperty(tag)) continue;
            chips.push({ tag: article.tags[tag] });
        }

        function updateArticleChipUpdate() {
            // currently there is a bug in MaterializeCSS, see
            // https://github.com/Dogfalo/materialize/issues/6317
            // This bug prevents an empty array given to a Chips-elemnt from being updated
            // correspondingly to the user input. Therefore if the array is empty here, it
            // has to be checked, if there is actually no chip to send to the server
            var tags;
            if (!chips || chips.length < 1) {
                tags = [];
                var chipElements = listItem.find(".js-article-tags .chip");
                chipElements.each(function (index, elem) {
                    var textNode = $(elem).contents().first();
                    if (!textNode) return;
                    var tag = textNode.text();
                    if (tag && tag.length > 0) {
                        tags.push(
                            tag
                                .trim()
                                .replaceAll(/[^a-zA-Z0-9äöüÄÖÜß\.-]+/g, "-")
                        );
                    }
                });
            } else {
                tags = chips.map((obj) => {
                    let newTag = obj.tag
                        .trim()
                        .replaceAll(/[^a-zA-Z0-9äöüÄÖÜß\.-]+/g, "-");
                    return newTag;
                });
            }

            var params = { id: article.id, tags: tags };
            return instance._updateArticle(params, {
                html: instance.i18next.t("toasts.tags_updated"),
            });
        }

        function addTag(chipsElement) {
            let textNodeOfNewChip = chipsElement.find(".chip").last()[0]
                .childNodes[0];
            let textOfNewChip = textNodeOfNewChip.data;
            textNodeOfNewChip.data = textOfNewChip
                .trim()
                .replaceAll(/[^a-zA-Z0-9äöüÄÖÜß\.-]+/g, "-");
            updateArticleChipUpdate();
        }

        var chipsOptions = {
            data: chips,
            placeholder: instance.i18next.t("toasts.chips"),
            limit: 5,
            onChipAdd: function (chips) {
                addTag(chips);
            },
            onChipDelete: function () {
                updateArticleChipUpdate();
            },
        };

        var chipsElement = listItem.find(".js-article-tags");
        M.Chips.init(chipsElement, chipsOptions); // hint: returns instances
    }

    /**
     * Initialises the input element for the language of an article.
     * NOTE! This has to be called after the list item has been added to the DOM. Otherwise
     * the materielizecss-initialisation-methods will not work.
     *
     * @param {*} listItem the article list item to initialise language select for
     * @param {*} article the article data object corresponding to the given list item
     */
    _initLanguageSelectOnItem(listItem, article) {
        const instance = this;

        function updateArticleLanguageUpdate() {
            var iso = listItem.find(".js-language-select").val();
            var params = { id: article.id, language: iso };
            instance._updateArticle(params, {
                html: instance.i18next.t("toasts.language_updated"),
            });
        }

        var languageElement = listItem.find(".js-language-select");
        languageElement.on("change", updateArticleLanguageUpdate);
        M.FormSelect.init(languageElement, { classes: "edit-language" });
    }

    /**
     * If an article item has been created by the current user this method should be called to
     * enable editing of the article.
     */
    _addEditableBehaviour(article, articleItem, itemHeaderId, articleItemBody) {
        const instance = this;
        let titleElement = articleItem.find("#" + itemHeaderId + "Title");
        const titleEditField = new monstecLib.InPlaceEditField(titleElement);

        let titleButtonConfig = [];

        if (article.status == "PUBLISHED") {
            titleButtonConfig.push({
                icon: "link",
                func: function () {
                    var host = "https://www.produck.de";

                    window.open(
                        `${host}/quackref/article/${article.id}/`,
                        "_blank"
                    );
                },
            });
        } else if (article.status == "EXTERNAL") {
            titleButtonConfig.push({
                icon: "link",
                func: function () {
                    var host = "https://" + article.domain;

                    window.open(
                        `${host}/quack/${article.id}/`,
                        "_blank"
                    );
                },
            });
        }

        titleButtonConfig.push(            
            {
                icon: "delete_forever",
                func: async function () {
                        // init delete articles and unbind former clicks
                        
                        try {
                            instance._deleteArticle(
                                article.id,
                                {
                                    html: instance.i18next.t(
                                        "toasts.delete_article_accomplished"
                                    ),
                                }
                            );
                        } catch (errorResponse) {
                            return false;
                        };                    
                },
            },
            {
                icon: "edit",
                func: function () {
                    titleEditField.onCommit = async function () {
                        let articleUpdate = {};
                        articleUpdate.id = article.id;
                        articleUpdate.title = titleEditField.getValue();

                        try {
                            return await instance._updateArticle(
                                articleUpdate,
                                {
                                    html: instance.i18next.t(
                                        "toasts.title_updated"
                                    ),
                                }
                            );
                        } catch (errorResponse) {
                            return false;
                        }
                    };

                    titleEditField.replaceSource();
                },
            }
        );

        let titleButtons = new monstecLib.ActionButtons(
            titleButtonConfig,
            "left"
        );
        titleButtons.attachTo(
            instance.htmlId + "ArticleActionButtons" + article.id,
            articleItem
        );

        let summaryElement = articleItemBody.find(".js-summary-source");
        const summaryEditField = new monstecLib.InPlaceEditField(
            summaryElement,
            false
        );

        summaryEditField.onGetContent = async function () {
            let rawArticle = await instance.chatService.getArticleForEditing(
                article.id
            );
            return rawArticle.summary;
        };

        let summaryButtonConfig = [
            {
                icon: "edit",
                func: function () {
                    summaryEditField.onCommit = async function () {
                        let articleUpdate = {};
                        articleUpdate.id = article.id;
                        articleUpdate.summary = summaryEditField.getValue();

                        try {
                            await instance.chatService.updateArticle(
                                articleUpdate
                            );

                            instance._recountWordsOnButtonEvent(
                                articleItemBody,
                                articleUpdate.summary,
                                "updatedSummary"
                            );
                            M.toast({
                                html: instance.i18next.t("toasts.data_updated"),
                            });
                            return true;
                        } catch (errorResponse) {
                            instance.log.debug(
                                "Update of article failed!",
                                errorResponse
                            );
                        }
                    };

                    summaryEditField.replaceSource();
                },
            }, //,
            //{ icon: 'delete_forever'}
        ];

        let summaryButtons = new monstecLib.ActionButtons(
            summaryButtonConfig,
            "left"
        );
        summaryButtons.attachTo("js-summary-action-button", articleItemBody);
    }

    /**
     * Performs an update request to the service that is intended to update the parts
     * of an article object that are included in 'params'.
     *
     * @param {*} params the article data, that should be updated as JSON, for example { "id": 1, "title": "new title"}
     * @param {*} tostParams a parameter object for the materializecss function M.toast, for example {html: 'Hurray'}
     */
    async _updateArticle(params, toastParams) {
        var instance = this;

        return instance.chatService
            .updateArticle(params)
            .then(function () {
                $(document).trigger("loader:off");

                return true;
            })
            .catch(function (errorResponse) {
                $(document).trigger("loader:off");
                instance.log.debug("Update of article failed!", errorResponse);
                if (errorResponse.status == 400) {
                    M.toast({
                        html: instance.i18next.t("toasts.data_invalid"),
                    });
                } else {
                    M.toast({ html: instance.i18next.t("toasts.error") });
                }

                return false;
            });
    }

    /**
     * Performs an update request to the service that is intended to update the parts
     * of an article object that are included in 'params'.
     *
     * @param {*} articleId the article id
     * @param {*} tostParams a parameter object for the materializecss function M.toast, for example {html: 'Hurray'}
     */
    async _deleteArticle(articleId, toastParams) {
        var instance = this;

        var articleContainer = $(
            ".collapsible-header[data-article-id=" + articleId + "]"
        );
        articleContainer.css("background-color", "rgba(178,0,35,.6)");

        var headline = instance.i18next.t("articlesection.delete_article");
        var text = instance.i18next.t("articlesection.delete_article_confirmation");
        var optionOne = instance.i18next.t("text.yes_delete");
        var optionTwo = instance.i18next.t("text.no");

        //creates a suitable modal
        instance.utils.createModal(
            $("body"),
            headline,
            text,
            optionOne,
            optionTwo,
            "deleteArticleModal"
        );

        // call delete function if delete gets confirmed
        $("#deleteArticleModal .modal-close.option-one").on(
            "click",
            function () {
                return instance.chatService
                    .deleteArticle(articleId)
                    .then(function () {
                        articleContainer.remove();
                        $("#deleteArticleModal").remove();
                        $(document).trigger("loader:off");
                    })
                    .catch(function (errorResponse) {
                        $("#deleteArticleModal").remove();
                        $(document).trigger("loader:off");
                        articleContainer.css("background-color", "");
                        if (errorResponse.status == 400) {
                            M.toast({
                                html: instance.i18next.t(
                                    "toasts.data_invalid"
                                ),
                            });
                        } else if (errorResponse.status == 500) {
                            M.toast({
                                html: instance.i18next.t("toasts.error")
                            });                            
                            instance.utils.createSimpleAlertModal(instance.i18next.t("articlesection.delete_article_failed"));
                        }
                         else {
                            M.toast({
                                html: instance.i18next.t("toasts.error")
                            });
                        }

                        instance.log.debug(
                            "Deletion of article failed!",
                            errorResponse
                        );
                    });
            }
        );

        // reset all conditions if delete gets declined
        $("#deleteArticleModal .modal-close.option-two").on(
            "click",
            function () {
                articleContainer.css("background-color", "");
                $("#deleteArticleModal").remove();
            }
        );
    }

    /**
     * Adds controls to an article list item that enable the user to add new article blocks.
     */
    _addNewBlockControls(articleItem) {
        const instance = this;
        const articleId = articleItem.data("articleId");
        const articleItemBody = articleItem.find(".collapsible-body");
        let newBlockButtonWrapper = $(
            '<div id="newBlockButtonWrapper' +
                articleId +
                '" class="full-width-center-content-row"></div>'
        );
        let newBlockButton = $(
            '<button data-i18n="articlesection.add_block" class="btn waves-effect waves-light" type="submit"></button>'
        );

        newBlockButtonWrapper.append(newBlockButton);
        articleItemBody.find(".js-article-tags").before(newBlockButtonWrapper);

        newBlockButton.on("click", function () {
            let editSection = $(
                '<div id="newBlockEditSection' +
                    articleId +
                    '" class="dialogue-summary narrow center-duck-light">' +
                    '  <div class="summary field-editable" data-article-id="' +
                    articleId +
                    '">' +
                    '    <form class="edit-form form-chat-message-field" action="#">' +
                    '      <textarea id="newBlockField' +
                    articleId +
                    '" class="chat-message-field" type="text" style="height:73px;overflow-y:hidden;"></textarea>' +
                    '      <div class="js-visual-input-area in-place-edit-field-visual hide"></div>' +
                    '      <div class="save-options">' +
                    '        <div class="edit-item update"><button type="submit" class="js-save-block-button btn prdk-btn icon-btn edit-button waves-effect waves-light"><i class="material-icons edit-icon">check</i></button></div>' +
                    '        <div class="edit-item cancel"><button type="cancel" class="js-cancel-block-button btn prdk-btn icon-btn edit-button waves-effect waves-light"><i class="material-icons edit-icon">cancel</i></button></div>' +
                    "      </div>" +
                    "    </form>" +
                    "  </div>" +
                    '  <div class="author"><span class="author-name" i18></span></div>' +
                    "</div>"
            );

            const blockInputArea = editSection.find(
                "#newBlockField" + articleId
            );

            newBlockButtonWrapper.addClass("hide");
            editSection.insertAfter(newBlockButtonWrapper);

            instance.utils.adjustTextarea(blockInputArea, 1500, 72);
            instance.utils.adjustScrollBugInTextarea(
                blockInputArea,
                ".panel_content"
            );
            instance.utils.addWswgBar(blockInputArea);

            editSection
                .find(".js-cancel-block-button")
                .on("click", function (e) {
                    e.preventDefault();
                    newBlockButtonWrapper.removeClass("hide");
                    editSection.remove();
                });

            editSection
                .find(".js-save-block-button")
                .on("click", async function (e) {
                    e.preventDefault();
                    instance._recountWordsOnButtonEvent(articleItem);
                    instance._createNewBlock(blockInputArea.val(), articleItem);
                });
        });

        articleItem.localize();
    }

    _recountWordsOnButtonEvent(articleItem, newBlockVal, blockIdentifier) {
        const instance = this;

        let countWordsArrMapped = [],
            summaryText = articleItem.find(".js-summary-source").text();

        // ignore certain text parts such as amzn widgets
        $.fn.ignore = function (sel, replacement) {
            return this.clone().find(sel).empty().end();
        };

        // collect words from summary, existing blocks and new blocks
        if (summaryText.length > 0 && blockIdentifier !== "updatedSummary")
            countWordsArrMapped.push({ text: summaryText });
        if (newBlockVal && newBlockVal.length > 0)
            countWordsArrMapped.push({ text: newBlockVal });

        articleItem.find(".js-block-content-source").map((i, textEle) => {
            let textContent = $(textEle).ignore(".prdk-widget").text();

            if (
                $(textEle).parents(".js-article-block").data("block-id") !==
                    blockIdentifier &&
                textContent.length > 0
            ) {
                countWordsArrMapped.push({ text: textContent });
            }
        });

        let wordsMapped =
            instance.utils.countWordsInQuacks(countWordsArrMapped);

        articleItem
            .find("#wordCount")
            .html(wordsMapped + '<i class="material-icons">subject</i>');
    }

    /**
     * Sends text meant to form an article block to the service and updates the GUI according to the result of the operation.
     *
     * @param {*} text the answer text
     * @param {*} articleItem the article list item corresponding to the article the block is intended for
     */
    async _createNewBlock(text, articleItem) {
        const instance = this;
        const articleId = articleItem.data("articleId");

        if (!text || text.length == 0) {
            M.toast({ html: instance.i18next.t("toasts.data_missing") });
            return;
        }

        let blocksArea = articleItem.find(
            "#" + instance.htmlId + "ArticleItem" + articleId + "Blocks"
        );

        let wrappedContent = instance.utils.wrapParagraphs(text);

        try {
            let response = await instance.chatService.createArticleBlock(
                articleId,
                wrappedContent
            );
            let savedBlock = {};
            savedBlock.userId = instance.currentUserId;
            savedBlock.text = wrappedContent;
            savedBlock.id = response.blockId;

            instance._appendArticleBlock(savedBlock, blocksArea);

            articleItem
                .find("#newBlockButtonWrapper" + articleId)
                .removeClass("hide");
            articleItem.find("#newBlockEditSection" + articleId).remove();

            M.toast({ html: instance.i18next.t("toasts.data_updated") });
        } catch (error) {
            instance.log.error("Could not send answer.", error);
            M.toast({ html: instance.i18next.t("toasts.data_update_failed") });
        }
    }

    _appendArticleBlock(block, blocksArea) {
        const instance = this;
        instance.utils.linkifyText();

        let textLinkified = block.text.linkify(false);

        let blockElement = $(
            '<div class="js-article-block dialogue-summary narrow center-duck-light" data-block-id="' +
                block.id +
                '">' +
                '  <div class="js-block-action-buttons"></div>' +
                '  <div class="summary">' +
                '    <div class="current-content">' +
                '      <div class="js-block-content-source question-hyperlink">' +
                textLinkified +
                "      </div>" +
                "    </div>" +
                "  </div>" +
                "</div>"
        );

        blocksArea.append(blockElement);
        setTimeout(() => {
            instance.utils.initMaterializeInContentBlock();
        }, 0);

        let textElement = blockElement.find(".js-block-content-source");
        const textEditField = new monstecLib.InPlaceEditField(
            textElement,
            true,
            ".panel_content"
        );

        textEditField.onCommit = async function () {
            try {
                let textValue = textEditField.getValue();
                await instance.chatService.updateArticleBlock(
                    block.id,
                    textValue
                );
                M.toast({ html: instance.i18next.t("toasts.data_updated") });
                instance._recountWordsOnButtonEvent(
                    blocksArea.parents(".collapsible-body"),
                    textValue,
                    parseInt(block.id)
                );
                return true;
            } catch (errorResponse) {
                instance.log.debug(
                    "Update of article block failed!",
                    errorResponse
                );
                if (errorResponse.status == 400) {
                    M.toast({
                        html: instance.i18next.t("toasts.data_invalid"),
                    });
                } else {
                    M.toast({ html: instance.i18next.t("toasts.error") });
                }
            }
        };

        textEditField.onGetContent = async function () {
            let rawBlock = await instance.chatService.getArticleBlockForEditing(
                block.id
            );
            return rawBlock.text;
        };

        textEditField.onBeforeRender = function (value) {
            instance.utils.linkifyText();

            let textLinkified = value.linkify();

            setTimeout(() => {
                instance.utils.initMaterializeInContentBlock();
            }, 0);

            return textLinkified;
        };

        function swapBlocks(block1, block2) {
            let a = $(".js-article-block[data-block-id=" + block1 + "]");
            let b = $(".js-article-block[data-block-id=" + block2 + "]");
            let tmp = $("<span>").hide();
            a.before(tmp);
            b.before(a);
            tmp.replaceWith(b);
        }

        let blockButtonConfig = [
            {
                icon: "delete_forever",
                func: async function () {
                    try {
                        let blockElement = $(
                            ".js-article-block[data-block-id=" + block.id + "]"
                        );
                        blockElement.css({
                            "background-color": "rgba(178,0,35,.6) !important",
                        });

                        var headline = instance.i18next.t(
                            "articlesection.delete_block"
                        );
                        var text = instance.i18next.t(
                            "articlesection.delete_block_confirmation"
                        );
                        var optionOne = instance.i18next.t("text.yes_delete");
                        var optionTwo = instance.i18next.t("text.no");

                        //creates a suitable modal
                        instance.utils.createModal(
                            $("body"),
                            headline,
                            text,
                            optionOne,
                            optionTwo,
                            "deleteArticleBlockModal"
                        );

                        // call delete function if delete gets confirmed
                        $(
                            "#deleteArticleBlockModal .modal-close.option-one"
                        ).on("click", function () {
                            instance.chatService
                                .deleteArticleBlock(block.id)
                                .then(function () {
                                    blockElement.remove();
                                    instance._recountWordsOnButtonEvent(
                                        blocksArea.parents(".collapsible-body")
                                    );
                                });
                            $("#deleteArticleBlockModal").remove();
                        });

                        // reset all conditions if delete gets declined
                        $(
                            "#deleteArticleBlockModal .modal-close.option-two"
                        ).on("click", function () {
                            blockElement.css({ background: "" });
                            $("#deleteArticleBlockModal").remove();
                        });
                    } catch (errorResponse) {
                        instance.log.debug(
                            "Deleting article block failed!",
                            errorResponse
                        );
                        if (errorResponse.status == 400) {
                            M.toast({
                                html: instance.i18next.t("toasts.data_invalid"),
                            });
                        } else {
                            M.toast({
                                html: instance.i18next.t("toasts.error"),
                            });
                        }
                    }
                },
            },
            {
                icon: "arrow_upward",
                func: async function () {
                    try {
                        let result =
                            await instance.chatService.moveArticleBlockUp(
                                block.id
                            );
                        if (result.displaced) {
                            swapBlocks(block.id, result.displaced);
                            M.toast({
                                html: instance.i18next.t("toasts.data_updated"),
                            });
                        }
                    } catch (errorResponse) {
                        instance.log.debug(
                            "Moving article block failed!",
                            errorResponse
                        );
                        if (errorResponse.status == 400) {
                            M.toast({
                                html: instance.i18next.t("toasts.data_invalid"),
                            });
                        } else {
                            M.toast({
                                html: instance.i18next.t("toasts.error"),
                            });
                        }
                    }
                },
            },
            {
                icon: "arrow_downward",
                func: async function () {
                    try {
                        let result =
                            await instance.chatService.moveArticleBlockDown(
                                block.id
                            );
                        if (result.displaced) {
                            swapBlocks(block.id, result.displaced);
                            M.toast({
                                html: instance.i18next.t("toasts.data_updated"),
                            });
                        }
                    } catch (errorResponse) {
                        instance.log.debug(
                            "Moving article block failed!",
                            errorResponse
                        );
                        if (errorResponse.status == 400) {
                            M.toast({
                                html: instance.i18next.t("toasts.data_invalid"),
                            });
                        } else {
                            M.toast({
                                html: instance.i18next.t("toasts.error"),
                            });
                        }
                    }
                },
            },
            {
                icon: "edit",
                showLoader: true,
                func: function () {
                    return textEditField.replaceSource();
                },
            },
        ];

        let blockButtons = new monstecLib.ActionButtons(
            blockButtonConfig,
            "left"
        );
        blockButtons.attachTo("js-block-action-buttons", blockElement);
    }
}
