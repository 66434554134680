/* global monstecLib */
/* global M */

import AbstractQuackPage from './quackviewpage.js';

export default class QuestionQuackPage extends AbstractQuackPage {
    constructor() {
        super();

        this.log = new monstecLib.Log(1);
    }

    async initialise() {
        const instance = this;
        instance.register = new monstecLib.Register(instance.authenticator);
        let userId = await instance.authenticator.getUser();

        let questionCommentSection = new monstecLib.QuackCommentSection();
        questionCommentSection.attachTo('questionCommentSection');
        questionCommentSection.sendComment = function(referenceId, text) {
            return instance.chatService.addQuestionComment(referenceId, text);
        };

        // look for answer comment sections and attach a corresponding component to them
        $('.js-answer-comment-section').each(function(index, element) {
            let answerCommentSection = new monstecLib.QuackCommentSection();
            answerCommentSection.attachTo($(element).attr('id'));
            answerCommentSection.sendComment = function(referenceId, text) {
                return instance.chatService.addAnswerComment(referenceId, text);
            };
        });

        // init rating controls for answers, except if the answer is given by the current user
        $('.js-rating-control').each(function(index, element) {
            let answerId = $(element).attr('data-answer-id');
            let answerUserId = $(element).attr('data-user-id');

            if (userId != answerUserId) {
                instance._initAnswerRatingControl(answerId);
            }
        });

        instance._tablifyDialogue(); //tablify before linkify

        let asyncLinkifyCaller = async function() {
            await instance._linkifyDialogue($('#quacklist-wrapper .question-hyperlink'));
            await instance._linkifyDialogue($('.quack-comment-section .quack-comment-text'));
        }

        asyncLinkifyCaller().then(() => {
            instance.lazyload.lazyloaderInit();
            instance.utils.initMaterializeInContentBlock()
        });

        instance.initBaseSiteMaterialize();
        instance._initQuacksScrollBar();
        instance._showHotOffersInAdBox();
        instance.utils.initShareContent();
        instance.utils.styleShariff();
        instance._showInternalAds();        
        await instance._initAnswerControls();
        
        instance._adjustCarouselHeight();

        instance._initReviewCapabilites(instance.authenticator.permCat.REVIEW_QUESTIONS);

        instance.cookie.setCookieOkCookies();
        instance.register.initSignInForm(false, instance.cookie, false, true, ['#raise-new-question-bottom', '#answer-questions-bottom']);
        $('[data-i18n]').localize();

    }

    async _initAnswerControls() {
        const instance = this;
        const quackContainer = $('#quackSingleChatContainer');
        const questionId = quackContainer.attr('data-question-id');

        // check for own answer
        const currentUserId = await instance.authenticator.getUser();

        function checkForOwnAnswer() {
            return quackContainer.find(`.js-answer-bubble[data-user-id=${currentUserId}]`).attr('data-answer-id');
        }

        let buttonText = (!!checkForOwnAnswer()) ? 'questionsection.edit_answer' : 'general.answer';
        let answerButton = $('#answer-question-bottom');
        answerButton.attr('data-i18n', buttonText);

        let editSection  = $('<div id="answerEditSection" class="dialogue-summary narrow right-duck">'
        + '  <div class="summary field-editable in-place-edit-field" data-question-id="' + questionId + '">'
        + '    <form class="edit-form form-chat-message-field" action="#">'
        + '      <textarea class="js-new-answer-input chat-message-field" type="text" style="height:73px;overflow-y:hidden;"></textarea>'
        + '      <div class="js-visual-input-area in-place-edit-field-visual hide"></div>'
        + '      <div class="save-options">'
        + '        <div class="edit-item update"><button type="submit" class="js-save-answer-button btn prdk-btn icon-btn edit-button waves-effect waves-light"><i class="material-icons edit-icon">check</i></button></div>'
        + '        <div class="edit-item cancel"><button type="cancel" class="js-cancel-answer-button btn prdk-btn icon-btn edit-button waves-effect waves-light"><i class="material-icons edit-icon">cancel</i></button></div>'
        + '      </div>'
        + '    </form>'
        + '  </div>'
        + '  <div class="author"><span class="author-name"></span></div>'
        + '</div>');

        let answerInput = editSection.find('.js-new-answer-input');

        answerButton.on('click', async function() {
            if (!await instance.authenticator.checkPermission(instance.authenticator.permCat.WRITE_ANSWER)) {
                instance.register.initSignInForm(true);
                return;
            }

            let ownAnswer = checkForOwnAnswer();
            if (ownAnswer) {
                try {
                    let rawAnswer = await instance.chatService.getAnswerForEditing(ownAnswer);
                    answerInput.val(rawAnswer.text);
                } catch (error) {
                    instance.log.error('Could not retrieve data form ProDuck Service!', error);
                    M.toast({html:instance.i18next.t('toasts.error')});
                    return;
                }
            }

            instance.utils.addWswgBar(answerInput);
            answerButton.addClass('disabled');
            editSection.insertBefore(quackContainer.find('.interaction-bar').last());
            instance.utils.adjustTextarea(answerInput, 1500, 72);
            instance.utils.adjustScrollBugInTextarea(answerInput, 'window');

            editSection.find('.js-cancel-answer-button').on('click', function(e) {
                e.preventDefault();
                e.stopImmediatePropagation();
                answerButton.removeClass('disabled');
                editSection.detach();
            });

            editSection.find('.js-save-answer-button').off().on('click', async function(e) {
                e.preventDefault();
                e.stopImmediatePropagation();
                instance.utils.addButtonLoader($(this));
                instance._sendAnswerToServer(questionId, ownAnswer, answerInput.val());
            });
        });
    }

    /**
     * Send an answer to the service and update the GUI according to the result of the operation.
     *
     * @param {*} questionId the question the answer is to
     * @param {*} answerId an answerId in case the answer actually is an update to a current one
     * @param {*} text the answer text
     *
     * @return a status indicating what has happend:
     *         -1: the answer could not be saved
     *          0: an existing answer has been updated
     *          1: a new answer has successfully been created
     */
    async _sendAnswerToServer(questionId, answerId, text) {
        const instance = this;

        if (!text || text.length == 0) {
            M.toast({html: instance.i18next.t('toasts.data_missing')});
            return;
        }

        let fieldValue = instance.utils.wrapParagraphs(text);

        let answerButton = $('#answer-question-bottom');
        const editSection = $('#answerEditSection');
        let userId = await instance.authenticator.getUser();

        try {
            if (answerId) {

                await instance.chatService.updateAnswer(answerId, fieldValue);

                let existingAnswerTextBlock = $(`.js-answer-bubble[data-user-id=${userId}] div[itemprop="text"]`);
                existingAnswerTextBlock.empty();
                existingAnswerTextBlock.append(fieldValue);

                editSection.detach();               

            } else {
                let response = await instance.chatService.createAnswer(questionId, fieldValue);
                let savedAnswer = {};
                savedAnswer.userId = userId;
                savedAnswer.text = fieldValue;
                savedAnswer.id = response.answerId;

                let answerBlock = await instance._createAnswerBlock(savedAnswer);

                editSection.after(answerBlock);
                editSection.detach();

                // The answer button has now to be changed so that it will update the just created answer
                // instead of creating a new one.
                answerButton.attr('data-i18n', 'questionsection.edit_answer');
                answerButton.localize();
                $('#quackSingleChatContainer').find(`.js-answer-bubble[data-user-id=${userId}]`).attr('data-answer-id', savedAnswer.id);
            }

            answerButton.removeClass('disabled');
            instance.utils.removeButtonLoader(editSection.find('.js-save-answer-button'));

            M.toast({html: instance.i18next.t('toasts.data_updated')});
            setTimeout(() => {
                instance.utils.initMaterializeInContentBlock();
            }, 1000);

        } catch(error) {
            instance.log.error('Could not send answer.', error);
            M.toast({html: instance.i18next.t('toasts.data_update_failed')});
        }
    }

    async _createAnswerBlock(answer) {
        const instance = this;
        let nickname = await instance.authenticator.getUserNickname();

        if (!nickname) {
            nickname = instance.i18next.t('general.you');
        }

        let html = `<div class="js-answer-bubble dialogue-summary narrow right-duck" data-answer-id="${answer.id}" data-user-id="${answer.userId}" itemscope itemtype="http://schema.org/Answer">`
            + '  <div class="summary-text">'
            + `    <div itemprop="text" class="question-hyperlink">${answer.text}</div>`
            + '  </div>'
            + '  <div itemprop="author" itemscope itemtype="http://schema.org/Person" class="author">'
            + `    <a class="prdk-link" href="/profile/${answer.userId}"><span  itemprop="name" class="author-name">${nickname}</span></a>`
            + '    <span itemprop="name" class="author-divider">&#10072;</span>'
            + '    <span itemprop="name" class="author-status">Experte</span>'
            + '  </div>'
            + '</div>'
            + '<div class="vertical-spacer medium"></div>';

        return $(html);
    }

    /**
     * Initialises the rating control for a specific answer.
     */
    _initAnswerRatingControl(answerId) {
        const instance = this;
        let permission = instance.authenticator.permCat.RATE_ANSWER;

        let ratingControl = new monstecLib.RatingControl(permission, true);

        ratingControl.getCurrentRating = async function() {
            try {
                if (!await instance.authenticator.checkPermission(permission)) {
                    instance.log.debug(`No permission ${permission}!`);
                    return undefined;
                }
            } catch (e) {
                instance.log.debug(`Permission check for ${permission} failed!`);
                return undefined;
            }

            return await instance.chatService.getAnswerRating(answerId);
        };

        ratingControl.onStarClicked = async function(value) {
            try {
                let result = await instance.chatService.addAnswerRating(answerId, value);
                ratingControl.setRating(result.value);
                $('#aggregatedRatingLabel' + answerId).text(Number(result.aggregatedRating).toFixed(1));
                $('#ratingCountLabel' + answerId).text(result.ratingCount);
                M.toast({html: instance.i18next.t('toasts.data_updated')});
            } catch (error) {
                M.toast({html: instance.i18next.t('toasts.error')});
                instance.log.error('Could not add answer rating: ',error);
            }
        }.bind(this);

        ratingControl.attachTo('answerRatingControl' + answerId);
    }

    /**
     * Actually adds the review controls.
     */
    async _addReviewCapabilites() {
        const instance = this;
        instance.utils.linkifyText();

        instance.log.debug('Initialising review controls!');

        $('#question').append('<div id="topicReviewControls"></div>');
        let questionId = $('#quackSingleChatContainer').attr('data-question-id');

        // helper function to update question properties
        function updateQuestion(labelId, property, value) {
            let params = { "id": questionId};
            params[property] = value;

            return instance.chatService.updateQuestion(params)
                .then(function () {
                    M.toast({html: instance.i18next.t('toasts.data_updated')});
                    setTimeout(() => {
                        instance.utils.initMaterializeInContentBlock();
                    }, 1000);
                    return true;
                }, function(reason) {
                    M.toast({html: instance.i18next.t('toasts.error')});
                    instance.log.error('update failed because ', reason);
                    return false;
                });
        }

        const topicEditField = new monstecLib.InPlaceEditField($('#questionTopicLabel'));

        // define actions buttons for the question title
        let topicButtonConfig = [
            {
                icon: 'edit',
                showLoader: true,
                func: function() {
                    topicEditField.onCommit = async function() {
                        let topic = topicEditField.getValue();
                        return updateQuestion('questionTopicLabel', 'topic', topic);
                    };

                    topicEditField.onGetContent = async function() {
                        let question = await instance.chatService.getQuestionForEditing(questionId);
                        return question.topic;
                    };

                    topicEditField.onBeforeRender = async function() {
                        let question = await instance.chatService.getPublicQuestionData(questionId);
                        return question.topic;
                    };

                    return topicEditField.replaceSource();
                }
            }
        ];

        let topicActionButtons = new monstecLib.ActionButtons(topicButtonConfig, 'left');
        topicActionButtons.attachTo('topicReviewControls');

        const textEditField = new monstecLib.InPlaceEditField($('#questionTextLabel'), true, 'window');

        // define actions buttons for the question details
        let textButtonConfig = [
            {
                icon: 'edit',
                showLoader: true,
                func: function() {
                    textEditField.onCommit = async function() {
                        let text = textEditField.getValue();
                        return updateQuestion('questionTextLabel', 'text', text);
                    };

                    textEditField.onGetContent = async function() {
                        let question = await instance.chatService.getQuestionForEditing(questionId);
                        return question.text;
                    };

                    textEditField.onBeforeRender = async function() {
                        let question = await instance.chatService.getPublicQuestionData(questionId);
                        return question.text.linkify('*');
                    };

                    return textEditField.replaceSource();
                }
            }
        ];

        let textActionButtons = new monstecLib.ActionButtons(textButtonConfig, 'left');
        textActionButtons.attachTo('textReviewControls');
        $('#textReviewControls').removeClass('hide');

        // a function for sending an update for an answer to the platform
        async function updateAnswer(text, answerId) {
            return instance.chatService.updateAnswer(answerId, text)
                .then(function () {
                    $(document).trigger("loader:off");
                    M.toast({html: instance.i18next.t('toasts.data_updated')});

                    setTimeout(() => {
                        instance.utils.initMaterializeInContentBlock();
                    }, 1000);

                    return true;
                }, function(reason) {
                    $(document).trigger("loader:off");
                    M.toast({html: instance.i18next.t('toasts.error')});
                    instance.log.error('update failed because ', reason);
                    return false;
                });
        }

        $('.js-answer-action-buttons').each(function(index, element) {

            if ($(element).children().length !== 0) {
                return false;
            }

            let answerId = $(element).attr('data-answer-id');

            const answerEditField = new monstecLib.InPlaceEditField($('#answerTextLabel' + answerId), true, 'window');

            let answerButtonConfig = [
                {
                    icon: 'edit',
                    showLoader: true,
                    func: function() {
                        answerEditField.onCommit = async function() {
                            let text = answerEditField.getValue();
                            $(document).trigger("loader:on");
                            return updateAnswer(text, answerId);
                        };

                        answerEditField.onGetContent = async function() {
                            let answer = await instance.chatService.getAnswerForEditing(answerId);
                            return answer.text;
                        };

                        answerEditField.onBeforeRender = async function() {
                            let answer = await instance.chatService.getPublicAnswerData(answerId);
                            return answer.text.linkify('*');
                        };

                        return answerEditField.replaceSource();
                    }
                }/*,
                    { icon: 'delete_forever', func: function() {
                        deleteAnswer(messageId);
                    }
                }*/
            ];

            let answerActionButtons = new monstecLib.ActionButtons(answerButtonConfig, 'left');
            answerActionButtons.attachTo('answerReviewControls' + answerId);
            $(element).removeClass('hide');
        });

        $(document).trigger("loader:off");
    }

    _showInternalAds() {

        const instance = this;

        let tagsArr = $('.quack-tag-block').data('tags');
        let targetElem = $('.answer').first();

        instance.advertisement.initialise(tagsArr, targetElem);

    }
}
