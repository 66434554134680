/* global monstecLib */
/* global M */
import ObjectList from '../common/objectlist';

/**
 * Article list for "Suggested Articles".
 */
export default class ArticleTemplateList extends ObjectList {
    constructor(currentUserId) {
        super();

        this.i18next = monstecLib.i18next;
        this.currentUserId = currentUserId;
        this.noResultsProperty = 'articlesection.no_templates';

        this.chatService = monstecLib.produckContext.chatService;

        this.log = new monstecLib.Log(1);
    }

    /**
     * Creates a DOM-element that will display a single article template in the list of article templates.
     *
     * @param {*} template the article template to create a list item for
     */
    _createListItem(template) {
        const instance = this;

        let itemHeaderId = instance.htmlId + 'TemplateItemHeader' + template.id;
        instance.utils.linkifyText();


        let templateItemHtml = '<li class="js-collapsible-item">'
          + '  <div class="js-collapsible-header collapsible-header object-list-item" id="' + itemHeaderId + '" data-template-id="' + template.id + '">'
          + '    <div class="article-info-header simple-vertical-content">'
          + '      <span id="' + itemHeaderId + 'Title" class="object-title current-content bold">' + template.name + '</span>'
          + '    </div>'
          + '    <div class="simple-horizontal-content">'
          + '      <div id="' + instance.htmlId + 'ArticleTemplateActionButtons' + template.id + '"></div>'
          + '    </div>'
          + '  </div>'
          + '  <div class="collapsible-body" data-template-id="' + template.id + '" data-user-id="' + template.userId + '">'
          + '    <div class="stats-wrapper">'
          + '      <span class="timestamp stats" title="' + instance.i18next.t("text.date_of_creation") + '">' + instance.utils.formatDate(template.creationTime) + '</span>'
          + '    </div>'
          + '    <div class="dialogue-summary narrow left-duck">'
          + '      <div class="summary">'
          + '        <div class="current-content flex-wrap">'
          + '          <div class="w100"><b>' + instance.i18next.t('articlesection.description_label') + '</b></div>'
          + '          <div class="vertical-spacer small"></div>'
          + '          <div class="article-hyperlink">' + ((template.description && template.description.length > 0) ? template.description : instance.i18next.t('articlesection.no_description')) + '</div>'
          + '        </div>'
          + '      </div>'
          + '    </div>'
          + '    <div class="js-template-tags edit-tags chips-initial"></div>'
          + '  </div>'
          + '</li>';

        const templateItem = $(templateItemHtml);
        templateItem.data('templateId', template.id);
        const articleItemBody = templateItem.find('.collapsible-body');

        instance._addEditableBehaviour(template, templateItem);

        if (template.criteria && template.criteria.length > 0) {
            const criteriaArea = $('<div id="' + instance.htmlId + 'TemplateItem' + template.id + 'Criteria"></div>');
            articleItemBody.find('.dialogue-summary').after(criteriaArea);

            template.criteria.forEach(function(critertion) {
                instance._appendCriterion(critertion, criteriaArea);
            });
        }

        var chipsElement = templateItem.find('.js-template-tags');
        if (template.tagString && template.tagString.length > 1) {
            template.tagString.split('|').forEach(function(chip) {
                chipsElement.append(`<div class="chip">${chip}</div>`);
            });
        }

        return templateItem;
    }

    _addEditableBehaviour(template, templateItem) {
        const instance = this;

        let headerButtonConfig = [];

        headerButtonConfig.push(
            {
                icon: 'edit',
                func: function() {
                    let templateModal = new monstecLib.ArticleTemplateModal('changeArticleTemplateModal' +  template.id);
                    templateModal.initialise();
                    templateModal.open(template);
                    templateModal.onSuccess = instance.updateView.bind(instance);
                }
            },
            {
                icon: 'delete_forever',
                func: async function() {
                    try {
                        let templateElementHeader = $('.js-collapsible-header[data-template-id=' + template.id +']');
                        templateElementHeader.css({'background-color': 'rgba(178,0,35,.6) !important'});

                        var headline = instance.i18next.t('articlesection.delete_template');
                        var text = instance.i18next.t('articlesection.delete_template_confirmation');
                        var optionOne = instance.i18next.t('text.yes_delete');
                        var optionTwo = instance.i18next.t('general.no');

                        //creates a suitable modal
                        instance.utils.createModal($('body'), headline, text, optionOne, optionTwo, 'deleteArticleTemplateModal');

                        // call delete function if delete gets confirmed
                        $('#deleteArticleTemplateModal .modal-close.option-one').on('click', function() {
                            instance.chatService.deleteArticleTemplate(template.id).then(function() {
                                templateElementHeader.parent('.js-collapsible-item').remove();
                            });
                            $('#deleteArticleTemplateModal').remove();
                        });

                        // reset all conditions if delete gets declined
                        $('#deleteArticleTemplateModal .modal-close.option-two').on('click', function() {
                            templateElementHeader.css({'background': ''});
                            $('#deleteArticleTemplateModal').remove();
                        });

                    } catch (errorResponse) {
                        instance.log.debug('Deleting article template failed!', errorResponse);
                        if (errorResponse.status == 400) {
                            M.toast({html: instance.i18next.t('toasts.data_invalid')});
                        } else {
                            M.toast({html: instance.i18next.t('toasts.error')});
                        }
                    }
                }
            }
        );

        let headerButtons = new monstecLib.ActionButtons(headerButtonConfig, 'left');
        headerButtons.attachTo(instance.htmlId + 'ArticleTemplateActionButtons' + template.id, templateItem);
    }

    _appendCriterion(criterion, criteriaArea) {
        let subCriteriaHtml = '';
        if (criterion.subCriteria && criterion.subCriteria.length > 0) {
            subCriteriaHtml += '<ul>';
            criterion.subCriteria.forEach(subCriterion => subCriteriaHtml += '<li>' + subCriterion.name + '</li>');
            subCriteriaHtml += '</ul>';
        }

        let criterionElement = $(
            '<div class="js-article-block dialogue-summary narrow center-duck-light">'
            + '  <div class="summary">'
            + '    <div class="current-content">'
            + '      <div class="question-hyperlink">'
            + '        <h2>' + criterion.name + '</h2>'
            +          subCriteriaHtml
            + '      </div>'
            + '    </div>'
            + '  </div>'
            + '</div>'
        );

        criteriaArea.append(criterionElement);
    }
}
