/* global monstecLib */

/**
 * Classes inheriting from this one must implement an attachTo-method.
 * Furthermore in that method the main container corresponding to the object must be stored in 'this.container' as well
 * as the HTML-ID of the container in 'this.htmlId.
 */
export default class ObjectList {
    constructor() {
        this.utils = monstecLib.produckContext.utils;
        this.authenticator = monstecLib.produckContext.authenticator;
        this.i18next = monstecLib.i18next;

        this.log = new monstecLib.Log(1);
    }

    attachTo(htmlId) {
        const instance = this;
        let container = $('#' + htmlId);
        instance.htmlId = htmlId;
        instance.container = container;

        instance.list = $('<ul class="collapsible"></ul>');
        container.append(instance.list);

        instance.pagination = $('<ul class="pagination"></ul>');
        container.append(instance.pagination);
    }

    setDataProvider(providerFunction) {
        this.dataProvider = providerFunction;
    }

    setFilter(filter) {
        if (!filter) return;
        if (!filter.page) filter.page = 1;
        this.currentFilter = filter;
    }

    resetFilter() {
        this.currentFilter = undefined;
    }

    async updateView(page) {
        const instance = this;
        instance.log.debug('Updating view of object list.');

        if (!instance.currentFilter) {
            instance.currentFilter = {};
        }

        instance.currentFilter.page = (!!page && instance.utils.isNumber(page)) ? page : 1;
        $(document).trigger("loader:on", ['', 'transparent', document.getElementById(instance.htmlId)]);

        try {
            let data = await instance.dataProvider(instance.currentFilter);
            instance.pagination.empty();
            instance.list.empty();
            instance.container.find('.no-result-indicator').remove();

            instance.log.debug('List data', data);

            if (!data || data.numElements < 1 || data.length < 1 || !data.result) {
                instance.pagination.addClass('hide');
                instance.list.addClass('hide');
                let noResultsHint = (!!instance.noResultsProperty) ? instance.i18next.t(instance.noResultsProperty) : instance.i18next.t('general.no_results');
                let tutorialHint = (!!instance.noResultsProperty) ? instance.tutorialProperty : "";

                instance.container.append(`<div class="no-result-indicator"><h3>${noResultsHint}</h3><a target="_blank" href="${tutorialHint.link}" class="prdk-link fs-16">${instance.i18next.t(tutorialHint.text)}</a></div>`);

                instance.container.localize();
            } else {
                instance.pagination.removeClass('hide');
                instance.list.removeClass('hide');


                instance.utils.buildPagination(instance.pagination, data.numPages, data.page, instance.updateView.bind(instance));
                
                data.result.forEach(dataObject => {
                    let isEditable = (instance._determineIfItemEditable) ? instance._determineIfItemEditable(dataObject) : true;

                    const user = data.usersData ? data.usersData.find(user => user.id === dataObject.userId) : false;     
                    dataObject.nickname = user ? user.nickname : "Unknown Author";   

                    let listItem = this._createListItem(dataObject, isEditable);
                    instance.list.append(listItem);
                    if (instance._initTagsInputOnItem) instance._initTagsInputOnItem(listItem, dataObject, isEditable);
                    if (instance._initLanguageSelectOnItem) instance._initLanguageSelectOnItem(listItem, dataObject, isEditable);
                });
            }
        } catch (error) {
            instance.log.error('Could not create object list.', error);
        }

        $(document).trigger("loader:off");
    }
}
