// load the class definitions
import Setup from './setup.js';
import i18next from './localisation.js';
import AboutPage from './about.js';
import AbstractOfferSection from './xpert/abstractoffersection.js';
import AbstractQuackPage from './pages/quackviewpage.js';
import ArticleQuackPage from './pages/articlequackviewpage.js';
import ActionButtons from './tools/actionbuttons.js';
import ArticleForm from './article/articleform.js';
import ArticleList from './article/articlelist.js';
import ArticleRequestForm from './article/articlerequestform.js';
import ArticleSuggestionList from './article/articlesuggestionlist.js';
import ArticleTemplateList from './article/articletemplatelist.js';
import ArticleRequestList from './article/articlerequestlist.js';
import ArticleSection from './article/articlesection.js';
import ArticleTemplateModal from './article/articletemplatemodal.js';
import Authenticator from './authenticator.js';
import BillingServiceAccess from './services/billingserviceaccess.js';
import Canvas from './canvas.js';
import CatalogueServiceAccess from './services/catalogueserviceaccess.js';
import ChatClient from './comm/chatclient.js';
import ChatFileUpload from './fileupload.js';
import ChatQuackPage from './pages/chatquackviewpage.js';
import ChatServiceAccess from './services/chatserviceaccess.js';
import ChatSupport from './chatsupport.js';
import Constants from './constants.js';
import ContactForm from './init-scripts/contactform.js';
import Cookie from './cookie.js';
import Credentials from './credentials.js';
import DealsHandler from './dealshandler.js';
import ExpertChatsView from './xpert/expertchats.js';
import IdentityServiceAccess from './services/identityserviceaccess.js';
import InPlaceEditField from './tools/inplaceeditfield.js';
import InternalAdvertisement from './tools/produck-ads.js'; 
import IFrameMessageDispatcher from './comm/iframedispatcher.js';
import LazyLoad from './lazyload.js';
import Log from './tools/log.js';
import NavBarControl from './tools/navbar.js';
import RatingControl from './tools/rating.js';
import PasswordReset from './pwreset.js';
import PortalSupport from './pages/portalsupport.js';
import PredefinedText from './pre_text.js';
import ProductHandler from './producthandler.js';
import ProductManagementSection from './xpert/productsection.js';
import ProductOfferContainer from './user/offercontainer.js';
import ProfileDataHandler from './profiledatahandler.js';
import ProfilePage from './pages/profilepage.js';
import ProviderChatsView from './xpert/provchats.js';
import ProviderUpgradePage from './pages/upgradetoprovpage.js';
import PublicChatsView from './public/publicchats.js';
import QuackCommentSection from './publicquacks/quackcommentsection.js';
import QuackListPage from './pages/quacklistpage.js';
import QuestionForm from './question/questionform.js';
import QuestionList from './question/questionlist.js';
import QuestionQuackPage from './pages/questionquackviewpage.js';
import QuestionSection from './question/questionsection.js';
import Register from './reg_basic.js';
import RestClientBuilder from './servicecommon.js';
import SalesHandler from './sales.js';
import ServiceHealthCheckPage from './pages/servicehealthcheckpage.js';
import ServiceSupport from './services/servicesupport.js';
import SettingsSection from './xpert/settingssection.js';
import UserChat from './pages/indexpage.js';
import Utils from './tools/utils.js';
import UserChatsView from './user/userchats.js';
import UserPortal from './pages/userportal.js';
import WebSocketClient from './comm/websocketclient.js';
import wvBridge from './webview_bridge.js';
import WysiwygControl from './tools/wysiwyg.js';
import Wizard from './tools/wizard.js';
import Xpert from './xpert.js';

// Central context which will hold various utility objects example for access to services
// so that they only have to be created once but be used almost anywhere.
const produckContext = {'version':'1.0.0'};

produckContext.setBean = function(name, bean) {
    if (!!bean) {
        this[name] = bean;
    }
};

produckContext.initialiseRegisteredBeans = function() {
    for (let beanName in this) {
        if (!this.hasOwnProperty(beanName)) continue;
        let bean = this[beanName];
        if (bean.initialiseContextBeans) {
            this[beanName].initialiseContextBeans();
        }
    }
};

// Central registry for javascript objects that represent or are associated with certain parts
// of the web page. This registry is intended to help when one component wants to communicate
// to another.
// Components should be registered here using the HTML ID of their corresponding HTML element
// as key.
const pageComponentRegistry = {};

pageComponentRegistry.register = function(key, component) {
    this[key] = component;
};

pageComponentRegistry.getComponent = function(key) {
    return this[key];
};

// export objects or classes
export {
    produckContext,
    pageComponentRegistry,
    i18next,
    Constants,
    // general classes
    Setup,
    Utils,
    Log,
    Cookie,
    Authenticator,
    NavBarControl,
    RestClientBuilder,
    Wizard,
    wvBridge,
    WysiwygControl,
    // service access classes
    ServiceSupport,
    BillingServiceAccess,
    ChatServiceAccess,
    CatalogueServiceAccess,
    IdentityServiceAccess,
    // other business logic classes
    ChatClient,
    WebSocketClient,
    DealsHandler,
    ProductHandler,
    ProfileDataHandler,
    ContactForm,
    // GUI page classes
    AboutPage,
    AbstractQuackPage,
    ArticleQuackPage,
    ChatQuackPage,
    QuackListPage,
    QuestionQuackPage,
    ProviderUpgradePage,
    ProfilePage,
    PortalSupport,
    ServiceHealthCheckPage,
    // GUI component classes
    AbstractOfferSection,
    ActionButtons,
    ArticleForm,
    ArticleList,
    ArticleRequestForm,
    ArticleSuggestionList,
    ArticleTemplateList,
    ArticleRequestList,
    ArticleSection,
    ArticleTemplateModal,
    Canvas,
    ChatFileUpload,
    ChatSupport,
    Credentials,
    ExpertChatsView,
    IFrameMessageDispatcher,
    InPlaceEditField,
    InternalAdvertisement,
    LazyLoad,
    PasswordReset,
    PredefinedText,
    ProviderChatsView,
    ProductOfferContainer,
    ProductManagementSection,
    PublicChatsView,
    QuackCommentSection,
    QuestionForm,
    QuestionList,
    QuestionSection,
    RatingControl,
    Register,
    SalesHandler,
    SettingsSection,
    UserChatsView,
    UserPortal,
    UserChat,
    Xpert
};
