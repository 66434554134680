/* global monstecLib */
/* global M */
import i18next from "../localisation.js";

/**
 * Classes inheriting from this one must implement an attachTo-method.
 * Furthermore in that method the main container corresponding to the object must be stored in 'this.container' as well
 * as the HTML-ID of the container in 'this.htmlId.
 */
export default class ObjectForm {
    constructor() {
        this.utils = monstecLib.produckContext.utils;

        this.log = new monstecLib.Log(1);
    }

    /**
     * Shows the form to the user if it is not visible yet.
     *
     * @param {*} emptyFormOnOpen if true all fields are emptied before the form is shown,
     *                            given _emptyOfferForm is defined
     */
    open(emptyFormOnOpen) {
        const instance = this;

        if (emptyFormOnOpen && instance._emptyOfferForm) {
            instance._emptyOfferForm();
        }

        instance.container.addClass('active');
    }

    /**
     * Hides the form from the user.
     *
     * @param {*} emptyFormOnClose if true all fields are emptied after the form has been closed,
     *                             given _emptyOfferForm is defined
     */
    close(emptyFormOnClose) {
        const instance = this;
        instance.container.removeClass('active');

        if (emptyFormOnClose && instance._emptyOfferForm) {
            instance._emptyOfferForm();
        }
    }

    _processFieldErrorsFromServer(response) {
        let errors = JSON.parse(response.responseText).errors;
        this. _processFieldErrors(errors);
    }

    _processFieldErrors(errors) {
        let instance = this;
        let container = this.container;
        let htmlId = this.htmlId;

        instance.log.debug('Processing the following errors: ', errors);

        for (let item in errors) {
            if(!errors.hasOwnProperty(item)) continue;
            let fieldId = instance.utils.capitaliseFirstLetter(item);
            let field = container.find('#' + htmlId + fieldId + 'Input');
            let helperText = field.siblings('.js-validation-info');

            console.log(field, fieldId, errors[item]);

            switch (errors[item]) {
                case 'MISSING':
                case 'INVALID':
                    field.removeClass('valid');
                    field.addClass('invalid');

                    if (helperText) {
                        helperText.attr('data-error', i18next.t('general.validation_info_' + errors[item].toLowerCase()));
                    }

                    break;

                default:
                    instance.log.error('Encountered unknown validation error type: ', errors[item]);
            }
        }
    }

    _initLanguageSelect(selectField) {
        selectField.attr('id', this.htmlId + 'LanguageInput');
        selectField.siblings('label').attr('for', selectField.attr('id'));

        var lngOptions = {
            'classes': 'edit-language'
        };

        return M.FormSelect.init(selectField, lngOptions)[0];
    }

    /**
     * Handles the click on the send button, by calling the externally defined send-action and processing the
     * return value of that action.
     */
    async _onSendButtonClicked(e) {
        const instance = this;
        e.preventDefault();
        e.stopImmediatePropagation();

        if (instance.sendAction) {
            try {
                let object = instance._createObjectFromFields();
                let response = await instance.sendAction(object);

                if (response)  {
                    let status = response.status;

                    if (status == 'ok' || status == 200 || status == 201) {
                        instance._emptyForm();
                        M.toast({html: instance.i18next.t('toasts.data_updated')});

                        if (instance.onSendingSuccessful) {
                            instance.onSendingSuccessful(response);
                        }

                    } else if (status == 400) {
                        instance._processFieldErrorsFromServer(response);
                    }
                }

                $(document).trigger("loader:off");

            } catch (error) {
                $(document).trigger("loader:off");
                M.toast({html: instance.i18next.t('toasts.error')});
                instance.log.error('Send action yielded an unexpected error:', error);
            }
        } else {
            instance.log.warn('No sendAction defined!');
            $(document).trigger("loader:off");
        }
    }
}
